import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from '../Components/Navbar/Navbar';
import { Profile } from 'iconsax-react';
import ScrollToTop from '../Helper/ScrollToTop';
import Home from '../pages/Evaluator/Home';
import { notificationShow } from '../redux/Slice/EvaluatorSlice';
import { useDispatch } from 'react-redux';
import Reports from '../pages/CommunityScreening/Reports';

const EvaluatorRoute = () => {
    const dispatch = useDispatch();

    const routes = [{
        route: [
            {
                url: "/",
                name: "Dashboard"
            },
            {
                url: "/reports",
                name: "Reports"
            },
        ]
    },
    {
        button: [
            {
                url: "/",
                name: "logout",
                icon: <Profile size="20" />
            }
        ]
    }]

    useEffect(() => {
        dispatch(notificationShow([]));
    }, []);

    return (
        <>
            <Navbar routes={routes} />
            <ScrollToTop>
                <div className="position-relative  w-100 vh-100 container-xxl" style={{ top: "92px" }}>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/reports" element={<Reports />} />
                    </Routes>
                </div>
            </ScrollToTop>
        </>

    )
}

export default EvaluatorRoute