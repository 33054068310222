import React, { useState } from 'react';
import userProfile from '../../constants/assests/images/man.png';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { loggedUserDetails, login, role, username } from '../../redux/Slice/LoginSlice';
import { useNavigate } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import store from '../../redux/Store/Store';
import { toast } from 'react-hot-toast';

function ProfileModal({ routes, toggles }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const CustomerAdminDetails = useSelector((state) => state.login.loggedUserDetails);

    const logout = async () => {
        try {
            await Auth.signOut();
            dispatch(login(false));
            dispatch(loggedUserDetails(undefined));
            dispatch(role('guest'));
            dispatch(username(undefined));
            navigate('/');
        } catch (error) {
            toast.error("Something went wrong, while logging out --- 2")
        }
    };

    return (
        <div className="user d-flex justify-content-between align-items-center gap-1">
            <div className="user-img shadow-none py-2 px-0  border-0 bg-transparent btn-secondary">
                <img
                    className="round-image"
                    src={CustomerAdminDetails?.logo ? CustomerAdminDetails?.logo : userProfile} alt="User_Profile"
                />
            </div>
            {
                routes[1]?.button?.map((item, i) =>
                    <div className='border-0 outline-none d-flex align-items-center name border-start ps-3 logout-first' key={i}>
                        <div className='text-dark text-decoration-none d-flex align-items-center gap-3  dropdown-item p-0' onClick={() => logout()}>
                            <span className="rounded-5 py-2 mb-2 px-3 bg-base-color text-white title-font-500 text-capitalize" role="button">{item.name}</span>
                        </div>
                    </div>)
            }

        </div>
    );
}


export default ProfileModal;