import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import DentalScreening from "./DentalScreening/DentalScreening";
import SleepApneaScreening from "./SleepApneaScreening/SleepApneaScreening";

const PatientCollectionApp = () => {

  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      <Nav tabs className="all-tabs" style={{ width: "100%", display:'flex', flexDirection:'row', flexFlow:'wrap', flexWrap:'nowrap' }}>
        <NavItem className="custom-nav-item">
          <NavLink
            className={activeTab === "1" ? "active-tab fs-xs" : "non-active-tab fs-xs"}
            onClick={() => toggleTab("1")}
          >
            Dental Screening
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "2" ? "active-tab fs-xs" : "non-active-tab fs-xs"}
            onClick={() => toggleTab("2")}
          >
            Sleep Apnea Screening
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <>
            {activeTab === "1" && (
              <>
                <DentalScreening />
              </>
            )}
          </>
        </TabPane>

        <TabPane tabId="2">
          <>
            {activeTab === "2" && (
              <>
                <SleepApneaScreening />
              </>
            )}
          </>
        </TabPane>
      </TabContent>
    </>
  );
};

export default PatientCollectionApp;