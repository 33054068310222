import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selectedPatientType: { value: 'patient', label: 'Patient' },
    selectedPatient: undefined,
    selectedStudent: undefined,
    patientFormDetails: undefined,
    selectedImportStudent: [],
    sleepApneaSelectedPatient:undefined
}

export const CollectionSlice = createSlice({
    name: 'collection',
    initialState,
    reducers: {
        selectPatientType: (state, action) => {
            state.selectedPatientType = action.payload
        },
        selectPatient: (state, action) => {
            state.selectedPatient = action.payload
        },
        selectStudent: (state, action) => {
            state.selectedStudent = action.payload
        },
        setPatientFormDetails: (state, action) => {
            state.patientFormDetails = action.payload
        },
        setSelectedImportStudent: (state, action) => {
            state.selectedImportStudent = action.payload
        },
        sleepApneaSelectPatient:(state,action) => {
            state.sleepApneaSelectedPatient = action.payload
        }
    },
})

export const { selectPatientType, selectPatient, selectStudent, setPatientFormDetails, setSelectedImportStudent,sleepApneaSelectPatient } = CollectionSlice.actions

export default CollectionSlice.reducer;