import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../constants/assests/images/virtuscreenlogo.png";
import ProfileModal from "./ProfileModal";
import "./Navbar.css";
import { useEffect, useState } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CloseCircle, HambergerMenu, NotificationBing } from "iconsax-react";
import { Profile, Notification } from "iconsax-react";
import { Auth } from "aws-amplify";
import {
  loggedUserDetails,
  login,
  role,
  username,
} from "../../redux/Slice/LoginSlice";
import HeaderTitle from "../ProfileHeader/HeaderTitle";
import moment from "moment-timezone";
import {
  selectEvaluationStatus,
  selectPatientFromNotification,
  setSelectedNotificationPatientType,
} from "../../redux/Slice/EvaluatorSlice";
import { useMemo } from "react";
import { toast } from "react-hot-toast";

const Navbar = ({ routes }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [actived, setactived] = useState(0);
  const [serviceTypes, setserviceTypes] = useState("");
  const roleIs = useSelector((state) => state.login.roleIs);
  const loggedusername = useSelector((state) => state.login.username);
  const CustomerAdminDetails = useSelector(
    (state) => state.login.loggedUserDetails
  );
  const notificationShowList = useSelector(
    (state) => state.evaluator.notificationShowList
  );

  const options = [
    {
      value: "clinic_community_screening",
      label: "Clinic Community Screening",
    },
    {
      value: "clinic_individual_screening",
      label: "Clinic Individual Screening",
    },
    {
      value: "clinic_integrated_screening",
      label: "Clinic Integrated Screening",
    },
    { value: "community_screening", label: "Community Screening" },
  ];

  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);

  const toggleSubMenu = () => {
    setIsSubMenuVisible(!isSubMenuVisible);
  };

  const logout = async () => {
    try {
      await Auth.signOut();
      dispatch(login(false));
      dispatch(loggedUserDetails(undefined));
      dispatch(role("guest"));
      dispatch(username(undefined));
      localStorage.clear();
      navigate("/");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong while logout --- 1");
    }
  };

  const dispatchThings = (data) => {
    dispatch(setSelectedNotificationPatientType(data?.patient?.type));
    dispatch(selectPatientFromNotification(data?.patient_number));
    dispatch(selectEvaluationStatus({ value: "screened", label: "Screened" }));
  };

  const checkServiceType = (serviceType) => {
    if (serviceType) {
      const newServiceTypeLabel = options.filter(
        (servicettype) => servicettype.value == serviceType
      )[0]?.label;
      setserviceTypes(newServiceTypeLabel);
    }
  };

  const filterData = () => {
    if (roleIs == "customeradmin") {
      return checkServiceType(CustomerAdminDetails?.service_type);
    } else if (roleIs == "branchadmin") {
      return checkServiceType(CustomerAdminDetails?.customer?.service_type);
    } else if (roleIs == "evaluator") {
      return checkServiceType(
        CustomerAdminDetails?.branch?.customer?.service_type
      );
    } else if (roleIs == "nurse") {
      return checkServiceType(
        CustomerAdminDetails?.branch?.customer?.service_type
      );
    } else if (roleIs == "community_screening") {
      return checkServiceType(CustomerAdminDetails?.service_type);
    }
  };
  React.useEffect(() => {
    if (CustomerAdminDetails) {
      filterData();
    }
  }, [CustomerAdminDetails]);
  useEffect(() => {
    const windowResize = window.addEventListener("resize", () => {
      if (window.innerWidth > 768) {
        setToggle(false);
      }
    });
  }, []);

  return (
    <>
      <nav className="navbar_main nbp">
        <NavLink className="logo-fit" to="/">
          <img src={logo} alt="login form" className="img-fluid" />
        </NavLink>
        {roleIs !== "guest" ? (
          <HeaderTitle title={routes[0].route[actived].name} />
        ) : (
          ""
        )}
        <div className="d-flex gap-3 gap-md-4 align-items-center">
          {roleIs == "evaluator" && (
            <button
              type="button"
              className="notification_box border-0 bg-transparent d-lg-none"
              style={{ outline: "none" }}
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasResponsive"
              aria-controls="offcanvasResponsive"
            >
              <Notification size="32" color="#000" />
              <div className="badge_icon">
                <span className="badge bg-danger rounded-pill p-1 badge_size">
                  {notificationShowList?.length}
                </span>
              </div>
            </button>
          )}
          <button
            onClick={() => setToggle(!toggle)}
            className="d-block d-lg-none border-0 shadow-none bg-transparent transitions-easy"
            style={{ outline: "none" }}
          >
            {toggle ? (
              <i
                className="bx bx-x text-base-color fw-medium transitions-easy"
                style={{ fontSize: "3.5rem" }}
              ></i>
            ) : (
              <HambergerMenu
                size="45"
                className="text-base-color transitions-easy"
              />
            )}
          </button>
        </div>
        {toggle ? (
          <>
            <div
              className="background-dims_navbar"
              onClick={() => setToggle(!toggle)}
            ></div>
            <div className="mobile-res">
              {roleIs !== "guest" ? (
                <div className="d-flex justify-content-center align-items-center  flex-column border-bottom py-2 gap-2">
                  <ProfileModal routes={routes} toggles={toggle} />
                  <div className="ps-1 text-center">
                    {roleIs == "branchadmin" ? (
                      <h6
                        className="title-font-600 text-capitalize fs-6 mb-0"
                        style={{ fontSize: ".8rem" }}
                      >
                        {CustomerAdminDetails?.branch_admin_user_id}
                      </h6>
                    ) : (
                      <h6
                        className="title-font-600 fs-6 mb-0"
                        style={{ fontSize: ".8rem" }}
                      >
                        {loggedusername}
                      </h6>
                    )}
                    <h6
                      className="mb-0 d-flex align-items-center justify-content-center fs-6  "
                      style={{ fontSize: ".8rem" }}
                    >
                      <i
                        className="bx bx-radio-circle-marked bx-flashing fs-5 text-base-green mb-0"
                        style={{ fontSize: ".8rem" }}
                      ></i>{" "}
                      <span className="title-font-600 text-base-gray fs-6">
                        {roleIs}
                      </span>
                    </h6>
                    {/* <h6
                      className="title-font-600 mb-0 text-capitalize text-end"
                      style={{ fontSize: ".8rem" }}
                    >
                      Hello
                    </h6> */}
                  </div>
                </div>
              ) : (
                ""
              )}
              <ul className="my-3 d-flex gap-2 flex-column p-0 nav_links position-relative list-unstyled">
                {routes[0]?.route?.map((item, i) => {
					const subRoutes = item.subRoutes || []; // Ensure subRoutes is an array
                const isActive =
                  subRoutes.length > 0 &&
                  subRoutes.some((subItem) => {
                    return window.location.pathname === subItem.url;
                  });
                  {
                    /* return (
                        <NavLink to={item.url} className="fs-6 title-font-600  text-uppercase navbar_line" onClick={() => [setToggle(!toggle), setactived(i)]} key={i} >{item.name}</NavLink>
                      ) */
                  }

				  return (
                  <li key={i}>
                    {item.subRoutes ? (
                      <div
                        // className="fs-6 title-font-600  text-uppercase navbar_line"
                        className={`fs-6 title-font-600 text-uppercase navbar_line submenu-toggle ${
                          isActive ? "active" : "" 
                        }`}
                        onMouseEnter={toggleSubMenu}
                        onMouseLeave={toggleSubMenu}
                      >
                        {item.name}
                        {isSubMenuVisible && (
                          <ul className="m-0 d-flex flex-column nav_links list-unstyled submenu rounded-3 px-3 py-2">
                            {item.subRoutes.map((subItem, subIndex) => (
                              <li key={subIndex}>
                                <NavLink
                                  to={subItem.url}
                                  // className="fs-6 title-font-600  text-uppercase navbar_line"
                                  className={`fs-6 title-font-600 text-uppercase navbar_line ${
                                    window.location.pathname === subItem.url
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  {subItem.name}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    ) : (
                      <NavLink
                        to={item.url}
                        // activeClassName="active"
                        // className="fs-6 title-font-600  text-uppercase navbar_line"
                        className={`fs-6 title-font-600 text-uppercase navbar_line ${
                          window.location.pathname === item.url ? "active" : ""
                        }`}
                        key={i}
                      >
                        {item.name}
                      </NavLink>
                    )}
                  </li>
                );
                })}
              </ul>
              {roleIs !== "guest" ? (
                <div
                  className="text-dark text-decoration-none d-flex align-items-center gap-3  dropdown-item p-0 d-flex justify-content-around"
                  onClick={() => logout()}
                >
                  <span
                    className="rounded-5 py-2 mb-2 px-3 bg-base-color text-white title-font-500 text-capitalize"
                    role="button"
                  >
                    Logout
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          <div className="menu  align-items-center gap-4 d-none d-lg-flex">
            <ul className="m-0 d-flex gap-4 nav_links list-unstyled">
              {routes[0]?.route?.map((item, i) => {
                const subRoutes = item.subRoutes || []; // Ensure subRoutes is an array
                const isActive =
                  subRoutes.length > 0 &&
                  subRoutes.some((subItem) => {
                    return window.location.pathname === subItem.url;
                  });
                {
                  /* return (
                      <NavLink to={item.url} className="fs-6 title-font-600  text-uppercase navbar_line" key={i} >{item.name}</NavLink>
                    ) */
                }
                return (
                  <li key={i}>
                    {item.subRoutes ? (
                      <div
                        // className="fs-6 title-font-600  text-uppercase navbar_line"
                        className={`fs-6 title-font-600 text-uppercase navbar_line submenu-toggle ${
                          isActive ? "active" : "" 
                        }`}
                        onMouseEnter={toggleSubMenu}
                        onMouseLeave={toggleSubMenu}
                      >
                        {item.name}
                        {isSubMenuVisible && (
                          <ul className="m-0 d-flex flex-column nav_links list-unstyled submenu rounded-3 px-3 py-2">
                            {item.subRoutes.map((subItem, subIndex) => (
                              <li key={subIndex}>
                                <NavLink
                                  to={subItem.url}
                                  // className="fs-6 title-font-600  text-uppercase navbar_line"
                                  className={`fs-6 title-font-600 text-uppercase navbar_line ${
                                    window.location.pathname === subItem.url
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  {subItem.name}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    ) : (
                      <NavLink
                        to={item.url}
                        // activeClassName="active"
                        // className="fs-6 title-font-600  text-uppercase navbar_line"
                        className={`fs-6 title-font-600 text-uppercase navbar_line ${
                          window.location.pathname === item.url ? "active" : ""
                        }`}
                        key={i}
                      >
                        {item.name}
                      </NavLink>
                    )}
                  </li>
                );
              })}

              {roleIs == "evaluator" && (
                <button
                  type="button"
                  className="notification_box border-0 bg-transparent"
                  style={{ outline: "none" }}
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasResponsive"
                  aria-controls="offcanvasResponsive"
                >
                  <Notification size="32" color="#000" />
                  <div className="badge_icon">
                    <span
                      className={
                        notificationShowList?.length > 9
                          ? "badge bg-danger rounded-pill p-1 badge_size"
                          : "badge bg-danger rounded-pill badge_size"
                      }
                    >
                      {notificationShowList?.length}
                    </span>
                  </div>
                </button>
              )}
            </ul>
            {roleIs !== "guest" ? (
              <div className="user d-flex justify-content-between align-items-center gap-1">
                <div className="name border-start ps-3">
                  {roleIs == "branchadmin" ? (
                    <h6
                      className="title-font-600 text-capitalize mb-0 text-end"
                      style={{ fontSize: ".8rem" }}
                    >
                      {CustomerAdminDetails?.branch_admin_user_id}
                    </h6>
                  ) : (
                    <h6
                      className="title-font-600 mb-0 text-capitalize text-end"
                      style={{ fontSize: ".8rem" }}
                    >
                      {loggedusername}
                    </h6>
                  )}
                  <h6
                    className="mb-0 d-flex align-items-center justify-content-end fs-6  "
                    style={{ fontSize: ".8rem" }}
                  >
                    <i className="bx bx-radio-circle-marked bx-flashing fs-5 text-base-green"></i>
                    <span
                      className="title-font-600 text-base-gray text-capitalize"
                      style={{ fontSize: ".8rem" }}
                    >
                      {roleIs}
                    </span>
                  </h6>
                  <h6
                    className="title-font-600 mb-0 text-capitalize text-end"
                    style={{ fontSize: ".8rem" }}
                  >
                    {serviceTypes}
                  </h6>
                </div>
                <ProfileModal routes={routes} />
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </nav>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasResponsive"
        aria-labelledby="offcanvasResponsiveLabel"
        style={{ backgroundColor: "#f2f2f2" }}
      >
        <div className="offcanvas-header bg-base-color2">
          <h5
            className="base-font-600 fs-5 mb-0 text-white"
            id="offcanvasResponsiveLabel"
          >
            New Screening Notification
          </h5>
          <button
            type="button"
            className="btn-close fs-6 text-white shadow-none"
            data-bs-dismiss="offcanvas"
            data-bs-target="#offcanvasResponsive"
            aria-label="Close"
          ></button>
        </div>
        <div className="p-3 px-2  overflow-scroll overflow-x-hidden overflow-y-scroll">
          {notificationShowList?.map((data, i) => {
            return (
              <div
                key={i}
                className="alert border-bottom rounded-0 align-items-center d-flex gap-3 shadow border bg-white rounded-2"
                role="button"
                data-bs-dismiss="offcanvas"
                data-bs-target="#offcanvasResponsive"
                onClick={() => dispatchThings(data)}
              >
                <div className="h-25 p-2 rounded-circle bg-info">
                  <NotificationBing size="24" className="text-white" />
                </div>
                <div className="w-100">
                  <label className="base-font-600 text-capitalize">
                    {data?.title}
                  </label>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="text-capitalize base-font-500">
                      Status : {data?.evaluation_status}
                    </span>
                    {/* <small className='base-font-400'>{moment(data?.created_at,).tz("America/Chicago").fromNow()}</small>÷ */}
                    {/* <small className='base-font-400'>{moment.tz(data?.created_at, 'America/Chicago').tz('America/Chicago').startOf('hour').from()}</small> */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Navbar;
