import React, { useState } from "react";
import "react-calendar/dist/Calendar.css";
import { useForm, Controller } from "react-hook-form";
import { Form, Label, Card } from "reactstrap";
import "./CalendarDashboard.css";
import moment from "moment";
import { useEffect } from "react";
import ErrorComponents from "../ErrorComponents/ErrorComponents";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import {
  ApiEndPoint,
  server_name,
} from "../../constants/apiEndPoints/endPoints";
import { warningAlert, successAlert } from "../Alerts/Alerts";

import DatePicker, { Calendar } from "react-multi-date-picker";
import Navbar from "../Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
export const CalendarDashboard = () => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const search = window.location.search;
  const params = new URLSearchParams(search);
 const nurseID= params.get("nurse_id")

  const today = new Date();

  const [Loader, setLoader] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [appointmentData, setAppointmentData] = useState();
  const [values, setValues] = useState([today]);
  const [nurseDetails, setNurseDetails] = useState();
  const navigate = useNavigate();
  const [tempData, setTempData] = useState([]);

  const routes = [
    {
      route: [
        {
          url: "",
          name: "Appointment Schedule for Dental Screening",
        },
        {
          url: "/",
          name: "Login",
        },
      ],
    },
  ];

  const CancelButton = () => {
    reset();
  };

  const onSubmit = async (data) => {
    setLoader(true);
    let selectedDate = [];

    data.date.map((day) => {
      selectedDate.push(`${day.year}-${day.month.number}-${day.day}`);
    });
    data.multiple_date = selectedDate;
    data.date = "";
    setBookings((prevBooking) => [...prevBooking, ...selectedDate]);

    data.multiple_date.map((item)=>{
      const td = {
        multdate : item,
        school_name: data.school_name,
        school_address:data.school_address,
        num_of_brushes:data.num_of_brushes
      }
      setTempData((prev)=>[...prev, td])
    })


    const payload = {
      nurse_id: nurseDetails ? nurseDetails.id : "",
      branch_id: nurseDetails ? nurseDetails?.branch?.id : "",
      server: server_name,
      ...data,
    };

    await axios
      .post(ApiEndPoint.schoolScheduleAppointment, payload)
      .then((res) => {
        if (res.data.status === "success") {
          swal({
            title: "Appointment Created Successfully",
            icon: "success",
            buttons: {
              Done: "Done",
              AddMore: "Add More",
            },
          }).then((value) => {
            switch (value) {
              case "Done":
                navigate("/welcome-screen");
                setTempData([]);
                break;
              case "AddMore":
                break;
              default:
            }
          });
          setLoader(false);
          reset();
          getAppointment();
        } else if (res.data.status === "failed") {
          warningAlert(res.data.message);
          setLoader(false);
          reset();
        }
      });
  };

  const getAppointment = async () => {
    await axios
      .get(`${ApiEndPoint.getAllCustomerAppointment}${nurseDetails?.branch?.customer?.id}/`)
      .then((res) => {
        setAppointmentData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getNurseDetails = async () => {
    const payloadNurse = {
      nurse_id: params.get("nurse_id"),
    };

    await axios
      .post(ApiEndPoint.encryptedNurseDetails, payloadNurse)
      .then((res) => {
        if ((res.data.status = "success")) {
          setNurseDetails(res.data.nurseDetails);
        }
      });
  };

  useEffect(() => {
    if(nurseID){
      getNurseDetails();
    }
  }, [nurseID]);

  useEffect(() => {
    if (nurseDetails) {
      getAppointment();
    }
  }, [nurseDetails]);

  useEffect(() => {
    if (appointmentData) {
      appointmentData.map((item) => {
        const bookedDates = item.date.split("T")[0];
        setBookings((prevBookings) => [...prevBookings, bookedDates]);
      });
    }
  }, [appointmentData]);

  return (
    <>
      <Navbar routes={routes} />
      {
        nurseDetails && <>
        <div className="container pt-5">
        <div className="p-4 mt-5 bg-base-color2 rounded-1 border-bottom ">
          <div className="d-flex justify-content-center align-items-center gap-4 flex-wrap">
            <h6 className="title-font-700 fs-6 mb-0 text-white">
              Name:{" "}
              <span className="title-font-500">
                {nurseDetails
                  ? `${nurseDetails.fname} ${nurseDetails.lname}`
                  : ""}
              </span>
            </h6>

            <h6 className="title-font-700 fs-6 mb-0 text-white">
              Email:{" "}
              <span className="base-font-500">
                {nurseDetails ? nurseDetails.email : ""}
              </span>
            </h6>
            <h6 className="title-font-700 fs-6 mb-0 text-white">
              Branch Id :{" "}
              <span className="base-font-500">
                {nurseDetails ? nurseDetails.branch?.name : ""}
              </span>
            </h6>
          </div>
        </div>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="container mt-4">
            <div className="row">
              <div className="col d-flex flex-column p-3">
                <div className="row d-flex justify-content-center">
                  <div className="row">
                    <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1 w-100">
                      <Label className="fs-6 title-font-600 mb-0 text-black">
                        School Name*
                      </Label>
                      <input
                        type="text"
                        className="form-control p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                        placeholder="School Name"
                        {...register("school_name", {
                          required: true,
                        })}
                      />
                    </div>
                    {errors.school_name && (
                      <ErrorComponents error={"School Name is required *"} />
                    )}
                  </div>
                  <div className="row">
                    <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1 w-100">
                      <Label className="fs-6 title-font-600 mb-0 text-black">
                        School Address*
                      </Label>
                      <input
                        type="text"
                        className="form-control p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                        placeholder="School Address"
                        {...register("school_address", {
                          required: true,
                        })}
                      />
                    </div>
                    {errors.school_address && (
                      <ErrorComponents error={"School address is required *"} />
                    )}
                  </div>
                  <div className="row">
                    <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1 w-100">
                      <Label className="fs-6 title-font-600 mb-0 text-black">
                        Num of Brushes*
                      </Label>
                      <input
                        type="text"
                        className="form-control p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                        placeholder="Num of brushes"
                        {...register("num_of_brushes", {
                          required: true,
                        })}
                      />
                    </div>
                    {errors.num_of_brushes && (
                      <ErrorComponents error={"Num of brushes is required *"} />
                    )}
                  </div>
                </div>
                <div className="col-12 d-flex justify-content-end mx-auto mx-md-0 col-md-12 col-lg-12 col-xl-12 mt-4">
                  <div className="d-flex justify-content-end align-items-center">
                    <div className="d-flex  align-items-center gap-3">
                      {Loader ? (
                        <button
                          type="button"
                          className="submit-btn d-flex gap-2 align-items-center"
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            disabled
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Submiting . . .
                        </button>
                      ) : (
                        <button type="submit" className="submit-btn">
                          Submit
                        </button>
                      )}
                      <button
                        type="button"
                        className="close-btn"
                        onClick={CancelButton}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col d-flex flex-column justify-content-center align-items-center">
                <Controller
                  control={control}
                  name="date"
                  rules={{ required: true }} //optional
                  render={({
                    field: { onChange, name, value },
                    fieldState: { invalid, isDirty },
                    formState: { errors },
                  }) => (
                    <>
                      <Calendar
                        value={value}
                        highlightToday
                        onChange={(date) => {
                          onChange(date);
                        }}
                        multiple
                        className="p-1 border-radius-10 border-0"
                        placeholder="select date"
                        mapDays={({ date, isSameDate }) => {
                          let props = {};
                          let isWeekend = [0, 6].includes(date.weekDay.index);
                          let disabledDates = bookings;
                          if (isWeekend || disabledDates.includes(date.format("YYYY-MM-DD"))) {
                            props.disabled = true;
                          }
                          if (disabledDates.includes(date.format("YYYY-MM-DD"))) props.style = {
                            ...props.style,
                            color : '#5EC0D8',
                            disabled : true

                          }
                          return props;
                        }}
                      />
                      {errors &&
                        errors[name] &&
                        errors[name].type === "required" && (
                          //if you want to show an error message
                          <span className="text-danger">
                            Please Select Date *
                          </span>
                        )}
                    </>
                  )}
                />
              </div>
            </div>
          </div>
        </Form>
      </div>
      {tempData.length>0 && (
        <div className="container mt-5">
          <div className="table-responsive">
            <table className="table table-bordered table-light text-center">
              <thead>
                <tr className="border-0">
                  <th className=" text-white border-end-2 custom_table_color rounded-top-4 rounded-end-0 ">
                    School Name
                  </th>
                  <th className=" text-white border-end-2 custom_table_color">
                    School Address
                  </th>
                  <th className=" text-white border-end-2 custom_table_color">
                    Number of Brushes
                  </th>
                  <th className=" text-white custom_table_color rounded-top-4 rounded-start-0">
                    Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {tempData.map((data, i) => (
                  <tr key={i}>
                    <td className="bg-white">{data.school_name}</td>
                    <td className="bg-white">{data.school_address}</td>
                    <td className="bg-white">{data.num_of_brushes}</td>
                    <td className="bg-white">{data.multdate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
        </>
      }
     
    </>
  );
};
