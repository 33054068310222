import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import { ScreeningImageLink, UploadScreeningImages } from '../../../constants/AWS/AWSConfiguration';
import Collapse from "react-collapse";
import {
    BottomTeeth,
    FrontTeeth,
    TopTeeth,
} from '../../../constants/apiEndPoints/ReportCode';
import { Add } from "iconsax-react";
import EvaluationResult from '../EvaluationResult';
import axios from 'axios';
import { successAlert, warningAlert,errorAlert } from '../../Alerts/Alerts';
import { selectPatient } from '../../../redux/Slice/CollectionApp';
import { ApiEndPoint } from '../../../constants/apiEndPoints/endPoints';
import toast from 'react-hot-toast';
import ErrorComponents from '../../ErrorComponents/ErrorComponents';


const DentalScreeningContent = ({handleClearSelect}) => {
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const [frontTeethImageURL, setFrontTeethImageURL] = useState("");
    const [topTeethImageURL, setTopTeethImageURL] = useState("");
    const [bottonTeethImageURL, setBottomTeethImageURL] = useState("");
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [Loader, setLoader] = useState(false);


    const DentalPain = [
        { value: "yes", label: "Yes", dbvalue: "true" },
        { value: "no", label: "No", dbvalue: "false" },
    ];

    const selectedPatient = useSelector(
        (state) => state.collection.selectedPatient
    );

    const CustomerAdminDetails = useSelector(
        (state) => state.login.loggedUserDetails
    );

    const patientFormDetails = useSelector(
        (state) => state.collection.patientFormDetails
    );

    const handleFrontTeeth = (frontTeeth) => {
        setFrontTeethImageURL();
        if (frontTeeth.length > 0) {
            const frontTeethImageCreatedURL = URL.createObjectURL(frontTeeth[0]);
            setFrontTeethImageURL(frontTeethImageCreatedURL);
        }
    };

    const handleTopTeeth = (topTeeth) => {
        setTopTeethImageURL();
        if (topTeeth.length > 0) {
            const topTeethImageCreatedURL = URL.createObjectURL(topTeeth[0]);
            setTopTeethImageURL(topTeethImageCreatedURL);
        }
    };

    const handleBottomTeeth = (bottonTeeth) => {
        setBottomTeethImageURL();
        if (bottonTeeth.length > 0) {
            const bottomTeethImageCreatedURL = URL.createObjectURL(bottonTeeth[0]);
            setBottomTeethImageURL(bottomTeethImageCreatedURL);
        }
    };

    const screening = (evalStatus) => {
        if (evalStatus == "completed") {
            return false;
        } else if (evalStatus == "reported") {
            return false;
        }
        return true;
    };

    const diableForCompletedOrReported = (evalStatus) => {
        if (evalStatus == "completed") {
            return true;
        } else if (evalStatus == "reported") {
            return true;
        }
        return false;
    };

    const clear = () => {
        console.log('clear dental')
        reset({
            name: "",
            schoolname: "",
            grade: "",
            school_teacher: "",
            studentlistc: "",
            top_teeth: "",
            gmail1: "",
            front_teeth: "",
            email2: "",
            dentalpain: "",
            bottom_teeth: "",
            Opt_Out_Letter: "",
            gender: "",
            fname: "",
            lname: "",
            contact: "",
            email: "",
            address: "",
            emr: "",
            sleep_apnea_side_bite_image: "",
            sleep_apnea_tongue_image: "",
            sleep_apnea_top_image: "",
        });
        toast.success("Form Clear successfully!");
        setFrontTeethImageURL("");
        setTopTeethImageURL("");
        setBottomTeethImageURL("");
        dispatch(selectPatient());
        handleClearSelect()
    };

    const disabledTillSubmit = (submitedValue) => {
        if (submitedValue) {
            return true;
        }
        return false;
    };

    const getFileExtension = (fileName) => {
        const extensionMatch = fileName.match(/\.(.+)$/);
        return extensionMatch ? extensionMatch[1] : null;
    };

    const SendEvaluatorNotification = (
        fname,
        lname,
        patient_number,
        eval_status,
        pid
    ) => {
        const notificationMessage = `${fname} ${lname} Screening is completed`;
        const sendNotificationPayload = {
            title: notificationMessage,
            sender: CustomerAdminDetails?.id,
            branch_id: CustomerAdminDetails?.branch?.id,
            patient_number: patient_number,
            patient_id: pid,
            evaluation_status: eval_status,
        };
        axios
            .post(ApiEndPoint.createNotification, sendNotificationPayload)
            .catch((e) => {
                if (e) {
                    toast.error("An error occurred. Please try again.");
                }
            });
    };

    
  const handleSendReport = async (patient) => {
    setLoader(true);
    const sendMailPayload = {
      to: patient.email,
      code: patient.treatment_recommendation_code
        .replace(/(\w)\s+(\w)/g, "$1$2")
        ?.toLowerCase(),
      branch: CustomerAdminDetails.branch.id,
      screening_id: patient.id,
    };
    axios
      .post(ApiEndPoint.sendReportMail, sendMailPayload)
      .then((sendMailResponse) => {
        if (sendMailResponse.data.status === "success") {
          toast.success("Report sent");
          setLoader(false);
          axios
            .post(ApiEndPoint.patientEvaluationResult, {
              patient_number: selectedPatient?.patient_number,
            })
            .then((response) => {
              if (response.data.status === "success") {
                dispatch(selectPatient(response.data.patientDetails));
              }
            });
        } else {
          toast.error("Report not sent");
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
      });
  };

    const onSubmit = async (screeningSubmissionData) => {
        setIsFormSubmitted(true);

        if (screeningSubmissionData?.bottom_teeth?.length > 0) {

            const bottom_teeth_image_url_extension = getFileExtension(screeningSubmissionData?.bottom_teeth[0]?.name);
            // const bottom_teeth_image_url_extension =
            //     screeningSubmissionData?.bottom_teeth[0]?.name.split(".");

            await UploadScreeningImages(
                screeningSubmissionData?.bottom_teeth[0],
                `bottom_teeth_${selectedPatient?.patient_number}.${bottom_teeth_image_url_extension}`
            );
            var bottom_teeth_image_url = `${ScreeningImageLink}bottom_teeth_${selectedPatient?.patient_number}.${bottom_teeth_image_url_extension}`;
        }


        if (screeningSubmissionData?.front_teeth?.length > 0) {
            const front_teeth_image_url_extension = getFileExtension(screeningSubmissionData?.front_teeth[0]?.name);
            await UploadScreeningImages(
                screeningSubmissionData?.front_teeth[0],
                `front_teeth_${selectedPatient?.patient_number}.${front_teeth_image_url_extension}`
            );
            var front_teeth_image_url = `${ScreeningImageLink}front_teeth_${selectedPatient?.patient_number}.${front_teeth_image_url_extension}`;
        }

        if (screeningSubmissionData?.top_teeth?.length > 0) {
            const top_teeth_image_url_extension = getFileExtension(screeningSubmissionData?.top_teeth[0]?.name);
            // screeningSubmissionData?.top_teeth[0]?.name.split(".")[1];
            await UploadScreeningImages(
                screeningSubmissionData?.top_teeth[0],
                `top_teeth_${selectedPatient?.patient_number}.${top_teeth_image_url_extension}`
            );
            var top_teeth_image_url = `${ScreeningImageLink}top_teeth_${selectedPatient?.patient_number}.${top_teeth_image_url_extension}`;
        }

        screeningSubmissionData.bottom_image = bottom_teeth_image_url
            ? bottom_teeth_image_url
            : selectedPatient?.bottom_image;
        screeningSubmissionData.front_image = front_teeth_image_url
            ? front_teeth_image_url
            : selectedPatient?.front_image;
        screeningSubmissionData.top_image = top_teeth_image_url
            ? top_teeth_image_url
            : selectedPatient?.top_image;

        screeningSubmissionData.eval_status = "screened";
        screeningSubmissionData.patient_number = selectedPatient?.patient_number;
        screeningSubmissionData.nurse_id = CustomerAdminDetails?.id;
        screeningSubmissionData.first_name = patientFormDetails?.first_name;
        screeningSubmissionData.last_name = patientFormDetails?.last_name;
        screeningSubmissionData.contact_number = patientFormDetails?.contact_number;
        screeningSubmissionData.email = patientFormDetails?.email;
        screeningSubmissionData.number = patientFormDetails?.number;


        axios
            .post(ApiEndPoint.collection, screeningSubmissionData)
            .then((response) => {
                if (response.data.status === "success") {
                    successAlert("Data collection is completed.");
                    SendEvaluatorNotification(
                        selectedPatient.first_name,
                        selectedPatient.last_name ? selectedPatient.last_name : "",
                        response.data.patientIs.patient_number,
                        response.data.patientIs.eval_status,
                        response.data.patientIs.id
                    );
                    dispatch(selectPatient(response.data.patientIs));
                    clear()
                    setIsFormSubmitted(false);
                } else if (response.data.status === "failed") {
                    warningAlert("Data collection is not completed.");
                    setIsFormSubmitted(false);
                } else {
                    warningAlert("Data collection is not completed.");
                    setIsFormSubmitted(false);
                    clear()
                }
            })
            .catch((err) => {
                errorAlert(err.message);
                setIsFormSubmitted(false);
                clear()
            });
    };

    useEffect(() => {
        if (selectedPatient) {
          setFrontTeethImageURL(selectedPatient?.front_image);
          setTopTeethImageURL(selectedPatient?.top_image);
          setBottomTeethImageURL(selectedPatient?.bottom_image);
        }
      }, [selectedPatient]);

    return (
        <div className="row px-0 px-md-2 p-lg-3 shadow mt-4 rounded-2 border mx-0">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div
                    className="d-flex justify-content-between align-items-center px-2 px-lg-2 mt-4 mb-3"
                    style={{ cursor: "pointer" }}
                >
                    <h5 className="title-font-bold text-base-color fs-5 pb-1 mb-0 border-bottom-color-2">
                        DENTAL SCREENING
                    </h5>
                </div>

                <Collapse isOpened={true} className="mb-5">
                    <div className="px-2 px-lg-2 mt-4">
                        <p className="base-font-600 text-base-gray pt-1">
                            Please have your patient in good lighting and take the pictures
                            as shown . You can refer to this{" "}
                            <a
                                className="text-base-color title-font-600 "
                                href="https://www.youtube.com/watch?v=ZRb-4HpAE9Y"
                                target="_blank"
                            >
                                Video
                            </a>{" "}
                            on how to take the best photos for screening.
                        </p>
                    </div>

                    <div className="row row-gap-3 mt-5 mx-auto mb-5">
                        <div className="col mx-auto mx-md-0 col-md-6 col-lg-4 col-xl-3 text-center">
                            <div className="col mx-auto mx-md-0 mb-4 text-center">
                                <h6 className="title-font-600 text-black fs-6">
                                    Front Teeth Demo Image*
                                </h6>
                                <label className="image_overlay">
                                    <div className="image_box ">
                                        <img
                                            src={FrontTeeth}
                                            alt="home_wrapper"
                                            className="img-fluid rounded-3 "
                                        />
                                    </div>
                                </label>
                            </div>
                            {frontTeethImageURL ? (
                                <label
                                    className={
                                        screening(selectedPatient?.eval_status)
                                            ? "image_overlay cursor"
                                            : "image_overlay"
                                    }
                                    htmlFor={
                                        screening(selectedPatient?.eval_status)
                                            ? "Front_Teeth"
                                            : ""
                                    }
                                    title="Front Teeth"
                                >
                                    <figure className=" position-relative ">
                                        <img
                                            src={frontTeethImageURL}
                                            alt="Front teeth"
                                            className="img-fluid rounded-3 "
                                        />
                                    </figure>
                                </label>
                            ) : (
                                <label htmlFor="Front_Teeth" className="images_card_box">
                                    <div className="figcaptions" title="Front Teeth">
                                        <h6 className="base-font-600 text-white  ">
                                            <span>
                                                <Add size={24} className="text-white" />
                                            </span>{" "}
                                            Click Here to Add Front Teeth Photo
                                        </h6>
                                    </div>
                                </label>
                            )}
                            <div className="">
                                <input
                                    id="Front_Teeth"
                                    className="d-none"
                                    type="file"
                                    title="Front Teeth"
                                    placeholder="Upload Images..."
                                    accept="image/*"
                                    {...register("front_teeth", {
                                        required: selectedPatient?.front_image
                                            ? false
                                            : "Front Teeth Photo*",
                                        onChange: (e) => {
                                            handleFrontTeeth(e.target.files);
                                        },
                                    })}
                                />
                                {errors.front_teeth && (
                                    <ErrorComponents error={errors.front_teeth?.message} />
                                )}
                            </div>
                        </div>
                        <div className="col mx-auto mx-md-0 col-md-6 col-lg-4 col-xl-3 text-center">
                            <div className="col mx-auto mb-4 text-center">
                                <h6 className="title-font-600 text-black fs-6">
                                    Top Teeth Demo Image*
                                </h6>
                                <label className="image_overlay">
                                    <div className="image_box ">
                                        <img
                                            src={TopTeeth}
                                            alt="home_wrapper"
                                            className="img-fluid rounded-3 "
                                        />
                                    </div>
                                </label>
                            </div>
                            {topTeethImageURL ? (
                                <label
                                    htmlFor={
                                        screening(selectedPatient?.eval_status) ? "Top_Teeth" : ""
                                    }
                                    className={
                                        screening(selectedPatient?.eval_status)
                                            ? "image_overlay cursor"
                                            : "image_overlay"
                                    }
                                    title="Top Teeth"
                                >
                                    <figure className=" position-relative">
                                        <img
                                            src={topTeethImageURL}
                                            alt="Top teeth"
                                            className="img-fluid rounded-3 "
                                        />
                                    </figure>
                                </label>
                            ) : (
                                <label
                                    htmlFor="Top_Teeth"
                                    className="images_card_box"
                                    title="Top Teeth"
                                >
                                    <div className="figcaptions">
                                        <h6 className="base-font-600 text-white  ">
                                            <span>
                                                <Add size={24} className="text-white" />
                                            </span>{" "}
                                            Click Here to Add Top Teeth Photo.
                                        </h6>
                                    </div>
                                </label>
                            )}
                            <div className="">
                                <input
                                    id="Top_Teeth"
                                    className="d-none"
                                    type="file"
                                    title="Top Teeth"
                                    placeholder="Upload Images..."
                                    accept="image/*"
                                    {...register("top_teeth", {
                                        required: selectedPatient?.top_image
                                            ? false
                                            : "Top Teeth Photo*",
                                        onChange: (e) => {
                                            handleTopTeeth(e.target.files);
                                        },
                                    })}
                                />
                                {errors.top_teeth && (
                                    <ErrorComponents error={errors.top_teeth?.message} />
                                )}
                            </div>
                        </div>
                        <div className="col mx-auto mx-md-0 col-md-6 col-lg-4 col-xl-3 text-center">
                            <div className="col mx-auto mb-4 text-center">
                                <h6 className="title-font-600 text-black fs-6">
                                    Bottom Teeth Demo Image*
                                </h6>
                                <label className="image_overlay">
                                    <div className="image_box ">
                                        <img
                                            src={BottomTeeth}
                                            alt="home_wrapper"
                                            className="img-fluid rounded-3 "
                                        />
                                    </div>
                                </label>
                            </div>
                            {bottonTeethImageURL ? (
                                <label
                                    htmlFor={
                                        screening(selectedPatient?.eval_status)
                                            ? "Bottom_Teeth"
                                            : ""
                                    }
                                    className={
                                        screening(selectedPatient?.eval_status)
                                            ? "image_overlay cursor"
                                            : "image_overlay"
                                    }
                                    title="bottom teeth"
                                >
                                    <figure className=" position-relative ">
                                        <img
                                            src={bottonTeethImageURL}
                                            alt="Bottom teeth"
                                            className="img-fluid rounded-3 "
                                        />
                                    </figure>
                                </label>
                            ) : (
                                <label htmlFor="Bottom_Teeth" className="images_card_box">
                                    <div className="figcaptions" title="bottom teeth">
                                        <h6 className="base-font-600 text-white  ">
                                            <span>
                                                <Add size={24} className="text-white" />
                                            </span>{" "}
                                            Click Here to Add Bottom Teeth Photo
                                        </h6>
                                    </div>
                                </label>
                            )}
                            <div className="">
                                <input
                                    id="Bottom_Teeth"
                                    className="d-none"
                                    type="file"
                                    title="bottom teeth"
                                    placeholder="Upload Images..."
                                    accept="image/*"
                                    {...register("bottom_teeth", {
                                        required: selectedPatient?.bottom_image
                                            ? false
                                            : "Bottom Teeth Photo*",
                                        onChange: (e) => {
                                            handleBottomTeeth(e.target.files);
                                        },
                                    })}
                                />
                                {errors.bottom_teeth && (
                                    <ErrorComponents error={errors.bottom_teeth?.message} />
                                )}
                            </div>
                        </div>
                        <EvaluationResult />
                        <div className="d-flex align-items-center justify-content-center justify-content-md-end gap-3 pt-3 mb-4 flex-wrap flex-md-nowrap">
                            {(selectedPatient?.eval_status == "completed" || selectedPatient?.eval_status == "reported") && (
                                <>
                                    {Loader ? (
                                        <button
                                            type="button"
                                            className="submit-btn d-flex gap-2 align-items-center"
                                        >
                                            <span
                                                className="spinner-border spinner-border-sm"
                                                disabled
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                            Sending . . .
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="submit-btn"
                                            onClick={() => handleSendReport(selectedPatient)}
                                        >
                                            Send Report
                                        </button>
                                    )}
                                </>
                            )}
                            {isFormSubmitted ? (
                                <button
                                    type="button"
                                    className="submit-btn d-flex gap-2 align-items-center"
                                >
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        disabled
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    Submitting . . .
                                </button>
                            ) : (
                                <button
                                    type="submit"
                                    className="submit-btn"
                                    disabled={diableForCompletedOrReported(
                                        selectedPatient?.eval_status
                                    )}
                                >
                                    Submit
                                </button>
                            )}
                            <button
                                type="button"
                                className="close-btn"
                                onClick={()=>clear()}
                                disabled={disabledTillSubmit(isFormSubmitted)}
                            >
                                Clear
                            </button>
                        </div>
                    </div>
                </Collapse>
            </form>
        </div>

    )
}

export default DentalScreeningContent