import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { loggedUserDetails } from '../../redux/Slice/LoginSlice';
import axios from 'axios';
import Dashboard from './Dashboard';
import { ApiEndPoint } from '../../constants/apiEndPoints/endPoints';
import { toast } from 'react-hot-toast';

const Home = () => {
  const dispatch = useDispatch();
  const username = useSelector((state) => state.login.username);

  const GetUserDetails = async () => {
    const url = `${ApiEndPoint.nurseDetails}${username}/`;
    await axios.get(url).then((response) => {
      dispatch(loggedUserDetails(response.data.nurseDetails));
    }).catch((e) => {
      if (e) {
        toast.error("An error occurred. Please try again.")
      }
    });
  };

  useEffect( () => {
    if (username) {
      GetUserDetails();
    };
  }, [username]);

  return (
    <>
      <Dashboard />
    </>
  )
}

export default Home