import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ErrorComponents from "../ErrorComponents/ErrorComponents";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "reactstrap";
import {
    selectPatient,
    setPatientFormDetails,
    sleepApneaSelectPatient,
} from "../../redux/Slice/CollectionApp";
import axios from "axios";
import { ApiEndPoint } from "../../constants/apiEndPoints/endPoints";
import { toast } from "react-hot-toast";
import InputMask from "react-input-mask";
import { User } from "iconsax-react";

const CreatePatientForm = ({type_of_screening}) => {
    const dispatch = useDispatch();
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isDiable, setIsDiable] = useState(false);
    const [emailCheck, setEmailCheck] = useState(false);
    const [loaders, setloaders] = useState(false);
    const [btnDis, setbtnDis] = useState(false);
    const [isDiable1, setIsDiable1] = useState(false);
    const {
        register,
        handleSubmit,
        reset,
        control,
        watch,
        formState: { errors },
    } = useForm();

    const selectedPatient = useSelector(
        (state) => state.collection.selectedPatient
    );

    const CustomerAdminDetails = useSelector(
        (state) => state.login.loggedUserDetails
    );
    const sleepApneaSelectedPatient = useSelector(
        (state) => state.collection.sleepApneaSelectedPatient
    );

    const patientFormDetails = useSelector(
        (state) => state.collection.patientFormDetails
    );
    const first_name_watch = watch("first_name");
    const last_name_watch = watch("last_name");
    const contact_number_watch = watch("contact_number");
    const email_watch = watch("email");
    const number_watch = watch("number");

    const onSubmit = (createPatientData) => {
        createPatientData.type = "patient";
        createPatientData.nurse = CustomerAdminDetails.id;
        createPatientData.contact_number = createPatientData.contact_number;
        createPatientData.type_of_screening = type_of_screening

        setIsFormSubmitted(true);

        axios
            .post(ApiEndPoint.createPatient, createPatientData)
            .then((createPatientRespoonse) => {
                try {
                    if (createPatientRespoonse.data.status === "success") {
                        setIsFormSubmitted(false);
                        setIsDiable1(false);
                        dispatch(selectPatient(createPatientRespoonse.data.patientDetails));
                        dispatch(
                            sleepApneaSelectPatient(
                                createPatientRespoonse.data.patientDetails
                            )
                        );
                        toast.success("Patient Created Successfully!");
                    } else {
                        setIsFormSubmitted(false);
                        setIsDiable1(false);
                        toast.error("Patient is not created");
                    }
                } catch (error) {
                    setIsFormSubmitted(false);
                    setIsDiable1(false);
                    toast.error("Patient is not created");
                }
            })
            .catch((error) => {
                console.error(error);
                setIsFormSubmitted(false);
                setIsDiable1(false);
                toast.error("Patient is not created");
            });
    };
    const onEdit = (data) => {
        try {
            setloaders(true);
            const playload = {
                patient_number: selectedPatient?.patient_number,
                email: data?.email,
                first_name: data?.first_name,
                last_name: data?.last_name,
                contact_number: data?.contact_number,
                emr_number: data?.number
            };
            axios
                .put(ApiEndPoint.patientnEdit, playload)
                .then((res) => {
                    if (res?.data?.status === "success") {
                        toast.success("Patient Edit Successfully!");
                        setloaders(false);
                        setbtnDis(true);
                        setIsDiable1(false);
                        dispatch(selectPatient(res?.data?.patientDetails));
                    } else toast.error("Patient Not Edit!"), setloaders(false);
                })
                .catch((err) => {
                    if (err) {
                        setIsDiable1(false);
                        toast.error("An error occurred. Please try again.");
                    }
                });
        } catch (err) {
            setbtnDis(false);
            setloaders(false);
            setIsDiable1(false);
            if (err) {
                toast.error("An error occurred. Please try again.");
            }
        }
    };
    useEffect(() => {
        if (selectedPatient) {
            reset({
                first_name: selectedPatient?.first_name,
                last_name: selectedPatient?.last_name,
                contact_number: selectedPatient?.contact_number,
                email: selectedPatient?.email,
                address: selectedPatient?.address,
                number: selectedPatient.number,
            });
        } else {
            reset({
                first_name: "",
                last_name: "",
                contact_number: "",
                email: "",
                address: "",
                number: "",
            });
        }
    }, [selectedPatient]);

    useEffect(() => {
        dispatch(selectPatient(undefined));
    }, []);

    useEffect(() => {
        if (selectedPatient) {
            const editData = {
                first_name: first_name_watch,
                last_name: last_name_watch,
                contact_number: contact_number_watch,
                email: email_watch,
                number: number_watch,
            };
            dispatch(setPatientFormDetails(editData));
        }
    }, [
        selectedPatient,
        first_name_watch,
        last_name_watch,
        contact_number_watch,
        email_watch,
        number_watch,
    ]);

    useEffect(() => {
        if (selectedPatient) {
            if (selectedPatient?.eval_status == "screened" || selectedPatient?.eval_status == "completed" || selectedPatient?.eval_status == "reported") {
                setIsDiable1(true);
            } else {
                setIsDiable1(false);
            }
        } else {
            setIsDiable1(false);
        }

    }, [selectedPatient]);

    useEffect(() => {
        if (
            selectedPatient &&
            sleepApneaSelectedPatient?.patient_images?.eval_status
        ) {
            if (
                sleepApneaSelectedPatient?.patient_images?.eval_status == "screened" ||
                sleepApneaSelectedPatient?.patient_images?.eval_status == "completed" ||
                sleepApneaSelectedPatient?.patient_images?.eval_status == "reported"
            ) {
                setIsDiable1(true);
            } else {
                setIsDiable1(false);
            }
        } else {
            setIsDiable1(false);
        }
    }, [sleepApneaSelectedPatient])

    useEffect(() => {
        if (selectedPatient) {
            if (
                selectedPatient?.eval_status == "completed" ||
                selectedPatient?.eval_status == "reported"
            ) {
                setIsDiable(true);
            } else {
                setIsDiable(false);
            }
        } else {
            setIsDiable(false);
        }
    }, [selectedPatient, sleepApneaSelectedPatient]);

    const EmailCheck = () => {
        if (selectedPatient?.email != email_watch) {
            setEmailCheck(true);
            setbtnDis(false);
        } else {
            setEmailCheck(false);
        }
    };

    useEffect(() => {
        EmailCheck();
    }, [email_watch]);


    useEffect(() => {
        setbtnDis(false);


    }, [first_name_watch,
        last_name_watch,
        contact_number_watch,
        email_watch,
        number_watch,])



    return (
        <Form
            onSubmit={handleSubmit(isDiable1 ? onEdit : onSubmit)}
            autoComplete="off"
        >
            <div className="row px-0 px-md-2 p-lg-3 shadow mt-4 rounded-2 border mx-0">
                <div className="col-12 col-md-12 mx-auto text-center text-md-start mx-md-0 pb-3 pt-3  ">
                    {!selectedPatient && !selectedPatient ? (
                        <label className="title-font-600 mb-0 fs-5 text-uppercase border-bottom-color2-2 mobile-heading">
                            <User
                                size="26"
                                className="mb-1 text-base-color2"
                                variant="Bulk"
                            />{" "}
                            Create patient
                        </label>
                    ) : (
                        <label className="title-font-600 mb-0 fs-5 text-uppercase border-bottom-color2-2 mobile-heading">
                            <User
                                size="26"
                                className="mb-1 text-base-color2"
                                variant="Bulk"
                            />{" "}
                            Patient Screening
                        </label>
                    )}
                </div>

                <div className="col-12 mx-auto mx-md-0 col-md-6 col-lg-4 pb-3 pt-1">
                    <label className="fs-6 title-font-600 mb-0 text-black">
                        First Name*
                    </label>

                    <div className="mt-1 w-100">
                        <input
                            className="p-2 border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input"
                            type="text"
                            placeholder="Enter First Name..."
                            {...register("first_name", {
                                required: "First Name is required *",
                            })}
                        />
                    </div>
                    {errors.first_name && (
                        <ErrorComponents error={errors.first_name?.message} />
                    )}
                </div>
                <div className="col-12 mx-auto mx-md-0 col-md-6 col-lg-4 pb-3 pt-1">
                    <label className="fs-6 title-font-600 mb-0 text-black">
                        Last Name*
                    </label>

                    <div className="mt-1 w-100">
                        <input
                            className="p-2 border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input"
                            type="text"
                            placeholder="Enter Last Name..."
                            {...register("last_name", {
                                required: "Last Name is required *",
                            })}
                        />
                    </div>
                    {errors.last_name && (
                        <ErrorComponents error={errors.last_name?.message} />
                    )}
                </div>
                <div className="col-12 mx-auto mx-md-0 col-md-6 col-lg-4 pb-3 pt-1">
                    <label className="fs-6 title-font-600 mb-0 text-black">
                        Contact Number*
                    </label>

                    <div className="mt-1 w-100">
                        <Controller
                            name="contact_number"
                            control={control}
                            rules={{
                                required: "Phone number is required",
                                validate: (value) =>
                                    (value && value.length >= 10) ||
                                    "Invalid phone number format (e.g., (111)111-1111)",
                            }}
                            render={({ field: { onChange, value } }) => (
                                <div className="p-2 border-radius-10 shadow-none  w-100 fs-6 title-font-500 d-flex gap-2  modal-input">
                                    <span>+1</span>
                                    <InputMask
                                        mask="(999) 999-9999"
                                        value={value}
                                        onChange={(val) => onChange(val)}
                                        type="tel"
                                        placeholder=" (111)111-1111"
                                        className="bg-transparent title-font-500 w-100 base-white-light border-0 shadow-none p-0"
                                    />
                                </div>
                            )}
                        />
                    </div>
                    {errors.contact_number && (
                        <ErrorComponents error={errors.contact_number?.message} />
                    )}
                </div>
                <div className="col-12 mx-auto mx-md-0 col-md-6 col-lg-4 pb-3 pt-1">
                    <label className="fs-6 title-font-600 mb-0 text-black">Email*</label>

                    <div className="mt-1 w-100">
                        <input
                            className="p-2 border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input"
                            type="email"
                            autoComplete="nope"
                            placeholder="Enter Email..."
                            {...register("email", {
                                required: "Email is required *",
                            })}
                        />
                    </div>
                    {errors.email && <ErrorComponents error={errors.email?.message} />}
                </div>
                <div className="col-12 mx-auto mx-md-0 col-md-6 col-lg-4 pb-3 pt-1">
                    <label className="fs-6 title-font-600 mb-0 text-black">
                        Emr Number
                    </label>
                    <div className="mt-1 d-flex gap-3">
                        <input
                            className="p-2 border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input"
                            type="text"
                            maxLength={10}
                            placeholder="Enter Emr Number..."
                            {...register("number")}
                        />
                    </div>
                </div>


                {isDiable1 && selectedPatient && (
                    <>
                        <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-4 pb-3 pt-2">
                            <div className="d-flex align-items-center justify-content-center align-items-center justify-content-md-end gap-3 pt-3 mb-3">
                                {loaders ? (
                                    <button type="submit" className="submit-btn">
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            disabled
                                            role="status"
                                            aria-hidden="true"
                                            style={{ marginRight: "5px" }}
                                        ></span>
                                        Saving Details...
                                    </button>
                                ) : (
                                    <button
                                        type="submit"
                                        disabled={btnDis && btnDis}
                                        className="submit-btn"
                                    >
                                        Save
                                    </button>
                                )}
                            </div>
                        </div>
                    </>
                )}
                {!selectedPatient ? (
                    isFormSubmitted ? (
                        <div className="d-flex align-items-center justify-content-center align-items-center justify-content-md-end gap-3 mb-3">
                            <button type="submit" className="submit-btn" disabled>
                                <span
                                    className="spinner-border spinner-border-sm"
                                    disabled
                                    role="status"
                                    aria-hidden="true"
                                    style={{ marginRight: "5px" }}
                                ></span>
                                Submitting ...
                            </button>
                        </div>
                    ) : (
                        <div className="d-flex align-items-center justify-content-center justify-content-md-end gap-3 pt-3 mb-3">
                            <button type="submit" className="submit-btn">
                                Submit
                            </button>
                        </div>
                    )
                ) : (
                    ""
                )}
            </div>
        </Form>
    );
};

export default CreatePatientForm;
