import React, { useState, useEffect } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Select from "react-select";
import moment from "moment";
import { ApiEndPoint } from "../../constants/apiEndPoints/endPoints";
import axios from "axios";
import { useSelector , useDispatch} from "react-redux";
import { Calendar } from "react-multi-date-picker";
import ProfileLoader from "../ProfileHeader/ProfileLoader";
import ProfileHeader from "../ProfileHeader/ProfileHeader";
import NurseEdit from "../Modal/NurseModals/NurseEdit";
import { DocumentDownload } from "iconsax-react";
import { NavLink } from "react-router-dom";
import { ImportDistrictExcelDemo, letter_to_teacher } from "../../constants/apiEndPoints/ReportCode";
import { loggedUserDetails } from "../../redux/Slice/LoginSlice";


export const AppointmentTable = () => {
  const dispatch = useDispatch();
  const username = useSelector((state) => state.login.username);
  const [patientsList, setPatientsList] = useState([]);
  const [unFilteredPatientList, setUnFilteredPatientList] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [loaders, setLoaders] = useState(false);
  const today = new Date();
  const [Loader, setLoader] = useState(false);

  const [values, setValues] = useState([today]);
  const [bookings, setBookings] = useState([]);

  const CustomerAdminDetails = useSelector(
    (state) => state.login.loggedUserDetails
  );

  console.log("CustomerAdminDetails==>", CustomerAdminDetails);

  const patientHeader = [
    { header: "#", input: "dropdown", dbkey: "index", searchtype: "id" },
    {
      header: "School Name",
      input: "dropdown",
      dbkey: "school_name",
      searchtype: "input",
    },
    {
      header: "School Address",
      input: "dropdown",
      dbkey: "school_address",
      searchtype: "input",
    },
    {
      header: "Nurse Name",
      input: "dropdown",
      dbkey: "nurse_name",
      searchtype: "input",
    },
    {
      header: "Status",
      input: "dropdown",
      dbkey: "status",
      searchtype: "input",
    },
    {
      header: "No of brucehs",
      input: "dropdown",
      dbkey: "num_of_brushes",
      searchtype: "input",
    },
    {
      header: "Appointment Date",
      input: "dropdown",
      dbkey: "date",
      searchtype: "hidden",
    },
  ];

  const handleOnSelect = (select, value) => {
    upsert({ name: select, value: value });
  };

  const handleSearch = (select, value) => {
    upsert({ name: select, value: value });
  };

  const handleOnClear = (select) => {
    const filteredArray = filters.filter((obj) => obj.name !== select);
    setFilters(filteredArray);
  };

  const upsert = (item) => {
    const previousfilters = filters.find((_item) => _item.name === item.name);
    if (previousfilters) {
      const updatedArray = filters.map((obj) => {
        if (obj.name === item.name) {
          return { ...obj, value: item.value };
        }
        return obj;
      });
      setFilters(updatedArray);
    } else {
      setFilters([...filters, item]);
    }
  };

  const GetAppointment = async () => {
    await axios
      .get(`${ApiEndPoint.getAllNurseAppointment}${CustomerAdminDetails?.id}/`)
      .then(async (response) => {
        const nurseWithName = await response?.data?.map((item) => ({
          ...item,
          nurse_name: `${item?.nurse?.fname} ${item?.nurse?.lname}`,
        }));
        setPatientsList(nurseWithName);
        setUnFilteredPatientList(nurseWithName);
      });
  };

  const GetUserDetails = async () => {
    setLoader(true)
    const url = `${ApiEndPoint.nurseDetails}${username}/`;
    await axios.get(url).then((response) => {
      dispatch(loggedUserDetails(response.data.nurseDetails));
      setLoader(false)
    }).catch((e) => {
      if (e) {
        toast.error("An error occurred. Please try again.")
      }
    });
  };

  useEffect( () => {
    if (username) {
      GetUserDetails();
    };
  }, [username]);

  useEffect(() => {
    if (patientsList) {
      patientsList.map((item) => {
        const bookedDates = item.date.split("T")[0];
        setBookings((prevBookings) => [...prevBookings, bookedDates]);
      });
    }
  }, [patientsList]);
  useEffect(() => {
    if(CustomerAdminDetails){

      GetAppointment();
    }
  }, [CustomerAdminDetails]);

  useEffect(() => {
    if (unFilteredPatientList?.length > 0) {
      var filterPatient = unFilteredPatientList?.filter((patient) => {
        return filters.every((filter) => {
          return (
            patient[filter.name] &&
            patient[filter.name]
              .toString()
              .toUpperCase()
              .startsWith(filter.value.toString().toUpperCase())
          );
        });
      });
    }
    setPatientsList(filterPatient);
  }, [filters]);

  const formatDate = (dateString) => {
    const date = moment(dateString);
    return date.format("D MMMM YYYY");
  };
  const rowHeight = 40.8;
  const minVisibleRows = 6.5;

  // Calculate the height required to display at least minVisibleRows
  const minHeight = `${rowHeight * minVisibleRows}px`;

  return (
    <>
      <section className="m-3 m-md-4 d-flex gap-4 flex-column">
        <div>
          {Loader ? (
            <>
              <ProfileLoader />
            </>
          ) : (
            <ProfileHeader
              profilePic={
                CustomerAdminDetails?.logo
                  ? CustomerAdminDetails?.logo
                  : "https://i.pinimg.com/564x/1f/a9/fc/1fa9fc02ab8e2acab10bc6ca19016039.jpg"
              }
              name={`${CustomerAdminDetails?.fname} ${CustomerAdminDetails?.lname}`}
              branchname={CustomerAdminDetails?.branch?.name}
              email={CustomerAdminDetails?.email}
              // contact={`${CustomerAdminDetails?.branch?.customer?.name}`}
              contact={`${CustomerAdminDetails?.branch?.customer?.phone_number}`}
              address={
                CustomerAdminDetails?.branch?.street_address
                  ? CustomerAdminDetails?.branch?.street_address
                  : ""
              }
              city={
                CustomerAdminDetails?.branch?.city
                  ? CustomerAdminDetails?.branch?.city
                  : ""
              }
              state={
                CustomerAdminDetails?.branch?.state
                  ? CustomerAdminDetails?.branch?.state
                  : ""
              }
              zip={
                CustomerAdminDetails?.branch?.zip
                  ? CustomerAdminDetails?.branch?.zip
                  : ""
              }
              editModal={<NurseEdit data={CustomerAdminDetails} />}
            />
          )}
        </div>
        <div className="">
          <div className="bg-white p-3 p-md-4 shadow-lg rounded-4">
            <div className="">
              <h4 className="title-font-bold fs-4 text-base-color text-center text-md-start pb-2">
                Screening Schedule
              </h4>
            </div>
            <div className="d-flex justify-content-center">
              <Calendar
                value={values}
                highlightToday
                multiple
                className="p-1 border-radius-10 border-0"
                placeholder="select date"
                mapDays={({ date, isSameDate }) => {
                  let props = {};
                  let isWeekend = [0, 6].includes(date.weekDay.index);
                  let disabledDates = bookings;

                  if (
                    isWeekend ||
                    disabledDates.includes(date.format("YYYY-MM-DD"))
                  ) {
                    props.disabled = true;
                  }
                  if (disabledDates.includes(date.format("YYYY-MM-DD"))) props.style = {
                            ...props.style,
                            color : '#5EC0D8',
                            disabled : true

                          }

                  return props;
                }}
              />
            </div>
            <div
              className="shadow border rounded-3 p-3 px-md-3 py-md-1 mb-md-4 mt-4"
              style={{ backgroundColor: "#f9f9f9" }}
            >
              <div className="table-responsive-xxl pt-3  mb-0 ">
                <div
                  className="table-container"
                  style={{ maxHeight: 300, overflowY: "auto" }}
                >
                  <table className="table  table-responsive border rounded-3 table-hover">
                    <thead className="position-sticky top-0">
                      <tr className="">
                        {patientHeader?.map((headers, index) => (
                          <th
                            className="bg-base-color2 border-0 text-white"
                            key={index}
                          >
                            <span className="base-font-600 fs-6 text-uppercase text-center">
                              {headers?.header}
                            </span>
                            {headers?.searchtype === "id" && (
                              <div className="py-0 px-1 border-radius-10 shadow-none w-100 fs-6 title-font-500 mb-1"></div>
                            )}
                            {headers?.dbkey !== "index" && (
                              <span>
                                {headers?.searchtype === "input" ? (
                                  <ReactSearchAutocomplete
                                    items={patientsList}
                                    fuseOptions={{ keys: [headers?.dbkey] }}
                                    resultStringKeyName={headers?.dbkey}
                                    onSearch={(event) =>
                                      handleSearch(headers?.dbkey, event)
                                    }
                                    onSelect={(event) =>
                                      handleOnSelect(
                                        headers?.dbkey,
                                        event[headers?.dbkey]
                                      )
                                    }
                                    onClear={(event) =>
                                      handleOnClear(headers?.dbkey)
                                    }
                                    showIcon={false}
                                    placeholder={headers?.header + "..."}
                                    className="shadow-none w-100 fs-6 title-font-500 rounded-1 py-0"
                                    styling={{
                                      borderRadius: "8px",
                                      zIndex: 2,
                                    }}
                                  />
                                ) : headers?.searchtype === "select" ? (
                                  <Select
                                    options={evalStatus}
                                    onChange={handleSelectStatus}
                                    className="mb-2 border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input text-black"
                                    isClearable={true}
                                  />
                                ) : (
                                  <>
                                    <Select
                                      placeholder=""
                                      className="mb-1 border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input text-black"
                                      isDisabled={true}
                                    />
                                  </>
                                )}
                              </span>
                            )}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    {loaders ? (
                      <tbody>
                        <tr>
                          <td
                            colSpan="8"
                            className="base-font-600 fs-6 text-black text-center"
                          >
                            Loading...
                          </td>
                        </tr>
                      </tbody>
                    ) : patientsList && patientsList.length > 0 ? (
                      <tbody className="">
                        {patientsList?.map((item, index) => (
                          <tr
                            key={item.id}
                            style={{ cursor: "pointer" }}
                            className={
                              selectedRow && selectedRow.id === item.id
                                ? "selected-row"
                                : "border"
                            }
                            // onClick={() => handleRowClick(item)}
                          >
                            {patientHeader?.map((columnName, phindex) => (
                              <td
                                key={phindex}
                                className="base-font-500 fs-6 text-black"
                              >
                                {columnName?.dbkey === "index"
                                  ? `${index + 1}`
                                  : item[columnName.dbkey] == undefined
                                  ? "-----------"
                                  : item[columnName.dbkey] == null
                                  ? "-----------"
                                  : columnName.dbkey == "date"
                                  ? formatDate(item[columnName.dbkey])
                                  : item[columnName.dbkey]}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td
                            colSpan="8"
                            className="base-font-600 fs-6 text-black text-center"
                          >
                            Data not found!
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white p-md-4 shadow-lg rounded-4">
          <div className="pt-2">
            <h4 className="title-font-bold fs-4 text-base-color text-center text-md-start pb-2">
              Pre-Screening Activites
            </h4>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="shadow-lg rounded-4 p-3 mt-2 mx-1">
                <h5 className="title-font-bold fs-6 text-secondary">
                  Send a letter to parents to inform about the virtual
                  screening:
                </h5>
                <div className="d-flex flex-row-reverse">
                  <a
                    href={ImportDistrictExcelDemo}
                    download
                    className="border shadow-lg align-items-center text-white  rounded-3 px-4 py-2 bg-base-color2 base-font-400"
                    title={"Sample letter to parents"}
                  >
                    <DocumentDownload className="text-white pe-2" size="28" />{" "}
                    Sample letter to parents
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="shadow-lg rounded-4 p-3 mt-2 mx-1">
                <h5 className="title-font-bold fs-6 text-secondary">
                  Send a letter to parents to obtain consent for screening:
                </h5>
                <div className="d-flex flex-row-reverse">
                  <a
                    href={ImportDistrictExcelDemo}
                    download
                    className="border shadow-lg align-items-center text-white  rounded-3 px-4 py-2 bg-base-color2 base-font-400 mx-1 ms-3"
                    title={"Sample letter to parents"}
                  >
                    <DocumentDownload className="text-white pe-2" size="28" />{" "}
                    Sample Consent form
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="shadow-lg rounded-4 p-3 mt-2 mx-1">
                <h5 className="title-font-bold fs-6 text-secondary">
                  Send a letter to teachers to explain the screening process:
                </h5>
                <div className="d-flex flex-row-reverse">
                  <a
                    href={letter_to_teacher}
                    target="_blank"
                    download
                    className="border shadow-lg align-items-center text-white  rounded-3 px-4 py-2 bg-base-color2 base-font-400 mx-1 ms-3"
                    title={"Sample letter to parents"}
                  >
                    <DocumentDownload className="text-white pe-2" size="28" />{" "}
                    Sample letter to Teachers
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white p-md-4 mb-4 shadow-lg rounded-4">
          <div className="pt-2">
            <h4 className="title-font-bold fs-4 text-base-color text-center text-md-start pb-2">
              Data Upload
            </h4>
          </div>

          <div className="d-flex flex-row-reverse justify-content-lg-start justify-content-md-start justify-content-center ">
            <NavLink
              className="submit-btn py-2 btn-import text-nowrap mx-2 bg-base-color2"
              to="/create-student"
            >
              Create Students
            </NavLink>
            <NavLink
              className="submit-btn py-2 btn-import text-nowrap mx-2 bg-base-color2"
              to="/import"
            >
              Import Students
            </NavLink>
          </div>
        </div>
      </section>
    </>
  );
};
