import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { DentalScreenTable } from "./DentalScreenTable";
import { SleepApneaTable } from "./SleepApneaTable";

const PatientTable = ({ evalstatus, patientType }) => {
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <>
      <Nav tabs className="all-tabs" style={{ width: "100%" }}>
        <NavItem className="custom-nav-item">
          <NavLink
            className={activeTab === "1" ? "active-tab" : "non-active-tab"}
            onClick={() => toggleTab("1")}
          >
            Dental Screening
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "2" ? "active-tab" : "non-active-tab"}
            onClick={() => toggleTab("2")}
          >
            Sleep Apnea Screening
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <>
            {activeTab === "1" && (
              <>
                <DentalScreenTable
                  evalstatus={evalstatus}
                  patientType={patientType}
                />
              </>
            )}
          </>
        </TabPane>

        <TabPane tabId="2">
          <>
            {activeTab === "2" && (
              <>
                <SleepApneaTable
                  evalstatus={evalstatus}
                  patientType={patientType}
                />
              </>
            )}
          </>
        </TabPane>
      </TabContent>
    </>
  );
};

export default PatientTable;
