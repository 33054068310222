import React from 'react'

const ConditionalButton = ({ load, onClick, disabled, title }) => {
    return (
        <button
            className={`border-0 px-4 py-2 text-white base-font-600 bg-base-color rounded-1 d-flex gap-2 align-items-center m-1 ${disabled ? 'disabled' : ''}`}
            onClick={onClick}
            disabled={disabled}
        >
            {load ? (
                <>
                    <span
                        className="spinner-border spinner-border-sm" disabled
                        role="status"
                        aria-hidden="true"
                    ></span>
                    Sending . . .
                </>
            ) : (
                <>{title}</>
            )}
        </button>

    )
}

export default ConditionalButton