import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  customerProfileDetails: undefined,
  customerBranchList: undefined,
  defaultDropDownCustomerBranchList: undefined,
  dropDownCustomerBranchList: undefined,
};

export const CustomerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    customerProfile: (state, action) => {
      state.customerProfileDetails = action.payload
    },
    setCustomerBranch: (state, action) => {
      state.customerBranchList = action.payload
    },
    setDefaultDropDownCustomerBranchList: (state, action) => {
      state.defaultDropDownCustomerBranchList = action.payload
    },
    setDropDownCustomerBranchList: (state, action) => {
      state.dropDownCustomerBranchList = [state.defaultDropDownCustomerBranchList, ...action.payload]
    },
  },
})

export const { customerProfile, setCustomerBranch, setDropDownCustomerBranchList, setDefaultDropDownCustomerBranchList } = CustomerSlice.actions

export default CustomerSlice.reducer;