import React, { useState, useEffect, useRef } from "react";
import * as XLSX from "xlsx";
import axios from "axios";
import { ApiEndPoint } from "../../constants/apiEndPoints/endPoints";
import Table2 from "../../Components/Table/Table/Table2";
import { useDispatch, useSelector } from "react-redux";
import QRCode from "qrcode.react";
import {
    ScreeningImageLink,
    UploadScreeningImages,
} from "../../constants/AWS/AWSConfiguration";
import { errorAlert, successAlert } from "../../Components/Alerts/Alerts";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert";
import "../../Components/Alerts/Alerts.css";
import { setSelectedImportStudent } from "../../redux/Slice/CollectionApp";
import ImportsModal from "../../Components/Modal/Imports/ImportsModal";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { DocumentDownload } from "iconsax-react";
import { ImportStudentExcelDemo } from "../../constants/apiEndPoints/ReportCode";
import { toast } from "react-hot-toast";
import { saveAs } from "file-saver";

const columns = [
    {
        name: "Student Id",
        selector: (row) => row.studentid,
        sortable: true,
        wrap: true,
    },
    {
        name: "First Name",
        selector: (row) => row.firstname,
        sortable: true,
        wrap: true,
    },
    {
        name: "Gender",
        selector: (row) => row.gender || "NA",
        sortable: true,
        wrap: true,
    },
    {
        name: "School",
        selector: (row) => row.school,
        sortable: true,
        wrap: true,
    },
    {
        name: "Grade",
        selector: (row) => row.grade,
        sortable: true,
        wrap: true,
    },
    {
        name: "Class Teacher",
        selector: (row) => row.classteacher,
        sortable: true,
        wrap: true,
    },
    {
        name: "District",
        selector: (row) => row.district,
        sortable: true,
        wrap: true,
    },
    {
        name: "Guardian Email 1",
        selector: (row) => row.guardianemail1,
        sortable: true,
        wrap: true,
    },
    {
        name: "Guardian Email 2",
        selector: (row) => row.guardianemail2,
        sortable: true,
        wrap: true,
    },
    {
        name: "QR Code",
        selector: (row) => (
            <QRCode
                id={`${row.screeningId}`}
                value={`${row.school};${row.grade};${row.classteacher};${row.screeningId}`}
                size={85}
                includeMargin={true}
            />
        ),
        sortable: false,
    },
];

const crosscolumns = [
    {
        name: "Student Id",
        selector: (row) => row.studentid,
        sortable: true,
        wrap: true,
    },
    {
        name: "Screening Id",
        selector: (row) => row.screeningId,
        sortable: true,
        wrap: true,
    },
    {
        name: "First Name",
        selector: (row) => row.firstname,
        sortable: true,
        wrap: true,
    },
    {
        name: "Gender",
        selector: (row) => row.gender || "NA",
        sortable: true,
        wrap: true,
    },
    {
        name: "School",
        selector: (row) => row.school,
        sortable: true,
        wrap: true,
    },
    {
        name: "Grade",
        selector: (row) => row.grade,
        sortable: true,
        wrap: true,
    },
    {
        name: "Class Teacher",
        selector: (row) => row.classteacher,
        sortable: true,
        wrap: true,
    },
    {
        name: "District",
        selector: (row) => row.district,
        sortable: true,
        wrap: true,
    },
    {
        name: "Guardian Email 1",
        selector: (row) => row.guardianemail1,
        sortable: true,
        wrap: true,
    },
    {
        name: "Guardian Email 2",
        selector: (row) => row.guardianemail2,
        sortable: true,
        wrap: true,
    },
    // {
    //     name: 'QR Code',
    //     selector: row => <QRCode
    //         id={`${row.screeningId}`}
    //         value={`${row.school};${row.grade};${row.classteacher};${row.screeningId}`}
    //         size={100}
    //         includeMargin={true}
    //     />,
    //     sortable: false,
    // },
];

const Import = () => {
    const dispatch = useDispatch();
    const [excelData, setExcelData] = useState([]);
    const [originalExcelData, setOriginalExcelData] = useState([]);
    const [uploadDisabled, setUploadDisabled] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [sendMailToStudent, setSendMailToStudent] = useState(false);
    const [sendMailToStudentPDF, setSendMailToStudentPDF] = useState(false);
    const [sendMailToStudentAsNurse, setSendMailToStudentAsNurse] =
        useState(false);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const pdfRef = useRef(null);
    const fileInputRef = useRef(null);

    const CustomerAdminDetails = useSelector(
        (state) => state.login.loggedUserDetails
    );
    const IsSelectedImportStudent = useSelector(
        (state) => state.collection.selectedImportStudent
    );

    const navigate = useNavigate();

    const handleFileChange = (e) => {
        setExcelData([]);
        setOriginalExcelData([]);
        if (e.target.files[0]) {
            setUploadDisabled(true);
            const file = e.target.files[0];
            const reader = new FileReader();
            const ihb = (reader.onload = (event) => {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data);
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                const headersss = jsonData.shift();

                const myheaders = headersss.map((header) => {
                    return header.toLowerCase().replace(/\s/g, "");
                });

                const keysToCheck = [
                    "district",
                    "school",
                    "grade",
                    "classteacher",
                    "firstname",
                    // "gender",
                    // "studentid",
                    // "guardianemail1",
                    // "guardianemail2",
                ];

                const missingHeaders = keysToCheck.filter(
                    (key) => !myheaders.includes(key)
                );


                if (missingHeaders.length > 0) {
                    const missingHeadersText = missingHeaders.join(", ");
                    errorAlert(`Missing headers from excel file: ${missingHeadersText}`);
                    setUploadDisabled(false);
                    if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                    }
                } else {
                    const dataArray = jsonData.map((row) => {
                        const obj = {};
                        myheaders.forEach((header, index) => {
                            obj[header] = row[index] || "";
                            obj["nurseid"] = CustomerAdminDetails?.id;
                            obj["type"] = "student";
                        });
                        return obj;
                    });
                    if (missingHeaders.length > 0) {
                        const missingHeadersText = missingHeaders.join(", ");
                        errorAlert(`Missing headers from excel file: ${missingHeadersText}`);
                        setUploadDisabled(false);
                        if (fileInputRef.current) {
                            fileInputRef.current.value = "";
                        }
                    } else {
                        const dataArray = jsonData.map((row) => {
                            const obj = {};
                            myheaders.forEach((header, index) => {
                                if (header === "gender" && !row[index]) {
                                    obj[header] = "NA";
                                } else {
                                    obj[header] = row[index] || "";
                                }
                                // obj[header] = row[index] || "";
                                obj["nurseid"] = CustomerAdminDetails?.id;
                                obj["type"] = "student";
                            });
                            return obj;
                        });

                        const newArray = dataArray.filter((obj) => {
                            const emptyKeys = keysToCheck.filter(
                                (key) =>
                                    obj.hasOwnProperty(key) &&
                                    (obj[key] === null || obj[key] === undefined)
                            );
                            if (emptyKeys.length === 0) {
                                return true;
                            } else {
                                return false;
                            }
                        });

                        // Filter out rows with empty data
                        const nonEmptyRows = dataArray.filter((row) =>
                            keysToCheck.every((key) => row[key] !== "")
                        );
                        const mnewArray = nonEmptyRows.map((data, dindex) => {
                            return {
                                ...data,
                                screeningId: dindex + 1,
                                // screeningId: data.studentid,
                                studentid:   dindex + 1,
                                firstname: data.firstname.substring(0, 3),
                                gender: data.gender === "F" ? "Female" :  data.gender === "M" ? "Male" : data.gender,
                            };
                        });

                        var modifiedArray = nonEmptyRows.map((data, index) => {
                            var newObj = { ...data,
                                     //  screeningId: data.studentid 
                                    screeningId: index + 1,

                                };
                            delete newObj.nurseid;
                            delete newObj.type;
                            return newObj;
                        });
                        setOriginalExcelData(modifiedArray);
                        setExcelData(mnewArray);
                    }
                }
            });
            reader.readAsArrayBuffer(file);
        }
    };

    const uploadExcel = async () => {
        setIsUploading(true);
        try {
            const importData =
                IsSelectedImportStudent.length > 0
                    ? IsSelectedImportStudent
                    : excelData;
            const datas = await getDatas(importData);
            axios
                .post(ApiEndPoint.uploadPatientDetails, datas)
                .then((response) => {
                    setUploadDisabled(true);
                    if (response?.data.status === "success") {
                        Swal({
                            title: `Students details is saved`,
                            text:
                                `Unique students are ${Number(excelData.length) -
                                Number(response?.data.alreadyExistsStudent.length)
                                } \n` +
                                `Duplicate students are ${response?.data?.alreadyExistsStudent?.length}`,
                            icon: "success",
                            confirmButtonText: "Close",
                        });
                        setIsUploading(false);
                        if (fileInputRef.current) {
                            fileInputRef.current.value = "";
                        }
                    }
                })
                .catch((err) => {
                    errorAlert(err.message);
                    setIsUploading(false);
                    if (err) {
                        toast.error("An error occurred. Please try again.");
                    }
                });
        } catch (error) {
            setIsUploading(false);
            console.error("Error while uploading Excel data:", error);
            if (error) {
                toast.error("An error occurred. Please try again.");
            }
        }
    }

    const getDatas = async (excelData) => {
        const datas = [];
        for (const student of excelData) {
            const qrcode = await viewQR(student.screeningId);
            const fileName = new Date().getTime();
            const flname = `${fileName}_${student.screeningId}_${student.firstname}.jpeg`;
            const res = await fetch(qrcode);
            const buf = await res.arrayBuffer();
            const file = new File([buf], flname, { type: "image/jpeg" });
            const studentQR = `${ScreeningImageLink}qr_${flname}`;
            // await UploadScreeningImages(file, `qr_${flname}`);
            const studentData = {
                ...student,
                qr: studentQR,
            };
            datas.push(studentData);
        }
        return datas;
    };

    const viewQR = (index) => {
        const canvas = document.getElementById(`${index}`);
        const pngUrl = canvas
            ?.toDataURL("image/png")
            ?.replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        return pngUrl;
    };

    const SendToStudent = async () => {
        setSendMailToStudent(true);
        const importData =
            IsSelectedImportStudent.length > 0 ? IsSelectedImportStudent : excelData;
        const sendToStudentData = await getDatas(importData);
        let count = 0;
        sendToStudentData.map((send) => {
            const payload = {
                to: send.email,
                qrcode: send.qr,
                firstname: send.firstname,
            };
            axios
                .post(ApiEndPoint.sendQRCodeToStudents, payload)
                .then((response) => {
                    count = count + 1;
                    if (count == sendToStudentData.length) {
                        setSendMailToStudent(false);
                        successAlert("Mail Sent To all selected students");
                        if (fileInputRef.current) {
                            fileInputRef.current.value = "";
                        }
                    }
                })
                .catch((err) => {
                    setSendMailToStudent(false);
                    if (err) {
                        toast.error("An error occurred. Please try again.");
                    }
                });
        });
    };

    const SendAllToNurse = async () => {
        setSendMailToStudentPDF(true);
        try {
            const doc = new jsPDF();
            doc.autoTable({
                html: "#my-table2",
                styles: {
                    cellWidth: "wrap",
                },
                bodyStyles: { minCellHeight: "auto" },
                didDrawCell: function (data) {
                    if (data.column.index === 1 && data.cell.section === "body") {
                        var td = data.cell.raw;
                        var img = td.getElementsByTagName("canvas")[0];
                        var dim = data.cell.height - data.cell.padding("vertical");
                        var textPos = data.cell;
                        doc.addImage(img, textPos.x + 2, textPos.y + 2, 35, 35);
                    }
                },
                theme: "grid",
                pageBreak: "auto",
                rowPageBreak: "avoid",
                columnStyles: {
                    0: { cellWidth: 70, minCellHeight: 20 },
                    1: { cellWidth: 40, minCellHeight: 20 },
                    2: { cellWidth: 90, minCellHeight: 20 },
                },
            });

            var bloburls = doc.output("blob");
            let fileName = new Date().getTime();
            let date = moment(new Date()).format("DD_MM_YY");
            var pdfFile = new File(
                [bloburls],
                `${fileName}_${date}_${CustomerAdminDetails?.id}_${CustomerAdminDetails?.branch?.branch_admin_user_id}.pdf`
            );
            const studentQRPDF = `${ScreeningImageLink}qr_${pdfFile.name}`;
            // Generate Original Excel Sheet Code starts here
            const originalDataExcelSheet =
                XLSX.utils.json_to_sheet(originalExcelData);

            const newWorkbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
                newWorkbook,
                originalDataExcelSheet,
                "FilteredSheet"
            );

            const excelBuffer = XLSX.write(newWorkbook, {
                bookType: "xlsx",
                type: "array",
            });
            const excelFile = new Blob([excelBuffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            var excelFiles = new File([excelFile], `student-crossreference.xlsx`, {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const excels3url = `${ScreeningImageLink}${excelFiles.name}`;

            // Generate Original Excel Sheet Code ends here
            // Generate Stored data Excel Sheet Code Starts Here
            var modifiedExcelData = excelData.map((data, index) => {
                var newObj = { ...data, studentId: index + 1 };
                delete newObj.nurseid;
                delete newObj.type;
                delete newObj.screeningId;
                delete newObj.studentid
                return newObj;
            });
            const dataExcelSheet = XLSX.utils.json_to_sheet(modifiedExcelData);

            const dataExcelSheetWrokbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
                dataExcelSheetWrokbook,
                dataExcelSheet,
                "FilteredSheet"
            );

            const dataExcelBuffer = XLSX.write(dataExcelSheetWrokbook, {
                bookType: "xlsx",
                type: "array",
            });
            const dataExcelFile = new Blob([dataExcelBuffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            var uploadedExcelFiles = new File(
                [dataExcelFile],
                `uploaded-student-data.xlsx`,
                {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                }
            );
            const dataExcelFileS3url = `${ScreeningImageLink}${uploadedExcelFiles.name}`;
            // Generate Stored data Excel Sheet Code Ends Here
            await UploadScreeningImages(pdfFile, `qr_${pdfFile.name}`);
            await UploadScreeningImages(excelFiles, `${excelFiles.name}`);
            await UploadScreeningImages(
                uploadedExcelFiles,
                `${uploadedExcelFiles.name}`
            );
            // to: CustomerAdminDetails.email,
            const payload = {
                to: CustomerAdminDetails.email,
                qrcode: studentQRPDF,
                firstname: `qr_${pdfFile.name}`,
                excelData: `${excelFiles.name}`,
                excels3url: excels3url,
                uploadedfilename: `${uploadedExcelFiles.name}`,
                uploadedfiles3url: dataExcelFileS3url,
            };

            axios
                .post(ApiEndPoint.sendQRCodeToStudentPDF, payload)
                .then((response) => {
                    if (response.data.status === "success") {
                        setSendMailToStudentPDF(false);
                        successAlert("Mail Sent To Nurse");
                        if (fileInputRef.current) {
                            fileInputRef.current.value = "";
                        }
                    } else {
                        setSendMailToStudentPDF(false);
                        errorAlert("Error.. While sending mail");
                        if (fileInputRef.current) {
                            fileInputRef.current.value = "";
                        }
                    }
                })
                .catch((err) => {
                    setSendMailToStudentPDF(false);
                    if (err) {
                        toast.error("An error occurred. Please try again.");
                    }
                });
        } catch (error) {
            setSendMailToStudentPDF(false);
            if (error) {
                toast.error("An error occurred. Please try again.");
            }
        }
    };


    const uploadExcelSendEmail = async () => {
        setIsUploading(true);
        try {
            const importData =
                IsSelectedImportStudent.length > 0
                    ? IsSelectedImportStudent
                    : excelData;
            const datas = await getDatas(importData);

            // codee for excel and pdf starts here
            const doc = new jsPDF();
            doc.autoTable({
                html: "#my-table2",
                styles: {
                    cellWidth: "wrap",
                },
                bodyStyles: { minCellHeight: "auto" },
                didDrawCell: function (data) {
                    if (data.column.index === 1 && data.cell.section === "body") {
                        var td = data.cell.raw;
                        var img = td.getElementsByTagName("canvas")[0];
                        var dim = data.cell.height - data.cell.padding("vertical");
                        var textPos = data.cell;
                        doc.addImage(img, textPos.x + 2, textPos.y + 2, 35, 35);
                    }
                },
                theme: "grid",
                pageBreak: "auto",
                rowPageBreak: "avoid",
                columnStyles: {
                    0: { cellWidth: 70, minCellHeight: 20 },
                    1: { cellWidth: 40, minCellHeight: 20 },
                    2: { cellWidth: 90, minCellHeight: 20 },
                },
            });

            var bloburls = doc.output("blob");
            let fileName = new Date().getTime();
            let date = moment(new Date()).format("DD_MM_YY");
            var pdfFile = new File(
                [bloburls],
                `${fileName}_${date}_${CustomerAdminDetails?.id}_${CustomerAdminDetails?.branch?.branch_admin_user_id}.pdf`
            );
            const studentQRPDF = `${ScreeningImageLink}qr_${pdfFile.name}`;
            // Generate Original Excel Sheet Code starts here
            const originalDataExcelSheet =
                XLSX.utils.json_to_sheet(originalExcelData);

            const newWorkbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
                newWorkbook,
                originalDataExcelSheet,
                "FilteredSheet"
            );

            const excelBuffer = XLSX.write(newWorkbook, {
                bookType: "xlsx",
                type: "array",
            });
            const excelFile = new Blob([excelBuffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            var excelFiles = new File([excelFile], `student-crossreference.xlsx`, {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const excels3url = `${ScreeningImageLink}${excelFiles.name}`;

            // Generate Original Excel Sheet Code ends here
            // Generate Stored data Excel Sheet Code Starts Here
            var modifiedExcelData = excelData.map((data, index) => {
                var newObj = { ...data, studentId: index + 1 };
                delete newObj.nurseid;
                delete newObj.type;
                delete newObj.screeningId;
                delete newObj.studentid
                return newObj;
            });
            const dataExcelSheet = XLSX.utils.json_to_sheet(modifiedExcelData);

            const dataExcelSheetWrokbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
                dataExcelSheetWrokbook,
                dataExcelSheet,
                "FilteredSheet"
            );

            const dataExcelBuffer = XLSX.write(dataExcelSheetWrokbook, {
                bookType: "xlsx",
                type: "array",
            });
            const dataExcelFile = new Blob([dataExcelBuffer], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            var uploadedExcelFiles = new File(
                [dataExcelFile],
                `uploaded-student-data.xlsx`,
                {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                }
            );
            const dataExcelFileS3url = `${ScreeningImageLink}${uploadedExcelFiles.name}`;
            // Generate Stored data Excel Sheet Code Ends Here
            await UploadScreeningImages(pdfFile, `qr_${pdfFile.name}`);
            await UploadScreeningImages(excelFiles, `${excelFiles.name}`);
            await UploadScreeningImages(
                uploadedExcelFiles,
                `${uploadedExcelFiles.name}`
            );
            // to: CustomerAdminDetails.email,
            const payload = {
                student: datas,
                to: CustomerAdminDetails.email,
                qrcode: studentQRPDF,
                firstname: `qr_${pdfFile.name}`,
                excelData: `${excelFiles.name}`,
                excels3url: excels3url,
                uploadedfilename: `${uploadedExcelFiles.name}`,
                uploadedfiles3url: dataExcelFileS3url,
            };
            // codee for excel and pdf ends here
            axios
                .post(ApiEndPoint.uploadStudentDetailsSendMailNurse, payload)
                .then((response) => {
                    setUploadDisabled(true);
                    if (response?.data.status === "success") {
                        Swal({
                            title: `Students details is saved`,
                            text:
                                `Unique students are ${Number(excelData.length) -
                                Number(response?.data.alreadyExistsStudent.length)
                                } \n` +
                                `Duplicate students are ${response?.data?.alreadyExistsStudent?.length}`,
                            icon: "success",
                            confirmButtonText: "Close",
                        });
                        setIsUploading(false);
                        if (fileInputRef.current) {
                            fileInputRef.current.value = "";
                        }
                    } else {
                        setIsUploading(false);
                    }
                })
                .catch((err) => {
                    errorAlert(err.message);
                    setIsUploading(false);
                    if (err) {
                        toast.error("An error occurred. Please try again.");
                    }
                });
        } catch (error) {
            setIsUploading(false);
            console.error("Error while uploading Excel data:", error);
            if (error) {
                toast.error("An error occurred. Please try again.");
            }
        }
    };

    const submit = async (data) => {
        setSendMailToStudentAsNurse(true);
        const importData =
            IsSelectedImportStudent.length > 0 ? IsSelectedImportStudent : excelData;
        toggle();
        const sendToStudentData = await getDatas(importData);
        let count = 0;
        sendToStudentData.map((send) => {
            const payload = {
                to: send.email,
                qrcode: send.qr,
                firstname: send.firstname,
                nurseemail: data.email,
                nursepassword: data.password,
            };
            axios
                .post(ApiEndPoint.sendQRCodeToStudentASNurse, payload)
                .then((response) => {
                    count = count + 1;
                    if (count == sendToStudentData.length) {
                        setSendMailToStudentAsNurse(false);
                        successAlert("Mail Sent To all selected students");
                        if (fileInputRef.current) {
                            fileInputRef.current.value = "";
                        }
                    }
                })
                .catch((err) => {
                    setSendMailToStudentAsNurse(false);
                    if (err) {
                        toast.error("An error occurred. Please try again.");
                    }
                });
        });
    };

    useEffect(() => {
        dispatch(setSelectedImportStudent([]));
    }, []);

    return (
        <>
            <ImportsModal modal={modal} toggle={toggle} submit={submit} />
            <section className="m-3 m-md-4 d-flex gap-4 flex-column">
                <div className="d-flex align-items-center">
                    <button
                        className=" border-0 bg-transparent"
                        onClick={() => navigate(-1)}
                    >
                        <i className="bx bx-arrow-back fs-6 title-font-500 px-2 bx-fade-left"></i>
                        <span className="fs-6 base-font-500">Back</span>{" "}
                    </button>
                </div>
                <div className="d-flex justify-content-center align-items-center flex-wrap gap-4">
                    <div className="bg-white border shadow-lg d-flex align-items-center p-2 rounded-3">
                        <input
                            type="file"
                            ref={fileInputRef}
                            className="form-control border-0 shadow-none"
                            onChange={handleFileChange}
                            accept=".xlsx,.csv"
                        />
                    </div>
                    {excelData?.length > 0 && (
                        <>
                            {sendMailToStudent ? (
                                <button
                                    type="button"
                                    className="border shadow-lg d-flex align-items-center  rounded-3 px-4 py-2 bg-base-color2 text-black base-font-400 mx-1 ms-3"
                                    disabled
                                >
                                    <span
                                        className="spinner-border spinner-border-sm me-2"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    Sending...
                                </button>
                            ) : (
                                <button
                                    className="border shadow-lg d-flex align-items-center text-light rounded-3 px-4 py-2 bg-base-color2 base-font-400 mx-1 ms-3"
                                    onClick={() => SendToStudent()}
                                    disabled
                                >
                                    Send To Students
                                </button>
                            )}
                            {sendMailToStudentPDF ? (
                                <button
                                    type="button"
                                    className="border shadow-lg d-flex align-items-center  rounded-3 px-4 py-2 bg-base-color2 text-black base-font-400 mx-1 ms-3"
                                    disabled
                                >
                                    <span
                                        className="spinner-border spinner-border-sm me-2"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    Sending...
                                </button>
                            ) : (
                                <button
                                    className="border shadow-lg d-flex align-items-center text-light  rounded-3 px-4 py-2 bg-base-color2 base-font-400 mx-1"
                                    onClick={() => SendAllToNurse()}
                                    disabled
                                >
                                    Send All To Nurse
                                </button>
                            )}
                            {sendMailToStudentAsNurse ? (
                                <button
                                    type="button"
                                    className="border shadow-lg d-flex align-items-center  rounded-3 px-4 py-2 bg-base-color2 text-black base-font-400 mx-1 ms-3"
                                    disabled
                                >
                                    <span
                                        className="spinner-border spinner-border-sm me-2"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    Sending...
                                </button>
                            ) : (
                                <button
                                    className="border shadow-lg d-flex align-items-center text-light rounded-3 px-4 py-2 bg-base-color2 base-font-400 mx-1"
                                    onClick={toggle}
                                    disabled
                                >
                                    Send As Nurse
                                </button>
                            )}
                            {isUploading ? (
                                <button
                                    type="button"
                                    className="border shadow-lg d-flex align-items-center  rounded-3 px-4 py-2 bg-base-color2 text-light base-font-400 mx-1 ms-3"
                                    disabled
                                >
                                    <span
                                        className="spinner-border spinner-border-sm me-2"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    Uploading...
                                </button>
                            ) : (
                                <button
                                    className="border shadow-lg d-flex align-items-center  rounded-3 px-4 py-2 bg-base-color2 base-font-500 mx-1  text-light"
                                    onClick={uploadExcelSendEmail}
                                >
                                    Save Students
                                </button>
                            )}
                        </>
                    )}
                    {!uploadDisabled && (
                        <a
                            href={ImportStudentExcelDemo}
                            download
                            className="border shadow-lg d-flex align-items-center text-white  rounded-3 px-4 py-2 bg-base-color2 base-font-400 mx-1 ms-3"
                            title={ImportStudentExcelDemo}
                        >
                            <DocumentDownload className="text-white pe-2" size="28" /> Demo
                            Excel
                        </a>
                    )}
                </div>
                <section className="">
                    {excelData?.length > 0 && (
                        <div className="bg-white p-3 p-md-4  mb-5 shadow-lg border-radius-3">
                            <div className="">
                                {<Table2 columns={columns} data={excelData} ref={pdfRef} />}
                            </div>
                        </div>
                    )}
                </section>

                <section className="">
                    {excelData?.length > 0 && (
                        <div className="bg-white p-3 p-md-4  mb-5 shadow-lg border-radius-3">
                            <div className="">
                                {
                                    <Table2
                                        columns={crosscolumns}
                                        data={excelData}
                                        ref={pdfRef}
                                    />
                                }
                            </div>
                        </div>
                    )}
                </section>

                <div className="row w-100 justify-content-center d-none">
                    <table id="my-table2" className="row w-100 justify-content-center">
                        <tbody>
                            {excelData?.map((tableData, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <div className="card-body text-left">
                                                <h4>Dear Parents,</h4>
                                                <br />
                                                <br />
                                                <p className="card-text">
                                                    Please scan the QR Code and take 3 pictures of your
                                                    childs teeth as shown in the video and submit.
                                                </p>
                                                <br />
                                                <br />
                                                <p className="card-text">
                                                    <a
                                                        href="#"
                                                        className="stretched-link text-danger"
                                                        style={{ position: "relative" }}
                                                    >
                                                        https://www.youtube.com/watch?v=y6l3FSt0DFs
                                                    </a>
                                                </p>
                                            </div>
                                        </td>
                                        <td>
                                            <QRCode
                                                id={`${tableData.screeningId}`}
                                                value={`${tableData.school};${tableData.grade};${tableData.classteacher};${tableData.screeningId}`}
                                                size={200}
                                                includeMargin={true}
                                                className="card-img-top"
                                            />
                                        </td>
                                        <td>
                                            <div className="card-body text-left">
                                                <h6 className="card-subtitle mb-2 text-muted">
                                                    School : {tableData.school} <br />
                                                </h6>
                                                <h6 className="card-subtitle mb-2 text-muted">
                                                    Grade : {tableData.grade}
                                                    {tableData.grade == 1 ? (
                                                        <sup>st</sup>
                                                    ) : tableData.grade == 2 ? (
                                                        <sup>nd</sup>
                                                    ) : tableData.grade == 3 ? (
                                                        <sup>rd</sup>
                                                    ) : (
                                                        <sup>th</sup>
                                                    )}
                                                    <br />
                                                </h6>
                                                <h6 className="card-subtitle mb-2 text-muted">
                                                    Class Teacher : {tableData.classteacher} <br />
                                                </h6>
                                                <h6 className="card-subtitle mb-2 text-muted">
                                                    Student Id : {tableData.screeningId} <br />
                                                </h6>
                                                <h6 className="card-subtitle mb-2 text-muted">
                                                    First Name : {tableData.firstname} <br />
                                                </h6>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
};

export default Import;
