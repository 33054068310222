import swal from "sweetalert";
import './Alerts.css'

export const warningAlert = (info) => {
    return swal({
        title: "Warning",
        icon: "https://i.giphy.com/media/05xkZpZokVu7pLxKtm/giphy.webp",
        text: info ?? "Are you sure!",
        dangerMode: true,
    });
}
export const successAlert = (info) => {
    return swal({
        title: "Success",
        timer: 2500,
        text: info ? info : 'Successfully !',
        icon: "https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g",
    })
}
export const errorAlert = (info) => {
    return swal({
        title: "Error",
        text: info ? info : "Delete",
        icon: "https://media2.giphy.com/media/1oaDRmHNYuzlw4LsUC/giphy.gif?cid=790b7611143e97e9257a94ee1c94260fd10462bf3f058b24&rid=giphy.gif&ct=g",
    });
}
