import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  username: undefined,
}

export const RegistrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    registrationUserName: (state, action) => {
      state.username = action.payload
    }
  },
})

export const { registrationUserName } = RegistrationSlice.actions

export default RegistrationSlice.reducer