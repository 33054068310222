export const reportCodeStudent = {
    code1: "Your child has no visible signs of an obvious dental problem; this is only a dental screening, not a comprehensive exam. It is recommended your child sees a dentist twice a year to prevent the development of dental disease.",
    code2: "If your child has not seen a dentist in the last six months, you should schedule an appointment for preventive care (cleaning or sealants).",
    code3: "Your child appears to have visible signs of dental problems which should be evaluated by a dentist. Please make an appointment at your earliest convenience so that your child can receive a complete examination. Your dentist will determine if any treatment is needed.",
    code4: "Your child appears to have visible signs of an urgent dental need. An immediate visit to a dentist for complete examination is recommended.",
};

export const reportCodePatient = {
    code1: "You have no visible signs of an obvious dental problem; this is only a dental screening, not a comprehensive exam. It is recommended you have sees a dentist twice a year to prevent the development of dental disease.",
    code2: "If You have not seen a dentist in the last six months, you should schedule an appointment for preventive care (cleaning or sealants).",
    code3: "You appear to have visible signs of dental problems which should be evaluated by a dentist. Please make an appointment at your earliest convenience so that You have can receive a complete examination. Your dentist will determine if any treatment is needed.",
    code4: "You appear to have visible signs of an urgent dental need. An immediate visit to a dentist for complete examination is recommended.",
};

export const states = [
    { label: "Alaska", value: "Alaska" },
    { label: "Alabama", value: "Alabama" },
    { label: "Arkansas", value: "Arkansas" },
    { label: "Arizona", value: "Arizona" },
    { label: "California", value: "California" },
    { label: "Colorado", value: "Colorado" },
    { label: "Connecticut", value: "Connecticut" },
    { label: "District of Columbia", value: "District of Columbia" },
    { label: "Delaware", value: "Delaware" },
    { label: "Florida", value: "Florida" },
    { label: "Georgia", value: "Georgia" },
    { label: "Hawaii", value: "Hawaii" },
    { label: "Iowa", value: "Iowa" },
    { label: "Idaho", value: "Idaho" },
    { label: "IL", value: "Illinois" },
    { label: "Illinois", value: "Indiana" },
    { label: "Kansas", value: "Kansas" },
    { label: "Kentucky", value: "Kentucky" },
    { label: "Louisiana", value: "Louisiana" },
    { label: "Massachusetts", value: "Massachusetts" },
    { label: "Maryland", value: "Maryland" },
    { label: "Maine", value: "Maine" },
    { label: "Michigan", value: "Michigan" },
    { label: "Minnesota", value: "Minnesota" },
    { label: "Missouri", value: "Missouri" },
    { label: "Mississippi", value: "Mississippi" },
    { label: "Montana", value: "Montana" },
    { label: "North Carolina", value: "North Carolina" },
    { label: "North Dakota", value: "North Dakota" },
    { label: "Nebraska", value: "Nebraska" },
    { label: "New Hampshire", value: "New Hampshire" },
    { label: "New Jersey", value: "New Jersey" },
    { label: "New Mexico", value: "New Mexico" },
    { label: "Nevada", value: "Nevada" },
    { label: "New York", value: "NewYork" },
    { label: "Ohio", value: "Ohio" },
    { label: "Oklahoma", value: "Oklahoma" },
    { label: "Oregon", value: "Oregon" },
    { label: "Pennsylvania", value: "Pennsylvania" },
    { label: "Rhode Island", value: "Rhode Island" },
    { label: "South Carolina", value: "South Carolina" },
    { label: "South Dakota", value: "South Dakota" },
    { label: "Tennessee", value: "Tennessee" },
    { label: "Texas", value: "Texas" },
    { label: "Utah", value: "Utah" },
    { label: "Virginia", value: "Virginia" },
    { label: "Vermont", value: "Vermont" },
    { label: "Washington", value: "Washington" },
    { label: "Wisconsin", value: "Wisconsin" },
    { label: "West Virginia", value: "West Virginia" },
    { label: "Wyoming", value: "Wyoming" },
];

export const ImportDistrictExcelDemo = "https://emailpdfbucket.s3.amazonaws.com/import-districts_demo_excel_sheet.xlsx";
export const ImportEvaluatorExcelDemo = "https://emailpdfbucket.s3.amazonaws.com/import_evaluator_demo_excel_sheet.xlsx";
export const ImportStudentExcelDemo = "https://emailpdfbucket.s3.amazonaws.com/student_upload_demo_excel_sheet.xlsx";

import FrontTeeth from "../../constants/assests/images/FrontTeethDemo.jpg";
import TopTeeth from "../../constants/assests/images/TopDemo.jpg";
import BottomTeeth from "../../constants/assests/images/BottomDemo.jpg";

export const low_risk_pdf = 'https://virtualscreeningprofilepic.s3.amazonaws.com/sleep_apnea/LowRisk.pdf'
export const moderate_risk_pdf = 'https://virtualscreeningprofilepic.s3.amazonaws.com/sleep_apnea/ModerateRisk.pdf'
export const high_risk_pdf = 'https://virtualscreeningprofilepic.s3.amazonaws.com/sleep_apnea/HighRisk.pdf'

export const mallampatiImage = 'https://virtualscreeningprofilepic.s3.amazonaws.com/sleep_apnea/mallampatiscore_913672.jpg';
export const mallampatiImgCredit = "https://www.clinicaladvisor.com/home/the-waiting-room/understanding-the-mallampati-score/";


export const letter_to_teacher = 'https://virtualscreeningprofilepic.s3.amazonaws.com/sleep_apnea/Letter_From_Nuse_to_Teachers.pdf'

export const  TongueImage = "https://virtualscreeningprofilepic.s3.amazonaws.com/sleep_apnea/Sleep_Apnea_Toung_Image_Demo.jpeg";
export const SideBiteImage = "https://virtualscreeningprofilepic.s3.amazonaws.com/sleep_apnea/Sleep_Apnea_Side_Bite_Image_Demo.jpeg";
export const TopImage = 'https://virtualscreeningprofilepic.s3.amazonaws.com/sleep_apnea/Sleep_Apnea_Top_Image_Demo.jpeg';

const code1PDF = "https://emailpdfbucket.s3.amazonaws.com/result_code/School_Screening_Result_Code_1.pdf";
const code2PDF = "https://emailpdfbucket.s3.amazonaws.com/result_code/School_Screening_Result_Code_2.pdf";
const code3PDF = "https://emailpdfbucket.s3.amazonaws.com/result_code/School_Screening_Result_Code_3.pdf";
const code4PDF = "https://emailpdfbucket.s3.amazonaws.com/result_code/School_Screening_Result_Code_4.pdf";


const IntegrateOptions = [
    { value: "patient", label: "Patient" },
    { value: "student", label: "Student" },
];

const ClinicOptions = [
    { value: "patient", label: "Patient" },
];

const SchoolOption = [{ value: "student", label: "Student" }]

export const patientOptions = (serviceType) => {
    if (serviceType === "clinic_community_screening") {
        return ClinicOptions
    } else if (serviceType === "clinic_individual_screening") {
        return SchoolOption
    } else if (serviceType === "clinic_integrated_screening") {
        return IntegrateOptions
    } else {
        return SchoolOption;
    }
};

export const defaultPatientType = (serviceType) => {
    if (serviceType === "clinic_community_screening") {
        return ClinicOptions[0]
    } else if (serviceType === "clinic_individual_screening") {
        return SchoolOption[0]
    } else if (serviceType === "clinic_integrated_screening") {
        return IntegrateOptions[0]
    } else if (serviceType === "community_screening") {
        return SchoolOption[0]
    }
};

const code_1_mail_body = `Dear Parent or Guardian,

Your child received a dental screening at school to fulfill the requirements for Kansas State Statute 72-6251.
A dental screening is a short visual assessment that does not require instruments, and no x-rays or
dental services were provided. A dental screening does not replace an in-office dental examination by a
dentist. A licensed dentist or dental hygienist completed the screening.

The results of the screening indicate that: Your child has no visible signs of an obvious dental problem;
this is only a dental screening, not a comprehensive exam. The American Dental Association
recommends everyone see the dentist twice per year for professional cleanings and oral examination.
This screening DOES NOT replace the exam provided by your dentist.

If you or someone in your family needs assistance, please visit the KanCare self-portal to see if you are
eligible and/or to apply for benefits (cssp.kees.ks.gov/apspssp/).

Thank you,
School Nurse.
`;

const code_2_mail_body = `Dear Parent or Guardian,

Your child received a dental screening at school to fulfill the requirements for Kansas State Statute 72-
6251. A dental screening is a short visual assessment that does not require instruments, and no x-rays or
dental services were provided. A dental screening does not replace an in-office dental examination by a
dentist. A licensed dentist or dental hygienist completed the screening.

The results of the screening indicate that: If your child has not seen a dentist in the last six months, you
should schedule an appointment for preventive care (cleaning or sealants). The American Dental
Association recommends everyone see the dentist twice per year for professional cleanings and oral
examination. This screening DOES NOT replace the exam provided by your dentist.

If you or someone in your family needs assistance, please visit the KanCare self-portal to see if you are
eligible and/or to apply for benefits (cssp.kees.ks.gov/apspssp/).

Thank you,
School Nurse.
`;


const code_3_mail_body = `Dear Parent or Guardian,

Your child received a dental screening at school to fulfill the requirements for Kansas State Statute 72-
6251. A dental screening is a short visual assessment that does not require instruments, and no x-rays or
dental services were provided. A dental screening does not replace an in-office dental examination by a
dentist. A licensed dentist or dental hygienist completed the screening.

The results of the screening indicate that: Your child appears to have visible signs of dental problems
which should be evaluated by a dentist. Please make an appointment at your earliest convenience so
that your child can receive a complete examination. Your dentist will determine if any treatment is
needed. The American Dental Association recommends everyone see the dentist twice per year for
professional cleanings and oral examination. This screening DOES NOT replace the exam provided by
your dentist.

If you or someone in your family needs assistance, please visit the KanCare self-portal to see if you are
eligible and/or to apply for benefits (cssp.kees.ks.gov/apspssp/).

Thank you,
School Nurse.
`;

const code_4_mail_body = `Dear Parent or Guardian,

Your child received a dental screening at school to fulfill the requirements for Kansas State Statute 72-
6251. A dental screening is a short visual assessment that does not require instruments, and no x-rays or
dental services were provided. A dental screening does not replace an in-office dental examination by a
dentist. A licensed dentist or dental hygienist completed the screening.

The results of the screening indicate that: Your child appears to have visible signs of an urgent dental
need. An immediate visit to a dentist for complete examination is recommended. The American Dental
Association recommends everyone see the dentist twice per year for professional cleanings and oral
examination. This screening DOES NOT replace the exam provided by your dentist.

If you or someone in your family needs assistance, please visit the KanCare self-portal to see if you are
eligible and/or to apply for benefits (cssp.kees.ks.gov/apspssp/).

Thank you,
School Nurse.
`;



export { FrontTeeth, TopTeeth, BottomTeeth, code1PDF, code2PDF, code3PDF, code4PDF, code_1_mail_body, code_2_mail_body, code_3_mail_body, code_4_mail_body}