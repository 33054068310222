import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import HeaderTitle from '../../Components/ProfileHeader/HeaderTitle';
import CollectionHeader from '../../Components/CollectionApp/CollectionHeader';
import PatientCollectionApp from '../../Components/CollectionApp/PatientCollectionApp';
import StudentCollectionApp from '../../Components/CollectionApp/StudentCollectionApp';
import { selectPatientType } from '../../redux/Slice/CollectionApp';
import { loggedUserDetails } from '../../redux/Slice/LoginSlice';
import axios from 'axios';
import { ApiEndPoint } from '../../constants/apiEndPoints/endPoints';
import { toast } from 'react-hot-toast';

const CollectionApp = () => {
  const dispatch = useDispatch();
  const selectedPatientType = useSelector((state) => state.collection.selectedPatientType);
  const username = useSelector((state) => state.login.username);

  const GetUserDetails = async () => {
    const url = `${ApiEndPoint.nurseDetails}${username}/`;
    await axios.get(url).then((response) => {
      dispatch(loggedUserDetails(response.data.nurseDetails));
    }).catch((e) => {
      if (e) {
        toast.error("An error occurred. Please try again.")
      }
    });
  };

  useEffect(() => {
    if (username) {
      GetUserDetails();
    };
  }, [username]);

  useEffect(() => {
    dispatch(selectPatientType({ value: 'patient', label: 'Patient' }));
  }, []);

  return (
    <>
      <section className="px-2 px-md-5 pt-2 pb-5">
        <CollectionHeader />
        {selectedPatientType?.value === "patient" ? <PatientCollectionApp /> : <StudentCollectionApp />}
      </section>
    </>
  )
}

export default CollectionApp