import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Form, Label } from "reactstrap";
import ErrorComponents from "../../Components/ErrorComponents/ErrorComponents";
import user from "../../constants/assests/images/user.png"
import { preventNegativeValues } from "../../Components/PreventValues/preventNegativeValues";
import {
  uploadProfilePic,
  UploadProfileLink,
} from "../../constants/AWS/AWSConfiguration";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { useDispatch, useSelector } from "react-redux";
import UserPool from "../../constants/UserPool";
import { registrationUserName } from "../../redux/Slice/RegistrationSlice";
import AWS from "aws-sdk";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import HeaderTitle from "../../Components/ProfileHeader/HeaderTitle";
import { ApiEndPoint } from "../../constants/apiEndPoints/endPoints";
import { errorAlert, successAlert } from "../../Components/Alerts/Alerts";
import PhoneInput from "react-phone-input-2";
import { Eye, EyeSlash, Lock, SmsNotification, UserEdit } from 'iconsax-react';
import Select from "react-select";
import { states } from "../../constants/apiEndPoints/ReportCode";
import InputMask from 'react-input-mask';
// import PhoneInput from ';

const CreateBranch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const CustomerAdminDetails = useSelector(
    (state) => state.login.loggedUserDetails
  );
  const [usernameExists, setUsernameExists] = useState();
  const [Loader, setLoader] = React.useState(false);
  const [PasswordToggle, setPasswordToggle] = useState(false);
  const customerProfileDetails = useSelector((state) => state.customer.customerProfileDetails);


  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const Submit = async (data) => {
    setLoader(true);
    if (upload) {
      const timestamp = Date.now();
      await uploadProfilePic(upload, timestamp);
      var url = `${UploadProfileLink}${timestamp}_${upload?.name}`;
    }
    data.image = url ? url : null;

    const attributesList = [
      new CognitoUserAttribute({
        Name: "email",
        Value: data.email,
      }),
      new CognitoUserAttribute({
        Name: "given_name",
        Value: data.name,
      }),
      new CognitoUserAttribute({
        Name: "name",
        Value: data.username,
      }),
      new CognitoUserAttribute({
        Name: "middle_name",
        Value: data.lname,
      }),
    ];

    const CreateBranchPayload = {
      logo: data.image,
      name: data.name,
      email: data.email,
      phone_number: data.phone_number,
      city: data.city,
      state: data.state,
      zip: data.zipcode,
      street_address: data.address,
      branch_admin_user_id: data.username,
      customer_id: CustomerAdminDetails.id,
      education_link: data.education_link,
      appointment_link: data.appointment_link,
    };

    UserPool.signUp(
      data.username,
      data.password,
      attributesList,
      null,
      async (err, response) => {
        if (err) {
          errorAlert(err.message);
          setLoader(false);
          if (err.message === "User already exists") {
            setUsernameExists(err.message);
            errorAlert(err.message);
            setLoader(false);
          }
        }
        if (response) {
          successAlert(response?.data?.massage);
          await CreateBranch(CreateBranchPayload);
          dispatch(registrationUserName(data.username));
          var cognitoidentityserviceprovider =
            new AWS.CognitoIdentityServiceProvider({
              accessKeyId: "AKIA2F5EPKY5GTWZLBO5",
              secretAccessKey:'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',
              region: "us-east-1",
              AWS_CONFIG_FILE: 1,
            });
          var params = {
            GroupName: "branchadmin" /* required */,
            UserPoolId: "us-east-1_dHwclMn0X" /* required */,
            Username: data.username /* required */,
          };
          var params2 = {
            UserPoolId: "us-east-1_dHwclMn0X",
            Username: data.username,
          };

          cognitoidentityserviceprovider.adminConfirmSignUp(params2, function (err, data) {
            if (err) toast.error("An error occurred. Please try again.");
          });
          setLoader(false);
          cognitoidentityserviceprovider.adminAddUserToGroup(
            params,
            function (err, data) {
              if (err) {
                toast.error("An error occurred. Please try again.");
                setLoader(false);
              } else {
                setLoader(false);
                navigate("/")
              }
            }
          );
        }
      }
    );
  };

  const CreateBranch = async (payload) => {
    payload.state = payload.state.value;
    setLoader(true);
    try {
      await axios.post(ApiEndPoint.createBranch, payload).then((response) => {
        successAlert(response?.data?.massage);
        setLoader(false);
      }).catch((err) => {
        errorAlert(err.massage);
        setLoader(false);
      })
    } catch (error) {
      errorAlert(error.message);
      setLoader(false);
    }
  };

  const [file, setFile] = React.useState("");
  const [upload, setUpload] = React.useState("");

  const handleimagechange = (e) => {
    const filedata = e.target.files[0]
    setUpload(filedata)
  }
  const handlechange = (e) => {
    handleimagechange(e);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  return (
    <section className="px-3 px-md-5 pt-2 ">
      <div className="shadow-lg rounded-4">
        <div className="basic-details-back p-2 rounded-4">
          <h4 className="title-font-700 mb-0 fs-4 px-3 text-capitalize text-base-color">
            {customerProfileDetails?.name}
          </h4>
        </div>
      </div>
      <div className="px-2 py-3 p-md-4  shadow-lg my-4 bg-white border-radius-3">
        <Form onSubmit={handleSubmit(Submit)} autoComplete="off">
          <div className="col-11 mx-auto mx-md-0 pb-3 text-center text-md-start pt-1">
            <label className="title-font-600 mb-0 fs-5 text-uppercase border-bottom-color2-2">
              Create Branch
            </label>
          </div>
          <div className="row">
            <div className="col-11 mx-auto mx-md-0 col-md-6 text-center text-md-start col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                Profile Pic
              </Label>
              <div className=" ">
                <div className=" d-flex justify-content-md-between justify-content-center align-items-center ">
                  <div className="d-flex align-items-center justify-content-center gap-4">
                    <div className="basic-details-imgBox position-relative">
                      <img src={file ? file : user} className='user-image-fit border shadow' />
                      <div className="position-absolute bottom-0 end-0 bg-white rounded-circle shadow border" style={{ cursor: "pointer" }}>
                        {/* <label htmlFor="file-input">
                          <UserEdit size="40" variant="Bulk" className='text-base-color p-2' />
                        </label> */}
                        <label htmlFor="file-input" className="text-base-color text-decoration-underline p-2 fs-6" role="button">
                          Edit
                        </label>
                        <input
                          id='file-input'
                          type="file"
                          className='d-none'
                          {...register("image", { required: true })}
                          onChange={(e) => handlechange(e)}
                          accept="image/jpeg,image/png,image/gif"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {(errors?.image && !file) && (
                <ErrorComponents error={"Images is required *"} />
              )}
            </div>

            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                Branch Name
              </Label>

              <div className="mt-1  d-flex gap-3">
                <input
                  className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="text"
                  placeholder="Name..."
                  {...register("name", { required: true })}
                />
              </div>
              {errors.name && <ErrorComponents error={"Name is required *"} />}
            </div>

            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                Email
              </Label>

              <div className="mt-1  d-flex gap-3">
                <input
                  className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="email"
                  autoComplete="nope"
                  placeholder="Email..."
                  {...register("email", { required: true })}
                />
              </div>
              {errors.email && (
                <ErrorComponents error={"Email is required *"} />
              )}
            </div>

            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                Phone Number
              </Label>

              <div className="p-1 border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input">
                <Controller
                  name="phone_number"
                  control={control}
                  rules={{
                    required: "Phone number is required",
                  }}
                  render={({ field: { onChange, value } }) => (
                    <div
                      className="p-2 border-radius-10 shadow-none  w-100 fs-6 title-font-500 d-flex gap-2"
                    >
                      <span>+1</span>
                      <InputMask
                        mask="(999) 999-9999"
                        value={value}
                        onChange={(val) => onChange(val)}
                        type="tel"
                        placeholder=" (111)111-1111"
                        className="bg-transparent title-font-500 w-100 base-white-light border-0 shadow-none p-0"
                      />
                    </div>
                  )}
                />
              </div>
              {errors?.phone_number && (
                <ErrorComponents error={errors.phone_number?.message} />
              )}
            </div>

            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                Street Address *
              </Label>
              <div className="mt-1  d-flex gap-3">
                <input
                  className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="text"
                  placeholder="Enter Address..."
                  {...register("address", {
                    required: "Address is required *",
                  })}
                />
              </div>
              {errors.address && (
                <ErrorComponents error={errors.address?.message} />
              )}
            </div>

            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                City *
              </Label>
              <div className="mt-1  d-flex gap-3">
                <input
                  className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="text"
                  placeholder="Enter city..."
                  {...register("city", {
                    required: "City is required *",
                  })}
                />
              </div>
              {errors.city && <ErrorComponents error={errors.city?.message} />}
            </div>
            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                State *
              </Label>

              <div className="mt-1  d-flex gap-3">
                {/* <input
                  className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="text"
                  placeholder="Enter State..."
                  {...register("state", {
                    required: "State is required *",
                  })}
                /> */}
                <Controller
                  control={control}
                  name="state"
                  autoComplete="nope"
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={states}
                      onChange={onChange}
                      value={value}
                      placeholder={"Select State "}
                      className="border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                      ref={register("state", {
                        required: "State is required *",
                      })}
                    />
                  )}
                />
              </div>
              {errors.state && (
                <ErrorComponents error={errors.state?.message} />
              )}
            </div>

            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                Zip code *
              </Label>

              <div className="mt-1  d-flex gap-3">
                <input
                  className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="number"
                  min={0}
                  maxLength={5}
                  onKeyDown={preventNegativeValues}
                  placeholder="Enter zipcode here..."
                  {...register("zipcode", {
                    required: true, maxLength: {
                      value: 6,
                      message: "Zip code cannot exceed 6 characters."
                    }
                  })}
                />
              </div>
              {errors?.zipcode?.type === "required" && (
                <ErrorComponents error={"Zipcode is required *"} />
              )}
              {errors?.zipcode?.type === "maxLength" && (
                <ErrorComponents error={errors.zipcode.message} />
              )}
            </div>

            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                Patient Education Content Link *
              </Label>
              <div className="mt-1  d-flex gap-3">
                <input
                  className="p-2 border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="text"
                  placeholder="Enter Patient Education Content Link..."
                  {...register("education_link", {
                    required: "Patient Education Content Link is required *",
                    // pattern: {
                    //   value: /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
                    //   message: "Invalid link format. eg 'www.example.com'",
                    // }
                  })}
                />
              </div>
              {errors.education_link && (
                <ErrorComponents error={errors.education_link?.message} />
              )}
            </div>
            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                Patient Appointment Scheduling Link *
              </Label>
              <div className="mt-1  d-flex gap-3">
                <input
                  className="p-2 border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="text"
                  placeholder="Enter Patient Appointment Scheduling Link..."
                  {...register("appointment_link", {
                    required: "Patient Appointment Scheduling Link is required *",
                    // pattern: {
                    //   value: /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
                    //   message: "Invalid link format. eg 'www.example.com'",
                    // }
                  })}
                />
              </div>
              {errors.appointment_link && (
                <ErrorComponents error={errors.appointment_link?.message} />
              )}
            </div>

            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                Branch Admin User Name
              </Label>

              <div className="mt-1  d-flex gap-3">
                <input
                  className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="text"
                  placeholder="User Name..."
                  {...register("username", { required: true })}
                />
              </div>
              {errors.username && (
                <ErrorComponents error={"User Name is required *"} />
              )}
              {usernameExists && (
                <ErrorComponents error={"User already exists"} />
              )}
            </div>

            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                Password
              </Label>

              <div className="mt-1 d-flex gap-3 p-1 px-2 border-radius-10 shadow-none w-100 modal-input">
                <input
                  className=" w-100 border-0 fs-6 title-font-500"
                  type={PasswordToggle ? 'text' : 'password'}
                  autoComplete="new-password"
                  placeholder="Password..."
                  {...register("password", { required: true })}
                />

                <button
                  type="button"
                  className="bg-white border-0 shadow-none p-1 rounded-3"
                  onClick={() => setPasswordToggle(!PasswordToggle)}
                >
                  {PasswordToggle ? (
                    <Eye size="25" className="text-base-color" />
                  ) : (
                    <EyeSlash size="25" className="text-base-color" />
                  )}
                </button>
              </div>
              {errors.password && (
                <ErrorComponents error={"Password is required *"} />
              )}
            </div>

            {/* <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
                            <Label className="fs-6 title-font-600 mb-0 text-black">
                                Phone Number
                            </Label>
                            <div className="mt-1  d-flex gap-3">
                                <input
                                    className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                                    type="text"
                                    placeholder="Enter Phone Number..."
                                    {...register("phone_number", {
                                        required: "Phone number is required *"
                                    })}
                                />
                            </div>
                            {errors.city && (
                                <ErrorComponents error={errors.city?.message} />
                            )}

                        </div> */}

            <div className="d-flex align-items-center justify-content-center justify-content-md-end  gap-3 pt-3">
              <div className="d-flex align-items-center gap-3">
                {Loader ? (
                  <button
                    type="button"
                    className="submit-btn d-flex gap-2 align-items-center"
                  >
                    <span
                      className="spinner-border spinner-border-sm" disabled
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Creating . . .
                  </button>
                ) : (
                  <button type="submit" className="submit-btn">
                    Submit
                  </button>
                )}
                <button
                  type="button"
                  className="close-btn"
                  onClick={() => navigate('/')}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </section>
  );
};

export default CreateBranch;
