import React, { useState, useEffect } from 'react'
import { FormGroup, Input, Label } from 'reactstrap'
import { useForm, Controller } from "react-hook-form"
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from '../../constants/UserPool';
import { NavLink, useNavigate } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from 'react-redux';
import { loggedUserDetails, login, role, username } from '../../redux/Slice/LoginSlice';
import { Eye, EyeSlash, Lock, SmsNotification } from 'iconsax-react';
import ErrorComponents from '../../Components/ErrorComponents/ErrorComponents';
import { toast } from 'react-hot-toast';
import Navbar from '../../Components/Navbar/Navbar';
import LoginImg from '../../constants/assests/images/login.jpg'
import { setDefaultDropDownCustomerBranchList } from '../../redux/Slice/CustomerSlice';

const Login = () => {
    const navigate = useNavigate();
    const [load, setLoad] = useState(false);
    const [PasswordToggle, setPasswordToggle] = useState(false);
    const dispatch = useDispatch();
    const routes = [{
        route: [
            {
                url: "/help",
                name: "Help"
            }
        ]
    }];

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        setLoad(true)
        const authData = {
            Username: data.email,
            Password: data.password
        };

        const authDetails = new AuthenticationDetails(authData);

        const userData = {
            Username: data.email,
            Pool: UserPool
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.authenticateUser(authDetails, {
            onSuccess: (results) => {
                const token = results.getIdToken().getJwtToken();
                var decoded = jwt_decode(token);
                dispatch(login(true));
                dispatch(role(decoded['cognito:groups'][0]));
                dispatch(username(data.email));
                toast.success("Successfully logged in");
                navigate('/');
                setLoad(false);
            },
            onFailure: (err) => {
                toast.error(err.message);
                setLoad(false)
            },
        })
    };

    useEffect(() => {
        dispatch(loggedUserDetails(undefined));
    }, []);


    return (
        <>
            <Navbar routes={routes} />
            <section className="px-2 px-md-5 pt-2 pb-5">
                <div className="d-flex justify-content-center align-items-center pt-4" >
                    <div className="container pt-5 mt-4 h-100 ">
                        <div className="row d-flex justify-content-center align-items-center h-100 ">
                            <div className="col col-xl-10 px-0 ">
                                <div className="card border-0 rounded-4 bg-white shadow-lg p-2 bg-white mx-2 mx-md-0">
                                    <div className="d-md-flex g-0 p-md-2  justify-content-md-center align-items-md-center">
                                        <div className="col-md-6 col-lg-4 d-none d-md-block ">
                                            <div className="rounded-4" style={{ width: "100%", height: "24rem" }} >
                                                <img src={LoginImg}
                                                    alt="login form" className="img-fit rounded-4" loading='lazy' />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-8 d-flex align-items-center">
                                            <div className="card-body p-2 p-lg-4 text-black">
                                                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                                    <div className="d-flex justify-content-center justify-content-md-start align-items-center">
                                                        <span className="fs-2 title-font-800  mb-0">Welcome <span className='text-base-color2'>back!</span></span>
                                                    </div>
                                                    <h6 className="base-font-500 fs-6 pb-2 text-base-gray text-center text-md-start" >Sign into your account</h6>
                                                    <FormGroup>
                                                        <label htmlFor="email" className='m-0 fs-6  title-font-600'>
                                                            Username
                                                        </label>
                                                        <Controller
                                                            control={control}
                                                            name="email"
                                                            rules={{
                                                                required: true,
                                                            }}
                                                            render={({ field: { onChange, value } }) => (
                                                                <div className='d-flex align-items-center rounded-3 base-white-light p-1 px-2'>
                                                                    <div className="bg-white p-1 rounded-3">
                                                                        <SmsNotification size="25" className='text-base-color' />
                                                                    </div>
                                                                    <Input type='text' invalid={errors.email} valid={errors.email} className='border-0 shadow-none bg-transparent title-font-500' id='email' placeholder='Please enter username.' autoComplete="nope" onChange={onChange} value={value} />
                                                                </div>
                                                            )}
                                                        />
                                                        {errors.email?.type === "required" && <ErrorComponents error={"User name is required *"} />}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label htmlFor="password" className='m-0 fs-6  title-font-600'>
                                                            Password
                                                        </Label>
                                                        <Controller
                                                            control={control}
                                                            name="password"
                                                            rules={{
                                                                required: true,
                                                            }}
                                                            render={({ field: { onChange, value } }) => (
                                                                <div className='d-flex align-items-center justify-content-between rounded-3 base-white-light p-1 px-2'>
                                                                    <div className="d-flex align-items-center w-100">
                                                                        <div className="bg-white p-1 rounded-3">
                                                                            <Lock size="25" className='text-base-color' />
                                                                        </div>
                                                                        <Input type={PasswordToggle ? 'text' : 'password'} invalid={errors.password} className='border-0 shadow-none bg-transparent w-100 title-font-500' id='password' placeholder='Please enter password.' autoComplete="new-password" onChange={onChange} value={value} />
                                                                    </div>
                                                                    <button type="button" className="bg-white border-0 shadow-none p-1 rounded-3" onClick={() => setPasswordToggle(!PasswordToggle)}>
                                                                        {PasswordToggle ? <Eye size="25" className='text-base-color' /> : <EyeSlash size="25" className='text-base-color' />}
                                                                    </button>
                                                                </div>
                                                            )}
                                                        />
                                                        {errors.password && <ErrorComponents error={"Password is required *"} />}
                                                        {/* <div className="d-flex justify-content-end py-2 pt-3">
                                                            <NavLink to="/forget-password" className='fs-6  text-base-gray-light  base-font-500 text-decoration-underline'>
                                                                Forget Password?
                                                            </NavLink>
                                                        </div> */}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        {
                                                            load ? <button
                                                                className="fw-bold btn fs-5 bg-base-color border-0 w-100 rounded-3 d-flex gap-2 align-items-center justify-content-center text-white title-font-bold bg-base-color text-uppercase"
                                                                type="button"
                                                                disabled
                                                            >
                                                                <span
                                                                    className="spinner-border spinner-border-sm" disabled
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                ></span>
                                                                Logging in . . .
                                                            </button> : <button className=' fw-bold btn fs-5 bg-base-color border-0 w-100 rounded-3 text-white title-font-bold text-uppercase shadow-none '>
                                                                sign in
                                                            </button>
                                                        }

                                                    </FormGroup>
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default Login