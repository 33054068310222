import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import AddComments from "./AddComments";
import { ReportCustomization } from "./ReportCustomization";

export const CustomSettings = () => {
    const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <>
      <Nav tabs className="all-tabs border-0" style={{ width: "100%" }}>
        <NavItem className="custom-nav-item">
          <NavLink
            className={activeTab === "1" ? "active-tab" : "non-active-tab"}
            onClick={() => toggleTab("1")}
          >
            Mallampati classification comments
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "2" ? "active-tab" : "non-active-tab"}
            onClick={() => toggleTab("2")}
          >
           Report Customization
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <>
            {activeTab === "1" && (
              <>
                <AddComments />
              </>
            )}
          </>
        </TabPane>

        <TabPane tabId="2">
          <>
            {activeTab === "2" && (
              <>
               <ReportCustomization />
              </>
            )}
          </>
        </TabPane>
      </TabContent>
    </>
  )
}
