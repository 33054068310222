import React from 'react'
import Skeleton from 'react-loading-skeleton'

const ProfileLoader = () => {
    return (
        <div className="basic-details-back-main  shadow-lg rounded-4">
            <div className="basic-details-back rounded-top-4">
                <div className="basic-details-user-img  d-flex align-items-center justify-content-between flex-column flex-md-row gap-4 gap-md-0">
                    <div className="d-flex align-items-center justify-content-center justify-content-md-start flex-column flex-md-row gap-3 gap-md-0">
                        <Skeleton
                            circle
                            height="8rem"
                            width="8rem"
                        />

                        <div className="nameBox d-flex flex-column gap-1 px-3">
                            <h4 className="title-font-700 mb-0 fs-4 text-capitalize text-base-color">
                                <Skeleton width={260} />
                            </h4>

                            <h6 className="text-black fs-6 base-font-600 mb-0">
                                <Skeleton width={260} />
                            </h6>

                            <h6 className="text-black fs-6 base-font-600 mb-0">
                                <Skeleton width={260} />
                            </h6>
                            <h6 className="text-black fs-6 base-font-600 mb-0">
                                <Skeleton width={260} />
                            </h6>
                        </div>
                    </div>
                    {<Skeleton width={100} height={50} className='rounded-5 shadow-lg' />}
                </div>
            </div>
        </div>
    )
}

export default ProfileLoader