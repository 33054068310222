import axios from "axios";
import { Refresh } from "iconsax-react";
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { ApiEndPoint } from "../../constants/apiEndPoints/endPoints";
import { toast } from "react-hot-toast";
import { selectPatient } from "../../redux/Slice/CollectionApp";

const EvaluationResult = () => {
  const dispatch = useDispatch();
  const selectedPatient = useSelector((state) => state.collection.selectedPatient);
  const [patientEvaluationResult, setPatientEvaluationResult] = useState(selectedPatient);
  const [rotate, setrotate] = React.useState(false);

  const handleEvaluationResult = () => {
    setrotate(true)
    axios.post(ApiEndPoint.patientEvaluationResult, { patient_number: selectedPatient?.patient_number }).then((response) => {
      if (response.data.status === "success") {
        setPatientEvaluationResult(response.data.patientDetails);
        dispatch(selectPatient(response.data.patientDetails));
        setrotate(false)
        toast.success("Evaluation results fetched");
      } else {
        toast.error("Error. fetching evaluation results")
        setrotate(false)
      }
    }).catch((e) => {
      if (e) {
        toast.error("An error occurred. Please try again.")
      }
    })
  };

  useEffect(() => {
    if (selectedPatient) {
      setPatientEvaluationResult(selectedPatient);
    }
  }, [selectedPatient]);

  return (
    <div className="col mx-auto mx-md-0 col-md-6 col-lg-4 col-xl-3 ">
      <div className="d-flex gap-1 align-items-center justify-content-between">
        <h6 className='title-font-700 text-black fs-6'>Evaluation Results</h6>
        <span className={selectedPatient?.eval_status === "new" ? "disabledbutton" : ""}>
          <button type='button' className='p-1 px-2 rounded-1 bg-base-color2 mb-2 title-font-600  border-0 text-white ' onClick={handleEvaluationResult}>
            Refresh <Refresh size="16" className={rotate ? 'rotateBox text-white' : 'text-white'} />
          </button>
        </span>
      </div>
      {patientEvaluationResult?.eval_status === "new" || patientEvaluationResult?.eval_status === "screened" || !patientEvaluationResult ?
        <div className="shadow border rounded-2 bg-white p-3">
          <div className="d-flex flex-column gap-1">
            <div className="d-flex align-items-center gap-1">
              <label className="base-font-600 fs-6 text-black text-capitalize">Evaluation Status </label>
              <div className="">
                <h6
                  className={`${patientEvaluationResult?.eval_status == "completed"
                    ? "base-green-dark text-white"
                    : patientEvaluationResult?.eval_status == "incomplete"
                      ? "base-yellow text-white"
                      : patientEvaluationResult?.eval_status == "screening" || patientEvaluationResult?.eval_status == "screened"
                        ? "bg-base-color text-white"
                        : patientEvaluationResult?.eval_status == "reported"
                          ? "bg-base-color2 text-white"
                          : patientEvaluationResult?.eval_status == "optout"
                            ? "base-purple text-white"
                            : patientEvaluationResult?.eval_status == "new"
                            && "base-pink text-white"
                    }  badge py-1 fs-6 base-font-500 rounded-1 text-capitalize`} style={{ letterSpacing: ".5px" }}>{patientEvaluationResult?.eval_status}</h6>
              </div>
            </div>
            <div className="d-flex align-items-center gap-1">
              <label className="base-font-600 fs-6 text-black text-capitalize">Sealants</label>
              <h6 className="base-font-400 mb-0 text-capitalize" style={{ color: 'rgba(140,140,140)' }}></h6>
            </div>
            <div className="d-flex align-items-center gap-1">
              <label className="base-font-600 fs-6 text-black text-capitalize">Treated Decay</label>
              <h6 className="base-font-400 mb-0 text-capitalize" style={{ color: 'rgba(140,140,140)' }}></h6>
            </div>
            <div className="d-flex align-items-center gap-1">
              <label className="base-font-600 fs-6 text-black text-capitalize">Untreated Decay</label>
              <h6 className="base-font-400 mb-0 text-capitalize" style={{ color: 'rgba(140,140,140)' }}></h6>
            </div>
            <div className="d-flex align-items-center gap-1">
              <label className="base-font-600 fs-6 text-black text-capitalize">Evaluation Code</label>
              <div className="">
                <h6
                  className={`${!patientEvaluationResult?.treatment_recommendation_code
                    ? "bg-secondary text-white "
                    : patientEvaluationResult?.treatment_recommendation_code == "CODE 1"
                      ? "base-green-dark text-white "
                      : patientEvaluationResult?.treatment_recommendation_code == "CODE 2"
                        ? "base-yellow text-white"
                        : patientEvaluationResult?.treatment_recommendation_code == "CODE 3"
                          ? "base-orange text-white"
                          : patientEvaluationResult?.treatment_recommendation_code == "CODE 4"
                          && "base-red text-white"
                    }  badge py-1 mb-0 fs-6 base-font-500 rounded-1 text-capitalize`} style={{ letterSpacing: ".5px" }} ></h6>
              </div>
            </div>

            <div className="">
              <small className="base-font-600 fs-6 text-black text-capitalize ">Recomendation</small>
              <div className="d-flex align-items-center gap-1">
                <p className="base-font-400 mb-0" style={{ color: 'rgba(140,140,140)' }}></p>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="shadow border rounded-2 bg-white p-3">
          <div className="d-flex flex-column gap-1">
            <div className="d-flex align-items-center gap-1">
              <label className="base-font-600 fs-6 text-black text-capitalize">Evaluation Status </label>
              <div className="">
                <h6
                  className={`${patientEvaluationResult?.eval_status == "completed"
                    ? "base-green-dark text-white"
                    : patientEvaluationResult?.eval_status == "incomplete"
                      ? "base-yellow text-white"
                      : patientEvaluationResult?.eval_status == "screening" || patientEvaluationResult?.eval_status == "screened"
                        ? "bg-base-color text-white"
                        : patientEvaluationResult?.eval_status == "reported"
                          ? "bg-base-color2 text-white"
                          : patientEvaluationResult?.eval_status == "optout"
                            ? "base-purple text-white"
                            : patientEvaluationResult?.eval_status == "new"
                            && "base-pink text-white"
                    }  badge py-1 fs-6 base-font-500 rounded-1 text-capitalize`} style={{ letterSpacing: ".5px" }}>{patientEvaluationResult?.eval_status}</h6>
              </div>
            </div>
            <div className="d-flex align-items-center gap-1">
              <label className="base-font-600 fs-6 text-black text-capitalize">Sealants</label>
              <h6 className="base-font-400 mb-0 text-capitalize" style={{ color: 'rgba(140,140,140)' }}>{patientEvaluationResult?.sealants_present === "True" ? "Present" : "Not-present"}</h6>
            </div>
            <div className="d-flex align-items-center gap-1">
              <label className="base-font-600 fs-6 text-black text-capitalize">Treated Decay</label>
              <h6 className="base-font-400 mb-0 text-capitalize" style={{ color: 'rgba(140,140,140)' }}>{patientEvaluationResult?.treated_decay === "True" ? "Present" : "Not-present"}</h6>
            </div>
            <div className="d-flex align-items-center gap-1">
              <label className="base-font-600 fs-6 text-black text-capitalize">Untreated Decay</label>
              <h6 className="base-font-400 mb-0 text-capitalize" style={{ color: 'rgba(140,140,140)' }}>{patientEvaluationResult?.untreated_decay === "True" ? "Present" : "Not-present"}</h6>
            </div>
            <div className="d-flex align-items-center gap-1">
              <label className="base-font-600 fs-6 text-black text-capitalize">Evaluation Code</label>
              <div className="">
                <h6
                  className={`${!patientEvaluationResult?.treatment_recommendation_code
                    ? "bg-secondary text-white "
                    : patientEvaluationResult?.treatment_recommendation_code == "CODE 1"
                      ? "base-green-dark text-white "
                      : patientEvaluationResult?.treatment_recommendation_code == "CODE 2"
                        ? "base-yellow text-white"
                        : patientEvaluationResult?.treatment_recommendation_code == "CODE 3"
                          ? "base-orange text-white"
                          : patientEvaluationResult?.treatment_recommendation_code == "CODE 4"
                          && "base-red text-white"
                    }  badge py-1 mb-0 fs-6 base-font-500 rounded-1 text-capitalize`} style={{ letterSpacing: ".5px" }} >{patientEvaluationResult?.treatment_recommendation_code ? patientEvaluationResult?.treatment_recommendation_code : "No Results"}</h6>
              </div>
            </div>

            <div className="">
              <label className="base-font-600 fs-6 text-black text-capitalize ">Recomendation</label>
              <div className="d-flex align-items-center gap-1">
                <small className="base-font-400 mb-0" style={{ color: 'rgba(140,140,140)' }}> {patientEvaluationResult?.eval_comments ? patientEvaluationResult?.eval_comments : "No Comment"}</small>
              </div>
            </div>
          </div>
        </div>}
    </div>
  )
}

export default EvaluationResult