// export default BranchSlice
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    branchProfileDetails: undefined,
    branchNurseList: [],
    branchScreenerList: []
};

export const BranchSlice = createSlice({
    name: 'branch',
    initialState,
    reducers: {
        branchProfile: (state, action) => {
            state.branchProfileDetails = action.payload
        },
        setNurseList: (state, action) => {
            state.branchNurseList = action.payload
        },
        setScreenerList: (state, action) => {
            state.branchScreenerList = action.payload
        },
    },
});

export const { branchProfile, setNurseList, setScreenerList } = BranchSlice.actions;

export default BranchSlice.reducer;