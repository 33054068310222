import DataTable, { TableColumn } from "react-data-table-component";
import React, { useState, useEffect } from "react";
import "../../../css/Table.css";

const customStyles = {
  rows: {
    style: {
      minHeight: "68px",
    },
  },
  headCells: {
    style: {
      fontSize: "5rem",
    },
  },
  cells: {
    style: {
      paddingLeft: "20px",
      paddingRight: "20px",
      color: "gray"
    },
  },
  contextMenu: {
    style: {
      fontSize: "18px",
      fontWeight: 400,
      paddingLeft: "16px",
      paddingRight: "8px",
      transform: "translate3d(0, -100%, 0)",
      transitionDuration: "125ms",
      transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
      willChange: "transform",
      backgroundColor: "red",
    },
    activeStyle: {
      transform: "translate3d(0, 0, 0)",
    },
  },
};

const Table = ({ columns, data }) => {
  const [pending, setPending] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <DataTable
      columns={columns}
      data={data}
      customStyles={customStyles}
      highlightOnHover
      pagination
      paginationPerPage={5}
      progressPending={pending}
      // progressComponent={<CustomLoader />}

      paginationRowsPerPageOptions={[5, 10, 15, 25, 50]}
      paginationComponentOptions={{
        rowsPerPageText: "Per Page:",
        rangeSeparatorText: "out of",
      }}

    />
  );
};

export default Table;
