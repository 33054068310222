import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from '../Components/Navbar/Navbar';
import { Profile } from 'iconsax-react';
import Home from '../pages/SuperAdmin/Home/Home';
import ScrollToTop from '../Helper/ScrollToTop';
import Registration from '../pages/Registration/Registration';
import EmailVerification from '../pages/Verification/EmailVerification';
import Login from '../pages/Login/Login';

const SuperAdminRoutes = () => {

    const routes = [{
        route: [
            {
                url: "/",
                name: "Customer Registration"
            },
            // {
            //     url: "/customer-registration",
            //     name: "Customer Registration"
            // },
        ]
    },
    {
        button: [
            {
                url: "/",
                name: "logout",
                icon: <Profile size="20" />
            }
        ]
    }]

    return (
        <>
            <Navbar routes={routes} />
            <ScrollToTop>
                <div className="position-relative  w-100 vh-100 container-xxl" style={{ top: "92px" }}>
                    <Routes>
                        <Route path="/" element={<Registration />} />
                        <Route path="/verify-email" element={<EmailVerification redirectURL={"/"} />} />
                        <Route path='/login' element={<Login />} />
                        {/* <Route path="/customer-registration" element={<Registration />} /> */}
                    </Routes>
                </div>
            </ScrollToTop>
        </>
    )
}

export default SuperAdminRoutes;