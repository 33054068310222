import React, { useState } from 'react'
import { ApiEndPoint } from '../../../constants/apiEndPoints/endPoints';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from "lodash";
import AsyncSelect from "react-select/async";
import { selectPatient, sleepApneaSelectPatient } from '../../../redux/Slice/CollectionApp';
import axios from 'axios';
import { DentalCollectionPatient } from './DentalCollectionPatient';
import CreatePatientForm from '../CreatePatientForm';
import CollapsibleScreeningSubmission from '../CollapsibleScreeningSubmission';
import DentalScreeningContent from './DentalScreeningContent';

const DentalScreening = () => {
    const dispatch = useDispatch();
    const [value, setValue] = useState()

    const CustomerAdminDetails = useSelector(
        (state) => state.login.loggedUserDetails
    );

    const selectedPatient = useSelector(
        (state) => state.collection.selectedPatient
    );

    const handleClearSelect = () => {
        setValue('')
      };
    

    const debouncedFunction = debounce(async (value, callback) => {
        const filteredPatientsList = await axios
            .post(`${ApiEndPoint.filterPatient}`, value)
            .catch((err) => {
                if (err) {
                    toast.error("An error occurred. Please try again.");
                }
            });
        const patientLists = filteredPatientsList?.data.patientList.map(
            (patient) => {
                return {
                    ...patient,
                    label: `${patient.patient_number} ${patient.first_name} ${patient.last_name} ${patient.email}`,
                    value: `${patient.patient_number}`,
                };
            }
        );
        callback(patientLists);
    }, 1000);

    const promiseOptions = (inputValue, callback) => {
        const payload = {
            clinic: CustomerAdminDetails?.branch?.customer?.id,
            branch: CustomerAdminDetails?.branch?.id,
            query: inputValue,
            patient_type: "patient",
        };
        debouncedFunction(payload, callback);
    };

    const handlePatientSelected = (selectedPatient) => {
        dispatch(selectPatient(selectedPatient));
        setValue(selectedPatient)
    };

    return (
        <>
            <AsyncSelect
            value={value}
                loadOptions={promiseOptions}
                onChange={handlePatientSelected}
                isClearable={true}
                placeholder="Search Patient By Number or Name or Email id..."
                className="border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input"
            />
            {/* <DentalCollectionPatient selectedPatientIs={handlePatientSelected} /> */}
            <CreatePatientForm type_of_screening='dental' />
            {selectedPatient && <DentalScreeningContent handleClearSelect={handleClearSelect} />}

        </>
    )
}

export default DentalScreening