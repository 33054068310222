import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { ApiEndPoint } from "../../constants/apiEndPoints/endPoints";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Select from "react-select";
import { Form, Modal, ModalBody } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { errorAlert, successAlert } from "../Alerts/Alerts";
import { reportCodePatient } from "../../constants/apiEndPoints/ReportCode";
import {
  notificationShow,
  setContinueScreening,
  setPatientLists,
  setUnFilteredPatientLists,
} from "../../redux/Slice/EvaluatorSlice";
import { ArrowDown2, ArrowUp2, Truck } from "iconsax-react";
import moment from "moment";
import { toast } from "react-hot-toast";
import { ArrowSquareDown, ArrowSquareUp } from "iconsax-react";
import Collapse from "react-collapse";

export const DentalScreenTable = ({ evalstatus, patientType }) => {
  var continueScreening = false;
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [loaders, setLoaders] = useState(false);
  const scrollToBottom = useRef(null);
  const [modal, setModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState();

  const toggle = () => setModal(!modal);

  const { register, handleSubmit, reset, control, watch, setValue } = useForm();


  const CustomerAdminDetails = useSelector(
    (state) => state.login.loggedUserDetails
  );
  const ScreeenerDetails = useSelector(
    (state) => state.login.loggedUserDetails
  );
  const selectedPatientFromNotification = useSelector(
    (state) => state.evaluator.selectedPatientIdFormNotification
  );

  const selectedNotificationPatientType = useSelector(
    (state) => state.evaluator.selectedNotificationPatientType
  );

  const notificationsList = useSelector(
    (state) => state.evaluator.notificationsList
  );
  const evaluationStatus = useSelector(
    (state) => state?.evaluator?.selectedEvalStatus
  );
  const patientListData = useSelector((state) => state?.evaluator?.patientList);
  const unFilteredPatientListData = useSelector(
    (state) => state?.evaluator?.unFilteredPatientList
  );

  const [patientsList, setPatientsList] = useState([]);
  const [unFilteredPatientList, setUnFilteredPatientList] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [open, setOpen] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [selectedReason, setSelectedReason] = useState(null);
  const [dropToggle, setdropToggle] = useState(false);
  const scrollToTop = useRef(null);



  var t_codes = watch("treatment_codes");

  const evalStatus = [
    { value: "new", label: "New" },
    { value: "screened", label: "Screened" },
    { value: "optout", label: "Optout" },
    { value: "completed", label: "Completed" },
    { value: "reported", label: "Reported" },
    { value: "incomplete", label: "Incomplete" },
  ];

  const DentalPain = [
    { value: "yes", label: "Yes", dbvalue: "true" },
    { value: "no", label: "No", dbvalue: "false" },
  ];

  const EvaluateImages = [
    { value: "N/A", label: "N/A", eval_status: "complete", isevaluate: true },
    {
      value: "One Or More Images Not Clear",
      label: "One Or More Images Not Clear",
      eval_status: "incomplete",
      isevaluate: false,
    },
    {
      value: "Student Opted Out of Screening",
      label: "Student Opted Out of Screening",
      eval_status: "incomplete",
      isevaluate: false,
    },
  ];
  const patientHeader = [
    { header: "#", input: "dropdown", dbkey: "index", searchtype: "id" },
    {
      header: "Nurse Name",
      input: "dropdown",
      dbkey: "nurse_name",
      searchtype: "input",
    },
    {
      header: "Patient EMR No.",
      input: "dropdown",
      dbkey: "number",
      searchtype: "input",
    },
    {
      header: "Patient Number",
      input: "dropdown",
      dbkey: "patient_number",
      searchtype: "input",
    },
    {
      header: "First Name",
      input: "dropdown",
      dbkey: "first_name",
      searchtype: "input",
    },
    {
      header: "Last Name",
      input: "dropdown",
      dbkey: "last_name",
      searchtype: "input",
    },
    {
      header: "Phone No.",
      input: "dropdown",
      dbkey: "contact_number",
      searchtype: "input",
    },
    {
      header: "Last Modified",
      input: "dropdown",
      dbkey: "modified_date",
      searchtype: "hidden",
    },
    // { header: "Screening Date", input: "dropdown", dbkey: "eval_status", searchtype: 'input' },
    // { header: "Status", input: "dropdown", dbkey: "eval_status", searchtype: 'select' },
  ];
  const TreatmentCode = [
    { value: "CODE 1", label: "CODE 1" },
    { value: "CODE 2", label: "CODE 2" },
    { value: "CODE 3", label: "CODE 3" },
    { value: "CODE 4", label: "CODE 4" },
  ];

  const handleOnSelect = (select, value) => {
    upsert({ name: select, value: value });
  };

  const handleSearch = (select, value) => {
    upsert({ name: select, value: value });
  };

  const handleSelectStatus = (selectedStatus) => {
    if (selectedStatus) {
      upsert({ name: "eval_status", value: selectedStatus.value });
    } else {
      handleOnClear("eval_status");
    }
  };

  const handleOnClear = (select) => {
    const filteredArray = filters.filter((obj) => obj.name !== select);
    setFilters(filteredArray);
  };

  const upsert = (item) => {
    const previousfilters = filters.find((_item) => _item.name === item.name);
    if (previousfilters) {
      const updatedArray = filters.map((obj) => {
        if (obj.name === item.name) {
          return { ...obj, value: item.value };
        }
        return obj;
      });
      setFilters(updatedArray);
    } else {
      setFilters([...filters, item]);
    }
  };

  const handleRowClick = (row) => {
    if (evalstatus != "new") {
      
      if (selectedRow && selectedRow.id === row.id) {
        setSelectedRow(null); // Deselect row if clicked again
      } else {
        reset({
          treatment_codes: "",
        });
        setSelectedRow(row);

        
        setTimeout(() => {
          const navbarHeight = 90; // Replace with your actual navbar height
          const offset = scrollToTop.current
            ? scrollToTop.current.getBoundingClientRect().top -
              navbarHeight +
              window.scrollY
            : 0;
          if (scrollToTop.current) {
            window.scrollTo({
              top: offset,
              behavior: "smooth",
            });
          }
        }, 1000);
      }
    }
  };

  const GetPatientList = async () => {

    setLoaders(true);
    const payload = {
      clinic: CustomerAdminDetails?.branch?.customer?.id,
      branch: CustomerAdminDetails?.branch?.id,
      evalstatus: evaluationStatus?.value,
      patient_type: patientType,
    };
    const evaluationURL =
      patientType === "selfscreening"
        ? ApiEndPoint.selfScreeningPatientEvaluation
        : ApiEndPoint.patientEvaluation;
    await axios.post(evaluationURL, payload).then(async (response) => {
      const nurseWithName = await response?.data?.patientList?.map((item) => ({
        ...item,
        nurse_name: `${item?.nurse?.fname} ${item?.nurse?.lname}`,
        modified_date: item?.last_modified_date
          ? formatDate(item?.last_modified_date)
          : null,
        contact_number: item?.contact_number
          ? item?.contact_number.replace(/\D/g, "")
          : null,
      }));
      dispatch(setPatientLists(nurseWithName));
      dispatch(setUnFilteredPatientLists(nurseWithName));
      setLoaders(false);
      setPatientsList(nurseWithName);
      setUnFilteredPatientList(nurseWithName);
      if (continueScreening) {
        if (nurseWithName[0]) {
          setSelectedRow(nurseWithName[0]);
        } else {
          continueScreening = false;
        }
      } else {
        setSelectedRow(null);
      }
    });
  };

  const GetAllNotificationShowList = () => {
    const payload = {
      branch: ScreeenerDetails?.branch?.id,
      evaluator: ScreeenerDetails?.id,
    };

    axios
      .post(ApiEndPoint.screenerNotificationList, payload)
      .then((response) => {
        dispatch(notificationShow(response.data.notificationList));
      })
      .catch((err) => {
        toast.error("An error occurred. Please try again.");
      });
  };

  const ShowPatientFromNotification = async () => {
    await GetPatientList();
  };

  const onSubmit = (data) => {
    setLoader(true);
    data.id = selectedRow.id;
    data.eval_comments = data.comment;
    data.cannot_evaluate = data?.evaluate_images?.isevaluate
      ? data?.evaluate_images?.isevaluate
      : true;
    data.eval_status = data?.evaluate_images?.eval_status
      ? data?.evaluate_images?.eval_status
      : "completed";
    data.evaluate_images = data?.evaluate_images?.value
      ? data?.evaluate_images?.value
      : "completed";
    data.sealants_present = data.sealants_present.dbvalue;
    data.treated_decay = data.treated_decay.dbvalue;
    data.untreated_decay = data.untreated_decay.dbvalue;
    data.treatment_recommendation_code = data.treatment_codes.value;
    data.patient_number = selectedRow.patient_number;
    data.nurse_id = selectedRow.nurse.id;
    axios
      .post(ApiEndPoint.patientEvaluationSubmit, data)
      .then((submitResponse) => {
        continueScreening = true;
        successAlert(submitResponse?.data?.message);
        reset();
        setLoader(false);
        setSelectedRow(null);
        setSelectedReason(null);
        GetPatientList();
        // GetAllNotificationShowList();
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const handleImageZoom = (imageURL) => {
    toggle();
    setSelectedImage(imageURL);
    setOpen(!open);
  };

  useEffect(() => {
    if (unFilteredPatientListData?.length > 0) {
      var filterPatient = unFilteredPatientListData?.filter((patient) => {
        return filters.every((filter) => {
          return (
            patient[filter.name] &&
            patient[filter.name]
              .toString()
              .toUpperCase()
              .startsWith(filter.value.toString().toUpperCase())
          );
        });
      });
    }
    setPatientsList(filterPatient);
  }, [filters]);

  useEffect(() => {
    if (selectedRow) {
      if (selectedRow.eval_status !== "new") {
        reset({
          untreated_decay: DentalPain.filter(
            (untreatedDecay) =>
              untreatedDecay.dbvalue ==
              selectedRow.untreated_decay?.toLowerCase()
          )[0],
          treated_decay: DentalPain.filter(
            (treated_decay) =>
              treated_decay.dbvalue == selectedRow.treated_decay?.toLowerCase()
          )[0],
          sealants_present: DentalPain.filter(
            (DentalPain) =>
              DentalPain.dbvalue == selectedRow.sealants_present?.toLowerCase()
          )[0],
          treatment_codes: TreatmentCode.filter(
            (untreatedDecay) =>
              untreatedDecay.value == selectedRow.treatment_recommendation_code
          )[0],
          evaluate_images: EvaluateImages.filter(
            (untreatedDecay) =>
              untreatedDecay.isevaluate == selectedRow.evaluate_images
          )[0],
          comment: selectedRow.eval_comments,
        });
      }
      if (
        selectedRow &&
        (selectedRow.eval_status === "completed" ||
          selectedRow.eval_status === "reported")
      ) {
        setDisableFields(true);
      } else {
        setDisableFields(false);
      }
    }
  }, [selectedRow]);

  useEffect(() => {
    if (
      t_codes?.value &&
      selectedRow?.eval_status !== ("completed" || "reported")
    ) {
      if (t_codes.value === "CODE 1") {
        setValue("comment", reportCodePatient.code1);
      } else if (t_codes.value === "CODE 2") {
        setValue("comment", reportCodePatient.code2);
      } else if (t_codes.value === "CODE 3") {
        setValue("comment", reportCodePatient.code3);
      } else if (t_codes.value === "CODE 4") {
        setValue("comment", reportCodePatient.code4);
      } else {
        setValue("comment", "");
      }
    }
  }, [t_codes]);

  useEffect(() => {
    if (evaluationStatus && CustomerAdminDetails) {
      GetPatientList();
    }
  }, [
    evaluationStatus,
    CustomerAdminDetails,
    selectedNotificationPatientType,
  ]);

  useEffect(() => {
    if (
      evaluationStatus &&
      CustomerAdminDetails &&
      notificationsList?.length > 0 &&
      !selectedRow
    ) {
      GetPatientList();
    }
  }, [notificationsList, evaluationStatus, CustomerAdminDetails]);

  useEffect(() => {
    if (selectedPatientFromNotification && CustomerAdminDetails) {
      ShowPatientFromNotification();
    }
  }, [
    selectedPatientFromNotification,
    CustomerAdminDetails,
    selectedNotificationPatientType,
  ]);

  useEffect(() => {
    if (selectedPatientFromNotification && patientListData.length > 0) {
      const selectedPatientIs = patientListData.filter(
        (patient) => patient.patient_number == selectedPatientFromNotification
      );
      setSelectedRow(selectedPatientIs[0]);
      setTimeout(() => {
        scrollToBottom.current?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }, [selectedPatientFromNotification, patientListData]);

  const handleReasonChange = (newValue) => {
    setSelectedReason(newValue);
    setValue("treatment_codes", "");
    setValue("comment", "");
  };

  const formatDate = (dateString) => {
    const date = moment(dateString);
    return date.format("D MMMM YYYY");
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalBody>
          <img
            className="cursor"
            src={selectedImage}
            style={{ width: "100%" }}
          />
        </ModalBody>
      </Modal>

      <div
        className="shadow border rounded-3 p-3 px-md-3 py-md-1 mb-md-4"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div className="table-responsive-xxl pt-3  mb-0 ">
          <table className="table  table-responsive border rounded-3 table-hover">
            <thead className="">
              <tr className="">
                {patientHeader?.map((headers, index) => (
                  <th
                    className="bg-base-color2 border-0 text-white"
                    key={index}
                  >
                    <span className="base-font-600 fs-6 text-uppercase text-center">
                      {headers?.header}
                    </span>
                    {headers?.searchtype === "id" && (
                      <div className="py-3 px-1 border-radius-10 shadow-none w-100 fs-6 title-font-500 mb-1"></div>
                    )}
                    {headers?.dbkey !== "index" && (
                      <span>
                        {headers?.searchtype === "input" ? (
                          <ReactSearchAutocomplete
                            items={patientsList}
                            fuseOptions={{ keys: [headers?.dbkey] }}
                            resultStringKeyName={headers?.dbkey}
                            onSearch={(event) =>
                              handleSearch(headers?.dbkey, event)
                            }
                            onSelect={(event) =>
                              handleOnSelect(
                                headers?.dbkey,
                                event[headers?.dbkey]
                              )
                            }
                            onClear={(event) => handleOnClear(headers?.dbkey)}
                            showIcon={false}
                            placeholder={headers?.header + "..."}
                            className="shadow-none w-100 fs-6 title-font-500 rounded-1 py-0"
                            styling={{
                              borderRadius: "8px",
                              zIndex: 2,
                            }}
                          />
                        ) : headers?.searchtype === "select" ? (
                          <Select
                            options={evalStatus}
                            onChange={handleSelectStatus}
                            className="mb-2 border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input text-black"
                            isClearable={true}
                          />
                        ) : (
                          <>
                            <Select
                              placeholder=""
                              className="mb-1 border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input text-black"
                              isDisabled={true}
                            />
                          </>
                        )}
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            {loaders ? (
              <tbody>
                <tr>
                  <td
                    colSpan="8"
                    className="base-font-600 fs-6 text-black text-center"
                  >
                    Loading...
                  </td>
                </tr>
              </tbody>
            ) : patientsList && patientsList.length > 0 ? (
              <tbody className="">
                {patientsList?.map((item, index) => (
                  <tr
                    key={item.id}
                    style={{ cursor: "pointer" }}
                    className={
                      selectedRow && selectedRow.id === item.id
                        ? "selected-row"
                        : "border"
                    }
                    onClick={() => handleRowClick(item)}
                  >
                    {patientHeader?.map((columnName, phindex) => (
                      <td
                        key={phindex}
                        className="base-font-500 fs-6 text-black"
                      >
                        {columnName?.dbkey === "index"
                          ? `${index + 1}`
                          : item[columnName.dbkey] == undefined
                          ? "-----------"
                          : item[columnName.dbkey] == null
                          ? "-----------"
                          : columnName.dbkey == "last_modified_date"
                          ? formatDate(item[columnName.dbkey])
                          : item[columnName.dbkey]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td
                    colSpan="8"
                    className="base-font-600 fs-6 text-black text-center"
                  >
                    Data not found!
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>

      {selectedRow && (
        <div className="">
        <span ref={scrollToTop}></span>

          <div ref={scrollToBottom}></div>
          <div className="p-4 my-3 bg-base-color rounded-1 border-bottom sticky-top" style={{top:70}}>
            <div className="d-flex justify-content-center align-items-center gap-4 flex-wrap">
              <h6 className="title-font-700 fs-6 mb-0 text-white">
                Name:{" "}
                <span className="title-font-500">
                  {selectedRow?.first_name} {selectedRow?.last_name}
                </span>
              </h6>
              <h6 className="title-font-700 fs-6 mb-0 text-white">
                Phone No. :{" "}
                <span className="base-font-500">
                  {selectedRow?.contact_number}
                </span>
              </h6>
              <h6 className="title-font-700 fs-6 mb-0 text-white">
                Email:{" "}
                <span className="base-font-500">{selectedRow?.email}</span>
              </h6>
              <h6 className="title-font-700 fs-6 mb-0 text-white">
                Emr No. :{" "}
                <span className="base-font-500">{selectedRow?.number}</span>
              </h6>
            </div>
          </div>
          <div className="row row-gap-3 justify-content-evenly align-items-center mx-0">
            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-3  text-center border p-3 rounded-3 shadow images_card">
              <h6 className="title-font-600 text-black fs-6  text-black py-b">
                Front Teeth Image
              </h6>
              <label className="image_overlay">
                <div className="image_box ">
                  <img
                    className="cursor"
                    src={selectedRow?.front_image}
                    onClick={() => handleImageZoom(selectedRow?.front_image)}
                  />
                </div>
              </label>
            </div>
            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-3  text-center border p-3 rounded-3 shadow images_card">
              <h6 className="title-font-600 text-black fs-6  text-black py-b">
                Top Teeth Image
              </h6>
              <label className="image_overlay">
                <div className="image_box ">
                  <img
                    className="cursor"
                    src={selectedRow?.top_image}
                    onClick={() => handleImageZoom(selectedRow?.top_image)}
                  />
                </div>
              </label>
            </div>
            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-3  text-center border p-3 rounded-3 shadow images_card">
              <h6 className="title-font-600 text-black fs-6  text-black py-b">
                Bottom Teeth Image
              </h6>
              <label className="image_overlay">
                <div className="image_box" onClick={() => setOpen(!open)}>
                  <img
                    className="cursor"
                    src={selectedRow?.bottom_image}
                    onClick={() => handleImageZoom(selectedRow?.bottom_image)}
                  />
                </div>
              </label>
            </div>
          </div>
          <div className="px-3 py-2 my-4 bg-base-color rounded-1">
            <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div
                className="d-flex justify-content-between align-items-center px-2 px-lg-2 mt-4 mb-4"
                style={{ cursor: "pointer" }}
              >
                <h5 className="title-font-600 text-light fs-5 pb-1 mb-0 border-bottom-color-2">
                  Dental Screening Evaluation
                </h5>
               
              </div>

              <Collapse isOpened={true} className="mb-5">
                <div className="row align-items-center px-0 px-md-2">
                  <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-3 pb-3 pt-1">
                    <label
                      htmlFor="type"
                      className="fs-6 title-font-500 mb-0 text-white"
                    >
                      Untreated Decay:
                    </label>
                    <Controller
                      control={control}
                      name="untreated_decay"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={DentalPain}
                          value={value}
                          onChange={onChange}
                          isDisabled={disableFields}
                          className="border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input "
                        />
                      )}
                    />
                  </div>
                  <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-3 pb-3 pt-1">
                    <label
                      htmlFor="type"
                      className="fs-6 title-font-500 mb-0 text-white"
                    >
                      Treated Decay:
                    </label>
                    <Controller
                      control={control}
                      name="treated_decay"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={DentalPain}
                          value={value}
                          onChange={onChange}
                          isDisabled={disableFields}
                          className="border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input "
                        />
                      )}
                    />
                  </div>
                  <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-3 pb-3 pt-1">
                    <label
                      htmlFor="type"
                      className="fs-6 title-font-500 mb-0 text-white"
                    >
                      Sealants Present:
                    </label>
                    <Controller
                      control={control}
                      name="sealants_present"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={DentalPain}
                          value={value}
                          onChange={onChange}
                          isDisabled={disableFields}
                          className="border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input "
                        />
                      )}
                    />
                  </div>
                  <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-3 pb-3 pt-1">
                    <label
                      htmlFor="type"
                      className="fs-6 title-font-500 mb-0 text-white"
                    >
                      Treatment Recomendation codes:
                    </label>
                    <Controller
                      control={control}
                      name="treatment_codes"
                      rules={{
                        required: selectedReason === null,
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          options={TreatmentCode}
                          value={value}
                          onChange={onChange}
                          isDisabled={selectedReason !== null || disableFields}
                          className="border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input "
                        />
                      )}
                    />
                  </div>
                  <div className="col-11 mx-auto col-md-12 pb-3 pt-1">
                    <label className="fs-6 title-font-500 mb-0 text-white">
                      Evaluation Comment:
                    </label>
                    <div className="mt-1 d-flex gap-3">
                      <textarea
                        {...register("comment")}
                        placeholder="Enter Comment..."
                        disabled={disableFields}
                        className="p-2 border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input"
                      />
                    </div>
                  </div>
                  <div className="col-12 mx-auto border-top"></div>
                  <div className="col-11 mx-auto mx-md-0 col-md-4 col-lg-4 pb-3 pt-2">
                    <label
                      htmlFor="type"
                      className="fs-6 title-font-500 mb-2 text-white"
                      style={{ opacity: 0.7 }}
                      onClick={() => setdropToggle(!dropToggle)}
                    >
                      Cannot evaluate the images?{" "}
                      {dropToggle ? (
                        <ArrowUp2 size={20} className="text-white" />
                      ) : (
                        <ArrowDown2 size={20} className="text-white " />
                      )}
                    </label>
                    {dropToggle && (
                      <Controller
                        control={control}
                        name="evaluate_images"
                        render={({ field: { onChange, value } }) => (
                          <Select
                            options={EvaluateImages}
                            value={value}
                            onChange={(newValue) => {
                              handleReasonChange(newValue);
                              onChange(newValue);
                            }}
                            isDisabled={disableFields}
                            className="border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input "
                          />
                        )}
                      />
                    )}
                  </div>
                  <div className="d-flex align-items-center justify-content-center justify-content-lg-end">
                    {loader ? (
                      <button
                        type="button"
                        className="border-0 rounded-1 py-2  px-3 bg-base-color2 text-white title-font-500 d-flex gap-2 align-items-center"
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          disabled
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Submiting . . .
                      </button>
                    ) : (
                      <button
                        className=" border-0 rounded-1 py-2 mb-2 px-3 bg-base-color2 text-white title-font-500"
                        disabled={disableFields}
                      >
                        Submit Screening Results
                      </button>
                    )}
                  </div>
                </div>
              </Collapse>
            </Form>
          </div>
        </div>
      )}
    </>
  );
};
