import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from '../Components/Navbar/Navbar';
import { Profile } from 'iconsax-react';
import CreateNurse from './../pages/BranchAdmin/CreateNurse';
import Dashboard from './../pages/BranchAdmin/Dashboard';
import EmailVerification from '../pages/Verification/EmailVerification';
import ScrollToTop from '../Helper/ScrollToTop';
import CreateScreener from '../pages/BranchAdmin/CreateScreener';
import { AppointmentTable } from '../Components/CalendarDashboard/AppointmentTable';

const BranchAdminRoute = () => {
  const routes = [{
    route: [
      {
        url: "/",
        name: "Dashboard"
      },
      {
        url: "/CreateNurse",
        name: "Create Nurse"
      },
      {
        url: "/CreateScreener",
        name: "Create Evaluator"
      },
    ]
  },
  {
    button: [
      {
        url: "/",
        name: "logout",
        icon: <Profile size="20" />
      }
    ]
  }]
  return (
    <>
      <Navbar routes={routes} />
      <ScrollToTop>
        <div className="position-relative  w-100 vh-100 container-xxl" style={{ top: "92px" }}>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/CreateNurse" element={<CreateNurse />} />
            <Route path="/CreateScreener" element={<CreateScreener />} />
            <Route path="/verify-email" element={<EmailVerification redirectURL={"/"} />} />
          </Routes>
        </div>
      </ScrollToTop>
    </>

  )
}

export default BranchAdminRoute