import React from 'react'

function ErrorComponents({ error }) {
  return (
    <>
      <small className="title-font-600 text-left "><span className='text-danger'>{error}</span></small>
    </>
  )
}

export default ErrorComponents