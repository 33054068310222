import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { ApiEndPoint } from "../../constants/apiEndPoints/endPoints";
import Table from "../../Components/Table/Table/Table";
import EmptyTable from "../../Components/Table/EmptyTable";
import { Edit } from "iconsax-react";
import { EditLinksModal } from "../../Components/Modal/Links/EditLinksModal";

export const ReportCustomization = () => {
  const [dataLinks, setDataLinks] = useState();

  // modal for editing comments
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // modal for adding comments
  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);

  const CustomerAdminDetails = useSelector(
    (state) => state.login.loggedUserDetails
  );


  const columns = [
    {
      name: "#",
      cell: (row, index) => index + 1,
    },
    {
      name: "Clinic Website",
      selector: (row) => row.website,
      sortable: true,
      wrap: true,
    },
    {
      name: "Appointment Schedule Link",
      selector: (row) => row.appointment_link,
      sortable: true,
      wrap: true,
    },
    {
      name: "Education Link",
      selector: (row) => row.education_link,
      sortable: true,
      wrap: true,
    },

    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex justify-content-between align-items-center gap-2 ">
          <button
            onClick={toggle}
            className="icon-bg-yellow border-0 p-1 rounded"
          >
            <Edit size="22" className="icon-color-yellow" />
          </button>

          {modal && (
            <EditLinksModal
              info={row}
              toggle={toggle}
              modal={modal}
              path="edit"
              GetLinks={GetLinks}
            />
          )}
        </div>
      ),
    },
  ];

  const GetLinks = async () => {
    await axios
      .get(
        `${ApiEndPoint.getCustomerLinks}${CustomerAdminDetails?.customer_number}/`
      )
      .then((res) => {
        if (res.data.status === "success") {
          setDataLinks(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetLinks();
  }, []);

  return (
    <>
      <div className="bg-white p-3 p-md-4  mb-4 shadow-lg border-radius-3 mt-3">
        <div className="">
          <div className="d-flex justify-content-between align-items-center m-2 my-3">
            <h4 className="title-font-bold fs-4 text-base-color text-center text-md-start pb-2">
              Website Links
            </h4>
            <button type="button" className="px-3 submit-btn" onClick={toggle1}>
              Add links
            </button>
          </div>

          {modal1 && (
            <EditLinksModal
              info={""}
              toggle={toggle1}
              modal={modal1}
              path="add"
              GetLinks={GetLinks}
            />
          )}

          {dataLinks ? (
            <Table columns={columns} data={[dataLinks]} />
          ) : (
            <EmptyTable />
          )}
        </div>
      </div>
    </>
  );
};
