import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLogged: false,
  roleIs: 'guest',
  username: undefined,
  loggedUserDetails: undefined,
}

export const LoginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isLogged = action.payload
    },
    role: (state, action) => {
      state.roleIs = action.payload
    },
    username: (state, action) => {
      state.username = action.payload
    },
    loggedUserDetails: (state, action) => {
      state.loggedUserDetails = action.payload
    }
  },
})

export const { login, role, username, loggedUserDetails } = LoginSlice.actions

export default LoginSlice.reducer