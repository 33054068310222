import { configureStore } from '@reduxjs/toolkit'
import LoginSlice from '../Slice/LoginSlice';
import RegistrationSlice from '../Slice/RegistrationSlice';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { combineReducers, applyMiddleware } from 'redux';
import CollectionApp from '../Slice/CollectionApp';
import CustomerSlice from '../Slice/CustomerSlice';
import { composeWithDevTools } from '@redux-devtools/extension';
import BranchSlice from '../Slice/BranchSlice';
import EvaluatorSlice from '../Slice/EvaluatorSlice';

const reducers = combineReducers({
    login: LoginSlice,
    registration: RegistrationSlice,
    collection: CollectionApp,
    customer: CustomerSlice,
    branch: BranchSlice,
    evaluator: EvaluatorSlice
});

const persistConfig = {
    key: 'virtualscreening',
    version: 1,
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);
const composedEnhancer = composeWithDevTools(applyMiddleware(thunk))

const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
});

export default store;