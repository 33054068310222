import axios from "axios";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    Label,
} from "reactstrap";
import ErrorComponents from "../../ErrorComponents/ErrorComponents";

const ImportsModal = ({ modal, toggle, submit }) => {
    const [Loader, setLoader] = useState(false);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const CancelButton = () => {
        reset();
        toggle();
    };


    return (
        <>
            <Modal isOpen={modal} toggle={toggle} centered>
                <ModalHeader>Nurse Credentials</ModalHeader>
                <Form onSubmit={handleSubmit(submit)} autoComplete="off">
                    <ModalBody className="bg-background-color px-4">
                        <div className="row">
                            <div className="col-11 mx-auto  pt-1 pb-1">
                                <Label className="fs-6 title-font-600 mb-0 text-black">
                                    Email*
                                </Label>

                                <div className="mt-1  d-flex gap-3">
                                    <input
                                        className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                                        type="email"
                                        placeholder="Email..."
                                        {...register("email", {
                                            required: "Email is required",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: "Invalid email address"
                                            }
                                        })}
                                    />
                                </div>
                                {errors.email && (
                                    <ErrorComponents error={"Email is required *"} />
                                )}
                            </div>
                            <div className="col-11 mx-auto   pt-1 pb-1">
                                <Label className="fs-6 title-font-600 mb-0 text-black">
                                    Password*
                                </Label>

                                <div className="mt-1  d-flex gap-3">
                                    <input
                                        className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                                        type="password"
                                        placeholder="Password..."
                                        {...register("password", { required: true })}
                                    />
                                </div>
                                {errors.password && (
                                    <ErrorComponents error={"Password is required *"} />
                                )}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type='button' className="close-btn" onClick={() => CancelButton()}>
                            Cancel
                        </button>
                        {Loader ? (
                            <button
                                type="button"
                                className="submit-btn d-flex gap-2 align-items-center"
                            >
                                <span
                                    className="spinner-border spinner-border-sm" disabled
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                Editing . . .
                            </button>
                        ) : (
                            <button type="submit" className="submit-btn">
                                Submit
                            </button>
                        )}
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    )
}

export default ImportsModal