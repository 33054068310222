import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { loggedUserDetails } from '../../redux/Slice/LoginSlice';
import axios from 'axios';
import Dashboard from './Dashboard';
import { ApiEndPoint } from './../../constants/apiEndPoints/endPoints';
import { NotificationBing } from 'iconsax-react';
import moment from 'moment-timezone';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from 'react-hot-toast';

const Home = () => {
    const dispatch = useDispatch();
    const username = useSelector((state) => state.login.username);
    const notificationsList = useSelector((state) => state.evaluator.notificationsList);
    const [modalOpen, setModalOpen] = useState(false);
    const GetUserDetails = async () => {
        const url = `${ApiEndPoint?.screenerDetails}${username}/`;
        await axios.get(url).then((response) => {
            dispatch(loggedUserDetails(response?.data?.screenerDetails));
        }).catch((e) => {
            if (e) {
                toast.error("An error occurred. Please try again.")
            }
        });
    };

    useEffect(() => {
        if (username) {
            GetUserDetails();
        };
    }, [username]);

    const toggleModal = async () => {
        await notificationsList?.map((data) => {
            axios.post(ApiEndPoint?.screenerNotificationRead, { notificationid: data.id }).catch((err) => {
                if (err) {
                    toast.error("An error occurred. Please try again.")
                }
            });
        })
        setModalOpen(!modalOpen);
    };

    useEffect(() => {
        if (notificationsList?.length > 0) {
            setModalOpen(true)
        }
    }, [notificationsList]);

    function handlePlay() {
        setTimeout(() => {
            const audio = new Audio(notificationSound);
            audio.play();
        }, 5000); // Delay in milliseconds
    }

    return (
        <>
            {(notificationsList && notificationsList.length > 0) &&
                <Modal isOpen={modalOpen} centered scrollable={true}>
                    <ModalHeader className='bg-base-color2 base-font-600 fs-5 mb-0 text-white'>New Screening Notification</ModalHeader>
                    <ModalBody className='border-0 mb-0'>
                        {
                            notificationsList?.map((data, i) => {
                                return (
                                    <div key={i} className="alert border-bottom rounded-0 align-items-center d-flex gap-3 shadow border bg-white rounded-2" role='button'>
                                        <div className="h-25 p-2 rounded-circle bg-info" >
                                            <NotificationBing size="26" className="text-white" />
                                        </div>
                                        <div className="w-100">
                                            <label className='base-font-600 text-capitalize'>
                                                {data?.title}
                                            </label>
                                            <div className='d-flex justify-content-end'>
                                                {/* <small className='base-font-400'>{moment.tz(data?.created_at, 'America/Chicago').tz('America/Chicago').startOf('hour').from()}</small> */}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </ModalBody>
                    <ModalFooter>
                        <button className='base-font-500 btn fs-6 bg-base-color border-0 w-100 rounded-3 text-white title-font-bold text-uppercase' onClick={() => toggleModal()}>
                            Ok
                        </button>
                    </ModalFooter>
                </Modal>
            }
            <Dashboard />
        </>
    )
}

export default Home;