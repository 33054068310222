import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Label } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { selectPatientType } from "../../redux/Slice/CollectionApp";
import QrReader from "react-qr-scanner";
import { Scanner } from "iconsax-react";
import { useCallback } from "react";

const CollectionHeader = () => {
  const dispatch = useDispatch();

  const CustomerAdminDetails = useSelector(
    (state) => state.login.loggedUserDetails
  );
  const selectedPatient = useSelector(
    (state) => state.collection.selectedPatient
  );
  const selectedPatientType = useSelector(
    (state) => state.collection.selectedPatientType
  );

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const IntegrateOptions = [
    { value: "patient", label: "Patient" },
    { value: "student", label: "Student" },
  ];

  const ClinicOptions = [{ value: "patient", label: "Patient" }];

  const SchoolOption = [{ value: "student", label: "Student" }];

  const patientType = watch("patient_type");

  const patientOptions = (serviceType) => {
    if (serviceType === "clinic_community_screening") {
      return ClinicOptions;
    } else if (serviceType === "clinic_individual_screening") {
      return SchoolOption;
    } else if (serviceType === "clinic_integrated_screening") {
      return IntegrateOptions;
    } else {
      return SchoolOption;
    }
  };

  const defaultPatientType = (serviceType) => {
    if (serviceType === "clinic_community_screening") {
      return ClinicOptions[0];
    } else if (serviceType === "clinic_individual_screening") {
      return SchoolOption[0];
    } else if (serviceType === "clinic_integrated_screening") {
      return IntegrateOptions[0];
    } else {
      return SchoolOption[0];
    }
  };

  useEffect(() => {
    dispatch(selectPatientType(patientType));
  }, [patientType]);

  useEffect(() => {
    dispatch(
      selectPatientType(
        defaultPatientType(CustomerAdminDetails?.branch?.customer?.service_type)
      )
    );
  }, [CustomerAdminDetails]);

  const [delay, setDelay] = useState(100);


  const handleScan = (scanedData) => {
    if (scanedData) {
      setResult(scanedData);
      setStartScan(false);
    }
  };

  const handleError = useCallback((err) => {
    console.error(err);
  }, []);

  const previewStyle = {
    height: 240,
    width: 320,

    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 9999,
    backgroundColor: "white",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  };



  return (
    <>
      {CustomerAdminDetails && (
        <div className="row px-0 px-md-2 p-md-2">
          {CustomerAdminDetails && (
            <div
              className="col-11 mx-auto col-lg-12 clinic-details-styles"
              style={{ zIndex: 1 }}
            >
              <div className="title-font-600 mb-0">
                {selectedPatientType?.value === "patient" ? (
                  <>{CustomerAdminDetails?.branch?.customer?.name}</>
                ) : (
                  <>{CustomerAdminDetails?.branch?.customer?.name}</>
                )}
              </div>
              <div className="title-font-600 mb-0">
                {CustomerAdminDetails?.branch?.name}
              </div>
            </div>
          )}
          <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-3 pb-3 pt-1 flex-column flex-row collection_app_form">
            <Label className="fs-6 title-font-600 mb-0 ">Nurse</Label>
            <div className="mt-1  w-100">
              <input
                className="p-2 border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input"
                type="text"
                readOnly
                placeholder="Nurse..."
                {...register("nurse")}
                value={`${CustomerAdminDetails?.fname} ${CustomerAdminDetails?.lname}`}
              />
            </div>
          </div>
          <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-3 pb-3 pt-2 flex-column flex-row  collection_app_form">
            <label htmlFor="type" className="fs-6 title-font-600 mb-0 ">
              Patient Type
            </label>
            <div className="mt-1 w-100 ">
              <Controller
                control={control}
                name="patient_type"
                rules={{
                  required: true,
                }}
                defaultValue={defaultPatientType(
                  CustomerAdminDetails?.branch?.customer?.service_type
                )}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={patientOptions(
                      CustomerAdminDetails?.branch?.customer?.service_type
                    )}
                    // options={patientOptions("integrated")}
                    value={value}
                    onChange={onChange}
                    className="border-radius-10 shadow-none w-100 fs-6 title-font-500 select-patient-type modal-input"
                  />
                )}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CollectionHeader;
