import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Controller, useForm } from "react-hook-form";
import ErrorComponents from '../ErrorComponents/ErrorComponents';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { ApiEndPoint } from '../../constants/apiEndPoints/endPoints';
import { selectPatient, selectStudent } from '../../redux/Slice/CollectionApp';
import StudentScreeningSubmission from './StudentScreeningSubmission';
import { ScreeningImageLink, UploadScreeningImages } from '../../constants/AWS/AWSConfiguration';
import { Form, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { errorAlert, successAlert, warningAlert } from '../Alerts/Alerts';
import { Add, ArrowDown2, ArrowUp2, DocumentDownload, DocumentText1, Scanner, ScanBarcode } from 'iconsax-react';
import { toast } from 'react-hot-toast';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { NavLink } from 'react-router-dom';
import { BottomTeeth, FrontTeeth, TopTeeth } from '../../constants/apiEndPoints/ReportCode';
// import QrReader from "react-qr-scanner";
import QrReader from 'react-web-qr-reader';

const StudentCollectionApp = () => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [fileExtension, setFileExtension] = useState('')

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const CustomerAdminDetails = useSelector((state) => state.login.loggedUserDetails);
  const selectedStudentIs = useSelector((state) => state.collection.selectedStudent);
  const selectedPatient = useSelector((state) => state.collection.selectedStudent);

  const [schoolsList, setSchoolsList] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState();
  const [gradesList, setGradesList] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState();
  const [teachersList, setTeachersList] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState();
  const [studentLists, setStudentList] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState();
  const [isFormSubmiting, setIsFormSubmiting] = useState(false);
  const [uptoplatter, setuptoplatter] = useState('');
  const [modal, setModal] = useState(false);
  const [result, setResult] = useState("No result");
  const [startScan, setStartScan] = useState(false);
  const [delay, setDelay] = useState(100);

  const scrollToImageUploadText = useRef(null);

  const previewStyle = {
    height: 240,
    width: 320,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  };

  const toggle = () => setModal(!modal);

  const Genders = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'other', label: 'Other' }
  ];

  const DentalPain = [
    { value: 'yes', label: 'Yes', dbvalue: "true" },
    { value: 'no', label: 'No', dbvalue: "false" }
  ];

  const schoolname = watch("schoolname");
  const grade = watch("grade");
  const school_teacher = watch("school_teacher");
  const studentlist = watch("studentlistc");
  const optOutLetterSelected = watch("Opt_Out_Letter");
  const front_teeth = watch("front_teeth");
  const gurdianEmail1 = watch("gmail1");
  const gurdianEmail2 = watch("email2");

  const handleSchoolSelect = (selectedSchool) => {
    setSelectedSchool(selectedSchool);
    const GetNurseList = {
      nurse: CustomerAdminDetails.id,
      school: selectedSchool.value
    };

    axios.post(ApiEndPoint.gradeList, GetNurseList).then((gradeResponse) => {
      if (gradeResponse.data.status === "success") {
        const gradesLists = gradeResponse?.data?.gradeList?.map((grades) => {
          return { label: grades.hierarchy_level4, value: grades.hierarchy_level4 }
        });
        setGradesList(gradesLists);
      }
    }).catch((err) => {
      if (err) {
        toast.error("An error occurred. Please try again.")
      }
    })
  };

  const handleGradeSelected = (selectedGrade) => {
    if (selectedGrade && selectedSchool && selectedGrade) {
      setSelectedGrade(selectedGrade);
      const GetClassTeacherPayload = {
        nurse: CustomerAdminDetails.id,
        school: selectedSchool.value,
        grade: selectedGrade.value
      };
      axios.post(ApiEndPoint.classTeacherList, GetClassTeacherPayload).then((classTeacherResponse) => {
        if (classTeacherResponse.data.status === "success") {
          const classTeacherLists = classTeacherResponse?.data?.teacherList?.map((grades) => {
            return { label: grades.hierarchy_level3, value: grades.hierarchy_level3 }
          });
          setTeachersList(classTeacherLists);
        }
      }).catch((err) => {
        if (err) {
          toast.error("An error occurred. Please try again.")
        }
      })
    }
  };

  const handleSelectedTeacher = (selectedTeacher) => {
    if (selectedTeacher && selectedSchool && selectedGrade) {
      setSelectedTeacher(selectedTeacher);
      const GetStudentList = {
        nurse: CustomerAdminDetails.id,
        school: selectedSchool?.value,
        grade: selectedGrade?.value,
        teacher: selectedTeacher?.value
      };

      axios.post(ApiEndPoint.studentsList, GetStudentList).then(studentResponse => {
        if (studentResponse.data.status === "success") {
          const studentsLists = studentResponse?.data?.studentsList?.map((students) => {
            return { label: students.number, value: students.number }
          });
          setStudentList(studentsLists);
        }
      }).catch((err) => {
        if (err) {
          toast.error("An error occurred. Please try again.")
        }
      })
    }
  };

  const handleSelectedStudent = (selectedStudent) => {
    if (selectedStudent && selectedSchool && selectedGrade && selectedTeacher) {
      setSelectedStudent(selectedStudent);
      const patientDetailsPayload = {
        nurse: CustomerAdminDetails.id,
        school: selectedSchool.value,
        grade: selectedGrade.value,
        teacher: selectedTeacher.value,
        studentid: Number(selectedStudent.value)
      };

      axios.post(ApiEndPoint.collectionPatientDetails, patientDetailsPayload).then((patientResponse) => {
        if (patientResponse.data.status === "success") {
          dispatch(selectStudent(patientResponse.data.patientDetails));
          setValue("name", patientResponse?.data?.patientDetails?.first_name);
          setValue("gender", Genders.filter((gender) => gender.value === patientResponse?.data?.patientDetails?.gender?.toLowerCase())[0]);
          setValue("dentalpain", DentalPain.filter((pain) => pain.dbvalue === patientResponse?.data?.patientDetails?.dental_pain?.toLowerCase())[0]);
          setValue("gmail1", patientResponse?.data?.patientDetails?.alternate_email1);
          setValue("email2", patientResponse?.data?.patientDetails?.alternate_email2);
        } else {
          dispatch(selectStudent());
          setValue("name", "");
          setValue("gender", "");
          setValue("dentalpain", "");
          setValue("gmail1", "");
          setValue("email2", "");
        }
      }).catch((err) => {
        if (err) {
          toast.error("An error occurred. Please try again.")
        }
      })
    }
  };

  const handleSelectedStudentFromQRCode = (selectedStudentPayload) => {
    axios.post(ApiEndPoint.collectionPatientDetails, selectedStudentPayload).then((patientResponse) => {
      if (patientResponse.data.status === "success") {
        dispatch(selectStudent(patientResponse.data.patientDetails));
        setValue("name", patientResponse?.data?.patientDetails?.first_name);
        setValue("gender", Genders.filter((gender) => gender.value === patientResponse?.data?.patientDetails?.gender?.toLowerCase())[0]);
        setValue("dentalpain", DentalPain.filter((pain) => pain.dbvalue === patientResponse?.data?.patientDetails?.dental_pain?.toLowerCase())[0]);
        setValue("gmail1", patientResponse?.data?.patientDetails?.alternate_email1);
        setValue("email2", patientResponse?.data?.patientDetails?.alternate_email2);
      } else {
        dispatch(selectStudent());
        setValue("name", "");
        setValue("gender", "");
        setValue("dentalpain", "");
        setValue("gmail1", "");
        setValue("email2", "");
      }
    }).catch((err) => {
      if (err) {
        toast.error("An error occurred. Please try again.")
      }
    })

  };

  const [frontTeethImageURL, setFrontTeethImageURL] = useState("");
  const [topTeethImageURL, setTopTeethImageURL] = useState("");
  const [bottonTeethImageURL, setBottomTeethImageURL] = useState("");
  const [optOutLetterURL, setOptOutLetterURL] = useState();
  const [toggleLetter, settoggleLetter] = useState(false);
  const [Loader, setLoader] = useState(false);

  const handleFrontTeeth = (frontTeeth) => {
    setFrontTeethImageURL();
    if (frontTeeth.length > 0) {
      const frontTeethImageCreatedURL = URL.createObjectURL(frontTeeth[0]);
      setFrontTeethImageURL(frontTeethImageCreatedURL);
    }
  };

  const handleTopTeeth = (topTeeth) => {
    setTopTeethImageURL()
    if (topTeeth.length > 0) {
      const topTeethImageCreatedURL = URL.createObjectURL(topTeeth[0]);
      setTopTeethImageURL(topTeethImageCreatedURL);
    }
  };

  const handleBottomTeeth = (bottonTeeth) => {
    setBottomTeethImageURL()
    if (bottonTeeth.length > 0) {
      const bottomTeethImageCreatedURL = URL.createObjectURL(bottonTeeth[0]);
      setBottomTeethImageURL(bottomTeethImageCreatedURL);
    }
  };

  const handleOptOutLetter = (optOutLetter) => {
    setuptoplatter(optOutLetter);
    if (optOutLetter.length > 0) {
      const split_name = optOutLetter[0]?.name?.split(".");
      const extension = split_name[1];
      setFileExtension(extension)
      const optOutLetterURL = URL.createObjectURL(optOutLetter[0]);
      setOptOutLetterURL(optOutLetterURL);
    }
  };

  const SendEvaluatorNotification = (fname, lname, patient_number, eval_status, pid) => {
    const notificationMessage = `${fname} ${lname} Screening is completed`;
    const sendNotificationPayload = {
      title: notificationMessage,
      sender: CustomerAdminDetails?.id,
      branch_id: CustomerAdminDetails?.branch?.id,
      patient_number: patient_number,
      patient_id: pid,
      evaluation_status: eval_status
    };
    axios.post(ApiEndPoint.createNotification, sendNotificationPayload).catch((err) => {
      if (err) {
        toast.error("An error occurred. Please try again.")
      }
    })
  };


  const onSubmit = async (screeningSubmissionData) => {
    try {
      setIsFormSubmiting(true);
      if (screeningSubmissionData?.Opt_Out_Letter?.length > 0) {
        const optout_letter_url_extension = screeningSubmissionData?.Opt_Out_Letter[0]?.name.split(".")[1];
        var opt_out_letter_url = `${ScreeningImageLink}optout_letter_${selectedPatient?.patient_number}.${optout_letter_url_extension}`;
        await UploadScreeningImages(screeningSubmissionData?.Opt_Out_Letter[0], `optout_letter_${selectedPatient?.patient_number}.${optout_letter_url_extension}`);
      };

      if (screeningSubmissionData?.bottom_teeth?.length > 0 && !opt_out_letter_url) {
        const bottom_teeth_image_url_extension = screeningSubmissionData?.bottom_teeth[0]?.name.split(".")[1];
        await UploadScreeningImages(screeningSubmissionData?.bottom_teeth[0], `bottom_teeth_${selectedPatient?.patient_number}.${bottom_teeth_image_url_extension}`);
        var bottom_teeth_image_url = `${ScreeningImageLink}bottom_teeth_${selectedPatient?.patient_number}.${bottom_teeth_image_url_extension}`;
      };

      if (screeningSubmissionData?.front_teeth?.length > 0 && !opt_out_letter_url) {
        const front_teeth_image_url_extension = screeningSubmissionData?.bottom_teeth[0]?.name.split(".")[1];
        await UploadScreeningImages(screeningSubmissionData?.front_teeth[0], `front_teeth_${selectedPatient?.patient_number}.${front_teeth_image_url_extension}`);
        var front_teeth_image_url = `${ScreeningImageLink}front_teeth_${selectedPatient?.patient_number}.${front_teeth_image_url_extension}`;
      };

      if (screeningSubmissionData?.top_teeth?.length > 0 && !opt_out_letter_url) {
        const top_teeth_image_url_extension = screeningSubmissionData?.bottom_teeth[0]?.name.split(".")[1];
        await UploadScreeningImages(screeningSubmissionData?.top_teeth[0], `top_teeth_${selectedPatient?.patient_number}.${top_teeth_image_url_extension}`);
        var top_teeth_image_url = `${ScreeningImageLink}top_teeth_${selectedPatient?.patient_number}.${top_teeth_image_url_extension}`;
      };

      screeningSubmissionData.optout = opt_out_letter_url ? opt_out_letter_url : selectedPatient?.opt_out_letter_url;
      screeningSubmissionData.bottom_image = opt_out_letter_url ? "" : bottom_teeth_image_url ? bottom_teeth_image_url : selectedPatient?.bottom_image;
      screeningSubmissionData.front_image = opt_out_letter_url ? "" : front_teeth_image_url ? front_teeth_image_url : selectedPatient?.front_image;
      screeningSubmissionData.top_image = opt_out_letter_url ? "" : top_teeth_image_url ? top_teeth_image_url : selectedPatient?.top_image;
      screeningSubmissionData.eval_status = "screened";
      screeningSubmissionData.patient_number = selectedPatient?.patient_number;
      screeningSubmissionData.nurse_id = CustomerAdminDetails?.id;
      screeningSubmissionData.alternate_email1 = screeningSubmissionData.gmail1;
      screeningSubmissionData.alternate_email2 = screeningSubmissionData.email2;
      screeningSubmissionData.gender = screeningSubmissionData.gender ? screeningSubmissionData.gender.value : null;
      screeningSubmissionData.grade = screeningSubmissionData.grade.value;
      screeningSubmissionData.dental_pain = screeningSubmissionData.dentalpain.value;


      axios.post(ApiEndPoint.collection, screeningSubmissionData).then((response) => {
        if (response.data.status === "success") {
          successAlert("Data collection is completed.");
          SendEvaluatorNotification(selectedPatient.first_name, selectedPatient.last_name ? selectedPatient.last_name : "", response.data.patientIs.patient_number, response.data.patientIs.eval_status, response.data.patientIs.id);
          setIsFormSubmiting(false);
          clear();
        } else if (response.data.status === "failed") {
          warningAlert("Data collection is not completed.");
          setIsFormSubmiting(false);
        } else {
          warningAlert("Data collection is not completed.");
          setIsFormSubmiting(false);
        }
      }).catch((err) => {
        errorAlert(err?.message);
        setIsFormSubmiting(false);
      });
    } catch {
      setIsFormSubmiting(false);
      toast.error("An error occurred. Please try again123.")
    }

  };

  const clear = () => {
    reset({
      "name": "",
      // "schoolname": "",
      // "grade": "",
      // "school_teacher": "",
      // "studentlistc": "",
      "top_teeth": "",
      "gmail1": "",
      "front_teeth": "",
      "email2": "",
      "dentalpain": "",
      "bottom_teeth": "",
      "Opt_Out_Letter": "",
      "gender": "",
      "fname": "",
      "lname": "",
      "contact": "",
      "email": "",
      "address": "",
      "emr": "",
    });
    toast.success("Form Clear successfully!");
    // setValue("schoolname", "");
    // setValue("grade", "");
    // setValue("school_teacher", "");
    // setGradesList();
    // setTeachersList();
    // setStudentList();
    //setSelectedGrade();
    //setSelectedTeacher();
    setValue("schoolname", schoolname);
    setValue("grade", grade);
    setValue("school_teacher", school_teacher);
    setSelectedStudent();
    dispatch(selectStudent());
    dispatch(selectPatient());
    setFrontTeethImageURL("");
    setTopTeethImageURL("");
    setBottomTeethImageURL("");
    setOptOutLetterURL("");
    setSelectedStudent();

  };

  const handleSpanClick = () => {
    // Trigger the click event of the hidden file input
    // fileInputRef?.current?.click();
    setModal(true);
    // if (!optOutLetterURL) {
    //   setModal(true);
    // }
  };

  const handleSendReport = (student) => {
    setLoader(true);

    if (student.alternate_email1 !== "" && student.alternate_email1 !== null) {
      const sendMailPayload = {
        to: student.alternate_email1,
        code: student.treatment_recommendation_code.replace(/(\w)\s+(\w)/g, '$1$2')?.toLowerCase(),
        branch: CustomerAdminDetails.branch.id,
        screening_id: student.id
      };

      axios.post(ApiEndPoint.sendReportMail, sendMailPayload).then((sendMailResponse) => {
        if (sendMailResponse.data.status === "success") {
          toast.success("Mail sent");
          setLoader(false)
        } else {
          toast.error("Mail not sent");
          // errorAlert("Email not provided for sending report")
          setLoader(false)
        }
      }).catch((error) => {
        setLoader(false)
        if (error) {
          toast.error("An error occurred. Please try again.")
        }
      })

    } else {
      errorAlert("Email not provided for sending report")
      setLoader(false)
    }
  };

  const screening = (evalStatus) => {
    if (evalStatus == "completed") {
      return false
    } else if (evalStatus == "reported") {
      return false
    }
    return true
  };

  const disabledTillSubmit = (submitedValue) => {
    if (submitedValue) {
      return true;
    }
    return false;
  };


  const continueOPTLetter = async () => {
    await fileInputRef?.current?.click();
    toggle();
  };

  const handleScanClick = () => {
    setStartScan(!startScan);
  };
  const handleScan = (scanedData) => {
    if (scanedData) {
      setResult(scanedData);
      setStartScan(false);
      const scannedValues = scanedData?.data?.split(";")
      setValue("schoolname", { label: scannedValues[0], value: scannedValues[0] });
      setValue("grade", { label: scannedValues[1], value: scannedValues[1] });
      setValue("school_teacher", { label: scannedValues[2], value: scannedValues[2] });
      setValue("studentlist", { label: scannedValues[3], value: scannedValues[3] });
      setValue("studentlistc", { label: scannedValues[3], value: scannedValues[3] });

      const payload = {
        nurse: CustomerAdminDetails.id,
        school: scannedValues[0],
        grade: scannedValues[1],
        teacher: scannedValues[2],
        studentid: scannedValues[3]
      };
      handleSelectedStudentFromQRCode(payload);
    }
  };

  const toggleQR = () => setStartScan(!startScan);

  const handleError = useCallback((err) => {
    console.error(err);
  }, []);

  const handleUpdateDetails = (patientNumber) => {
    if (gurdianEmail1 || gurdianEmail2) {
      const EmailUpdatePayload = {
        patient_number: patientNumber,
        gurdian_email_1: gurdianEmail1,
        gurdian_email_2: gurdianEmail2
      };
      axios.put(ApiEndPoint.patientnEdit, EmailUpdatePayload).then((response) => {
        if (response.data.status === "success") {
          toast.success("Student Email is Updated.")
        } else if (response.data.status === " failed") {
          toast.success("Student Email is not Updated.")
        } else {
          toast.success("Student Email is not Updated.")
        }
      })
    }
  };

  useEffect(() => {
    if (schoolname) {
      handleSchoolSelect(schoolname);
    };
  }, [schoolname]);

  useEffect(() => {
    if (grade) {
      handleGradeSelected(grade);
    }
  }, [grade]);

  useEffect(() => {
    if (school_teacher) {
      handleSelectedTeacher(school_teacher);
    }
  }, [school_teacher]);

  useEffect(() => {
    if (CustomerAdminDetails) {
      axios.post(`${ApiEndPoint.schoolsByNurse}${CustomerAdminDetails.id}/`).then((response) => {
        const schoolsListChnages = response?.data?.schoolList?.map((data) => {
          return { "label": data.hierarchy_level2, "value": data.hierarchy_level2 }
        });
        setSchoolsList(schoolsListChnages);
      }).catch((e) => {
        if (e) {
          toast.error("An error occurred. Please try again.")
        }
      })
    }
  }, [CustomerAdminDetails]);

  useEffect(() => {
    if (selectedPatient) {
      setFrontTeethImageURL(selectedPatient?.front_image);
      setTopTeethImageURL(selectedPatient?.top_image);
      setBottomTeethImageURL(selectedPatient?.bottom_image);
      setOptOutLetterURL(selectedPatient?.optout);
      if (selectedPatient?.optout) {
        settoggleLetter(true)
      } else {
        settoggleLetter(false)
      }
    } else {
      setFrontTeethImageURL("");
      setTopTeethImageURL("");
      setBottomTeethImageURL("");
      setOptOutLetterURL("");
    }
  }, [selectedPatient]);

  useEffect(() => {
    if (optOutLetterSelected) {
      setFrontTeethImageURL("");
      setTopTeethImageURL("");
      setBottomTeethImageURL("");
    }
  }, [optOutLetterSelected]);

  useEffect(() => {
    if (selectedPatient) {
      if (selectedPatient?.eval_status === "screened" || selectedPatient?.eval_status === "completed") {
        setTimeout(() => {
          scrollToImageUploadText.current?.scrollIntoView({ behavior: "smooth" });
        }, 500);
      }
    }
  }, [studentlist, selectedPatient]);



  useEffect(() => {
    dispatch(selectStudent());
  }, []);

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Warning : Any Photo's taken will be deleted.</ModalHeader>
        <ModalFooter>
          <button type='submit' className="close-btn" onClick={() => toggle()}>
            Cancel
          </button>{' '}
          <button type='submit' className="submit-btn" onClick={() => continueOPTLetter()}>
            Continue
          </button>
        </ModalFooter>
      </Modal>

      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">

        <div className="row px-0 px-md-2 p-md-2 shadow  py-2 rounded-2 border mx-0">
          <div className="d-flex pb-3 pt-1 justify-content-between flex-column flex-md-row gap-4 align-items-center">
            <div className='d-flex w-100'>
              <label className="title-font-600 mb-0 fs-5 text-uppercase border-bottom-color2-2 mobile-heading">
                Student Screening
              </label>
              <span className='px-3 cursor' data-bs-toggle="tooltip"
                title="Click to scan QR code">
                <ScanBarcode
                  size="32"
                  color="#5EC0D8"
                  onClick={handleScanClick}
                />

                <Modal isOpen={startScan} toggle={toggleQR} centered={true}>
                  <ModalHeader toggle={toggleQR}>Scan QR Code</ModalHeader>
                  <ModalBody className='d-inline-flex justify-content-center'>
                    <QrReader
                      facingMode={"environment"}
                      delay={delay}
                      onError={handleError}
                      onScan={handleScan}
                      style={{ width: "300px", margin: "0 auto" }}
                    />
                  </ModalBody>
                </Modal>

              </span>
            </div>
          </div>
          <div className='col-11 mx-auto mx-md-0 col-md-6 col-lg-4 pb-3 pt-1'>
            <label htmlFor="type" className='fs-6 title-font-600 mb-0 text-black'>
              School Name*
            </label>
            <Controller
              control={control}
              name="schoolname"
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <Select options={schoolsList} value={value} onChange={onChange} className='border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input ' /> //onChange={(e) => handleSchoolSelect(e)}
              )}
            />
            {errors.schoolname && (
              <ErrorComponents error={"Please select school"} />
            )}
          </div>
          <div className='col-11 mx-auto mx-md-0 col-md-6 col-lg-4 pb-3 pt-1'>
            <label htmlFor="type" className='fs-6 title-font-600 mb-0 text-black'>
              Grade*
            </label>
            <Controller
              control={control}
              name="grade"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Select options={gradesList} value={value} onChange={onChange} className='border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input ' /> //onChange={(e) => handleGradeSelected(e)}
              )}
            />
            {errors.grade && (
              <ErrorComponents error={"Please select grade"} />
            )}
          </div>
          <div className='col-11 mx-auto mx-md-0 col-md-6 col-lg-4 pb-3 pt-1'>
            <label htmlFor="type" className='fs-6 title-font-600 mb-0 text-black'>
              School Teacher*
            </label>
            <Controller
              control={control}
              name="school_teacher"
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <Select options={teachersList} value={value} onChange={onChange} className='border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input ' /> //onChange={(e) => handleSelectedTeacher(e)}
              )}
            />
            {errors.school_teacher && (
              <ErrorComponents error={"please select school teacher"} />
            )}
          </div>
          <div className='col-11 mx-auto mx-md-0 col-md-6 col-lg-4 pb-3 pt-1'>
            <label htmlFor="type" className='fs-6 title-font-600 mb-0 text-black'>
              Student List
            </label>
            <Controller
              control={control}
              name="studentlistc"
              rules={{
                required: false,
              }}
              render={({ field: { onChange, value } }) => (
                <Select options={studentLists} value={value} onChange={(e) => handleSelectedStudent(e)} className='border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input ' />
              )}
            />
          </div>
          <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-4 pb-3 pt-1">
            <label className="fs-6 title-font-600 mb-0 text-black">
              Student's Legal Name*
            </label>

            <div className="mt-1 d-flex gap-3">
              <input
                className="p-2 border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input"
                type="text"
                maxLength={3}
                placeholder="Enter Name..."
                {...register("name", {
                  required: "Student's Legal Name*"
                })}
              />
            </div>
            {errors.name && (
              <ErrorComponents error={errors.name?.message} />
            )}

          </div>
          {/* <div className='col-11 mx-auto mx-md-0 col-md-6 col-lg-4 pb-3 pt-1'>
            <label htmlFor="type" className='fs-6 title-font-600 mb-0 text-black'>
              Gender*
            </label>
            <Controller
              control={control}
              name="gender"
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <Select options={Genders} value={value} onChange={onChange} className='border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input ' />
              )}
            />
            {errors.gender && (
              <ErrorComponents error={"Please select gender"} />
            )}
          </div> */}
          <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-4 pb-3 pt-1">
            <label className="fs-6 title-font-600 mb-0 text-black">
              Parent/Guardian Email 1
            </label>

            <div className="mt-1 d-flex gap-3">
              <input
                className="p-2 border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input"
                type="text"
                placeholder="Enter Email..."
                {...register("gmail1")}
              />
            </div>

          </div>
          <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-4 pb-3 pt-1">
            <label className="fs-6 title-font-600 mb-0 text-black">
              Parent/Guardian Email 2
            </label>

            <div className="mt-1 d-flex gap-3">
              <input
                className="p-2 border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input"
                type="text"
                placeholder="Enter Email..."

                {...register("email2")}
              />
            </div>
          </div>
          <div className='col-11 mx-auto mx-md-0 col-md-6 col-lg-4 pb-3 pt-1'>
            <label htmlFor="type" className='fs-6 title-font-600 mb-0 text-black'>
              Are you experiencing dental pain?*
            </label>

            <Controller
              control={control}
              name="dentalpain"
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <Select options={DentalPain} value={value} onChange={onChange} className='border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input ' />
              )}
            />

            {errors.dentalpain && (
              <ErrorComponents error={"Please select one "} />
            )}
          </div>
          {(selectedStudentIs && selectedStudentIs.eval_status == "completed") &&
            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-4 pb-3 pt-1">
              <div className="mt-1 d-flex gap-3">
                <button type='button' className="submit-btn mt-2" onClick={() => handleUpdateDetails(selectedStudentIs.patient_number)}>Update Email</button>
              </div>
            </div>}
          <div ref={scrollToImageUploadText}></div>
        </div>

        {/* ----------------------- */}

        {selectedStudentIs && <>
          <div className='mb-4'>
            <input hidden />
          </div>
          <div className="px-2 px-lg-2 mt-4">
            <h5 className='title-font-bold text-base-color fs-5 pb-1 mb-0 border-bottom-color-2'>IMAGES</h5>
            <p className='base-font-600 text-base-gray pt-1'>Please have your student in good lighting and take the pictures as shown . You can refer to this <a className='text-base-color title-font-600 ' href='https://www.youtube.com/watch?v=ZRb-4HpAE9Y' target='_blank'>Video</a> on how to take the best photos for screening.</p>
          </div>
          <div className="row row-gap-3 mt-5 mx-auto">
            <div className='col-12 mx-auto mx-md-0 col-md-6 col-lg-4 col-xl-3 text-center'>
              <div className='col-12 mx-auto mx-md-0 mb-4 text-center'>
                <h6 className='title-font-600 text-black fs-6'>Front Teeth Demo Image*</h6>
                <label className="image_overlay">
                  <div className="image_box ">
                    <img src={FrontTeeth} alt="home_wrapper" className='img-fluid rounded-3 ' />
                  </div>
                </label>
              </div>
              {
                frontTeethImageURL ? <label className={screening(selectedPatient?.eval_status) ? "image_overlay cursor" : "image_overlay"} htmlFor={screening(selectedPatient?.eval_status) ? 'Front_Teeth' : ""} title={screening(selectedPatient?.eval_status) ? 'Front Teeth' : 'Not Editable!'}>
                  <figure className=" position-relative ">
                    <img src={frontTeethImageURL} alt="Front teeth" className='img-fluid rounded-3 ' />
                  </figure>
                </label> : <label htmlFor='Front_Teeth' className="images_card_box">
                  <div className="figcaptions" title='Front Teeth'>
                    <h6 className='base-font-600 text-white  '>
                      <span><Add size={24} className='text-white' /></span> Click Here to Add Front Teeth Photo</h6>
                  </div>
                </label>
              }
              <div className="">
                <input
                  id='Front_Teeth'
                  className="d-none"
                  type="file"
                  title='Front Teeth'
                  disabled={optOutLetterURL && optOutLetterURL}
                  placeholder="Upload Images..."
                  accept="image/*"
                  {...register("front_teeth", {
                    required: (selectedPatient?.front_image || optOutLetterURL) ? false : "Front Teeth Photo*",
                    onChange: (e) => { handleFrontTeeth(e.target.files) },
                  })}
                />
                {errors.front_teeth && (
                  <ErrorComponents error={errors.front_teeth?.message} />
                )}
              </div>
            </div>
            <div className='col-12 mx-auto mx-md-0 col-md-6 col-lg-4 col-xl-3 text-center'>
              <div className='col mx-auto mb-4 text-center'>
                <h6 className='title-font-600 text-black fs-6'>Top Teeth Demo Image*</h6>
                <label className="image_overlay">
                  <div className="image_box ">
                    <img src={TopTeeth} alt="home_wrapper" className='img-fluid rounded-3 ' />
                  </div>

                </label>

              </div>
              {
                topTeethImageURL ? <label htmlFor={screening(selectedPatient?.eval_status) ? 'Top_Teeth' : ""} className={screening(selectedPatient?.eval_status) ? "image_overlay cursor" : "image_overlay"} title={screening(selectedPatient?.eval_status) ? 'Top Teeth' : 'Not Editable!'}>
                  <figure className=" position-relative">
                    <img src={topTeethImageURL} alt="Bottom teeth" className='img-fluid rounded-3 ' />
                  </figure>
                </label> : <label htmlFor='Top_Teeth' className="images_card_box" title='Top Teeth'>
                  <div className="figcaptions">
                    <h6 className='base-font-600 text-white  '>
                      <span><Add size={24} className='text-white' /></span> Click Here to Add Top Teeth Photo.</h6>
                  </div>
                </label>
              }
              <div className="">
                <input
                  id='Top_Teeth'
                  className="d-none"
                  type="file"
                  title='Top Teeth'
                  placeholder="Upload Images..."
                  disabled={optOutLetterURL && optOutLetterURL}
                  accept="image/*"
                  {...register("top_teeth", {
                    required: (selectedPatient?.bottom_image || optOutLetterURL) ? false : "Top Teeth Photo*",
                    onChange: (e) => { handleTopTeeth(e.target.files) },
                  })}
                />
                {errors.top_teeth && (
                  <ErrorComponents error={errors.top_teeth?.message} />
                )}
              </div>
            </div>
            <div className='col-12 mx-auto mx-md-0 col-md-6 col-lg-4 col-xl-3 text-center'>
              <div className='col mx-auto mb-4 text-center'>
                <h6 className='title-font-600 text-black fs-6'>Bottom Teeth Demo Image*</h6>
                <label className="image_overlay">
                  <div className="image_box ">
                    <img src={BottomTeeth} alt="home_wrapper" className='img-fluid rounded-3 ' />
                  </div>
                </label>
              </div>
              {
                bottonTeethImageURL ? <label htmlFor={screening(selectedPatient?.eval_status) ? 'Bottom_Teeth' : ""} className={screening(selectedPatient?.eval_status) ? "image_overlay cursor" : "image_overlay"} title={screening(selectedPatient?.eval_status) ? 'bottom Teeth' : 'Not Editable!'}>
                  <figure className=" position-relative ">
                    <img src={bottonTeethImageURL} alt="Top teeth" className='img-fluid rounded-3 ' />
                  </figure>
                </label> : <label htmlFor='Bottom_Teeth' className="images_card_box">
                  <div className="figcaptions" title='bottom teeth'>
                    <h6 className='base-font-600 text-white  '>
                      <span><Add size={24} className='text-white' /></span> Click Here to Add Bottom Teeth Photo</h6>
                  </div>
                </label>
              }
              <div className="">
                <input
                  id='Bottom_Teeth'
                  className="d-none"
                  type="file"
                  title='bottom teeth'
                  placeholder="Upload Images..."
                  disabled={optOutLetterURL && optOutLetterURL}
                  accept="image/*"
                  {...register("bottom_teeth", {
                    required: (selectedPatient?.top_image || optOutLetterURL) ? false : "Bottom Teeth Photo*",
                    onChange: (e) => { handleBottomTeeth(e.target.files) }
                  })}
                />
                {errors.bottom_teeth && (
                  <ErrorComponents error={errors.bottom_teeth?.message} />
                )}
              </div>
            </div>
            <div className='col-12 mx-auto mx-md-0 col-md-6 col-lg-4 col-xl-3 text-center'>
              <div className='col mx-auto mb-4 text-center'>
                <div className="d-flex gap-1 align-items-center justify-content-center">
                  <h6 className='title-font-600 text-black fs-6 cursor' onClick={() => settoggleLetter(!toggleLetter)}>Dentist optout letter</h6>
                  {toggleLetter ? <ArrowUp2 className='text-black mb-1' size="20" onClick={() => settoggleLetter(!toggleLetter)} /> : <ArrowDown2 className='text-black mb-1' size="20" onClick={() => settoggleLetter(!toggleLetter)} />
                  }
                </div>
                {toggleLetter && <label className="image_overlay">
                  <div className="image_box ">

                    <img src="https://screeningdemoimages.s3.amazonaws.com/LetterDemo.png" alt="home_wrapper" className='img-fluid rounded-3 ' />
                  </div>
                </label>}
              </div>
              {toggleLetter &&
                <>
                  {
                    optOutLetterURL ?
                      <>
                        <div onClick={() => handleSpanClick()}>
                          <label htmlFor={'Opt_Out_Letter'} className={screening(selectedPatient?.eval_status) ? "image_overlay cursor" : "image_overlay"} title={screening(selectedPatient?.eval_status) ? 'bottom Teeth' : 'Not Editable!'} >
                            <figure className=" position-relative ">
                              <img src={optOutLetterURL} alt="Opt Out Letter" className='img-fluid rounded-3 ' />
                            </figure>
                          </label>
                        </div>
                        <div className='d-flex justify-content-center'>
                          <input type='checkbox' onChange={() => { setOptOutLetterURL(undefined), setValue("Opt_Out_Letter", undefined) }} />
                          <div style={{ marginLeft: '10px' }}>Delete Letter</div>
                        </div>
                      </>
                      :
                      <div onClick={() => handleSpanClick()}>
                        {
                          <label htmlFor='Opt_Out_Letter' className="images_card_box">
                            <div className="figcaptions" title='bottom teeth'>
                              <h6 className='base-font-600 text-white '>
                                <span><Add size={24} className='text-white' /></span> Click Here to Add Opt Out Letter</h6>
                            </div>
                          </label>
                        }
                      </div>
                  }
                </>
              }
              <div>
                <Controller
                  name="Opt_Out_Letter"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                      ref={(el) => {
                        field.ref(el); // Register the input with React Hook Form
                        fileInputRef.current = el; // Save the input ref in the custom useRef
                      }}
                      onChange={(e) => {
                        field.onChange(e?.target?.files); // Notify React Hook Form about the input change
                        handleOptOutLetter(e?.target?.files); // Handle the file input change separately
                      }}
                    />
                  )}
                />
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center justify-content-md-end gap-3 pt-3 mb-4">
              {
                selectedPatient?.eval_status == "completed" && <>
                  {
                    Loader ? <button type="button" className="submit-btn d-flex gap-2 align-items-center" ><span
                      className="spinner-border spinner-border-sm" disabled
                      role="status"
                      aria-hidden="true"
                    ></span>
                      Sending . . .</button> : <button type='button' className="submit-btn2" onClick={() => handleSendReport(selectedPatient)}>
                      Send Report
                    </button>
                  }
                </>
              }
              {/* <button type='submit' className="submit-btn" disabled={selectedPatient?.eval_status == "completed"} >
                Submit
              </button> */}
              {
                isFormSubmiting ? <button type="button" className="submit-btn d-flex gap-2 align-items-center" ><span
                  className="spinner-border spinner-border-sm" disabled
                  role="status"
                  aria-hidden="true"
                ></span>
                  Submitting . . .</button> : <button type='submit' className="submit-btn" disabled={selectedPatient?.eval_status == "completed"} >
                  Submit
                </button>
              }
              <button type='button' className="close-btn" onClick={() => clear()} disabled={disabledTillSubmit(isFormSubmiting)}>
                Clear
              </button>
            </div>
          </div>
        </>}
      </Form >
    </>
  )
}

export default StudentCollectionApp