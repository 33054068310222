import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    notificationsList: [],
    selectedPatientFromNotification: undefined,
    notificationShowList: [],
    selectedPatientIdFormNotification: undefined,
    selectedEvalStatus: { value: "screened", label: "Screened" },
    patientList: undefined,
    unFilteredPatientList: undefined,
    selectedNotificationPatientType: undefined,
    selectedPatientDistrict: undefined
}

export const EvaluatorSlice = createSlice({
    name: 'evaluator',
    initialState,
    reducers: {
        notifications: (state, action) => {
            state.notificationsList = action.payload
        },
        notificationShow: (state, action) => {
            state.notificationShowList = action.payload
        },
        selectPatientFromNotification: (state, action) => {
            state.selectedPatientIdFormNotification = action.payload
        },
        selectEvaluationStatus: (state, action) => {
            state.selectedEvalStatus = action.payload
        },
        setPatientLists: (state, action) => {
            state.patientList = action.payload
        },
        setUnFilteredPatientLists: (state, action) => {
            state.unFilteredPatientList = action.payload
        },
        setSelectedNotificationPatientType: (state, action) => {
            state.selectedNotificationPatientType = action.payload
        },
        setSelectedPatientDistrict: (state, action) => {
            state.selectedPatientDistrict = action.payload
        },
    },
});

export const { notifications, notificationShow, selectPatientFromNotification, selectEvaluationStatus, setPatientLists, setUnFilteredPatientLists, setSelectedNotificationPatientType, setSelectedPatientDistrict } = EvaluatorSlice.actions

export default EvaluatorSlice.reducer