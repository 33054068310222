import axios from "axios";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Label,
} from "reactstrap";

import { Edit, UserEdit } from "iconsax-react";
import ErrorComponents from "../../ErrorComponents/ErrorComponents";
import { ApiEndPoint } from "../../../constants/apiEndPoints/endPoints";
import { useDispatch, useSelector } from "react-redux";
import { successAlert } from "../../Alerts/Alerts";
import { setNurseList } from "../../../redux/Slice/BranchSlice";
import { toast } from "react-hot-toast";
import { loggedUserDetails } from "../../../redux/Slice/LoginSlice";

function NurseEdit(props) {
  const dispatch = useDispatch();
  const BranchAdminDetails = useSelector(
    (state) => state.login.loggedUserDetails
  );
  const [modal, setModal] = useState(false);
  const [Loader, setLoader] = useState(false);
  const toggle = () => setModal(!modal);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const CancelButton = () => {
    reset();
    toggle();
  };

  const GetNurseList = () => {
    if (props?.login == "community") {
      const branchNurseListURL = `${ApiEndPoint.communityNurseList}${BranchAdminDetails?.id}/`;
      axios.get(branchNurseListURL).then((response) => {
        dispatch(setNurseList(response?.data?.nurseList));
      }).catch((e) => {
        if (e) {
          toast.error("An error occurred. Please try again.")
        }
      })
    } else {
      const branchNurseListURL = `${ApiEndPoint.branchNurserList}${BranchAdminDetails?.id}/`;
      axios.get(branchNurseListURL).then((response) => {
        dispatch(setNurseList(response?.data?.nurseList));
      }).catch((e) => {
        if (e) {
          toast.error("An error occurred. Please try again.")
        }
      })
    }

  };

  const EditSubmit = async (data) => {
    setLoader(true);
    const editNursePayload = {
      fname: data?.fname,
      lname: data?.lname,
      email: data?.email,
      // address: data?.address,
    };

    try {
      await axios
        .post(
          `${ApiEndPoint.modifyNurse}${props?.data?.username}/`,
          editNursePayload
        )
        .then((response) => {
          console.log(response,"123");
          CancelButton();
          successAlert(response?.data?.message);
          dispatch(loggedUserDetails(response.data.nurseDetails));
          GetNurseList();
          setLoader(false);
          props.setToggle((prev) => !prev);
        })
        .catch((error) => {
          setLoader(false);
        });
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    reset({
      fname: props?.data?.fname,
      lname: props?.data?.lname,
      email: props?.data?.email,
      address: props?.data?.address,
    });
  }, [modal]);

  return (
    <>
      {props?.button == "row" ? (
        <button
          onClick={toggle}
          className="icon-bg-yellow border-0 p-1 rounded"
        >
          <Edit size="22" className="icon-color-yellow" />
        </button>
      ) : (
        <button
          className="submit-btn base-font-600 fw-bold d-flex align-items-center shadow-lg "
          onClick={toggle}
        >
          <span className="base-font-600">Edit</span>
          <UserEdit size="30" variant="Bulk" className="ps-2" />
        </button>
      )}

      <Modal
        className="border-0"
        isOpen={modal}
        toggle={props.toggle}
        size="lg"
        centered
      >
        <ModalHeader className="bg-base-color2 ">
          <span className="title-font-700 fs-5 text-base-white">
            Edit Nurse
          </span>
        </ModalHeader>
        <Form onSubmit={handleSubmit(EditSubmit)} autoComplete="off">
          <ModalBody className="bg-background-color px-4">
            <div className="row">
              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
                <Label className="fs-6 title-font-600 mb-0 text-black">
                  First Name*
                </Label>

                <div className="mt-1  d-flex gap-3">
                  <input
                    className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                    type="text"
                    placeholder="Name..."
                    {...register("fname", { required: true })}
                  />
                </div>
                {errors.fname && (
                  <ErrorComponents error={"First Name is required *"} />
                )}
              </div>
              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
                <Label className="fs-6 title-font-600 mb-0 text-black">
                  Last Name*
                </Label>

                <div className="mt-1  d-flex gap-3">
                  <input
                    className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                    type="text"
                    placeholder="Name..."
                    {...register("lname", { required: true })}
                  />
                </div>
                {errors.lname && (
                  <ErrorComponents error={"Last Name is required *"} />
                )}
              </div>

              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
                <Label className="fs-6 title-font-600 mb-0 text-black">
                  Email*
                </Label>

                <div className="mt-1  d-flex gap-3">
                  <input
                    className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                    type="email"
                    placeholder="Email..."
                    {...register("email", { required: true })}
                  />
                </div>
                {errors.email && (
                  <ErrorComponents error={"Email is required *"} />
                )}
              </div>

              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-3 pb-1">
                <ModalFooter className="border-0 ">
                  <div className="d-flex justify-content-end align-items-center">
                    <div className="d-flex align-items-center gap-3">
                      {Loader ? (
                        <button
                          type="button"
                          className="submit-btn d-flex gap-2 align-items-center"
                        >
                          <span
                            className="spinner-border spinner-border-sm" disabled
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Editing . . .
                        </button>
                      ) : (
                        <button type="submit" className="submit-btn">
                          Submit
                        </button>
                      )}

                      <button
                        type="button"
                        className="close-btn"
                        onClick={CancelButton}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </ModalFooter>
              </div>

              {/* <div className="col-12 mx-auto mx-md-0  pt-1 pb-1">
                                <Label className="fs-6 title-font-600 mb-0 text-black">
                                    Address*
                                </Label>
                                <div className="mt-1  d-flex gap-3">
                                    <textarea
                                        className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                                        type="text"
                                        placeholder="Enter Address..."
                                        {...register("address", {
                                            required: "Address is required *"
                                        })}
                                    ></textarea>
                                </div>
                                {errors.address && (
                                    <ErrorComponents error={errors.street_address?.message} />
                                )}

                            </div> */}
            </div>
          </ModalBody>
        </Form>
      </Modal>
    </>
  );
}

export default NurseEdit;
