import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Nurse/Home";
import Navbar from "../Components/Navbar/Navbar";
import { Profile } from "iconsax-react";
import CollectionApp from "../pages/Nurse/CollectionApp";
import Dashboard from "../pages/Nurse/Dashboard";
import Import from "../pages/Nurse/Import";
import ScrollToTop from "../Helper/ScrollToTop";
import { useSelector } from "react-redux";
import CreateStudentForm from "../Components/CollectionApp/CreateStudentForm";
import { CalendarDashboard } from "../Components/CalendarDashboard/CalendarDashboard";
import { AppointmentTable } from "../Components/CalendarDashboard/AppointmentTable";

const NurseRoute = () => {
  const CustomerAdminDetails = useSelector(
    (state) => state.login.loggedUserDetails
  );
  const clinicRoutes = [{ url: "/", name: "Collection App" },
  //  { url: "/school-schedule", name: "Data Calendar" },
  ];
  const schoolRoutes = [{ url: "/", name: "Collection App" }];
  const integratedRoutes = [{ url: "/", name: "Collection App" }];


  const massRoutes = [
    { url: "/", name: "On Boarding"},
    { url: "/dashboard", name: "Dashboard" },
    { url: "/data-collection", name: "Dental Screening" },
  ];

  const patientOptions = (serviceType) => {
    if (serviceType === "clinic_community_screening") {
      return clinicRoutes;
    } else if (serviceType === "clinic_individual_screening") {
      return schoolRoutes;
    } else if (serviceType === "clinic_integrated_screening") {
      return integratedRoutes;
    } else {
      return massRoutes;
    }
  };

  const routes = [
    {
      route: patientOptions(
        CustomerAdminDetails?.branch?.customer?.service_type
      ),
    },
    {
      button: [
        {
          url: "/",
          name: "logout",
          icon: <Profile size="20" />,
        },
      ],
    },
  ];

  return (
    <>
      <Navbar routes={routes} />
      <ScrollToTop>
        <div
          className="position-relative  w-100 vh-100 container-xxl"
          style={{ top: "92px" }}
        >
          {CustomerAdminDetails?.branch?.customer?.service_type === "clinic_community_screening" ? (
            <Routes>
              <Route path="/" element={<CollectionApp />} />
              {/* <Route path='/school-schedule' element={<CalendarDashboard />} /> */}
              <Route path="/import" element={<Import />} />
              <Route path="/create-student" element={<CreateStudentForm />} />


            </Routes>
          ) : CustomerAdminDetails?.branch?.customer?.service_type ===
            "clinic_individual_screening" ? (
            <Routes>
              <Route path="/" element={<CollectionApp />} />
              <Route path="/import" element={<Import />} />
              <Route path="/create-student" element={<CreateStudentForm />} />

            </Routes>
          ) : CustomerAdminDetails?.branch?.customer?.service_type ===
            "clinic_integrated_screening" ? (
            <Routes>
              <Route path="/" element={<CollectionApp />} />
              <Route path="/import" element={<Import />} />
              <Route path="/create-student" element={<CreateStudentForm />} />

            </Routes>
          ) : (
            <Routes>
              <Route path="/data-collection" element={<CollectionApp />} />
              <Route path="/dashboard" element={<Home />} />
              <Route path="/import" element={<Import />} />
              <Route path="/create-student" element={<CreateStudentForm />} />
              <Route path="/" element={<AppointmentTable/>} />
            </Routes>
          )}
        </div>
      </ScrollToTop>
    </>
  );
};

export default NurseRoute;
