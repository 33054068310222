import React, { useState, useEffect } from 'react'
import Table from '../../Components/Table/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { loggedUserDetails } from '../../redux/Slice/LoginSlice';
import { ApiEndPoint } from '../../constants/apiEndPoints/endPoints';
import ProfileHeader from '../../Components/ProfileHeader/ProfileHeader';
import NurseEdit from '../../Components/Modal/NurseModals/NurseEdit';
import ProfileLoader from '../../Components/ProfileHeader/ProfileLoader';
import EvaluatorEditModal from '../../Components/Modal/BranchAdminModals/EvaluatorEditModal';
import BranchAdminEdit from '../../Components/Modal/BranchAdminModals/BranchAdminEdit';
import { branchProfile, setNurseList, setScreenerList } from '../../redux/Slice/BranchSlice';
import EmptyTable from '../../Components/Table/EmptyTable';
import { toast } from 'react-hot-toast';
import NurseEditModal from '../../Components/Modal/BranchAdminModals/NurseEditModal';

const Dashboard = () => {
    const dispatch = useDispatch();
    const [Loader, setLoader] = useState(false);
    const username = useSelector((state) => state.login.username);
    const BranchAdminDetails = useSelector((state) => state?.login?.loggedUserDetails);
    const branchProfileDetails = useSelector((state) => state.branch.branchProfileDetails);
    const nurseList = useSelector((state) => state.branch.branchNurseList);
    const screenerList = useSelector((state) => state.branch.branchScreenerList);
    const [toggle, setToggle] = useState(false)

    const GetNurseList = () => {
        if (BranchAdminDetails) {
            const branchNurseListURL = `${ApiEndPoint.branchNurserList}${BranchAdminDetails?.id}/`;
            axios.get(branchNurseListURL).then((response) => {
                dispatch(setNurseList(response?.data?.nurseList));
            }).catch((e) => {
                if (e) {
                    toast.error("An error occurred. Please try again.")
                }
            });
        };
    };

    const GetScreenerList = () => {
        if (BranchAdminDetails) {
            const branchNurseListURL = `${ApiEndPoint.branchScreenerList}${BranchAdminDetails?.id}/`;
            axios.get(branchNurseListURL).then((response) => {
                dispatch(setScreenerList(response?.data?.screenerList));
            }).catch((e) => {
                if (e) {
                    toast.error("An error occurred. Please try again.")
                }
            });
        }
    };

    const GetUserDetails = async () => {
        const url = `${ApiEndPoint.branchDetails}${username}/`;
        await axios.get(url).then((response) => {
            dispatch(loggedUserDetails(response.data.branchDetails));
            dispatch(branchProfile(response.data.branchDetails));
            setLoader(false)
        }).catch((e) => {
            if (e) {
                toast.error("An error occurred. Please try again.")
            }
        });
    };

    useEffect(() => {
        setLoader(true);
        if (username) {
            GetUserDetails();
            GetScreenerList();
        };
    }, [username]);

    useEffect(() => {
        if (BranchAdminDetails) {
            GetNurseList();
            GetScreenerList();
        };
    }, [BranchAdminDetails]);

    const columns = [
        {
            name: 'Sr No',
            cell: (_, index) => index + 1
        },
        {
            name: 'First Name',
            selector: row => row.fname,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Last Name',
            selector: row => row.lname,
            sortable: true,
            wrap: true,
        },
        // {
        //     name: 'Address',
        //     selector: row => row.address,
        //     sortable: true,
        // },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            wrap: true,
        },
        {
            name: 'User Name',
            selector: row => row.username,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Action',
            selector: (row) => <div className="d-flex justify-content-between align-items-center gap-2 ">
                <NurseEditModal data={row} button="row" setToggle={setToggle} />
            </div>,
        },
    ];


    const columnss = [
        {
            name: 'Sr No',
            cell: (_, index) => index + 1
        },
        {
            name: 'Name',
            selector: row => row.fname,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Last Name',
            selector: row => row.lname,
            sortable: true,
            wrap: true,
        },
        // {
        //     name: 'Address',
        //     selector: row => row.address,
        //     sortable: true,
        // },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            wrap: true,
        },
        {
            name: 'User Name',
            selector: row => row.username,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Action',
            selector: (row) => <div className="d-flex justify-content-between align-items-center gap-2 ">
                <EvaluatorEditModal data={row} button="row" setToggle={setToggle} />
            </div>,
        },
    ];

    return (
        <>
            <section className='m-3 m-md-4 d-flex gap-4 flex-column'>
                {Loader ? <ProfileLoader /> : <ProfileHeader
                    profilePic={branchProfileDetails?.logo ? branchProfileDetails?.logo : "https://i.pinimg.com/564x/1f/a9/fc/1fa9fc02ab8e2acab10bc6ca19016039.jpg"}
                    name={`${branchProfileDetails?.customer?.name}`}
                    branchname={branchProfileDetails?.name}
                    email={branchProfileDetails?.email}
                    contact={branchProfileDetails?.phone_number}
                    address={branchProfileDetails?.street_address ? branchProfileDetails?.street_address : ''}
                    city={branchProfileDetails?.city ? branchProfileDetails?.city : ''}
                    state={branchProfileDetails?.state ? branchProfileDetails?.state : ''}
                    zip={branchProfileDetails?.zip ? branchProfileDetails?.zip : ''}
                    editModal={<BranchAdminEdit info={branchProfileDetails} route="brannchAdmin" />}
                    userName={branchProfileDetails?.branch_admin_user_id}
                />}

                <div className="bg-white p-3 p-md-4  mb-4 shadow-lg border-radius-3">
                    <div className="">
                        <h4 className='title-font-bold fs-4 text-base-color text-center text-md-start pb-2'>List of Nurses</h4>
                        {nurseList && nurseList.length > 0 ? <Table columns={columns} data={nurseList} /> : <EmptyTable />}
                    </div>
                </div>

                <div className="bg-white p-3 p-md-4  mb-4 shadow-lg border-radius-3">
                    <div className="">
                        <h4 className='title-font-bold fs-4 text-base-color text-center text-md-start pb-2'>List of Evaluators</h4>
                        {screenerList && screenerList.length > 0 ? <Table columns={columnss} data={screenerList} /> : <EmptyTable />}
                    </div>
                </div>

            </section>
        </>
    )
}

export default Dashboard