import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, Label } from "reactstrap";
import ErrorComponents from "../../Components/ErrorComponents/ErrorComponents";
import { useSelector, useDispatch } from "react-redux";
import UserPool from "../../constants/UserPool";
import AWS from "aws-sdk";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import "moment-timezone";
import {
  ApiEndPoint,
  server_name,
} from "../../constants/apiEndPoints/endPoints";
import { toast } from "react-hot-toast";
import Swal from "sweetalert";

const CreateDistrictAndNurse = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Loader, setLoader] = useState(false);

  const [excelData, setExcelData] = useState([]);
  const [uploadDisabled, setUploadDisabled] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [load, setLoad] = useState(false);
  const [alreadyExistData, setAlreadyExistData] = useState([]);
  const [excelUploaded, setExcelUploaded] = useState([]);
  const [defaultCustomerBranchcount, setDefaultCustomerBranchcount] =
    useState(undefined);
  let count = 0;
  let congnitoCount = 0;
  let duplicateBranchCount = 0;
  let duplicateNurseCount = 0;
  let backendDuplicateList = [];

  const CustomerAdminDetails = useSelector(
    (state) => state.login.loggedUserDetails
  );

  const createBranchNurse = async (data) => {
    var group = "nurse";
    setLoad(true);
    const attributesList = [
      new CognitoUserAttribute({
        Name: "email",
        Value: data.email,
      }),
      new CognitoUserAttribute({
        Name: "given_name",
        Value: data.firstname,
      }),
      new CognitoUserAttribute({
        Name: "name",
        Value: data.username,
      }),
    ];

    UserPool.signUp(
      data.username,
      data.password,
      attributesList,
      null,
      (err, response) => {
        if (err) {
          if (err.message === "User already exists") {
            congnitoCount = congnitoCount + 1;
          }
          if (err.message === "Invalid phone number format.") {
            setLoad(false);
          } else {
            alreadyExistData.push({ ...data, existing_nurse: true });
            if (excelData.length == alreadyExistData.length - 1) {
              setUploadDisabled(false);
            }
            if (congnitoCount == excelData.length) {
              setLoad(false);
              setExcelData(alreadyExistData);
              Swal({
                title: "Branch Details Saved",
                text:
                  `Unique nurses are ${Number(excelData?.length) - Number(congnitoCount)
                  }\n` + `Duplicate nurses are ${congnitoCount}\n`,
                icon: "https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g",
                confirmButtonText: "Close",
              });
              reset();
            }
          }
        }
        if (response) {
          var cognitoidentityserviceprovider =
            new AWS.CognitoIdentityServiceProvider({
              accessKeyId: "AKIA2F5EPKY5GTWZLBO5",
              secretAccessKey: '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',
              region: "us-east-1",
              AWS_CONFIG_FILE: 1,
            });
          var params = {
            GroupName: group,
            UserPoolId: "us-east-1_dHwclMn0X" /* required */,
            Username: data.username /* required */,
          };

          var params2 = {
            UserPoolId: "us-east-1_dHwclMn0X" /* required */,
            Username: data.username /* required */,
          };

          const params3 = {
            UserPoolId: "us-east-1_dHwclMn0X",
            Username: data.username,
            UserAttributes: [
              {
                Name: "email_verified",
                Value: "true",
              },
            ],
          };


          const CreateBranchNursePayload = {
            district: data.district,
            customerid: data.customerId,
            first_name: data.firstname,
            last_name: data.lastname,
            email: data.email,
            username: data.username,
            server: server_name,
          };
          cognitoidentityserviceprovider.adminConfirmSignUp(
            params2,
            function (err, data) {
              if (err) toast.error("An error occurred. Please try again.");
              CreateDistrictNurse(CreateBranchNursePayload);
            }
          );
          cognitoidentityserviceprovider.adminAddUserToGroup(
            params,
            function (err, data) {
              if (err) toast.error("An error occurred. Please try again.");
            }
          );

          cognitoidentityserviceprovider.adminUpdateUserAttributes(
            params3,
            (err, data) => {
              if (err) {
                console.error(err);
              } else {
                console.log("Email address verified successfully", data);
              }
            }
          );
        }
      }
    );
  };

  const CreateDistrictNurse = async (payload) => {
    try {
      await axios
        .post(ApiEndPoint.createDistrictNurse, payload)
        .then((response) => {
          count = count + 1;
          if (response?.data?.existingBranchList.length > 0) {
            duplicateBranchCount =
              duplicateBranchCount + response?.data?.existingBranchList.length;
          }
          if (response?.data?.existingNurseList.length > 0) {
            duplicateNurseCount =
              duplicateNurseCount + response?.data?.existingNurseList.length;
          }
          if (response?.data?.existingDetails?.length > 0) {
            var backendDuplicateRequest = excelUploaded.filter(
              (fildata) =>
                fildata.Customerid ===
                response?.data?.existingDetails[0].customerid
            )[0];
            const combinedObject = Object.assign(
              {},
              response?.data?.existingDetails[0],
              backendDuplicateRequest
            );

            backendDuplicateList.push(combinedObject);
          }
          if (count == excelData.length - congnitoCount) {
            setLoad(false);
            const backendMap = new Map(
              backendDuplicateList.map((obj) => [obj.District, obj])
            );

            const updatedAlreadyExisting = alreadyExistData.map((obj) => {
              const backendObj = backendMap.get(obj.District);
              if (backendObj && backendObj.existing_branch === true) {
                return { ...obj, existing_branch: true };
              }
              return obj;
            });
            setExcelData([...updatedAlreadyExisting, ...backendDuplicateList]);
            Swal({
              title: `District and Nurse Details Saved`,
              text:
                `District Summary:\n` +
                `Unique district are ${Number(excelData.length - congnitoCount) -
                Number(duplicateBranchCount)
                }\n` +
                `Duplicate district are ${congnitoCount + duplicateBranchCount
                }\n` +
                `Nurse Summary:\n` +
                `Unique nurses are ${Number(excelData.length - congnitoCount) -
                Number(duplicateNurseCount)
                }\n` +
                `Duplicate nurses are ${congnitoCount + duplicateNurseCount}`,
              icon: "success",
              confirmButtonText: "Close",
            });
            reset();
          }
        })
        .catch((err) => {
          toast.error("An error occurred. Please try again.");
        });
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };

  const Submit = async (data) => {
    data.username = data.nurse;
    data.password = `${data.firstname
      .toLowerCase()
      .replace(/ /g, "")}${data.lastname.toLowerCase().replace(/ /g, "")}`;
    data.customerId = CustomerAdminDetails?.id;
    excelData.push(data);
    excelUploaded.push(data);
    await createBranchNurse(data);
  };

  const CreateDefaultBranch = () => {
    const payload = {
      customer_id: CustomerAdminDetails.id,
      branch_name: `Default District`,
      district: `Default${CustomerAdminDetails.id}`,
    };
    axios
      .post(ApiEndPoint.createCustomerDefaultBranch, payload)
      .then((response) => {
        if (response.data.status === "success") {
          toast.success("Create Default Branch.");
        } else {
          toast.error("Something went wrong.");
        }
      });
  };

  const CheckDefaultBranch = async () => {
    try {
      const result = await axios
        .get(
          `${ApiEndPoint.checkCustomerDefaultBranch}${CustomerAdminDetails.id}/`
        )
        .catch((err) => toast.error(err.message));
      if (result.data.status === "success") {
        setDefaultCustomerBranchcount(result.data.default_branch_count);
        if (result.data.default_branch_count <= 0) {
          CreateDefaultBranch();
        }
      } else {
        toast.error("An error occurred. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };

  useEffect(() => {
    if (CustomerAdminDetails) {
      CheckDefaultBranch();
    }
  }, [CustomerAdminDetails]);

  return (
    <section className="px-1 px-md-2 px-lg-5 pt-2 ">
      <div className="px-2 py-3 p-md-4  shadow-lg mt-1 border-radius-3">
        <Form onSubmit={handleSubmit(Submit)} autoComplete="off">
          <div className="col-11 mx-auto mx-md-0 pb-3 pt-1 text-center text-md-start">
            <label className="title-font-600 mb-0 fs-5  text-uppercase border-bottom-color2-2">
              Create District And Nurse
            </label>
          </div>
          <div className="row">
            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                District
              </Label>

              <div className="mt-1  d-flex gap-3">
                <input
                  className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="text"
                  placeholder="District Name..."
                  {...register("district", { required: true })}
                />
              </div>
              {errors.district && (
                <ErrorComponents error={"District is required *"} />
              )}
            </div>
            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                User Name
              </Label>

              <div className="mt-1  d-flex gap-3">
                <input
                  className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="text"
                  placeholder="Nurse Name..."
                  {...register("nurse", { required: true })}
                />
              </div>
              {errors.nurse && (
                <ErrorComponents error={"Nurse is required *"} />
              )}
            </div>
            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                First Name
              </Label>

              <div className="mt-1  d-flex gap-3">
                <input
                  className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="text"
                  placeholder="First Name..."
                  {...register("firstname", { required: true })}
                />
              </div>
              {errors.firstname && (
                <ErrorComponents error={"First Name is required *"} />
              )}
            </div>
            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                Last Name
              </Label>

              <div className="mt-1  d-flex gap-3">
                <input
                  className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="text"
                  placeholder="Last Name..."
                  {...register("lastname", { required: true })}
                />
              </div>
              {errors.lastname && (
                <ErrorComponents error={"Last Name is required *"} />
              )}
            </div>
            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                Email
              </Label>

              <div className="mt-1  d-flex gap-3">
                <input
                  className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="email"
                  autoComplete="nope"
                  placeholder="Email..."
                  {...register("email", { required: true })}
                />
              </div>
              {errors.email && (
                <ErrorComponents error={"Email is required *"} />
              )}
            </div>

            <div className="d-flex align-items-center justify-content-center justify-content-md-end  gap-3 pt-3">
              <div className="d-flex align-items-center gap-3">
                {load ? (
                  <button
                    type="button"
                    className="submit-btn d-flex gap-2 align-items-center"
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      disabled
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Creating . . .
                  </button>
                ) : (
                  <button type="submit" className="submit-btn">
                    Submit
                  </button>
                )}
                <button
                  type="button"
                  className="close-btn"
                  onClick={() => navigate("/")}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </section>
  );
};

export default CreateDistrictAndNurse;
