const apiURL = process.env.REACT_APP_API_URL;
export const server_name = 'localhost'

export const ApiEndPoint = {

    createCustomer: `${apiURL}create-customer/`,
    customerDetails: `${apiURL}customer-detail/`,

    // --- Customer Admin End Points Starts Here  --- //
    customerBranchList: `${apiURL}customer-branch/`,
    customerBranchListCommunity: `${apiURL}customer-branch-list/`,
    customerEdit: `${apiURL}modify-customer/`,
    getCustomerLinks:`${apiURL}get-customer-links/`,
    customerLinks : `${apiURL}modify-customer-links/`,
    
    // --- Customer Admin End Points Ends Here  --- //

    // --- Branch Admin End Points Starts Here  --- //
    branchDetails: `${apiURL}branch-detail/`,
    branchNurserList: `${apiURL}nurse-list/`,
    createBranch: `${apiURL}create-brach/`,
    branchEdit: `${apiURL}modify-branch/`,
    checkCustomerDefaultBranch: `${apiURL}check-default-branch/`,
    createCustomerDefaultBranch: `${apiURL}create-default-branch/`,
    // --- Branch Admin End Points Ends Here  --- //

    // --- Nurse End Points Starts Here  --- //
    nurseDetails: `${apiURL}nurse-detail/`,
    createNurse: `${apiURL}create-nurse/`,
    uploadPatientDetails: `${apiURL}upload-details/`,
    createDistrictNurse: `${apiURL}create-district-nurse/`,
    createBulkScreener: `${apiURL}create-bulk-screener/`,
    uploadStudentDetailsSendMailNurse: `${apiURL}upload-details-send-excel/`,
    // --- Nurse End Points Ends Here  --- //

    // --- Patient Start Points Ends Here  --- //
    createPatient: `${apiURL}create-patient/`,
    filterPatient: `${apiURL}filter-patient/`,
    // --- Patient End Points Ends Here  --- //

    // --- Collection End Points Starts Here  --- //
    modifyNurse: `${apiURL}modify-nurse/`,
    schoolsByNurse: `${apiURL}schools/`,
    gradeList: `${apiURL}grade/`,
    classTeacherList: `${apiURL}teacher/`,
    studentsList: `${apiURL}students/`,
    collectionPatientDetails: `${apiURL}student-details/`,
    collection: `${apiURL}collection/`,
    sendReportMail: `${apiURL}send-report-email/`,
    // --- Collection End Points Ends Here  --- //

    // --- Evaluation Starts Points Starts Here  --- //
    patientEvaluation: `${apiURL}patient-evaluation/`,
    patientnEdit: `${apiURL}patient-update/`,
    patientEvaluationSubmit: `${apiURL}evaluation-submit/`,
    patientEvaluationResult: `${apiURL}patient-evaluation-result/`,
    sendQRCodeToStudents: `${apiURL}send-qr-code-student/`,
    sendQRCodeToStudentPDF: `${apiURL}send-qr-code-student-pdf/`,
    sendQRCodeToStudentASNurse: `${apiURL}send-qr-code-student-as-nurse/`,
    selfScreeningPatientEvaluation: `${apiURL}selfscreening-patient-evaluation/`,
    // ---- Evaluation End Points Starts Here  ---- //

    // --- Evaluators Starts Points Starts Here  --- //
    createScreener: `${apiURL}create-screener/`,
    branchScreenerList: `${apiURL}screener-list/`,
    screenerDetails: `${apiURL}screener-detail/`,
    modifyScreener: `${apiURL}modify-screener/`,
    screenerNotification: `${apiURL}evaluator-notification/`,
    screenerNotificationList: `${apiURL}evaluator-notification-list/`,
    createNotification: `${apiURL}create-notification/`,
    screenerNotificationRead: `${apiURL}evaluator-notification-read/`,
    // ---- Evaluators End Points Starts Here  ---- //

    // ---- Report End Points Starts Here ----- //
    communitySchoolReportList: `${apiURL}community-report-school-list/`,
    communityStudentReport: `${apiURL}community-report-student-list/`,
    communityStudentTreatmentList: `${apiURL}community-report-student-treatement-code/`,
    communityPatientTreatmentList: `${apiURL}community-report-patient-treatement-code/`,
    communityStudentReportMail: `${apiURL}community-report-student-treatement-code-mail/`,
    communityPatientReportMail: `${apiURL}community-report-patient-treatement-code-mail/`,
    communityEvaluatorList: `${apiURL}community-screener-list/`,
    communityNurseList: `${apiURL}community-nurse-list/`,
    communityPatientReport: `${apiURL}community-report-branch-patient-list/`,
    sendStudentReportPDFExcelToNurse: `${apiURL}send-student-report-to-nurse/`,
    communityStudentCountDistrict: `${apiURL}community-student-count-district/`,
    communityStudentCountSchool: `${apiURL}community-student-count-school/`,
    communityNurseStudentCountSchool: `${apiURL}community-nurse-student-count-school/`,
    communityNurseStudentCountSchoolEmail: `${apiURL}community-nurse-student-report/`,
    communityNurseStudentCountGradeEmail: `${apiURL}community-nurse-school-student-report/`,
    communityNurseSchoolReportStudentList : `${apiURL}community-nurse-school-report-student-list/`,
    // ---- Report End Points Ends Here ----- //


    //--- Sleep Apnea End Points Starts Here ---- //
    sleepApneaScreeningQuestions:`${apiURL}sleep-apnea-screening-questions`,
    sleepApneaScreeningDocCollection:`${apiURL}sleep-apnea-screening-doc-collection`,
    sleepApneaScreeningPatientQNA:`${apiURL}sleep-apnea-screening-patient-qna/`,
    sleepApneaScreeningQuestions:`${apiURL}sleep-apnea-screening-questions`,
    sleepApneaScreeningPatientImages:`${apiURL}sleep-apnea-screening-patient-images/`,
    sleepApneaScreeningEvaluationData:`${apiURL}evaluate-sleep-apnea-image`,
    sleepApneaPatientEvaluation: `${apiURL}patient-evaluation-sleepapnea/`,
    sleepApneaImageEvaluationResult: `${apiURL}sleep-apnea-image-evaluation-result/`,
    sleepApneaCombinedQNAImagesResult: `${apiURL}combined-qna-images-result/`,
    sleepApneaSendReportMail: `${apiURL}send-sleepapnea-report-mail/`,
    sleepApneaClinicAssessmentComments: `${apiURL}sleepapnea-clininc-assesment-code-get/`,
    sleepApneaClinincAddComments : `${apiURL}sleepapnea-clininc-assesment-code-post`,
    //--- Sleep Apnea End Points Ends Here ---- //


    

    //--- E2E Automation Feature Starts Here ---- //
    schoolScheduleAppointment:`${apiURL}school-schedule-appointment`,
    getAllAppointment:`${apiURL}get-all-appointment/`,
    getAllCustomerAppointment:`${apiURL}get-all-customer-appointment/`,
    getAllNurseAppointment:`${apiURL}get-all-nurse-appointment/`,
    encryptedNurseDetails:`${apiURL}nurse-detail/`,
    

     //--- E2E Automation Feature Ends Here ---- //





};