import React from 'react'

const HeaderTitle = ({ title, create }) => {
    return (
        <div className="d-flex justify-content-center align-items-center justify-content-md-start align-items-md-start">
            <h3 className='title-font-700 text-base-black fs-6 img_border header-title'>{create && "Create"} <span className='text-base-color2'>{title}</span></h3>
        </div>
    )
}

export default HeaderTitle