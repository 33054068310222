import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import ProfileHeader from "../../Components/ProfileHeader/ProfileHeader";
import ProfileLoader from "../../Components/ProfileHeader/ProfileLoader";
import NurseEdit from "../../Components/Modal/NurseModals/NurseEdit";
import { ApiEndPoint } from "../../constants/apiEndPoints/endPoints";
import axios from "axios";
import { NavLink } from "react-router-dom";
import {
  code1PDF,
  code2PDF,
  code3PDF,
  code4PDF,
  code_1_mail_body,
  code_2_mail_body,
  code_3_mail_body,
  code_4_mail_body,
} from "../../constants/apiEndPoints/ReportCode";
import { DocumentDownload } from "iconsax-react";
import toast from "react-hot-toast";
import { errorAlert } from "../../Components/Alerts/Alerts";

const Dashboard = () => {
  const CustomerAdminDetails = useSelector(
    (state) => state.login.loggedUserDetails
  );

  const [Loader, setLoader] = useState(false);
  const [load, setLoad] = useState(false);
  const scrollToGradeTable = useRef(null);
  const scrollToStudentListTable = useRef(null);
  const [schoolCount, setSchoolCount] = useState([]);
  const [schoolCountEmail, setSchoolCountEmail] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedCode, setSelectedCode] = useState();
  const [gradeCountEmail, setGradeCountEmail] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState();
  const [selectedGradeIndex, setSelectedGradeIndex] = useState();
  const [selectedGradeCode, setSelectedGradeCode] = useState();
  const [selectedGradeReport, setSelectedGradeReport] = useState([]);

  const GetNurseSchoolCountList = async () => {
    const url = `${ApiEndPoint.communityNurseStudentCountSchool}${CustomerAdminDetails?.id}`;
    axios.get(url).then((res) => {
      setSchoolCount(res.data?.countData);
    });
  };

  const GetNurseSchoolCountListEmail = async () => {
    setLoad(true);
    const url = `${ApiEndPoint.communityNurseStudentCountSchoolEmail}${CustomerAdminDetails?.id}`;
    axios.get(url).then((res) => {
      setSchoolCountEmail(res.data?.countData);
      setLoad(false);
    });
  };

  const GetStudents = (emails, school, index, code) => {
    setLoad(true);
    setSelectedSchool(school);
    setSelectedIndex(index);
    setSelectedCode(code);
    setGradeCountEmail([]);
    setSelectedGradeReport([]);
    if (emails.length <= 0) {
      setLoad(false);
      alert("No Email is attached to sent mail");
    } else {
      const GradestudentPayload = {
        nurseid: CustomerAdminDetails?.id,
        school_name: school,
      };
      try {
        axios
          .post(
            ApiEndPoint.communityNurseStudentCountGradeEmail,
            GradestudentPayload
          )
          .then((response) => {
            if (response.data.status == "success") {
              setGradeCountEmail(response.data.countData);
              setTimeout(() => {
                scrollToGradeTable.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
                setLoad(false);
              }, 500);
            } else {
              setGradeCountEmail([]);
              setLoad(false);
              toast.error(response.data.errorMessage);
            }
          })
          .catch((error) => toast.error(error));
      } catch (error) {
        setLoad(false);
        errorAlert("Something went wrong");
      }
    }
  };

  const GetNurseSchoolReportStudentList = async (
    school_name,
    grade,
    code,
    index
  ) => {
    setLoad(true);
    setSelectedGradeReport([]);
    setSelectedGrade(grade);
    setSelectedGradeIndex(index);
    setSelectedGradeCode(code);
    const url = `${ApiEndPoint.communityNurseSchoolReportStudentList}`;
    const payload = {
      nurseid: CustomerAdminDetails?.id,
      school_name: school_name,
      grade: grade,
      code: code,
    };
    axios.post(url, payload).then((res) => {
      setSelectedGradeReport(res?.data);
      setLoad(false);
      setTimeout(() => {
        scrollToStudentListTable.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100);
    });
  };

  const SendMail = (emails) => {
    setLoad(true);
    emails.push("juliah@teledentalsolutions.com");
    if (emails.length <= 0) {
      setLoad(false);
      alert("No Email is attached to sent mail");
    } else {
      const emailBody =
        selectedGradeCode == "CODE 1"
          ? code_1_mail_body
          : selectedGradeCode == "CODE 2"
          ? code_2_mail_body
          : selectedGradeCode == "CODE 3"
          ? code_3_mail_body
          : selectedGradeCode == "CODE 4"
          ? code_4_mail_body
          : "";
      const csvEmailAddresses = emails.join(",");
      const mailtoLink = `mailto:${
        CustomerAdminDetails.email
      }?bcc=${csvEmailAddresses}&subject=Dental%20Screening%20Results&body=${encodeURIComponent(
        emailBody
      )}`;
      window.location.href = mailtoLink;
      setTimeout(() => {
        setLoad(false);
      }, 5000);
    }
  };

  const getColumnTotal = (column) => {
    return schoolCountEmail.reduce((acc, item) => acc + item[column], 0);
  };
  const getColumnTotal1 = (column) => {
    return gradeCountEmail.reduce((acc, item) => acc + item[column], 0);
  };

  useEffect(() => {
    setLoader(true);
    if (CustomerAdminDetails) {
      GetNurseSchoolCountListEmail();
    }
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [CustomerAdminDetails]);

  return (
    <>
      <section className="m-3 m-md-4 d-flex gap-4 flex-column">
        {Loader ? (
          <>
            <ProfileLoader />
          </>
        ) : (
          <ProfileHeader
            profilePic={
              CustomerAdminDetails?.logo
                ? CustomerAdminDetails?.logo
                : "https://i.pinimg.com/564x/1f/a9/fc/1fa9fc02ab8e2acab10bc6ca19016039.jpg"
            }
            name={`${CustomerAdminDetails?.fname} ${CustomerAdminDetails?.lname}`}
            branchname={CustomerAdminDetails?.branch?.name}
            email={CustomerAdminDetails?.email}
            // contact={`${CustomerAdminDetails?.branch?.customer?.name}`}
            contact={`${CustomerAdminDetails?.branch?.customer?.phone_number}`}
            address={
              CustomerAdminDetails?.branch?.street_address
                ? CustomerAdminDetails?.branch?.street_address
                : ""
            }
            city={
              CustomerAdminDetails?.branch?.city
                ? CustomerAdminDetails?.branch?.city
                : ""
            }
            state={
              CustomerAdminDetails?.branch?.state
                ? CustomerAdminDetails?.branch?.state
                : ""
            }
            zip={
              CustomerAdminDetails?.branch?.zip
                ? CustomerAdminDetails?.branch?.zip
                : ""
            }
            editModal={<NurseEdit data={CustomerAdminDetails} />}
          />
        )}
        <div className="bg-white p-3 p-md-4 mb-4 shadow-lg rounded-4">
          <h1 className="title-font-700 fs-2 text-base-color text-center">
            <span className="custom-underline">
              Welcome to Virtual Dental Screening
            </span>
          </h1>

          <div className="d-flex flex-row-reverse mt-5 justify-content-lg-start justify-content-md-start justify-content-center ">
            <NavLink
              className="submit-btn py-2 btn-import text-nowrap mx-2"
              to="/create-student"
            >
              Create Students
            </NavLink>
            <NavLink
              className="submit-btn py-2 btn-import text-nowrap mx-2"
              to="/import"
            >
              Import Students
            </NavLink>
          </div>
        </div>
        {load ? (
          <div className="custom-loader-container">
            <div className="spinner-border text-info" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        ) : (
          <></>
        )}
        {schoolCount && schoolCount.length > 0 && (
          <div className="bg-white p-3 p-md-4 mb-4 shadow-lg border-radius-3">
            <div className="">
              <h4 className="title-font-bold fs-4 text-base-color text-center text-md-start pb-2">
                Screening Count by School
              </h4>
              <div className="table-responsive">
                <table className="table table-bordered table-light text-center">
                  <thead>
                    <tr className="border-0">
                      <th className=" text-white border-end-2 custom_table_color rounded-top-4 rounded-end-0"></th>
                      <th
                        className=" text-white border-end-2 custom_table_color"
                        colSpan={5}
                      >
                        Student Counts by Screening Status
                      </th>
                      <th
                        className=" text-white custom_table_color rounded-top-4 rounded-start-0"
                        colSpan={4}
                      >
                        Student Counts by Screening Results
                      </th>
                    </tr>
                  </thead>
                  <tbody className="">
                    <tr>
                      <th className="bg-white">School</th>
                      <th className="bg-white">Total</th>
                      <th className="bg-white">New</th>
                      <th className="bg-white">Screened</th>
                      <th className="bg-white">Completed</th>
                      <th className="bg-white">Reported</th>
                      <th className="bg-white">Code 1</th>
                      <th className="bg-white">Code 2</th>
                      <th className="bg-white">Code 3</th>
                      <th className="bg-white">Code 4</th>
                    </tr>
                    {schoolCount?.map((data, i) => (
                      <tr key={i}>
                        <td className="bg-white">{data.school}</td>
                        <td className="bg-white">{data.total}</td>
                        <td className="bg-white">{data.new}</td>
                        <td className="bg-white">{data.screened}</td>
                        <td className="bg-white">{data.completed}</td>
                        <td className="bg-white">{data.reported}</td>
                        <td className="bg-white">{data.code_1}</td>
                        <td className="bg-white">{data.code_2}</td>
                        <td className="bg-white">{data.code_3}</td>
                        <td className="bg-white">{data.code_4}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        <div className="bg-white p-3 p-md-4 mb-4 shadow-lg border-radius-3">
          <div className="">
            <h4 className="title-font-bold fs-4 text-base-color text-center text-md-start pb-2">
              Screening Code PDF's
            </h4>
          </div>
          <div className="row g-3 justify-content-lg-start justify-content-md-start justify-content-center">
            <div className="col-xl-2 col-lg-3 col-md-3 col-6">
              <a
                href={code1PDF}
                download
                className="fs-6 text-nowrap border shadow-lg d-flex align-items-center justify-content-center text-white rounded-3 px-lg-4 px-2 py-2 bg-base-color2 base-font-400"
                title={"Code PDF 1"}
                target="_blank"
              >
                <DocumentDownload className="text-white pe-2" size="28" /> Code
                PDF 1
              </a>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-3 col-6">
              <a
                href={code2PDF}
                download
                className="fs-6 text-nowrap border shadow-lg d-flex align-items-center justify-content-center text-white rounded-3 px-lg-4 px-2 py-2 bg-base-color2 base-font-400"
                title={"Code PDF 2"}
                target="_blank"
              >
                <DocumentDownload className="text-white pe-2" size="28" /> Code
                PDF 2
              </a>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-3 col-6">
              <a
                href={code3PDF}
                download
                className="fs-6 text-nowrap border shadow-lg d-flex align-items-center justify-content-center text-white rounded-3 px-lg-4 px-2 py-2 bg-base-color2 base-font-400"
                title={"Code PDF 3"}
                target="_blank"
              >
                <DocumentDownload className="text-white pe-2" size="28" /> Code
                PDF 3
              </a>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-3 col-6">
              <a
                href={code4PDF}
                download
                className="fs-6 text-nowrap border shadow-lg d-flex align-items-center justify-content-center text-white rounded-3 px-lg-4 px-2 py-2 bg-base-color2 base-font-400"
                title={"Code PDF 4"}
                target="_blank"
              >
                <DocumentDownload className="text-white pe-2" size="28" /> Code
                PDF 4
              </a>
            </div>
          </div>
        </div>
        <div className="bg-white p-3 p-md-4 mb-4 shadow-lg border-radius-3">
          <div className="">
            <h4 className="title-font-bold fs-4 text-base-color text-center text-md-start pb-2">
              Screening Count by School
            </h4>
            <div className="table-responsive">
              <table className="table table-bordered table-light text-center">
                <thead>
                  <tr className="border-0">
                    <th className=" text-white border-end-2 custom_table_color rounded-top-4 rounded-end-0"></th>
                    <th
                      className=" text-white border-end-2 custom_table_color"
                      colSpan={5}
                    >
                      Student Counts by Screening Status
                    </th>
                    <th
                      className=" text-white custom_table_color rounded-top-4 rounded-start-0"
                      colSpan={4}
                    >
                      Student Counts by Screening Results
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  <tr>
                    <th className="bg-white">School</th>
                    <th className="bg-white">Total</th>
                    <th className="bg-white">New</th>
                    <th className="bg-white">Screened</th>
                    <th className="bg-white">Completed</th>
                    <th className="bg-white">Reported</th>
                    <th className="bg-white">Code 1</th>
                    <th className="bg-white">Code 2</th>
                    <th className="bg-white">Code 3</th>
                    <th className="bg-white">Code 4</th>
                  </tr>
                  {schoolCountEmail?.map((data, i) => (
                    <tr key={i}>
                      <td className="bg-white">{data.school}</td>
                      <td className="bg-white">{data.total}</td>
                      <td className="bg-white">{data.new}</td>
                      <td className="bg-white">{data.screened}</td>
                      <td className="bg-white">{data.completed}</td>
                      <td className="bg-white">{data.reported}</td>
                      <td className="bg-white cursor">
                        <span
                          className={`${
                            selectedIndex == i &&
                            selectedSchool == data.school &&
                            selectedCode == "code_1"
                              ? "badge bg-info text-dark"
                              : "text-info"
                          } `}
                          onClick={() =>
                            GetStudents(
                              data.code_1_emails,
                              data.school,
                              i,
                              "code_1"
                            )
                          }
                        >
                          {data.code_1}
                        </span>
                      </td>
                      <td className="bg-white cursor">
                        <span
                          className={`${
                            selectedIndex == i &&
                            selectedSchool == data.school &&
                            selectedCode == "code_2"
                              ? "badge bg-info text-dark"
                              : "text-info"
                          } `}
                          onClick={() =>
                            GetStudents(
                              data.code_2_emails,
                              data.school,
                              i,
                              "code_2"
                            )
                          }
                        >
                          {data.code_2}
                        </span>
                      </td>
                      <td className="bg-white cursor">
                        <span
                          className={`${
                            selectedIndex == i &&
                            selectedSchool == data.school &&
                            selectedCode == "code_3"
                              ? "badge bg-info text-dark"
                              : "text-info"
                          } `}
                          onClick={() =>
                            GetStudents(
                              data.code_3_emails,
                              data.school,
                              i,
                              "code_3"
                            )
                          }
                        >
                          {data.code_3}
                        </span>
                      </td>
                      <td className="bg-white cursor">
                        <span
                          className={`${
                            selectedIndex == i &&
                            selectedSchool == data.school &&
                            selectedCode == "code_4"
                              ? "badge bg-info text-dark"
                              : "text-info"
                          } `}
                          onClick={() =>
                            GetStudents(
                              data.code_4_emails,
                              data.school,
                              i,
                              "code_4"
                            )
                          }
                        >
                          {data.code_4}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot className="text-center">
                  <tr>
                    <td
                      className="base-font-700 fs-6 text-black table-light"
                    >
                      Total
                    </td>
                    <td className="base-font-700 fs-6 text-black table-light">
                      {getColumnTotal("total")}
                    </td>
                    <td className="base-font-700 fs-6 text-black table-light">
                      {getColumnTotal("new")}
                    </td>
                    <td className="base-font-700 fs-6 text-black table-light">
                      {getColumnTotal("screened")}
                    </td>
                    <td className="base-font-700 fs-6 text-black table-light">
                      {getColumnTotal("completed")}
                    </td>
                    <td className="base-font-700 fs-6 text-black table-light">
                      {getColumnTotal("reported")}
                    </td>
                    <td className="base-font-700 fs-6 text-black table-light">
                      {getColumnTotal("code_1")}
                    </td>
                    <td className="base-font-700 fs-6 text-black table-light">
                      {getColumnTotal("code_2")}
                    </td>
                    <td className="base-font-700 fs-6 text-black table-light">
                      {getColumnTotal("code_3")}
                    </td>
                    <td className="base-font-700 fs-6 text-black table-light">
                      {getColumnTotal("code_4")}
                    </td>
                  </tr>
                </tfoot>
              </table>
              <span ref={scrollToGradeTable} className="mt-5"></span>
            </div>
          </div>
        </div>

        {gradeCountEmail && gradeCountEmail?.length > 0 && (
          <div className="bg-white p-3 p-md-4 mb-4 shadow-lg border-radius-3">
            <div className="">
              <h4 className="title-font-bold fs-4 text-base-color text-center text-md-start pb-2">
                Screening Count by Grade - {selectedSchool}
              </h4>
              <div className="table-responsive">
                <table className="table table-bordered table-light text-center">
                  <thead>
                    <tr className="border-0">
                      <th className=" text-white border-end-2 custom_table_color rounded-top-4 rounded-end-0"></th>
                      <th
                        className=" text-white border-end-2 custom_table_color"
                        colSpan={5}
                      >
                        Student Counts by Screening Status
                      </th>
                      <th
                        className=" text-white custom_table_color rounded-top-4 rounded-start-0"
                        colSpan={4}
                      >
                        Student Counts by Screening Results
                      </th>
                    </tr>
                  </thead>
                  <tbody className="">
                    <tr>
                      <th className="bg-white">Grade</th>
                      <th className="bg-white">Total</th>
                      <th className="bg-white">New</th>
                      <th className="bg-white">Screened</th>
                      <th className="bg-white">Completed</th>
                      <th className="bg-white">Reported</th>
                      <th className="bg-white">Code 1</th>
                      <th className="bg-white">Code 2</th>
                      <th className="bg-white">Code 3</th>
                      <th className="bg-white">Code 4</th>
                    </tr>
                    {gradeCountEmail?.map((data, i) => (
                      <tr key={i}>
                        <td className="bg-white">{data.grade}</td>
                        <td className="bg-white">{data.total}</td>
                        <td className="bg-white">{data.new}</td>
                        <td className="bg-white">{data.screened}</td>
                        <td className="bg-white">{data.completed}</td>
                        <td className="bg-white">{data.reported}</td>
                        <td className="bg-white cursor">
                          <span
                            className={`${
                              selectedGradeIndex == i &&
                              selectedGrade == data.grade &&
                              selectedGradeCode == "CODE 1"
                                ? "badge bg-info text-dark"
                                : "text-info"
                            } `}
                            onClick={() =>
                              GetNurseSchoolReportStudentList(
                                selectedSchool,
                                data.grade,
                                "CODE 1",
                                i
                              )
                            }
                          >
                            {data.code_1}
                          </span>
                        </td>
                        <td className="bg-white cursor">
                          <span
                            className={`${
                              selectedGradeIndex == i &&
                              selectedGrade == data.grade &&
                              selectedGradeCode == "CODE 2"
                                ? "badge bg-info text-dark"
                                : "text-info"
                            } `}
                            onClick={() =>
                              GetNurseSchoolReportStudentList(
                                selectedSchool,
                                data.grade,
                                "CODE 2",
                                i
                              )
                            }
                          >
                            {data.code_2}
                          </span>
                        </td>
                        <td className="bg-white cursor">
                          <span
                            className={`${
                              selectedGradeIndex == i &&
                              selectedGrade == data.grade &&
                              selectedGradeCode == "CODE 3"
                                ? "badge bg-info text-dark"
                                : "text-info"
                            } `}
                            onClick={() =>
                              GetNurseSchoolReportStudentList(
                                selectedSchool,
                                data.grade,
                                "CODE 3",
                                i
                              )
                            }
                          >
                            {data.code_3}
                          </span>
                        </td>
                        <td className="bg-white cursor">
                          <span
                            className={`${
                              selectedGradeIndex == i &&
                              selectedGrade == data.grade &&
                              selectedGradeCode == "CODE 4"
                                ? "badge bg-info text-dark"
                                : "text-info"
                            } `}
                            onClick={() =>
                              GetNurseSchoolReportStudentList(
                                selectedSchool,
                                data.grade,
                                "CODE 4",
                                i
                              )
                            }
                          >
                            {data.code_4}
                          </span>
                        </td>
                      </tr>
                    ))}
                    <span ref={scrollToStudentListTable}></span>
                  </tbody>
                  <tfoot className="text-center">
                  <tr>
                    <td
                      className="base-font-700 fs-6 text-black table-light"
                    >
                      Total
                    </td>
                    <td className="base-font-700 fs-6 text-black table-light">
                      {getColumnTotal1("total")}
                    </td>
                    <td className="base-font-700 fs-6 text-black table-light">
                      {getColumnTotal1("new")}
                    </td>
                    <td className="base-font-700 fs-6 text-black table-light">
                      {getColumnTotal1("screened")}
                    </td>
                    <td className="base-font-700 fs-6 text-black table-light">
                      {getColumnTotal1("completed")}
                    </td>
                    <td className="base-font-700 fs-6 text-black table-light">
                      {getColumnTotal1("reported")}
                    </td>
                    <td className="base-font-700 fs-6 text-black table-light">
                      {getColumnTotal("code_1")}
                    </td>
                    <td className="base-font-700 fs-6 text-black table-light">
                      {getColumnTotal("code_2")}
                    </td>
                    <td className="base-font-700 fs-6 text-black table-light">
                      {getColumnTotal("code_3")}
                    </td>
                    <td className="base-font-700 fs-6 text-black table-light">
                      {getColumnTotal("code_4")}
                    </td>
                  </tr>
                </tfoot>
                </table>
              </div>
            </div>
          </div>
        )}
        {selectedGradeReport &&
          selectedGradeReport?.patientlist?.length > 0 && (
            <div className="bg-white p-3 p-md-4 mb-4 shadow-lg border-radius-3">
              <div className="">
                <div className="d-flex justify-content-between my-2">
                  <h4 className="title-font-bold fs-4 text-base-color text-center text-md-start pb-2">
                    Students List: {selectedSchool} Grade: {selectedGrade}{" "}
                    {selectedGradeCode}
                  </h4>
                  <button
                    type="button"
                    onClick={() => SendMail(selectedGradeReport.emaillist)}
                    className="border shadow-lg d-flex align-items-center text-white rounded-3 px-3 py-1 bg-base-color2 base-font-400 my-1"
                  >
                    Send Mail
                  </button>
                </div>
                <div className="table-responsive">
                  <table className="table table-bordered table-light text-center">
                    <thead>
                      <tr className="border-0">
                        <th
                          colSpan={5}
                          className=" text-white border-end-2 custom_table_color rounded-top-4 rounded-end-0"
                        >
                          {" "}
                          Student Counts by Screening Status
                        </th>
                        <th
                          className=" text-white custom_table_color rounded-top-4 rounded-start-0"
                          colSpan={2}
                        >
                          Send Mail
                        </th>
                      </tr>
                    </thead>
                    <tbody className="">
                      <tr>
                        <th className="bg-white">#</th>
                        <th className="bg-white">School</th>
                        <th className="bg-white">Student Id</th>
                        <th className="bg-white">First Name</th>
                        <th className="bg-white">Grade</th>
                        <th className="bg-white">Email</th>
                        <th className="bg-white">Code</th>
                      </tr>
                      {selectedGradeReport.patientlist?.map((data, i) => (
                        <tr key={i}>
                          <td className="bg-white">{i + 1}</td>
                          <td className="bg-white">{data.hierarchy_level2}</td>
                          <td className="bg-white">{data.number}</td>
                          <td className="bg-white">{data.first_name}</td>
                          <td className="bg-white">{data.hierarchy_level4}</td>
                          <td className="bg-white">{data.alternate_email1}</td>
                          <td className="bg-white">
                            {data.treatment_recommendation_code}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
      </section>
    </>
  );
};

export default Dashboard;
