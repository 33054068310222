import axios from "axios";
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Label,
} from "reactstrap";

import { Edit, UserEdit } from "iconsax-react";
import user from "../../../constants/assests/images/user.png"
import ErrorComponents from "../../ErrorComponents/ErrorComponents";
import { preventNegativeValues } from "../../PreventValues/preventNegativeValues";
import {
  UploadProfileLink,
  uploadProfilePic,
} from "../../../constants/AWS/AWSConfiguration";
import { ApiEndPoint } from "../../../constants/apiEndPoints/endPoints";
import { errorAlert, successAlert } from "../../Alerts/Alerts";
import { useDispatch, useSelector } from "react-redux";
import { setCustomerBranch } from "../../../redux/Slice/CustomerSlice";
import { branchProfile } from "../../../redux/Slice/BranchSlice";
import { toast } from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import InputMask from 'react-input-mask';
import Select from "react-select";
import { states } from "../../../constants/apiEndPoints/ReportCode";
import { loggedUserDetails } from "../../../redux/Slice/LoginSlice";

function BranchAdminEdit({ info, route }) {
  const CustomerAdminDetails = useSelector(
    (state) => state.login.loggedUserDetails
  );
  const [modal, setModal] = useState(false);
  const [Loader, setLoader] = useState(false);
  const toggle = () => setModal(!modal);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const GetCustomerBranch = () => {
    try {
      const CustomerBranchListURL = `${ApiEndPoint.customerBranchList}${CustomerAdminDetails?.id}/`;
      axios.get(CustomerBranchListURL).then((response) => {
        dispatch(setCustomerBranch(response?.data?.branchList));
      }).catch((err) => {
        if (err) {
          toast.error("An error occurred. Please try again.")
        }
      })
    } catch (error) {
      if (error) {
        toast.error("An error occurred. Please try again.")
      }
    }
  };

  const CancelButton = () => {
    reset();
    toggle();
  };

  const EditSubmit = async (data) => {
    data.state = data.state.value;

    setLoader(true);
    const timestamp = Date.now();
    if (upload) {
      await uploadProfilePic(upload, timestamp);
      var url = `${UploadProfileLink}${timestamp}_${upload?.name}`;
    }

    const editBranchPayload = {
      name: data?.name,
      street_address: data?.street_address,
      email: data?.email,
      phone_number: data?.phone_number,
      city: data?.city,
      state: data?.state,
      zip: data?.zip,
      logo: url ? url : info?.logo,
      education_link: data?.education_link,
      appointment_link: data?.appointment_link,
    };

    try {
      await axios
        .put(
          `${ApiEndPoint.branchEdit}${info?.branch_admin_user_id}/`,
          editBranchPayload
        )
        .then((response) => {
          if (response.data.status === "success") {
            CancelButton();
            successAlert(response?.data?.message);
            GetCustomerBranch();
            dispatch(branchProfile(response?.data?.branchDetails));
            CustomerAdminDetails?.branch_admin_user_id ? dispatch(loggedUserDetails(response.data.branchDetails)) : ""
            setLoader(false);
          } else {
            errorAlert(response?.data?.message);
            setLoader(false);
          }
        })
        .catch((error) => {
          toast.error(error.message);
          setLoader(false);
        });
    } catch (error) {
      errorAlert(error.message);
      setLoader(false);
    }
  };

  // function addProtocolIfNeeded(url) {
  //   if (url && !/^https?:\/\//i.test(url)) {
  //     return `http://${url}`;
  //   }
  //   return url;
  // }

  const addProtocolIfNeeded = (url) => {
    if (url && !/^https?:\/\//i.test(url)) {
      return `http://${url}`;
    }
    return url;
  }

  useEffect(() => {
    reset({
      name: info?.name,
      state: states.filter((state) => state?.value?.toLowerCase() === info?.state?.toLowerCase())[0],
      street_address: info?.street_address,
      email: info?.email,
      phone_number: info?.phone_number,
      zip: Number(info?.zip),
      city: info?.city,
      education_link:
        info?.education_link,
      appointment_link:
        info?.appointment_link,
    });
    setFile(info?.logo)
  }, [modal]);
  const [file, setFile] = React.useState("");
  const [upload, setUpload] = React.useState("");

  const handleimagechange = (e) => {
    const filedata = e.target.files[0]
    setUpload(filedata)
  }
  const handlechange = (e) => {
    handleimagechange(e);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  return (
    <>
      {route == "brannchAdmin" ? (
        <button
          className="submit-btn base-font-600 fw-bold d-flex align-items-center shadow-lg "
          onClick={toggle}
        >
          <span className="base-font-600">Edit</span>
          <UserEdit size="30" variant="Bulk" className="ps-2" />
        </button>
      ) : (
        <button
          onClick={toggle}
          className="icon-bg-yellow border-0 p-1 rounded"
        >
          <Edit size="22" className="icon-color-yellow" />
        </button>
      )}

      <Modal
        className="border-0"
        isOpen={modal}
        toggle={toggle}
        size="lg"
        centered
      >
        <ModalHeader className="bg-base-color2 ">
          <span className="title-font-700 fs-5 text-base-white">
            Edit Branch
          </span>
        </ModalHeader>
        <Form onSubmit={handleSubmit(EditSubmit)} autoComplete="off">
          <ModalBody className="bg-background-color px-4">
            <div className="row">
              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
                <div className=" ">
                  <div className=" d-flex justify-content-between align-items-center ">
                    <div className="d-flex align-items-center justify-content-center gap-4">
                      <div className="basic-details-imgBox position-relative shadow-lg border">
                        {
                          file ? <img src={file} className='user-image-fit' /> : <img src={info?.logo ? info?.logo : user} className='user-image-fit' />
                        }

                        <div className="position-absolute bottom-0 end-0 bg-white rounded-circle shadow border" style={{ cursor: "pointer" }}>
                          <label htmlFor="file-input" className="text-base-color text-decoration-underline p-2 fs-6" role="button">
                            Edit
                          </label>
                          {/* <label htmlFor="file-input">
                            <UserEdit size="40" variant="Bulk" className='text-base-color p-2' />
                          </label> */}
                          <input
                            id='file-input'
                            type="file"
                            className='d-none'
                            {...register("image", { required: !file })}
                            onChange={(e) => handlechange(e)}
                            accept="image/jpeg,image/png,image/gif"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {errors.image && (
                  <ErrorComponents error={"Images is required *"} />
                )}
              </div>
              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
                <Label className="fs-6 title-font-600 mb-0 text-black">
                  Name*
                </Label>

                <div className="mt-1  d-flex gap-3">
                  <input
                    className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                    type="text"
                    placeholder="Name..."
                    {...register("name", { required: true })}
                  />
                </div>
                {errors.name && (
                  <ErrorComponents error={"Name is required *"} />
                )}
              </div>

              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
                <Label className="fs-6 title-font-600 mb-0 text-black">
                  Email
                </Label>

                <div className="mt-1  d-flex gap-3">
                  <input
                    className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                    type="email"
                    autoComplete="nope"
                    placeholder="Email..."
                    {...register("email", { required: true })}
                  />
                </div>
                {errors.email && (
                  <ErrorComponents error={"Email is required *"} />
                )}
              </div>

              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
                <Label className="fs-6 title-font-600 mb-0 text-black">
                  Phone Number
                </Label>

                <div className="p-0 mt-1 border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input">
                  <Controller
                    name="phone_number"
                    control={control}
                    rules={{
                      required: "Phone number is required",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <div
                        className="p-2 border-radius-10 shadow-none  w-100 fs-6 title-font-500 d-flex gap-2"
                      >
                        <span>+1</span>
                        <InputMask
                          mask="(999) 999-9999"
                          value={value}
                          onChange={(val) => onChange(val)}
                          type="tel"
                          placeholder=" (111)111-1111"
                          className="bg-transparent title-font-500 w-100 base-white-light border-0 shadow-none p-0"
                        />
                      </div>
                    )}
                  />
                </div>
                {errors?.phone_number && (
                  <ErrorComponents error={errors.phone_number?.message} />
                )}
              </div>

              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
                <Label className="fs-6 title-font-600 mb-0 text-black">
                  Address*
                </Label>
                <div className="mt-1  d-flex gap-3">
                  <input
                    className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                    type="text"
                    placeholder="Enter Address..."
                    {...register("street_address", {
                      required: "Address is required *",
                    })}
                  />
                </div>
                {errors.street_address && (
                  <ErrorComponents error={errors.street_address?.message} />
                )}
              </div>

              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
                <Label className="fs-6 title-font-600 mb-0 text-black">
                  City*
                </Label>
                <div className="mt-1  d-flex gap-3">
                  <input
                    className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                    type="text"
                    placeholder="Enter city..."
                    {...register("city", {
                      required: "city is required *",
                    })}
                  />
                </div>
                {errors.city && (
                  <ErrorComponents error={errors.city?.message} />
                )}
              </div>
              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
                <Label className="fs-6 title-font-600 mb-0 text-black">
                  State*
                </Label>

                <div className="mt-1  d-flex gap-3">
                  {/* <input
                    className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                    type="text"
                    placeholder="Enter State..."
                    {...register("state", {
                      required: "State is required *",
                    })}
                  /> */}
                  <Controller
                    control={control}
                    name="state"
                    autoComplete="nope"
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={states}
                        onChange={onChange}
                        value={value}
                        placeholder={"Select State "}
                        className="border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input"
                        ref={register("state", {
                          required: "State is required *",
                        })}
                      />
                    )}
                  />
                </div>
                {errors.state && (
                  <ErrorComponents error={errors.state?.message} />
                )}
              </div>
              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
                <Label className="fs-6 title-font-600 mb-0 text-black">
                  Zip code*
                </Label>

                <div className="mt-1  d-flex gap-3">
                  <input
                    className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                    type="number"
                    maxLength={6}
                    min={0}
                    onKeyDown={preventNegativeValues}
                    placeholder="Enter zipcode here..."
                    {...register("zip", { required: true })}
                  />
                </div>
                {errors.zip && (
                  <ErrorComponents error={"Zipcode is required *"} />
                )}
              </div>

              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
                <Label className="fs-6 title-font-600 mb-0 text-black">
                  Patient Education Content Link*
                </Label>
                <div className="mt-1  d-flex gap-3">
                  <input
                    className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                    type="text"
                    placeholder="Enter Patient Education Content Link..."
                    {...register("education_link", {
                      required: "Educational link is required *",
                      // pattern: {
                      //   value: /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
                      //   message: "Invalid link format. eg 'www.example.com'",
                      // },
                    })}
                  />
                </div>
                {errors.education_link && (
                  <ErrorComponents error={errors.education_link?.message} />
                )}
                {info?.education_link && (
                  <div className="py-2 mx-1 mt-1 rounded-2 w-100">
                    Click <a
                      // href={info?.education_link}
                      href={addProtocolIfNeeded(info?.education_link)}
                      target="_blank"
                      className="link-opacity-75"
                      rel="Educational Link"
                    // rel="noopener noreferrer"
                    >
                      here
                    </a>
                    &nbsp;to open Patient Education Content Link
                  </div>
                )}
              </div>
              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
                <Label className="fs-6 title-font-600 mb-0 text-black">
                  Patient Appointment Scheduling Link*
                </Label>
                <div className="mt-1  d-flex gap-3">
                  <input
                    className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                    type="text"
                    placeholder="Enter Patient Appointment Scheduling Link..."
                    {...register("appointment_link", {
                      required: "Appointment link is required *",
                      // pattern: {
                      //   value: /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
                      //   message: "Invalid link format. eg 'www.example.com'",
                      // },
                    })}
                  />
                </div>
                {errors.appointment_link && (
                  <ErrorComponents error={errors.appointment_link?.message} />
                )}
                {info?.appointment_link && (
                  <div className="py-2 mx-1 mt-1 rounded-2 w-100 fs-6">
                    Click <a
                      // href={info?.appointment_link}
                      href={addProtocolIfNeeded(info?.appointment_link)}
                      target="_blank"
                      className="link-underline-primary link-opacity-75"
                      rel="Educational Link"
                    >
                      here
                    </a>
                    &nbsp;to open Patient Appointment Scheduling Link
                  </div>
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="border-0 ">
            <div className="d-flex justify-content-end align-items-center">
              <div className="d-flex align-items-center gap-3">
                {Loader ? (
                  <button
                    type="button"
                    className="submit-btn d-flex gap-2 align-items-center"
                  >
                    <span
                      className="spinner-border spinner-border-sm" disabled
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Editing . . .
                  </button>
                ) : (
                  <button type="submit" className="submit-btn">
                    Submit
                  </button>
                )}
                <button
                  type="button"
                  className="close-btn"
                  onClick={CancelButton}
                >
                  Cancel
                </button>
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default BranchAdminEdit;
