import axios from "axios";
import { Refresh } from "iconsax-react";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiEndPoint } from "../../constants/apiEndPoints/endPoints";
import { toast } from "react-hot-toast";
import {
  selectPatient,
  sleepApneaSelectPatient,
} from "../../redux/Slice/CollectionApp";
import {
  low_risk_pdf,
  moderate_risk_pdf,
  high_risk_pdf,
} from "../../constants/apiEndPoints/ReportCode";

const EvaluationResultSleepApnea = () => {
  const dispatch = useDispatch();
  const selectedPatient = useSelector(
    (state) => state.collection.selectedPatient
  );
  const sleepApneaSelectedPatient = useSelector(
    (state) => state.collection.sleepApneaSelectedPatient
  );

  const [assessmentScore, setAssessmentScore] = useState();

  const [patientEvaluationResult, setPatientEvaluationResult] =
    useState(selectedPatient);
  const [rotate, setrotate] = React.useState(false);

  const handleEvaluationResult = () => {
    setrotate(true);
    axios
      .get(
        `${ApiEndPoint.sleepApneaCombinedQNAImagesResult}${selectedPatient?.id}`
      )
      .then((response) => {
        if (response.data.status === "success") {
          setPatientEvaluationResult(response.data);
          dispatch(sleepApneaSelectPatient(response.data));
          setrotate(false);
          toast.success("Evaluation results fetched");
        } else {
          toast.error("Error. fetching evaluation results");
          setrotate(false);
        }
      })
      .catch((e) => {
        if (e) {
          toast.error("An error occurred. Please try again.");
        }
      });
  };

  useEffect(() => {
    if (sleepApneaSelectedPatient) {
      setPatientEvaluationResult(sleepApneaSelectedPatient);
      let count = 0;
      for (const key in sleepApneaSelectedPatient?.patient_image_result) {
        if (
          sleepApneaSelectedPatient?.patient_image_result.hasOwnProperty(key) &&
          sleepApneaSelectedPatient?.patient_image_result[key] === "yes"
        ) {
          count++;
        }
      }
      setAssessmentScore(count);
    }
  }, [sleepApneaSelectedPatient]);

  return (
    <div className="col mx-auto mx-md-0 col-md-6 col-lg-4 col-xl-12 ">
      <div className="d-flex gap-1 align-items-center">
        <h6 className="title-font-700 text-black fs-6">Evaluation Results</h6>
        <span
          className={
            sleepApneaSelectedPatient?.patient_images?.eval_status === "new"
              ? "disabledbutton"
              : ""
          }
        >
          <button
            type="button"
            className="p-1 px-2 rounded-1 bg-base-color2 mb-2 title-font-600  border-0 text-white "
            onClick={handleEvaluationResult}
          >
            Refresh{" "}
            <Refresh
              size="16"
              className={rotate ? "rotateBox text-white" : "text-white"}
            />
          </button>
        </span>
      </div>

      {patientEvaluationResult?.patient_images?.eval_status === "new" ||
      patientEvaluationResult?.patient_images?.eval_status === "screened" ||
      !patientEvaluationResult ? (
        <div className="shadow border rounded-2 bg-white p-3">
          <div className="d-flex flex-column gap-1">
            <div className="d-flex align-items-center gap-1">
              <label className="base-font-600 fs-6 text-black text-capitalize">
                Evaluation Status{" "}
              </label>
              <div className="">
                <h6
                  className={`${
                    patientEvaluationResult?.patient_images?.eval_status ==
                    "completed"
                      ? "base-green-dark text-white"
                      : patientEvaluationResult?.patient_images?.eval_status ==
                        "incomplete"
                      ? "base-yellow text-white"
                      : patientEvaluationResult?.patient_images?.eval_status ==
                          "screening" ||
                        patientEvaluationResult?.patient_images?.eval_status ==
                          "screened"
                      ? "bg-base-color text-white"
                      : patientEvaluationResult?.patient_images?.eval_status ==
                        "reported"
                      ? "bg-base-color2 text-white"
                      : patientEvaluationResult?.patient_images?.eval_status ==
                        "optout"
                      ? "base-purple text-white"
                      : patientEvaluationResult?.patient_images?.eval_status ==
                          "new" && "base-pink text-white"
                  }  badge py-1 fs-6 base-font-500 rounded-1 text-capitalize`}
                  style={{ letterSpacing: ".5px" }}
                >
                  {patientEvaluationResult?.patient_images?.eval_status}
                </h6>
              </div>
            </div>

            <div className="d-flex flex-row">
              <small className="base-font-600 fs-6 text-black text-capitalize ">
                Assessment Score
              </small>
              <div className="d-flex align-items-center gap-1 px-2">
                <p
                  className="base-font-400 mb-0"
                  style={{ color: "rgba(140,140,140)" }}
                ></p>
              </div>
            </div>

            <div className="d-flex align-items-center gap-1 flex-wrap">
              <label className="base-font-600 fs-6 text-black text-capitalize">
                Assessment Code
              </label>
              <div className="">
                <h6
                  className={`${
                    !patientEvaluationResult?.patient_images
                      ?.treatment_recommendation_code
                      ? "bg-secondary text-white "
                      : patientEvaluationResult?.patient_images
                          ?.treatment_recommendation_code == "Low Risk"
                      ? "base-green-dark text-white "
                      : // : patientEvaluationResult?.patient_images?.treatment_recommendation_code == "CODE 2"
                      //   ? "base-yellow text-white"
                      patientEvaluationResult?.patient_images
                          ?.treatment_recommendation_code == "Moderate Risk"
                      ? "base-orange text-white"
                      : patientEvaluationResult?.patient_images
                          ?.treatment_recommendation_code == "High Risk" &&
                        "base-red text-white"
                  }  badge py-1 mb-0 fs-6 base-font-500 rounded-1 text-capitalize`}
                  style={{ letterSpacing: ".5px" }}
                ></h6>
              </div>
            </div>

            <div className="d-flex flex-row">
              <small className="base-font-600 fs-6 text-black text-capitalize ">
                Comments
              </small>
              <div className="d-flex align-items-center gap-1 px-2">
                <p
                  className="base-font-400 mb-0 "
                  style={{ color: "rgba(140,140,140)" }}
                ></p>
              </div>
            </div>

            <div className="d-flex flex-row">
              {/* <small className="base-font-600 fs-6 text-black text-capitalize ">
                PDF Url
              </small> */}
              <div className="d-flex align-items-center gap-1 px-2">
                <p
                  className="base-font-400 mb-0"
                  style={{ color: "rgba(140,140,140)" }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="shadow border rounded-2 bg-white p-3">
          <div className="d-flex flex-column gap-1">
            <div className="d-flex align-items-center gap-1">
              <label className="base-font-600 fs-6 text-black text-capitalize">
                Evaluation Status:{" "}
              </label>
              <div className="px-2">
                <h6
                  className={`${
                    patientEvaluationResult?.patient_images?.eval_status ==
                    "completed"
                      ? "base-green-dark text-white"
                      : patientEvaluationResult?.patient_images?.eval_status ==
                        "incomplete"
                      ? "base-yellow text-white"
                      : patientEvaluationResult?.patient_images?.eval_status ==
                          "screening" ||
                        patientEvaluationResult?.patient_images?.eval_status ==
                          "screened"
                      ? "bg-base-color text-white"
                      : patientEvaluationResult?.patient_images?.eval_status ==
                        "reported"
                      ? "bg-base-color2 text-white"
                      : patientEvaluationResult?.patient_images?.eval_status ==
                        "optout"
                      ? "base-purple text-white"
                      : // : patientEvaluationResult?.patient_images?.eval_status ==
                        //     "new" &&
                        "base-pink text-white"
                  }  badge py-1 fs-6 base-font-500 rounded-1 text-capitalize`}
                  style={{ letterSpacing: ".5px" }}
                >
                  {patientEvaluationResult?.patient_images?.eval_status
                    ? patientEvaluationResult?.patient_images?.eval_status
                    : "new"}
                </h6>
              </div>
            </div>

            <div className="d-flex flex-row">
              <label className="base-font-600 fs-6 text-black text-capitalize ">
                Assessment Score:
              </label>
              <div className="d-flex align-items-center gap-1 px-4">
                <small
                  className="base-font-600 mb-0 fs-6"
                  // style={{ color: "rgba(140,140,140)" }}
                >
                  {" "}
                  {assessmentScore ? assessmentScore : ""}
                </small>
              </div>
            </div>

            <div className="d-flex align-items-center gap-1 flex-wrap">
              <label className="base-font-600 fs-6 text-black text-capitalize">
                Assessment Code:
              </label>
              <div className="px-2">
                <h6
                  className={`${
                    !patientEvaluationResult?.patient_images
                      ?.treatment_recommendation_code
                      ? "bg-secondary text-white "
                      : patientEvaluationResult?.patient_images
                          ?.treatment_recommendation_code == "Low Risk"
                      ? "text-success"
                      : patientEvaluationResult?.patient_images
                          ?.treatment_recommendation_code == "Moderate Risk"
                      ? "text-warning"
                      : patientEvaluationResult?.patient_images
                          ?.treatment_recommendation_code == "High Risk" &&
                        "text-danger"
                  }  badge py-1 mb-0 fs-6 base-font-500 rounded-1 text-capitalize`}
                  style={{ letterSpacing: ".5px" }}
                >
                  {patientEvaluationResult?.patient_images
                    ?.treatment_recommendation_code
                    ? patientEvaluationResult?.patient_images
                        ?.treatment_recommendation_code
                    : ""}
                </h6>
              </div>
            </div>

            <div className="d-flex flex-row">
              <label className="base-font-600 fs-6 text-black text-capitalize ">
                Comments:
              </label>
              <div className="d-flex align-items-center gap-1 px-3">
                <small
                  className="base-font-400 mb-0 text-uppercase"
                  style={{ color: "rgba(140,140,140)" }}
                >
                  {" "}
                  {patientEvaluationResult?.patient_image_result?.comments
                    ? patientEvaluationResult?.patient_image_result?.comments
                    : ""}
                </small>
              </div>
            </div>

            <div className="d-flex flex-row">
              {/* <small className="base-font-600 fs-6 text-black text-capitalize ">
                PDF Url:
              </small> */}
              <div className="d-flex align-items-center gap-1">
                <p
                  className="base-font-400 mb-0"
                  style={{ color: "rgba(140,140,140)" }}
                ></p>
                <a
                  href={
                    patientEvaluationResult?.patient_images
                      ?.treatment_recommendation_code === "Low Risk"
                      ? `${low_risk_pdf}`
                      : patientEvaluationResult?.patient_images
                          ?.treatment_recommendation_code === "Moderate Risk"
                      ? `${moderate_risk_pdf}`
                      : patientEvaluationResult?.patient_images
                          ?.treatment_recommendation_code === "High Risk"
                      ? `${high_risk_pdf}`
                      : ""
                  }
                  target="_blank"
                >{patientEvaluationResult?.patient_images
                      ?.treatment_recommendation_code ? 'Please click here for PDF report' :'' }
                  
                </a>
              </div>
            </div>

            <div>
              {/* <iframe src={low_risk_pdf} width="100%" height="500px" frameborder="0"></iframe> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EvaluationResultSleepApnea;
