import React from 'react'
import Table from './Table/Table';
const EmptyTable = () => {
  const columns = [
    {
      name: '',
      selector: row => row.status,
      sortable: true,
      wrap: true,
      style: {
        fontSize: "2.3rem",
      }
    },
    {
      name: '',
      selector: row => row.title,
      sortable: true,
      wrap: true,
      style: {
        fontSize: "2.3rem",
      }
    },
    {
      name: '',
      selector: row => row.galaNo,
      sortable: true,
      wrap: true,
      style: {
        fontSize: "2.3rem",
      }
    },
    {
      name: '',
      selector: row => row.title,
      sortable: true,
      wrap: true,
      style: {
        fontSize: "2.3rem",
      }
    },
    {
      name: '',
      selector: row => row.title,
      sortable: true,
      wrap: true,
      style: {
        fontSize: "2.3rem",
      }
    },

  ];

  const data = [
    {
      id: 1,
      galaNo: 'Data Not Found !',
    },

  ]
  return (
    <>
      <Table columns={columns} data={data} />
    </>
  )
}

export default EmptyTable