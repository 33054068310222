import React, { useState, useEffect, useRef } from 'react'
import HeaderTitle from '../../Components/ProfileHeader/HeaderTitle';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import Table from '../../Components/Table/Table/Table';
import { toast } from 'react-hot-toast';
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import UserPool from "../../constants/UserPool";
import { errorAlert, successAlert } from '../../Components/Alerts/Alerts';
import { ApiEndPoint } from '../../constants/apiEndPoints/endPoints';
import axios from 'axios';
import { DocumentDownload } from 'iconsax-react';
import { ImportDistrictExcelDemo } from '../../constants/apiEndPoints/ReportCode';
import Swal from "sweetalert";

const ImportDistrict = () => {
    const fileInputRef = useRef(null);
    const [excelData, setExcelData] = useState([]);
    const [uploadDisabled, setUploadDisabled] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [load, setLoad] = useState(false);
    const [alreadyExistData, setAlreadyExistData] = useState([]);
    const [excelUploaded, setExcelUploaded] = useState([]);
    const [defaultCustomerBranchcount, setDefaultCustomerBranchcount] = useState(undefined);
    let count = 0;
    let congnitoCount = 0;
    let duplicateBranchCount = 0;
    let duplicateNurseCount = 0;
    let backendDuplicateList = [];


    const CustomerAdminDetails = useSelector((state) => state.login.loggedUserDetails);

    const createBranchNurse = async (data) => {

        var group = "nurse";
        setLoad(true);
        const attributesList = [
            new CognitoUserAttribute({
                Name: "email",
                Value: data.Email,
            }),
            new CognitoUserAttribute({
                Name: "given_name",
                Value: data.FirstName,
            }),
            new CognitoUserAttribute({
                Name: "name",
                Value: data.Username,
            })
        ];

        UserPool.signUp(
            data.Username,
            data.Password,
            attributesList,
            null,
            (err, response) => {
                if (err) {
                    if (err.message === "User already exists") {
                        congnitoCount = congnitoCount + 1;
                    }
                    if (err.message === "Invalid phone number format.") {
                        setLoad(false);
                    } else {
                        alreadyExistData.push({ ...data, existing_nurse: true });
                        if (excelData.length == alreadyExistData.length - 1) {
                            setUploadDisabled(false);
                        };
                        if (congnitoCount == excelData.length) {
                            setLoad(false);
                            setExcelData(alreadyExistData);
                            Swal({
                                title: "Branch Details Saved",
                                text:
                                    `Unique nurses are ${Number(excelData?.length) - Number(congnitoCount)}\n` +
                                    `Duplicate nurses are ${congnitoCount}\n`,
                                icon: "https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g",
                                confirmButtonText: "Close"
                            });
                            if (fileInputRef.current) {
                                fileInputRef.current.value = '';
                            }
                        }
                    }
                }
                if (response) {
                    var cognitoidentityserviceprovider =
                        new AWS.CognitoIdentityServiceProvider({
                            accessKeyId: "AKIA2F5EPKY5GTWZLBO5",
                            secretAccessKey: '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',
                            region: "us-east-1",
                            AWS_CONFIG_FILE: 1,
                        });
                    var params = {
                        GroupName: group,
                        UserPoolId: "us-east-1_dHwclMn0X" /* required */,
                        Username: data.Username /* required */,
                    };

                    var params2 = {
                        UserPoolId: "us-east-1_dHwclMn0X" /* required */,
                        Username: data.Username /* required */,
                    };

                    const CreateBranchNursePayload = {
                        district: data.District,
                        customerid: data.Customerid,
                        first_name: data.FirstName,
                        last_name: data.LastName,
                        email: data.Email,
                        username: data.Username
                    };
                    cognitoidentityserviceprovider.adminConfirmSignUp(params2, function (err, data) {
                        if (err) toast.error("An error occurred. Please try again.");
                        CreateDistrictNurse(CreateBranchNursePayload);
                    });
                    cognitoidentityserviceprovider.adminAddUserToGroup(
                        params,
                        function (err, data) {
                            if (err) toast.error("An error occurred. Please try again.");
                        }
                    );
                }
            }
        )
    };

    const CreateDistrictNurse = async (payload) => {
        try {
            await axios.post(ApiEndPoint.createDistrictNurse, payload).then((response) => {
                count = count + 1;
                if (response?.data?.existingBranchList.length > 0) {
                    duplicateBranchCount = duplicateBranchCount + response?.data?.existingBranchList.length
                }
                if (response?.data?.existingNurseList.length > 0) {
                    duplicateNurseCount = duplicateNurseCount + response?.data?.existingNurseList.length
                }
                if (response?.data?.existingDetails?.length > 0) {
                    var backendDuplicateRequest = excelUploaded.filter((fildata) => fildata.Customerid === response?.data?.existingDetails[0].customerid)[0];
                    const combinedObject = Object.assign({}, response?.data?.existingDetails[0], backendDuplicateRequest);

                    backendDuplicateList.push(combinedObject)
                }
                if (count == (excelData.length - congnitoCount)) {
                    setLoad(false);
                    const backendMap = new Map(backendDuplicateList.map(obj => [obj.District, obj]));

                    const updatedAlreadyExisting = alreadyExistData.map(obj => {
                        const backendObj = backendMap.get(obj.District);
                        if (backendObj && backendObj.existing_branch === true) {
                            return { ...obj, existing_branch: true };
                        }
                        return obj;
                    });
                    setExcelData([...updatedAlreadyExisting, ...backendDuplicateList]);
                    Swal({
                        title: `District and Nurse Details Saved`,
                        text: `District Summary:\n` +
                            `Unique district are ${Number((excelData.length - congnitoCount)) - Number(duplicateBranchCount)}\n` +
                            `Duplicate district are ${congnitoCount + duplicateBranchCount}\n` +
                            `Nurse Summary:\n` +
                            `Unique nurses are ${Number((excelData.length - congnitoCount)) - Number(duplicateNurseCount)}\n` +
                            `Duplicate nurses are ${congnitoCount + duplicateNurseCount}`,
                        icon: "success",
                        confirmButtonText: "Close"
                    });
                    if (fileInputRef.current) {
                        fileInputRef.current.value = '';
                    }
                }
            }).catch((err) => {
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                if (err) {
                    toast.error("An error occurred. Please try again.")
                }
            })
        } catch (error) {
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
            if (error) {
                toast.error("An error occurred. Please try again.")
            }
        }
    };

    const handleFileChange = (e) => {
        setExcelData([]);
        setAlreadyExistData([]);
        if (e.target.files[0]) {
            const file = e.target.files[0];
            setUploadDisabled(true)
            const reader = new FileReader();
            reader.onload = (event) => {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data);
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                const headersss = jsonData.shift();

                const dataArray = jsonData.map(row => {
                    const obj = {};
                    headersss.forEach((header, index) => {
                        obj[header] = row[index];
                        obj["Customerid"] = CustomerAdminDetails?.id;
                    });
                    return obj;
                });
                const keysToCheck = ['District', 'Nurse', 'FirstName', 'LastName', 'Email'];
                const newArray = dataArray.filter(obj => {
                    const emptyKeys = keysToCheck.filter(key => obj.hasOwnProperty(key) && (obj[key] === null || obj[key] === undefined));
                    if (emptyKeys.length === 0) {
                        return true;
                    } else {
                        return false;
                    }
                });
                setExcelData(newArray);
                setExcelUploaded(newArray);
            };
            reader.readAsArrayBuffer(file);
        }
    };

    const uploadExcel = async () => {
        setIsUploading(true);
        for (const obj of excelData) {
            obj.Username = `${obj.Nurse}`;
            obj.Password = `${obj.FirstName?.toLowerCase().replace(/ /g, "")}${obj.LastName?.toLowerCase().replace(/ /g, "")}`;
            await createBranchNurse(obj);
        };
    };

    const columns = [
        {
            name: 'District',
            selector: row => <span className={row.existing_branch && "text-danger"}>{row.District}</span>,
            sortable: true,
            wrap: true,

        },
        {
            name: 'User Name',
            selector: row => <span className={row.existing_nurse && "text-danger"}>{row.Nurse}</span>,
            sortable: true,
            wrap: true,

        },
        {
            name: 'First Name',
            selector: row => row.FirstName,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Last Name',
            selector: row => row.LastName,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Email',
            selector: row => row.Email,
            sortable: true,
            wrap: true,
        }
    ];

    const CreateDefaultBranch = () => {
        const payload = {
            customer_id: CustomerAdminDetails.id,
            branch_name: `Default District`,
            district: `Default${CustomerAdminDetails.id}`
        }
        axios.post(ApiEndPoint.createCustomerDefaultBranch, payload).then((response) => {
            if (response.data.status === "success") {
                toast.success("Create Default Branch.");
            } else {
                toast.error("Something went wrong.")
            }
        })
    }

    const CheckDefaultBranch = async () => {
        try {
            const result = await axios.get(`${ApiEndPoint.checkCustomerDefaultBranch}${CustomerAdminDetails.id}/`).catch((err) => toast.error(err.message));
            if (result.data.status === "success") {
                setDefaultCustomerBranchcount(result.data.default_branch_count);
                if (result.data.default_branch_count <= 0) {
                    CreateDefaultBranch();
                }
            } else {
                toast.error("An error occurred. Please try again.")
            }
        } catch (error) {
            toast.error("An error occurred. Please try again.")
        }
    }

    useEffect(() => {
        if (CustomerAdminDetails) {
            CheckDefaultBranch();
        }
    }, [CustomerAdminDetails]);

    return (
        <section className='m-3 m-md-4 d-flex gap-4 flex-column'>
            <div className="d-flex justify-content-center flex-wrap flex-md-nowrap align-items-center gap-3 gap-md-0">
                <div className="bg-white border shadow-lg d-flex align-items-center p-2 rounded-3">
                    <input type="file" ref={fileInputRef} className='form-control border-0 shadow-none' onChange={handleFileChange} accept=".xlsx,.csv" />
                    {load ? <button type="button" className='py-2 px-3 border-0 bg-base-color2 text-white rounded-3 d-flex align-items-center' disabled><span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                    ></span>
                        Uploading...</button> :
                        <button className='py-2 px-3 border-0 bg-base-color2 text-white rounded-3' onClick={uploadExcel} disabled={excelData.length === 0}>Upload</button>
                    }
                </div>
                {!uploadDisabled && <a href={ImportDistrictExcelDemo} download className='border shadow-lg d-flex align-items-center text-white  rounded-3 px-4 py-2 bg-base-color2 base-font-400 mx-1 ms-3' title={ImportDistrictExcelDemo}><DocumentDownload className="text-white pe-2" size="28" /> Demo Excel</a>}
            </div>
            <section className=''>
                {
                    excelData?.length > 0 && <div className="bg-white p-3 p-md-4  mb-4 shadow-lg border-radius-3">
                        <div className="">
                            {<Table columns={columns} data={excelData} />}
                        </div>
                    </div>
                }
            </section>
        </section>
    )
}

export default ImportDistrict;