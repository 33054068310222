import React from "react";
import { useState } from "react";
import { useEffect } from "react";

export const WelcomeScreen = () => {
  const [loader, setLoader] = useState(true);
  const [showData, setShowData] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
      setShowData(true);
    }, 2000);
  }, []);
  return (
    <>
      {loader && (
        <div class="background-dims bg-white" id="lodingSpinner">
          <div class="d-flex justify-content-center align-items-center">
            <div class="loader-img">
              <img
                src={process.env.PUBLIC_URL + "/Welcome.gif"}
                alt="Welcome Gif"
                class="img-fit loader-img"
              />
            </div>
          </div>
        </div>
      )}

      {showData && (
        <div className="d-flex justify-content-center align-items-center p-5 vh-100">
          <div className="bg-white p-3 p-md-4  mb-4 shadow-lg border-radius-3 text-align-center">
            <h4 className="title-font-bold fs-4 text-base-color text-center text-md-start pb-2 px-5 pt-2">
              {" "}
              ThankYou for Making Appointment
            </h4>
            <p className="px-5 pb-1">
              Please check mail for further instructions
            </p>
          </div>
        </div>
      )}
    </>
  );
};
