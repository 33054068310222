import React, { useState } from "react";
import Navbar from '../../Components/Navbar/Navbar';
import helpImageAdmin from '../../assets/images/virtual-screening-help-image-admin.png';
import helpImageNurse from '../../assets/images/virtual-screening-help-image-nurse.png';
import helpImageEvaluator from '../../assets/images/virtual-screening-help-image-evaluator.png';

const Help = () => {
  const routes = [{
    route: [
      {
        url: "/",
        name: "Login"
      }
    ]
  }];

  return (
    <>
      <Navbar routes={routes} />
      <section className="d-flex justify-content-center align-items-center vh-100 ">
        <div className="container py-6 h-100 ">
          <div className="row d-flex justify-content-center ">
            <div className="ccol-md-11 col-lg-8 col-lg-10 text-center text-md-start mx-auto mx-md-0 pb-3 pt-1">
              <label className="title-font-600 mb-0 fs-5 text-uppercase border-bottom-color2-2">
                Help
              </label>
            </div>
          </div>
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col col-xl-10 ">
              <div className="card border-0 rounded-4 bg-white dow-lg p-2 bg-white">
                <div className="d-md-flex g-0 p-md-2  justify-content-md-center align-items-md-center">
                  <div className="col-md-11 d-flex align-items-center">

                    <div className="card-body p-2 p-lg-4 text-black">
                      <p className="title-font-600 mb-0 fs-5 pb-3">
                        We would love to provide personal help so you feel comfortable using VirtuScreen. Please contact us at <a href="mailto:support@teledentalsolutions.com">support@teledentalsolutions.com</a>
                      </p>
                      <p className="title-font-600 mb-0 fs-5"> Meanwhile you can refer to the following Role-based Activity Flows.</p>

                      {/* Administrative flow */}
                      <div className="col col-12  mb-5 py-5">
                        <label className="title-font-600 mb-0 fs-5 mb-3 text-base-color border-bottom-color-2 d-block">Administrator Role Definition and Activity Flow</label>
                        <p className="title-font-600 mb-0 fs-5 py-3">  <span className="title-font-600 text-base-color">Super Administrator:</span> An administrator from Teledental Solutions who will create a skeleton account and profile for your organization. You will receive an email with your “Administrator” username and password so that you can start creating profile and other configurations for your organization.</p>
                        <p className="title-font-600 mb-0 fs-5 py-3">  <span className="title-font-600 text-base-color">Administrator:</span> Administrator is any individual you designate to manage the basic profile of your organization and create branches/locations.</p>
                        <p className="title-font-600 mb-0 fs-5 py-3">  <span className="title-font-600 text-base-color">Branch Administrator:</span> Any individual you designate to manage branch profile information and create the Nurse and Evaluator accounts.</p>

                        <span className="title-font-600 fs-5 title-font-600 text-base-color">Activity Flow:</span>
                        <img className="shadow rounded-4 p-2" src={helpImageAdmin} width="100%"></img>
                      </div>

                      {/* Nurse flow */}
                      <div className="col col-12 mb-4">
                        <label className="title-font-600 mb-0 fs-5 mb-3 text-base-color  border-bottom-color-2 d-block"> Nurse Role Definition and Activity Flow</label>
                        <p className="title-font-600 mb-0 fs-5 py-3">  <span className="title-font-600 text-base-color">Role Definition:</span> Any individual who can engage with a patient and take 3 images of patient’s teeth. Also can be referred to as “Data Collector”</p>
                        <span className="title-font-600 fs-5 title-font-600 text-base-color">Activity Flow:</span>
                        <img className="shadow rounded-4 p-2" src={helpImageNurse} width="100%"></img>
                      </div>



                      {/* Evaluator flow */}
                      <div className="col col-12 mb-4">
                        <label className="title-font-600 mb-0 fs-5 mb-3 text-base-color  border-bottom-color-2 d-block"> Evaluator Role Definition and Activity Flow</label>
                        <p className="title-font-600 mb-0 fs-5 py-3">  <span className="title-font-600 text-base-color">Role Definition:</span> Any calibrated dental professional you designate to receive notification of new screenings (3 patient images) to evaluate and provide the evaluation recommendation and comments.</p>
                        <span className="title-font-600 fs-5 title-font-600 text-base-color">Activity Flow:</span>
                        <img className="shadow rounded-4 p-2" src={helpImageEvaluator} width="100%"></img>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Help;
