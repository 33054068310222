import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { ApiEndPoint } from "../../constants/apiEndPoints/endPoints";
import { Badge, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { toast } from "react-hot-toast";
import { Spinner } from "reactstrap";
import { setCustomerBranch } from "../../redux/Slice/CustomerSlice";
import { defaultPatientType, patientOptions } from "../../constants/apiEndPoints/ReportCode";
import ConditionalButton from "../../Components/Button/ConditionalButton";
import * as XLSX from 'xlsx';
import { saveAs } from "file-saver";
import { ScreeningImageLink, UploadScreeningImages } from "../../constants/AWS/AWSConfiguration";
import { errorAlert, successAlert } from "../../Components/Alerts/Alerts";
import ImportsModal from "../../Components/Modal/Imports/ImportsModal";

const Reports = () => {
  const dispatch = useDispatch();
  const CustomerAdminDetails = useSelector(
    (state) => state.login.loggedUserDetails
  );
  const customerBranchList = useSelector(
    (state) => state.customer.customerBranchList
  );
  const types = [
    { value: "patient", label: "Patient" },
    { value: "student", label: "Student", },
  ];
  const [schoolLists, setSchoolLists] = useState([]);
  const [load, setLoad] = useState(false);
  const [load3, setLoad3] = useState(false);
  const [load2, setLoad2] = useState(false);
  const [secTable, setSecTable] = useState(false);
  const [sendToNurse, setSendToNurse] = useState(false);
  const [sendToStudent, setSendToStudent] = useState(false);
  const [sendAsNurse, setSendAsNurse] = useState(false);
  const [modal, setModal] = useState(false);
  const [countList, setCountList] = useState([]);
  const [treatmentList, setTreatmentList] = useState([]);
  const [code, setCode] = useState("");

  const toggle = () => setModal(!modal);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const selectedDistrict = watch("district");
  const selectedSchool = watch("school");
  const type = watch("types");

  const getColumnTotal = (column) => {
    return countList.reduce((acc, item) => acc + item[column], 0);
  };

  const handleClick = async (value) => {
    setCode(value);
    setSecTable(true);
    const studentTreatmentCodeListPayload = {
      evaluator: CustomerAdminDetails.id,
      school: selectedSchool.value,
      district: selectedDistrict.value,
      code: value,
    };

    const requestURL = type.value === "patient" ? ApiEndPoint.communityPatientTreatmentList : ApiEndPoint.communityStudentTreatmentList;

    // communityPatientTreatmentList
    setLoad2(true);
    await axios
      .post(
        requestURL,
        studentTreatmentCodeListPayload
      )
      .then((response) => {
        setTreatmentList(response.data.list);
        setLoad2(false)
      }).catch((e) => {
        setLoad2(false)
        setSecTable(false)
      })
  };

  const GetEvaluatorSchoolList = () => {
    setCountList([]);
    setTreatmentList([]);
    setCode("");
    const payload = {
      evaluator: CustomerAdminDetails?.id,
      district: selectedDistrict.value,
    };
    axios
      .post(ApiEndPoint.communitySchoolReportList, payload)
      .then((response) => {
        const schoolsListChnages = response?.data?.schoolList?.map((data) => {
          return { label: data.hierarchy_level2, value: data.hierarchy_level2 };
        });
        setSchoolLists(schoolsListChnages);
      });
  };

  const handleSendReport = async () => {
    setSendToStudent(true)
    const sendReportPayload = {
      evaluator: CustomerAdminDetails.id,
      school: selectedSchool.value,
      district: selectedDistrict.value,
      code: code,
    };
    const sendReportURL = type?.value === "patient" ? ApiEndPoint.communityPatientReportMail : ApiEndPoint.communityStudentReportMail;
    try {
      await axios
        .post(sendReportURL, sendReportPayload)
        .then((response) => {
          if (response.data.status == "success") {
            successAlert("Report sent");
            setSendToStudent(false)
          } else {
            errorAlert("Report not sent");
            setSendToStudent(false)
          }
        }).catch((e) => {
          if (e) {
            errorAlert("An error occurred. Please try again.")
          }
        });
    } catch (error) {
      errorAlert("An error occurred. Please try again.")
    }
  };


  const GetCustomerBranch = () => {
    const CustomerBranchListURL = `${ApiEndPoint.customerBranchListCommunity}${CustomerAdminDetails?.branch?.customer?.id}/`;
    try {
      axios.get(CustomerBranchListURL).then((response) => {
        const reformedData = response?.data?.branchList
          ?.filter(data => data.default_branch !== true)
          .map(data => ({
            label: data.name,
            value: data.id,
          }));
        dispatch(setCustomerBranch(reformedData));
      }).catch((e) => {
        if (e) {
          toast.error("An error occurred. Please try again.")
        }
      });
    } catch (error) {
      toast.error("An error occurred. Please try again.")
    }
  };

  const GetStudentList = () => {
    setCountList([]);
    setTreatmentList([]);
    setCode("");
    const selectedSchoolPayload = {
      evaluator: CustomerAdminDetails.id,
      school: selectedSchool.value,
      district: selectedDistrict.value
    };

    setLoad(true);
    axios
      .post(ApiEndPoint.communityStudentReport, selectedSchoolPayload)
      .then((response) => {
        setCountList(response.data.countList);
        setLoad(false);
      });
  };

  const GetPatientList = () => {
    setCountList([]);
    setTreatmentList([]);
    setCode("");
    const selectedPatientPayload = {
      evaluator: CustomerAdminDetails.id,
      district: selectedDistrict.value
    };
    setLoad(true);
    axios
      .post(ApiEndPoint.communityPatientReport, selectedPatientPayload)
      .then((response) => {
        setCountList(response.data.countList);
        setLoad(false);
      }).catch(err => toast.error("Something went wrong"))
  }

  const handleSendToNurse = async () => {
    try {
      setSendToNurse(true);
      // Generate Original Excel Sheet Code starts here
      let excelCreationList = [];
      treatmentList.map((data) => {
        excelCreationList.push({
          guardianemail1: data.alternate_email1,
          guardianemail2: data.alternate_email2,
          district: data.hierarchy_level1,
          school: data.hierarchy_level2,
          grade: data.hierarchy_level4,
          classteacher: data.hierarchy_level3,
          firstname: data.first_name,
          // gender: data.gender,
          studentId: data.number,
        })
      });

      const originalDataExcelSheet = XLSX.utils.json_to_sheet(excelCreationList);

      const newWorkbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(newWorkbook, originalDataExcelSheet, "FilteredSheet");

      const excelBuffer = XLSX.write(newWorkbook, {
        bookType: "xlsx",
        type: "array",
      });
      const excelFile = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      var excelFiles = new File([excelFile], `student-resport-${CustomerAdminDetails.id}-${selectedDistrict.value}-${code.replace(/ /g, '').toLocaleLowerCase()}.xlsx`, { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const excels3url = `${ScreeningImageLink}${excelFiles.name}`;
      await UploadScreeningImages(excelFiles, `${excelFiles.name}`);

      // Generate Original Excel Sheet Code ends here

      const sendReportToNursePayload = {
        evaluator: CustomerAdminDetails.id,
        nurseid: treatmentList[0].nurse_id,
        school: selectedSchool.value,
        district: selectedDistrict.value,
        code: code,
        excelURL: excels3url,
        excelFileName: excelFiles.name,
        eval_comments: treatmentList[0].eval_comments
      };
      axios.post(ApiEndPoint.sendStudentReportPDFExcelToNurse, sendReportToNursePayload).then((response) => {
        if (response.data.status === "success") {
          setSendToNurse(false);
          successAlert("Report Sent to nurse");
        } else {
          setSendToNurse(false);
          errorAlert("Error, while sending report");
        }
      }).catch((error) => { toast.error(error); setSendToNurse(false); })
    } catch (error) {
      setSendToNurse(false);
      errorAlert("Error, while sending report");
    }
  };

  const handleSendAsNurse = async (data) => {
    setSendAsNurse(true);
    toggle();
    const sendReportPayload = {
      evaluator: CustomerAdminDetails.id,
      school: selectedSchool.value,
      district: selectedDistrict.value,
      code: code,
      nurseemail: data.email,
      nursepass: data.password
    };

    try {
      await axios
        .post(ApiEndPoint.communityStudentReportMail, sendReportPayload)
        .then((response) => {
          if (response.data.status == "success") {
            successAlert("Report sent");
            setSendAsNurse(false)
          } else {
            errorAlert("Report not sent");
            setSendAsNurse(false)
          }
        }).catch((e) => {
          if (e) {
            errorAlert("An error occurred. Please try again.")
            setSendAsNurse(false)
          }
        });
    } catch (error) {
      errorAlert("An error occurred. Please try again.");
      setSendAsNurse(false)
    }
  };

  useEffect(() => {
    GetCustomerBranch();
  }, []);

  useEffect(() => {
    if (selectedDistrict && type.value === "student") {
      setSecTable(false);
      setValue("school", "");
      GetEvaluatorSchoolList();
    } else if (selectedDistrict && type.value === "patient") {
      GetPatientList();
    }
  }, [selectedDistrict]);

  useEffect(() => {
    if (selectedSchool) {
      setSecTable(false);
      GetStudentList();
    }
  }, [selectedSchool]);

  useEffect(() => {
    if (type && type.value) {
      setCountList([]);
      setTreatmentList([]);
      setCode("");
      setValue("district", "");
      setValue("school", "");
    }
  }, [type]);

  return (
    <div className="mt-2">
      <ImportsModal modal={modal} toggle={toggle} submit={handleSendAsNurse} />
      <label className="title-font-600 m-2   fs-4 text-uppercase mobile-heading text-base-color2">
        Community Screening Reports
      </label>
      {(customerBranchList && customerBranchList.length > 0 && CustomerAdminDetails) &&
        <section className="shadow bg-base-color  rounded-2 border">
          <div className="row px-2 p-md-2  mx-0 mb-2">
            <div className={type?.value != 'patient' ? "col-12  pb-3 pt-2" : "col-11 mx-auto mx-md-0 col-md-6 col-lg-6 pb-3 pt-2"} >
              <label
                htmlFor="type"
                className="fs-6 title-font-600 mb-0 text-white text-center"
              >
                Type
              </label>
              <div className="mt-1 d-flex gap-3 screener_form_input">
                <Controller
                  control={control}
                  name="types"
                  defaultValue={defaultPatientType(CustomerAdminDetails?.branch?.customer?.service_type)}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={patientOptions(CustomerAdminDetails?.branch?.customer?.service_type)}
                      value={value}
                      onChange={onChange}
                      className="border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input"
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 pb-3 pt-2">
              <label
                htmlFor="type"
                className="fs-6 title-font-600 mb-0 text-white text-center"
              >
                {type?.value == "patient" ? "Branch Name" : "District Name"}
              </label>
              <div className="mt-1 d-flex gap-3 screener_form_input">
                <Controller
                  control={control}
                  name="district"
                  // defaultValue={customerBranchList[0]}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={customerBranchList}
                      value={value}
                      onChange={onChange}
                      className="border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input"
                    />
                  )}
                />
              </div>
            </div>
            {type?.value != 'patient' && <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 pb-3 pt-2">
              <label
                htmlFor="type"
                className="fs-6 title-font-600 mb-0 text-white text-center"
              >
                School Name
              </label>
              <div className="mt-1 d-flex gap-3 screener_form_input">
                <Controller
                  control={control}
                  name="school"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={schoolLists}
                      value={value}
                      onChange={onChange}
                      className="border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input"
                    />
                  )}
                />
              </div>
            </div>}
          </div>
          <div className="mx-4">
            <div className="">
              <table className="table  table-responsive rounded-3 table-hover table-bordered">
                <thead className="text-center ">
                  {
                    selectedSchool && <tr className="border-0">
                      <th colSpan="11" className="border-0 table-light bg-base-color text-white">
                        {selectedSchool?.value}
                      </th>
                    </tr>
                  }
                  <tr className="">
                    <th className="border base-font-600 table-light bg-base-color text-white">{type?.value == "patient" ? "Nurse" : "Grade"}</th>
                    <th className="border base-font-600 table-light bg-base-color text-white">
                      Untreated Decay <br /> Yes
                    </th>
                    <th className="border base-font-600 table-light bg-base-color text-white">
                      Untreated Decay <br /> No
                    </th>
                    <th className="border base-font-600 table-light bg-base-color text-white">
                      Treated Decay <br /> Yes
                    </th>
                    <th className="border base-font-600 table-light bg-base-color text-white">
                      Treated Decay <br /> No
                    </th>
                    <th className="border base-font-600 table-light bg-base-color text-white">
                      Sealants Present <br /> Yes
                    </th>
                    <th className="border base-font-600 table-light bg-base-color text-white">
                      Sealants Present <br /> No
                    </th>
                    <th className="border base-font-600 table-light bg-base-color text-white">
                      CODE 1 No decay/<br />problems
                    </th>
                    <th className="border base-font-600 table-light bg-base-color text-white">
                      CODE 2 Sealants/<br />needed
                    </th>
                    <th className="border base-font-600 table-light bg-base-color text-white">
                      CODE 3 <br />DDS exam <br />suggested
                    </th>
                    <th className="border base-font-600 table-light bg-base-color text-white ">
                      Treatment<br /> needs:<br /> CODE 4 <br />Urgent <br />care<br /> needs
                    </th>
                  </tr>
                </thead>
                {load ? <tbody className="text-center">
                  <tr >

                    <td className="text-center border-0 text-center" colSpan={11}>
                      <Spinner className="table-fetch-spinner text-base-color2" />
                    </td>

                  </tr>
                </tbody>
                  : (
                    <>
                      <tbody className="text-center">
                        {countList?.map((item, i) => (
                          <tr key={i}>
                            <td className="base-font-500 fs-6 text-black border-bottom">
                              {item.grade}
                            </td>
                            <td className="base-font-500 fs-6 text-black border-bottom">
                              {item.untreated_decay_yes}
                            </td>
                            <td className="base-font-500 fs-6 text-black border-bottom">
                              {item.untreated_decay_no}
                            </td>
                            <td className="base-font-500 fs-6 text-black border-bottom">
                              {item.treated_decay_yes}
                            </td>
                            <td className="base-font-500 fs-6 text-black border-bottom">
                              {item.treated_decay_no}
                            </td>
                            <td className="base-font-500 fs-6 text-black border-bottom">
                              {item.sealants_present_yes}
                            </td>
                            <td className="base-font-500 fs-6 text-black border-bottom">
                              {item.sealants_present_no}
                            </td>
                            <td className="base-font-500 fs-6 text-black border-bottom">
                              {item.code_1}
                            </td>
                            <td className="base-font-500 fs-6 text-black border-bottom">
                              {item.code_2}
                            </td>
                            <td className="base-font-500 fs-6 text-black border-bottom">
                              {item.code_3}
                            </td>
                            <td className="base-font-500 fs-6 text-black border-bottom">
                              {item.code_4}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      {countList.length > 0 ? (
                        <tfoot className="text-center">
                          <tr>
                            <td
                              onClick={() => handleClick("id")}
                              className="base-font-700 fs-6 text-black table-light"
                            >
                              Total
                            </td>
                            <td className="base-font-700 fs-6 text-black table-light">
                              {getColumnTotal("untreated_decay_yes")}
                            </td>
                            <td className="base-font-700 fs-6 text-black table-light">
                              {getColumnTotal("untreated_decay_no")}
                            </td>
                            <td className="base-font-700 fs-6 text-black table-light">
                              {getColumnTotal("treated_decay_yes")}
                            </td>
                            <td className="base-font-700 fs-6 text-black table-light">
                              {getColumnTotal("treated_decay_no")}
                            </td>
                            <td className="base-font-700 fs-6 text-black table-light">
                              {getColumnTotal("sealants_present_yes")}
                            </td>
                            <td className="base-font-700 fs-6 text-black table-light">
                              {getColumnTotal("sealants_present_no")}
                            </td>

                            <td
                              className="base-font-700 fs-6 text-info table-light stud_report"
                              onClick={() => handleClick("CODE 1")}
                            >
                              {code == "CODE 1" ? (
                                <Badge color="info" pill>
                                  {getColumnTotal("code_1")}
                                </Badge>
                              ) : (
                                <span>{getColumnTotal("code_1")}</span>
                              )}
                            </td>
                            <td
                              className="base-font-700 fs-6 text-info table-light stud_report"
                              onClick={() => handleClick("CODE 2")}
                            >
                              {code == "CODE 2" ? (
                                <Badge color="info" pill>
                                  {getColumnTotal("code_2")}
                                </Badge>
                              ) : (
                                <span>{getColumnTotal("code_2")}</span>
                              )}
                            </td>
                            <td
                              className="base-font-700 fs-6 text-info table-light stud_report"
                              onClick={() => handleClick("CODE 3")}
                            >
                              {code == "CODE 3" ? (
                                <Badge color="info" pill>
                                  {getColumnTotal("code_3")}
                                </Badge>
                              ) : (
                                <span>{getColumnTotal("code_3")}</span>
                              )}
                            </td>
                            <td
                              className="base-font-700 fs-6 text-info table-light stud_report"
                              onClick={() => handleClick("CODE 4")}
                            >
                              {code == "CODE 4" ? (
                                <Badge color="info" pill>
                                  {getColumnTotal("code_4")}
                                </Badge>
                              ) : (
                                <span>{getColumnTotal("code_4")}</span>
                              )}
                            </td>
                          </tr>
                        </tfoot>
                      ) : (
                        ""
                      )}
                    </>
                  )}
              </table>
            </div>
          </div>

          <>
            {secTable && (
              <div className="mx-4">
                <div className="">
                  <div className="d-flex bg-light table-responsive table-hover border-0  text-center justify-content-between align-items-center">
                    <div className="mx-auto">
                      Treatment Needs: {code}, {selectedDistrict?.label}
                    </div>
                    <div className="d-flex">
                      {/* <ConditionalButton load={load3} handleSendReport={handleSendReport} disabled={load2} title={"Send"} /> */}
                      <ConditionalButton load={sendToNurse} onClick={handleSendToNurse} disabled={sendToNurse} title={"Send To Nurse"} />
                      <ConditionalButton load={sendToStudent} onClick={handleSendReport} disabled={sendToStudent || treatmentList.every(obj => obj.guardianemail1 === null)} title={"Send To Student"} />
                      {/* <ConditionalButton load={sendAsNurse} onClick={toggle} 
                      disabled
                      // disabled={sendAsNurse || treatmentList.every(obj => obj.email === null)}
                       title={"Send as Nurse"} /> */}
                    </div>
                  </div>
                  <table className="table  table-responsive rounded-3 table-hover border-0">
                    <thead className="text-center">
                      {/* <tr>
                        <th colSpan={type.value === "patient" ? "4" : "5"} className="border-0 table-light">
                          Treatment Needs: {code}, {selectedDistrict?.label}
                        </th>
                        <th className="border-start border-0 table-light d-flex justify-content-center">
                          {
                            load3 ? <button
                              type="button"
                              disabled
                              className="border-0 rounded-1 py-2   px-3 bg-base-color text-white title-font-500 d-flex gap-2 align-items-center"
                            >
                              <span
                                className="spinner-border spinner-border-sm" disabled
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Sending . . .
                            </button> : <button
                              className="border-0  px-4 py-2 text-white base-font-600  bg-base-color rounded-1"
                              onClick={handleSendReport}
                              disabled={load2}
                            >
                              Send
                            </button>
                          }
                          <ConditionalButton load={load3} handleSendReport={handleSendReport} disabled={load2} title={"Send"} />
                          <ConditionalButton load={load3} onClick={handleSendReport} disabled={load2} title={"Send To Nurse"} />
                          <ConditionalButton load={load3} onClick={handleSendReport} disabled={load2} title={"Send To Student"} />
                          <ConditionalButton load={load3} onClick={handleSendReport} disabled={load2} title={"Send as Nurse"} />
                        </th>
                      </tr> */}
                      {type.value === 'patient' ?
                        <tr className="">
                          <th className="border base-font-600 table-light">Patient Number</th>
                          <th className="border base-font-600 table-light">
                            First Name
                          </th>
                          <th className="border base-font-600 table-light">Last Name</th>
                          {/* <th className="border base-font-600 table-light">Gender</th> */}
                          <th className="border base-font-600 table-light">Email</th>
                        </tr>
                        :
                        <tr className="">
                          <th className="border base-font-600 table-light">School</th>
                          <th className="border base-font-600 table-light">
                            Student Id
                          </th>
                          <th className="border base-font-600 table-light">
                            First Name
                          </th>
                          <th className="border base-font-600 table-light">Grade</th>
                          {/* <th className="border base-font-600 table-light">Gender</th> */}
                          <th className="border base-font-600 table-light">Email</th>
                        </tr>
                      }
                    </thead>
                    <>
                      {
                        load2 ? <tbody className="text-center">
                          <tr >
                            <td className="base-font-500 fs-6 text-black border-bottom text-center" colSpan={6}>
                              <Spinner className="table-fetch-spinner text-base-color2" />
                            </td>


                          </tr>
                        </tbody> :
                          type.value === 'patient' ?
                            <tbody>
                              {treatmentList?.map((item) => (
                                <tr key={item.id}>
                                  <td className="base-font-500 fs-6 text-black border-bottom text-center">
                                    {item.patient_number}
                                  </td>
                                  <td className="base-font-500 fs-6 text-black border-bottom text-center">
                                    {item.first_name}
                                  </td>
                                  <td className="base-font-500 fs-6 text-black border-bottom text-center">
                                    {item.last_name}
                                  </td>
                                  {/* <td className="base-font-500 fs-6 text-black border-bottom text-center">
                                    {item.gender}
                                  </td> */}
                                  <td className="base-font-500 fs-6 text-black border-bottom text-center">
                                    {item.email}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            : <tbody>
                              {treatmentList?.map((item) => (
                                <tr key={item.id}>
                                  <td className="base-font-500 fs-6 text-black border-bottom text-center">
                                    {item.hierarchy_level2}
                                  </td>
                                  <td className="base-font-500 fs-6 text-black border-bottom text-center">
                                    {item.number}
                                  </td>
                                  <td className="base-font-500 fs-6 text-black border-bottom text-center">
                                    {item.first_name}
                                  </td>
                                  <td className="base-font-500 fs-6 text-black border-bottom text-center">
                                    {item.hierarchy_level4}
                                  </td>
                                  {/* <td className="base-font-500 fs-6 text-black border-bottom text-center">
                                    {item.gender}
                                  </td> */}
                                  <td className="base-font-500 fs-6 text-black border-bottom text-center">
                                    {item.alternate_email1}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                      }
                    </>
                  </table>
                </div>
              </div>
            )}
          </>
        </section>}
    </div>

  );
};

export default Reports;
