import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfileHeader from "../../Components/ProfileHeader/ProfileHeader";
import ProfileLoader from "../../Components/ProfileHeader/ProfileLoader";
import PatientTable from "../../Components/Table/PatientTable";
import {
  Label,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import StudentTable from "../../Components/Table/StudentTable";
import ScreenerEditModal from "../../Components/Modal/ScreenerModal/ScreenerEditModal";
import axios from "axios";
import { ApiEndPoint } from "../../constants/apiEndPoints/endPoints";
import { notifications, notificationShow, selectEvaluationStatus, selectPatientFromNotification, setSelectedNotificationPatientType, setSelectedPatientDistrict } from "../../redux/Slice/EvaluatorSlice";
import { setDropDownCustomerBranchList } from "../../redux/Slice/CustomerSlice";
import { toast } from "react-hot-toast";

const Dashboard = () => {

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const roleIs = useSelector((state) => state.login.roleIs);
  const ScreeenerDetails = useSelector(
    (state) => state?.login?.loggedUserDetails
  );

  const evaluationStatus = useSelector(
    (state) => state?.evaluator?.selectedEvalStatus
  );

  const selectedNotificationPatientType = useSelector(
    (state) => state.evaluator.selectedNotificationPatientType
  );

  const customerBranchList = useSelector(
    (state) => state.customer.dropDownCustomerBranchList
  );

  const [Loader, setLoader] = useState(false);

  const IntegrateOptions = [
    { value: "patient", label: "Patient" },
    { value: "student", label: "Student" },
  ];

  const ClinicOptions = [
    { value: "patient", label: "Patient" },
  ];

  const SchoolOption = [{ value: "student", label: "Student" }]

  const patientOptions = (serviceType) => {
    if (serviceType === "clinic_community_screening") {
      return ClinicOptions
    } else if (serviceType === "clinic_individual_screening") {
      return SchoolOption
    } else if (serviceType === "clinic_integrated_screening") {
      return IntegrateOptions
    } else if (serviceType === "public_self_screening") {
      return [{ value: "selfscreening", label: "Self Screening" }]
    } else {
      return SchoolOption;
    }
  };

  const defaultPatientType = (serviceType) => {
    if (serviceType === "clinic_community_screening") {
      return ClinicOptions[0]
    } else if (serviceType === "clinic_individual_screening") {
      return SchoolOption[0]
    } else if (serviceType === "clinic_integrated_screening") {
      return IntegrateOptions[0]
    } else if (serviceType === "community_screening") {
      return SchoolOption[0]
    } else if (serviceType === "public_self_screening") {
      return { value: "selfscreening", label: "Self Screening" }
    }
  };

  const evalStatus = [
    { value: "new", label: "New" },
    { value: "screened", label: "Screened" },
    { value: "optout", label: "Optout" },
    { value: "completed", label: "Completed" },
    { value: "reported", label: "Reported" },
    { value: "incomplete", label: "Incomplete" },
  ];

  const selecteedPatientType = watch("patient_type");
  const evalstatus = watch("evalstatus");
  const district = watch("district");

  useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, []);

  const GetAllNotification = () => {
    try {
      const payload = {
        branch: ScreeenerDetails.branch.id,
        evaluator: ScreeenerDetails.id
      };

      axios.post(ApiEndPoint.screenerNotification, payload).then((response) => {
        dispatch(notifications(response.data.notificationList));
      }).catch((err) => {
        if (err) {
          toast.error("An error occurred. Please try again.")
        }
      })
    } catch (error) {
      if (error) {
        toast.error("An error occurred. Please try again.")
      }
    }
  };

  const GetAllNotificationShowList = () => {
    const payload = {
      branch: ScreeenerDetails?.branch?.id,
      evaluator: ScreeenerDetails?.id
    };

    axios.post(ApiEndPoint.screenerNotificationList, payload).then((response) => {
      dispatch(notificationShow(response.data.notificationList));
    }).catch((err) => {
      if (err) {
        toast.error("An error occurred. Please try again.")
      }
    });
  };


  const GetCustomerBranch = () => {
    const CustomerBranchListURL = `${ApiEndPoint.customerBranchListCommunity}${ScreeenerDetails?.branch?.customer.id}/`;
    axios.get(CustomerBranchListURL).then((response) => {
      const reformedData = response?.data?.branchList
        ?.filter(data => data.default_branch !== true)
        .map(data => ({
          label: data.name,
          value: data.id,
        }));
      dispatch(setDropDownCustomerBranchList(reformedData));
    }).catch((e) => {
      if (e) {
        toast.error("An error occurred. Please try again.")
      }
    });
  };

  const fetchNotifications = () => {
    if (ScreeenerDetails && roleIs === "evaluator") {
      GetAllNotification();
      // GetAllNotificationShowList();
    }
  };

  useEffect(() => {
    fetchNotifications()
  }, [ScreeenerDetails, roleIs]);

  const MINUTE_MS = 30000;

  useEffect(() => {
    const interval = setInterval(() => {
      fetchNotifications()
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [])

  useEffect(() => {
    if (evalstatus?.value) {
      dispatch(selectEvaluationStatus(evalstatus));
    };
  }, [evalstatus]);

  useEffect(() => {
    if (selectedNotificationPatientType == "patient") {
      setValue("patient_type", { value: "patient", label: "Patient" })
    } else if (selectedNotificationPatientType == "student") {
      setValue("patient_type", { value: "student", label: "Student" })
    } else if (selectedNotificationPatientType == "selfscreening") {
      setValue("patient_type", { value: "selfscreening", label: "Self Screening" })
    }
  }, [selectedNotificationPatientType]);

  useEffect(() => {
    if (ScreeenerDetails) {
      GetCustomerBranch();
    }
  }, [ScreeenerDetails]);

  useEffect(() => {
    if (district?.value) {
      dispatch(setSelectedPatientDistrict(district));
    }
  }, [district])

  useEffect(() => {
    dispatch(selectEvaluationStatus(evalStatus[1]));
    dispatch(selectPatientFromNotification(undefined));
    dispatch(setSelectedNotificationPatientType(undefined));
  }, []);


  return (
    <>
      <section className="m-3 m-md-4 d-flex gap-4 flex-column">
        {(Loader && !ScreeenerDetails) ? (
          <ProfileLoader />
        ) : (
          <ProfileHeader
            profilePic={
              ScreeenerDetails?.branch?.logo
                ? ScreeenerDetails?.branch?.logo
                : "https://i.pinimg.com/564x/1f/a9/fc/1fa9fc02ab8e2acab10bc6ca19016039.jpg"
            }
            branchname={ScreeenerDetails?.branch?.name}
            name={ScreeenerDetails?.branch?.customer?.name}
            email={ScreeenerDetails?.email}
            contact={`${ScreeenerDetails?.branch?.customer?.phone_number}`}
            address={
              ScreeenerDetails?.branch?.street_address
                ? ScreeenerDetails?.branch?.street_address
                : ""
            }
            city={
              ScreeenerDetails?.branch?.city
                ? ScreeenerDetails?.branch?.city
                : ""
            }
            state={
              ScreeenerDetails?.branch?.state
                ? ScreeenerDetails?.branch?.state
                : ""
            }
            zip={
              ScreeenerDetails?.branch?.zip ? ScreeenerDetails?.branch?.zip : ""
            }
            editModal={<ScreenerEditModal data={ScreeenerDetails} />}
            userName={ScreeenerDetails?.username}
          />
        )}
        {ScreeenerDetails &&
          <section>
            <div className="row px-2 py-2 p-md-2 shadow bg-base-color2 mt-2 rounded-2 border mx-0 mb-4">
              <div className="col-12 mx-auto mx-md-0 col-md-6 col-lg-3 pb-3 pt-1 flex-column flex-md-row">
                <Label className="fs-6 title-font-600 mb-0 text-white ">
                  {selecteedPatientType?.value === "patient" ?
                    "Customer"
                    :
                    "Screening Program"
                  }

                </Label>
                <div className="mt-1 w-100">
                  <input
                    className="p-2 border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input"
                    type="text"
                    readOnly
                    placeholder="customer..."
                    {...register("customer")}
                    disabled={ScreeenerDetails?.branch?.customer?.service_type !== "community_screening" ? false : true}
                    value={ScreeenerDetails?.branch?.customer?.name}
                  />
                </div>
              </div>
              <div className="col-12 mx-auto mx-md-0 col-md-6 col-lg-3 pb-3 pt-1 flex-column flex-md-row">
                <label
                  htmlFor="type"
                  className="fs-6 title-font-600 mb-0 text-white "
                >
                  Type
                </label>
                <div className="mt-1 w-100">
                  <Controller
                    control={control}
                    name="patient_type"
                    defaultValue={defaultPatientType(ScreeenerDetails?.branch?.customer?.service_type)}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={patientOptions(ScreeenerDetails?.branch?.customer?.service_type)}
                        value={value}
                        onChange={onChange}
                        isDisabled={ScreeenerDetails?.branch?.customer?.service_type !== "community_screening" ? false : true}
                        className="border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: ScreeenerDetails?.branch?.customer?.service_type === "community_screening" ? "#dddddd" : "#fff",
                          }),
                        }}
                      /> //onChange={(e) => handlePatientType(e)}
                    )}
                  />
                </div>
              </div>
              <div className="col-12 mx-auto mx-md-0 col-md-6 col-lg-3 pb-3 pt-1 flex-column flex-md-row">
                <Label className="fs-6 title-font-600 mb-0 text-white ">
                  {selecteedPatientType?.value === ("patient" || "selfscreening") ?
                    "Branch"
                    :
                    "District"
                  }
                </Label>
                {ScreeenerDetails?.branch?.customer?.service_type !== "community_screening" ?
                  <div className="mt-1 w-100">
                    <input
                      className="p-2 border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input"
                      type="text"
                      readOnly
                      placeholder="Branch..."
                      {...register("branch")}
                      value={ScreeenerDetails?.branch?.name}
                    />
                  </div>
                  :
                  <Controller
                    control={control}
                    name="district"
                    defaultValue={customerBranchList && customerBranchList[0]}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={customerBranchList}
                        value={value}
                        onChange={onChange}
                        // isDisabled={ScreeenerDetails?.branch?.customer?.service_type !== "community_screening" ? false : true}
                        className="border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input mt-1"
                        // styles={{
                        //   control: (baseStyles, state) => ({
                        //     ...baseStyles,
                        //     backgroundColor: ScreeenerDetails?.branch?.customer?.service_type !== "community_screening" ? '#f0f0f0' : "#dddddd",
                        //   }),
                        // }}
                      />
                    )}
                  />
                }
              </div>
            
              <div className="col-12 mx-auto mx-md-0 col-md-6 col-lg-3 pb-3 pt-1 flex-column flex-md-row">
                <label
                  htmlFor="type"
                  className="fs-6 title-font-600 mb-0 text-white"
                >
                  Status
                </label>
                <div className="mt-1 w-100">
                  <Controller
                    control={control}
                    name="evalstatus"
                    defaultValue={evalStatus[1]}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={evalStatus}
                        value={evaluationStatus}
                        onChange={onChange}
                        className="border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input"
                      /> //onChange={(e) => handlePatientType(e)}
                    )}
                  />
                </div>
              </div>
            </div>
            {(selecteedPatientType?.value === "patient" || selecteedPatientType?.value === "selfscreening") ? (
              <PatientTable evalstatus={evalstatus?.value} patientType={selecteedPatientType?.value} />
            ) : (
              <StudentTable evalstatus={evalstatus?.value} />
            )}
          </section>
        }
      </section>
    </>
  );
};

export default Dashboard;
