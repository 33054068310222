import axios from "axios";
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Label,
} from "reactstrap";

import { UserEdit } from "iconsax-react";
import { useDispatch } from "react-redux";
import user from "../../../constants/assests/images/user.png"
import ErrorComponents from "../../ErrorComponents/ErrorComponents";
import { preventNegativeValues } from "../../PreventValues/preventNegativeValues";
import {
  UploadProfileLink,
  uploadProfilePic,
} from "../../../constants/AWS/AWSConfiguration";
import { ApiEndPoint } from "../../../constants/apiEndPoints/endPoints";
import { errorAlert, successAlert } from "../../Alerts/Alerts";
import { customerProfile } from "../../../redux/Slice/CustomerSlice";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import { states } from "../../../constants/apiEndPoints/ReportCode";
import InputMask from 'react-input-mask';
import { loggedUserDetails } from "../../../redux/Slice/LoginSlice";

function CustomerProfileEdit(props) {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [Loader, setLoader] = useState(false);
  const toggle = () => setModal(!modal);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const CancelButton = () => {
    reset();
    toggle();
  };

  const EditSubmit = async (data) => {

    data.appointment_link = data.appointment_link;
    data.education_link = data.education_link;
    data.website = data.website;
    data.state = data.state.value;

    setLoader(true);
    const timestamp = Date.now();

    if (upload) {
      await uploadProfilePic(upload, timestamp);
      var url = `${UploadProfileLink}${timestamp}_${upload?.name}`;
    }
    data.logo = url ? url : props?.data?.logo;
    try {
      await axios
        .put(`${ApiEndPoint.customerEdit}${props.data.customer_number}/`, data)
        .then((response) => {
          if (response.data.status === "success") {
            CancelButton();
            successAlert("Profile is updated");
            setLoader(false);
            dispatch(customerProfile(response.data.customerDetails));
            dispatch(loggedUserDetails(response.data.customerDetails))
          } else if (response.data.status === "failed") {
            errorAlert(response.data.errorMessage);
            setLoader(false);
          } else {
            errorAlert("Something went wrong");
            setLoader(false);
          }
        })
        .catch((error) => {
          setLoader(false);
          errorAlert("Something went wrong");
        });
    } catch (error) {
      errorAlert(error);
    }
  };

  useEffect(() => {
    reset({
      customer_admin_user_id: props?.data?.customer_admin_user_id,
      id: props?.data?.id,
      city: props?.data?.city,
      zip: props?.data?.zip,
      appointment_link: props?.data?.appointment_link,
      education_link: props?.data?.education_link,
      customer_number: props?.data?.customer_number,
      name: props?.data?.name,
      street_address: props?.data?.street_address,
      state: states.filter((state) => state?.value?.toLowerCase() === props?.data?.state?.toLowerCase())[0],
      email: props?.data?.email,
      website: props?.data?.website,
      phone_number: props?.data?.phone_number,

    });
    setFile(props?.data?.logo)
  }, [props]);
  const [file, setFile] = React.useState("");
  const [upload, setUpload] = React.useState("");

  const handleimagechange = (e) => {
    const filedata = e.target.files[0]
    setUpload(filedata)
  }
  const handlechange = (e) => {
    handleimagechange(e);
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  return (
    <>
      <button
        className="submit-btn base-font-600 fw-bold d-flex align-items-center shadow-lg "
        onClick={toggle}
      >
        <span className="base-font-600">Edit</span>
        <UserEdit size="30" variant="Bulk" className="ps-2" />
      </button>

      <Modal
        className="border-0"
        isOpen={modal}
        toggle={props.toggle}
        size="lg"
        centered
      >
        <ModalHeader className="bg-base-color2 " toggle={props.toggle}>
          <span className="title-font-700 fs-5 text-base-white">
            Edit Clinic
          </span>
        </ModalHeader>
        <Form onSubmit={handleSubmit(EditSubmit)} autoComplete="off">
          <ModalBody className="bg-background-color px-4">
            <div className="row">
              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
                <div className=" ">
                  <div className=" d-flex justify-content-between align-items-center ">
                  


                    <div className="d-flex align-items-center justify-content-center gap-4">
                      <div className="basic-details-imgBox position-relative shadow-lg border">
                        {
                          file ? <img src={file} className='user-image-fit' /> : <img src={props?.data?.logo ? props?.data?.logo : user} className='user-image-fit' />
                        }

                        <div className="position-absolute bottom-0 end-0 bg-white rounded-circle shadow border" style={{ cursor: "pointer" }}>
                          <label htmlFor="file-input" className="text-base-color text-decoration-underline p-2 fs-6" role="button">
                            Edit
                          </label>
                          <input
                            id='file-input'
                            type="file"
                            className='d-none'
                            {...register("image", { required: !file })}
                            onChange={(e) => handlechange(e)}
                            accept="image/jpeg,image/png,image/gif"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {(errors?.image && !file) && (
                  <ErrorComponents error={"Images is required *"} />
                )}
              </div>
              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
                <Label className="fs-6 title-font-600 mb-0 text-black">
                  Name*
                </Label>

                <div className="mt-1  d-flex gap-3">
                  <input
                    className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                    type="text"
                    placeholder="Name..."
                    {...register("name", { required: true })}
                  />
                </div>
                {errors.name && (
                  <ErrorComponents error={"Name is required *"} />
                )}
              </div>
              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
                <Label className="fs-6 title-font-600 mb-0 text-black">
                  Phone Number*
                </Label>
                <div className="mt-1  d-flex gap-3">
                  <Controller
                    name="phone_number"
                    control={control}
                    rules={{
                      required: "Phone number is required",
                      validate: (value) =>
                        (value && value.length >= 10) ||
                        "Invalid phone number format (e.g., (111)111-1111)",
                    }}
                    render={({ field: { onChange, value } }) => (
                      <div
                        className="p-2 border-radius-10 shadow-none  w-100 fs-6 title-font-500 d-flex gap-2  modal-input"
                      >
                        <span>+1</span>
                        <InputMask
                          mask="(999) 999-9999"
                          value={value}
                          onChange={(val) => onChange(val)}
                          type="tel"
                          placeholder=" (111)111-1111"
                          className="bg-transparent title-font-500 w-100 base-white-light border-0 shadow-none p-0"
                        />
                      </div>
                    )}
                  />
                </div>
                {errors?.phone_number && (
                  <ErrorComponents error={errors.phone_number?.message} />
                )}
              </div>

              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
                <Label className="fs-6 title-font-600 mb-0 text-black">
                  Address*
                </Label>
                <div className="mt-1  d-flex gap-3">
                  <input
                    className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                    type="text"
                    placeholder="Enter Address..."
                    {...register("street_address", {
                      required: "Address is required *",
                    })}
                  />
                </div>
                {errors.street_address && (
                  <ErrorComponents error={errors.street_address?.message} />
                )}
              </div>
              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
                <Label className="fs-6 title-font-600 mb-0 text-black">
                  City*
                </Label>
                <div className="mt-1  d-flex gap-3">
                  <input
                    className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                    type="text"
                    placeholder="Enter city..."
                    {...register("city", {
                      required: "City is required *",
                    })}
                  />
                </div>
                {errors.city && (
                  <ErrorComponents error={errors.city?.message} />
                )}
              </div>
              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
                <Label className="fs-6 title-font-600 mb-0 text-black">
                  State*
                </Label>

                <div className="mt-1  d-flex gap-3">
                  {/* <input
                    className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                    type="text"
                    placeholder="Enter State..."
                    {...register("state", {
                      required: "State is required *",
                    })}
                  /> */}
                  <Controller
                    control={control}
                    name="state"
                    autoComplete="nope"
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={states}
                        onChange={onChange}
                        value={value}
                        placeholder={"Select State "}
                        className="border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                        ref={register("state", {
                          required: "State is required *",
                        })}
                      />
                    )}
                  />
                </div>
                {errors.state && (
                  <ErrorComponents error={errors.state?.message} />
                )}
              </div>
              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
                <Label className="fs-6 title-font-600 mb-0 text-black">
                  Zip code*
                </Label>

                <div className="mt-1  d-flex gap-3">
                  <input
                    className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                    type="number"
                    maxLength={6}
                    min={0}
                    onKeyDown={preventNegativeValues}
                    placeholder="Enter zipcode here..."
                    {...register("zip", {
                      required: true, maxLength: {
                        value: 6,
                        message: "Zip code cannot exceed 6 characters."
                      }
                    })}
                  />
                </div>
                {errors.zip?.type === "required" && (
                  <ErrorComponents error={"Zipcode is required *"} />
                )}
                {errors?.zip?.type === "maxLength" && (
                  <ErrorComponents error={errors?.zip?.message} />
                )}
              </div>
              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
                <Label className="fs-6 title-font-600 mb-0 text-black">
                  Website*
                </Label>

                <div className="mt-1  d-flex gap-3">
                  <input
                    className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                    type="text"
                    placeholder="Enter Customer Website here..."
                    {...register("website", {
                      required: "Website is required *",
                      // pattern: {
                      //   value: /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
                      //   message: "Invalid link format. eg 'www.example.com'",
                      // }
                    })}
                  />
                </div>
                {errors.website && (
                  <ErrorComponents error={errors.website?.message} />
                )}
              </div>
              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
                <Label className="fs-6 title-font-600 mb-0 text-black">
                  Patient Appointment Scheduling Link*
                </Label>

                <div className="mt-1  d-flex gap-3">
                  <input
                    className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                    type="text"
                    placeholder="Enter Patient Appointment Scheduling Link..."
                    {...register("appointment_link", {
                      required: "Patient Appointment Scheduling Link is required*",
                      // pattern: {
                      //   value: /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
                      //   message: "Invalid link format. eg 'www.example.com'",
                      // }
                    })}
                  />
                </div>
                {errors.appointment_link && (
                  <ErrorComponents error={errors.appointment_link?.message} />
                )}
              </div>
              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
                <Label className="fs-6 title-font-600 mb-0 text-black">
                  Patient Education Content Link*
                </Label>

                <div className="mt-1  d-flex gap-3">
                  <input
                    className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                    type="text"
                    placeholder="Enter Patient Education Content Link..."
                    {...register("education_link", {
                      required: "Patient Education Content Link is required *",
                      // pattern: {
                      //   value: /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
                      //   message: "Invalid link format. eg 'www.example.com'",
                      // }
                    })}
                  />
                </div>
                {errors.education_link && (
                  <ErrorComponents error={errors.education_link?.message} />
                )}
              </div>


            </div>
          </ModalBody>
          <ModalFooter className="border-0 ">
            <div className="d-flex justify-content-end align-items-center">
              <div className="d-flex align-items-center gap-3">
                {Loader ? (
                  <button
                    type="button"
                    className="submit-btn d-flex gap-2 align-items-center"
                  >
                    <span
                      className="spinner-border spinner-border-sm" disabled
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Editing . . .
                  </button>
                ) : (
                  <button type="submit" className="submit-btn">
                    Submit
                  </button>
                )}
                <button
                  type="button"
                  className="close-btn"
                  onClick={CancelButton}
                >
                  Cancel
                </button>
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default CustomerProfileEdit;
