import axios from "axios";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Label,
} from "reactstrap";

import { UserEdit } from "iconsax-react";
import ErrorComponents from "../../ErrorComponents/ErrorComponents";
import { errorAlert, successAlert } from "../../Alerts/Alerts";
import { loggedUserDetails } from "../../../redux/Slice/LoginSlice";
import { useDispatch } from "react-redux";
import { ApiEndPoint } from "../../../constants/apiEndPoints/endPoints";

function ScreenerEditModal(props) {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [Loader, setLoader] = useState(false);
  const toggle = () => setModal(!modal);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const CancelButton = () => {
    reset();
    toggle();
  };
  const EditSubmit = async (data) => {
    setLoader(true);
    const editScreenerPayload = {
      address: data?.address,
      fname: data?.fname,
      lname: data?.lname,
      email: data?.email,
    };
    try {
      await axios
        .post(
          `${ApiEndPoint.modifyScreener}${props?.data?.username}/`,
          editScreenerPayload
        )
        .then((response) => {
          if (response.data.status === "success") {
            CancelButton();
            successAlert(response?.data?.message);
            dispatch(loggedUserDetails(response.data.screenerDetails));
            reset();
            setLoader(false);
          } else {
            errorAlert(response?.data?.errorMessage);
            setLoader(false);
          }
        });
    } catch (error) {
      errorAlert(error);
    }
  };

  useEffect(() => {
    reset({
      fname: props?.data?.fname,
      lname: props?.data?.lname,
      email: props?.data?.email,
      address: props?.data?.address,
    });
  }, [modal]);

  return (
    <>
      <button
        className="submit-btn base-font-600 fw-bold d-flex align-items-center shadow-lg "
        onClick={toggle}
      >
        <span className="base-font-600">Edit</span>
        <UserEdit size="30" variant="Bulk" className="ps-2" />
      </button>

      <Modal
        className="border-0"
        isOpen={modal}
        toggle={props.toggle}
        size="lg"
        centered
      >
        <ModalHeader className="bg-base-color2 ">
          <span className="title-font-700 fs-5 text-base-white">
            Edit Evaluators
          </span>
        </ModalHeader>
        <Form onSubmit={handleSubmit(EditSubmit)} autoComplete="off">
          <ModalBody className="bg-background-color px-4">
            <div className="row">
              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
                <Label className="fs-6 title-font-600 mb-0 text-black">
                  First Name*
                </Label>

                <div className="mt-1  d-flex gap-3">
                  <input
                    className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                    type="text"
                    placeholder="Name..."
                    {...register("fname", { required: true })}
                  />
                </div>
                {errors.fname && (
                  <ErrorComponents error={"First Name is required *"} />
                )}
              </div>
              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
                <Label className="fs-6 title-font-600 mb-0 text-black">
                  Last Name*
                </Label>

                <div className="mt-1  d-flex gap-3">
                  <input
                    className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                    type="text"
                    placeholder="Name..."
                    {...register("lname", { required: true })}
                  />
                </div>
                {errors.lname && (
                  <ErrorComponents error={"Last Name is required *"} />
                )}
              </div>

              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
                <Label className="fs-6 title-font-600 mb-0 text-black">
                  Email*
                </Label>

                <div className="mt-1  d-flex gap-3">
                  <input
                    className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                    type="email"
                    placeholder="Email..."
                    {...register("email", { required: true })}
                  />
                </div>
                {errors.email && (
                  <ErrorComponents error={"Email is required *"} />
                )}
              </div>

              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-3 pb-1">
                <ModalFooter className="border-0 ">
                  <div className="d-flex justify-content-end align-items-center">
                    <div className="d-flex align-items-center gap-3">
                      {Loader ? (
                        <button
                          type="button"
                          className="submit-btn d-flex gap-2 align-items-center"
                        >
                          <span
                            className="spinner-border spinner-border-sm" disabled
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Editing . . .
                        </button>
                      ) : (
                        <button type="submit" className="submit-btn">
                          Submit
                        </button>
                      )}
                      <button
                        type="button"
                        className="close-btn"
                        onClick={CancelButton}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </ModalFooter>
              </div>
            </div>
          </ModalBody>
        </Form>
      </Modal>
    </>
  );
}

export default ScreenerEditModal;
