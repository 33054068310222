import { UserEdit } from "iconsax-react";
import React from "react";

const ProfileHeader = ({
  profilePic,
  name,
  branchname,
  email,
  contact,
  address,
  city,
  state,
  zip,
  editModal,
  userName,
}) => {
  const formatedPhone = (contact) => {
    const cleaned = ("" + contact).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `+${match[1]}(${match[2]})${match[3]}-${match[4]}`;
    }
    return contact;
  };

  const phoneNo = formatedPhone(contact);
  return (
    <div className="basic-details-back-main  shadow-lg rounded-4">
      <div className="basic-details-back rounded-top-4">
        <div className="basic-details-user-img  d-flex align-items-center justify-content-between flex-column flex-md-row gap-4 gap-md-0">
          <div className="d-flex align-items-center justify-content-center justify-content-md-start flex-column flex-md-row gap-3 gap-md-0">
            <div className="basic-details-imgBox shadow-lg">
              <img
                loading="lazy"
                src={profilePic}
                alt="user-img"
                className="user-image-fit object-fit-contain"
              />
            </div>
            <div className="nameBox d-flex flex-column gap-1 px-3">
              {name && (
                <h4 className="title-font-700 mb-0 fs-4 text-capitalize text-base-color">
                  {name}
                </h4>
              )}

              {branchname && (
                <h6 className="text-black fs-6 base-font-600 mb-0">
                  {branchname}
                </h6>
              )}

              {(address || city || state) && <h6 className="text-black fs-6 title-font-600  text-capitalize mb-0">
                {`${address}${city ? `, ${city},` : ""} ${state} ${zip ? `${zip}` : ""
                  }`}
              </h6>}

              {phoneNo && (
                <h6 className="text-black fs-6 base-font-600 mb-0">
                  {phoneNo}
                </h6>
              )}

              <h6 className="text-black fs-6 base-font-600 mb-0">{email}</h6>
            </div>
          </div>
          {editModal}
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
