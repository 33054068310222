import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import './App.css';
import './css/Components.css'
import { useDispatch, useSelector } from 'react-redux';
import GuestRoute from './routes/GuestRoute';
import BranchAdminRoute from './routes/BranchAdminRoute';
import CustomerAdminRoute from './routes/CustomerAdminRoute';
import NurseRoute from './routes/NurseRoute';
import toast, { Toaster } from 'react-hot-toast';
import '@coreui/coreui/dist/css/coreui.min.css'
import EvaluatorRoute from './routes/EvaluatorRoute';
import SuperAdminRoutes from './routes/SuperAdminRoutes';
import CommunityScreeningRoutes from './routes/CommunityScreeningRoutes';
import { setDefaultDropDownCustomerBranchList } from './redux/Slice/CustomerSlice';


const App = () => {
  const dispatch = useDispatch();
  const isLogged = useSelector((state) => state.login.isLogged);
  const loggedUserDetails = useSelector((state) => state.login.loggedUserDetails);
  const roleIs = useSelector((state) => state.login.roleIs);
  const [lodingSpinner, setLodingSpinner] = React.useState(false)
  const spinner = document.getElementById('lodingSpinner')

  if (spinner) {
    setTimeout(() => {
      spinner.style.display = 'none';
      setLodingSpinner(true)
    }, 2000);
  }

  useEffect(() => {
    if (isLogged) {
      if (loggedUserDetails?.branch?.customer?.service_type === "community_screening") {
        dispatch(setDefaultDropDownCustomerBranchList({ label: "All Districts", value: "0", name: "All Districts" }))
      } else {
        dispatch(setDefaultDropDownCustomerBranchList({ label: "All Branch", value: "0", name: "All Branches" }))
      }
    }
  }, [isLogged, roleIs, loggedUserDetails]);


  return (
    lodingSpinner &&
    <Router>
      <Toaster position="top-right"
        gutter={8}
        containerClassName=""
        containerStyle={{}}
      />
      {isLogged ?
        roleIs === "customeradmin" ?
          <CustomerAdminRoute />
          :
          roleIs === "branchadmin" ?
            <BranchAdminRoute />
            :
            roleIs === "evaluator" ?
              <EvaluatorRoute />
              :
              roleIs === "nurse" ?
                <NurseRoute />
                : roleIs === "superadmin" ?
                  <SuperAdminRoutes />
                  : roleIs === "community_screening" ?
                    <CommunityScreeningRoutes />
                    :
                    <Routes>
                      <Route path="/" element={<Home />} />
                    </Routes>
        :
        <GuestRoute />
      }
    </Router >
  )
}

export default App;