import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Label,
} from "reactstrap";
import { useSelector } from "react-redux";
import ErrorComponents from "../../ErrorComponents/ErrorComponents";
import axios from "axios";
import { ApiEndPoint } from "../../../constants/apiEndPoints/endPoints";
import { errorAlert, successAlert, warningAlert } from "../../Alerts/Alerts";
import { useEffect } from "react";

export const EditLinksModal = ({ info, modal, toggle, path, GetLinks }) => {
  const [loader, setLoader] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const CustomerAdminDetails = useSelector(
    (state) => state.login.loggedUserDetails
  );

  const Submit = async (data) => {
    setLoader(true);

    await axios
      .put(
        `${ApiEndPoint.customerLinks}${CustomerAdminDetails?.customer_number}/`,
        data
      )
      .then((response) => {
        if (response?.data?.status === "success") {
          successAlert(response?.data?.message);
          setLoader(false);
          toggle();
          GetLinks();
          reset();
        } else {
          warningAlert(response?.data?.message);
          setLoader(false);
        }
      })
      .catch((error) => {
        errorAlert(error.message);
        setLoader(false);
      });
  };
  useEffect(() => {
    if (path === "edit") {
      reset({
        website: info.website,
        appointment_link: info.appointment_link,
        education_link: info.education_link,
      });
    }
  }, []);
  return (
    <>
      <Modal
        className="border-0"
        isOpen={modal}
        toggle={toggle}
        size="lg"
        centered
      >
        <ModalHeader className="bg-base-color2 ">
          {path === "edit" ? (
            <span className="title-font-700 fs-5 text-base-white">
              Edit Links
            </span>
          ) : (
            <span className="title-font-700 fs-5 text-base-white">
              Add Links
            </span>
          )}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit(Submit)} autoComplete="off">
            <div className="row p-2">
              <div className="col-md-6 p-2 d-flex flex-column">
                <label htmlFor="" className="fs-6 title-font-600 text-black">
                 Clinic Website :
                </label>
                <input
                  placeholder="Assesment Comments..."
                  className="p-2 border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="text"
                  {...register("website", { required: true })}
                />
                {errors.website && (
                  <ErrorComponents error={"this field is required*"} />
                )}
              </div>
              <div className="col-md-6 p-2 d-flex flex-column">
                <label htmlFor="" className="fs-6 title-font-600 text-black">
                  Appointment Schedule Link :
                </label>
                <input
                  placeholder="Assesment Comments..."
                  className="p-2 border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="text"
                  {...register("appointment_link", { required: true })}
                />
                {errors.appointment_link && (
                  <ErrorComponents error={"this field is required*"} />
                )}
              </div>
              <div className="col-md-6 p-2 d-flex flex-column">
                <label htmlFor="" className="fs-6 title-font-600 text-black">
                  Education Link :
                </label>
                <input
                  placeholder="Assesment Comments..."
                  className="p-2 border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="text"
                  {...register("education_link", { required: true })}
                />
                {errors.education_link && (
                  <ErrorComponents error={"this field is required*"} />
                )}
              </div>
              <div className="col-md-6 p-4 d-flex justify-content-end">
                {loader ? (
                  <button
                    type="button"
                    className="submit-btn mx-2 mt-2 align-items-center"
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      disabled
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Submiting . . .
                  </button>
                ) : (
                  <button type="submit" className=" mx-2 mt-2 submit-btn">
                    Submit
                  </button>
                )}
                <button
                  type="button"
                  onClick={() => toggle()}
                  className="mx-2 mt-2 close-btn"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
