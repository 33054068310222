import React, { useState, useEffect, useRef } from "react";
// import { Form, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useForm, Controller } from "react-hook-form";
import { Form, Label } from "reactstrap";
import ErrorComponents from "../../Components/ErrorComponents/ErrorComponents";
import { useSelector, useDispatch } from "react-redux";
import UserPool from "../../constants/UserPool";
import AWS from "aws-sdk";
import { useNavigate } from "react-router-dom";


import "moment-timezone";
import * as XLSX from "xlsx";
import axios from "axios";
import { ApiEndPoint } from "../../constants/apiEndPoints/endPoints";
import Table2 from "../../Components/Table/Table/Table2";
import QRCode from "qrcode.react";
import {
  ScreeningImageLink,
  UploadScreeningImages,
} from "../../constants/AWS/AWSConfiguration";
import { errorAlert, successAlert } from "../../Components/Alerts/Alerts";
import Swal from "sweetalert";
import "../../Components/Alerts/Alerts.css";
import { setSelectedImportStudent } from "../../redux/Slice/CollectionApp";
import ImportsModal from "../../Components/Modal/Imports/ImportsModal";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Select from 'react-select';

import moment from "moment";
import { DocumentDownload } from "iconsax-react";
import { ImportStudentExcelDemo } from "../../constants/apiEndPoints/ReportCode";
import { toast } from "react-hot-toast";
import { saveAs } from "file-saver";

const CreateStudentForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const CustomerAdminDetails = useSelector(
    (state) => state.login.loggedUserDetails
  );

  const Genders = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'na', label: 'NA' },

  ];

  const navigate = useNavigate();
  const [excelData, setExcelData] = useState([]);
  const [load, setLoad] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [originalExcelData, setOriginalExcelData] = useState([]);
  const [uploadDisabled, setUploadDisabled] = useState(false);

  const Submit = async (data) => {
    data.gender = data.gender.value;
    data.nurseid = CustomerAdminDetails?.id;
    data.type = "student";
    excelData.push(data);
    const setOri = { ...data };
    delete setOri.type;
    delete setOri.nurseid;
    originalExcelData.push(setOri);
    setIsUploading(true);
    try {
      const importData = [data];
      const datas = await getDatas(importData);

      // codee for excel and pdf starts here
      const doc = new jsPDF();
      doc.autoTable({
        html: "#my-table2",
        styles: {
          cellWidth: "wrap",
        },
        bodyStyles: { minCellHeight: "auto" },
        didDrawCell: function (data) {
          if (data.column.index === 1 && data.cell.section === "body") {
            var td = data.cell.raw;
            var img = td.getElementsByTagName("canvas")[0];
            var dim = data.cell.height - data.cell.padding("vertical");
            var textPos = data.cell;
            doc.addImage(img, textPos.x + 2, textPos.y + 2, 35, 35);
          }
        },
        theme: "grid",
        pageBreak: "auto",
        rowPageBreak: "avoid",
        columnStyles: {
          0: { cellWidth: 70, minCellHeight: 20 },
          1: { cellWidth: 40, minCellHeight: 20 },
          2: { cellWidth: 90, minCellHeight: 20 },
        },
      });

      var bloburls = doc.output("blob");
      let fileName = new Date().getTime();
      let date = moment(new Date()).format("DD_MM_YY");
      var pdfFile = new File(
        [bloburls],
        `${fileName}_${date}_${CustomerAdminDetails?.id}_${CustomerAdminDetails?.branch?.branch_admin_user_id}.pdf`
      );
      const studentQRPDF = `${ScreeningImageLink}qr_${pdfFile.name}`;
      // Generate Original Excel Sheet Code starts here
      const originalDataExcelSheet =
        XLSX.utils.json_to_sheet(originalExcelData);

      const newWorkbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        newWorkbook,
        originalDataExcelSheet,
        "FilteredSheet"
      );

      const excelBuffer = XLSX.write(newWorkbook, {
        bookType: "xlsx",
        type: "array",
      });
      const excelFile = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      var excelFiles = new File([excelFile], `student-crossreference.xlsx`, {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const excels3url = `${ScreeningImageLink}${excelFiles.name}`;

      // Generate Original Excel Sheet Code ends here
      // Generate Stored data Excel Sheet Code Starts Here
      var modifiedExcelData = excelData.map((data, index) => {
        var newObj = { ...data, studentId: index + 1 };
        delete newObj.nurseid;
        delete newObj.type;
        delete newObj.screeningId;
        delete newObj.studentid
        return newObj;
      });
      const dataExcelSheet = XLSX.utils.json_to_sheet(modifiedExcelData);

      const dataExcelSheetWrokbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        dataExcelSheetWrokbook,
        dataExcelSheet,
        "FilteredSheet"
      );

      const dataExcelBuffer = XLSX.write(dataExcelSheetWrokbook, {
        bookType: "xlsx",
        type: "array",
      });
      const dataExcelFile = new Blob([dataExcelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      var uploadedExcelFiles = new File(
        [dataExcelFile],
        `uploaded-student-data.xlsx`,
        {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }
      );
      const dataExcelFileS3url = `${ScreeningImageLink}${uploadedExcelFiles.name}`;
      // Generate Stored data Excel Sheet Code Ends Here
      await UploadScreeningImages(pdfFile, `qr_${pdfFile.name}`);
      await UploadScreeningImages(excelFiles, `${excelFiles.name}`);
      await UploadScreeningImages(
        uploadedExcelFiles,
        `${uploadedExcelFiles.name}`
      );

      // to: CustomerAdminDetails.email,
      const payload = {
        student: datas,
        to: CustomerAdminDetails.email,
        qrcode: studentQRPDF,
        firstname: `qr_${pdfFile.name}`,
        excelData: `${excelFiles.name}`,
        excels3url: excels3url,
        uploadedfilename: `${uploadedExcelFiles.name}`,
        uploadedfiles3url: dataExcelFileS3url,
      };

      // codee for excel and pdf ends here
      axios
        .post(ApiEndPoint.uploadStudentDetailsSendMailNurse, payload)
        .then((response) => {
          setUploadDisabled(true);
          if (response?.data.status === "success") {
            Swal({
              title: `Students details is saved`,
              text:
                `Unique students are ${Number(excelData.length) -
                Number(response?.data.alreadyExistsStudent.length)
                } \n` +
                `Duplicate students are ${response?.data?.alreadyExistsStudent?.length}`,
              icon: "success",
              confirmButtonText: "Close",
            });
            reset();
            setValue("gender","")
            setIsUploading(false);
          } else {
            setIsUploading(false);
          }
        })
        .catch((err) => {
          errorAlert(err.message);
          setIsUploading(false);
          if (err) {
            toast.error("An error occurred. Please try again.");
          }
        });
    } catch (error) {
      setIsUploading(false);
      console.error("Error while uploading Excel data:", error);
      if (error) {
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  const getDatas = async (excelData) => {
    const datas = [];
    for (const student of excelData) {
      const qrcode = await viewQR(student.screeningId);
      const fileName = new Date().getTime();
      const flname = `${fileName}_${student.screeningId}_${student.firstname}.jpeg`;
      const res = await fetch(qrcode);
      const buf = await res.arrayBuffer();
      const file = new File([buf], flname, { type: "image/jpeg" });
      const studentQR = `${ScreeningImageLink}qr_${flname}`;
      await UploadScreeningImages(file, `qr_${flname}`);
      const studentData = {
        ...student,
        qr: studentQR,
      };
      datas.push(studentData);
    }
    return datas;
  };

  const viewQR = (index) => {
    const canvas = document.getElementById(`${index}`);
    const pngUrl = canvas
      ?.toDataURL("image/png")
      ?.replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    return pngUrl;
  };

  return (
    <section className="px-1 px-md-2 px-lg-5 pt-2 ">
      <div className="d-flex align-items-center">
        <button
          className=" border-0 bg-transparent mb-2"
          onClick={() => navigate(-1)}
        >
          <i className="bx bx-arrow-back fs-6 title-font-500 px-2 bx-fade-left"></i>
          <span className="fs-6 base-font-500">Back</span>{" "}
        </button>
      </div>
      <div className="px-2 py-3 p-md-4  shadow-lg mt-1 border-radius-3">
        <Form onSubmit={handleSubmit(Submit)} autoComplete="off">
          <div className="col-11 mx-auto mx-md-0 pb-3 pt-1 text-center text-md-start">
            <label className="title-font-600 mb-0 fs-5  text-uppercase border-bottom-color2-2">
              Create Student
            </label>
          </div>
          <div className="row">
            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                District
              </Label>
              <div className="mt-1  d-flex gap-3">
                <input
                  className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="text"
                  placeholder="District Name..."
                  {...register("district", { required: true })}
                />
              </div>
              {errors.district && (
                <ErrorComponents error={"District is required *"} />
              )}
            </div>
            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                School
              </Label>

              <div className="mt-1  d-flex gap-3">
                <input
                  className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="text"
                  placeholder="School Name..."
                  {...register("school", { required: true })}
                />
              </div>
              {errors.school && (
                <ErrorComponents error={"School is required *"} />
              )}
            </div>

            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                Grade
              </Label>

              <div className="mt-1  d-flex gap-3">
                <input
                  className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="text"
                  placeholder="Grade ..."
                  {...register("grade", { required: true })}
                />
              </div>
              {errors.grade && (
                <ErrorComponents error={"Grade is required *"} />
              )}
            </div>


            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                Class Teacher
              </Label>

              <div className="mt-1  d-flex gap-3">
                <input
                  className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="text"
                  placeholder="Class Teacher..."
                  {...register("classteacher", { required: true })}
                />
              </div>
              {errors.classteacher && (
                <ErrorComponents error={"Class Teacher Name is required *"} />
              )}
            </div>
            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                First Name
              </Label>

              <div className="mt-1  d-flex gap-3">
                <input
                  className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="text"
                  max={3}
                  maxLength={3}
                  placeholder="First Name..."
                  {...register("firstname", { required: true })}
                />
              </div>
              {errors.firstname && (
                <ErrorComponents error={"First Name is required *"} />
              )}
            </div>
            <div className='col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1'>
              <label htmlFor="type" className='fs-6 title-font-600 mb-0 text-black'>
                Gender
              </label>
              <Controller
                control={control}
                name="gender"
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <Select options={Genders} value={value} onChange={onChange} placeholder="Select Gender" className='border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input ' />
                )}
              />
              {errors.gender && (
                <ErrorComponents error={"Please select gender *"} />
              )}
            </div>
            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                Student Id
              </Label>

              <div className="mt-1  d-flex gap-3">
                <input
                  className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="number"
                  autoComplete="nope"
                  placeholder="Student Id..."
                  {...register("screeningId", { required: true })}
                />
              </div>
              {errors.screeningId && (
                <ErrorComponents error={"Student Id is required *"} />
              )}
            </div>
            <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-6 col-xl-6 pt-1 pb-1">
              <Label className="fs-6 title-font-600 mb-0 text-black">
                Email
              </Label>

              <div className="mt-1  d-flex gap-3">
                <input
                  className="p-2  border-radius-10 shadow-none  w-100 fs-6 title-font-500  modal-input"
                  type="email"
                  autoComplete="nope"
                  placeholder="Email..."
                  {...register("guardianemail1", { required: true })}
                />
              </div>
              {errors.guardianemail1 && (
                <ErrorComponents error={"Email is required *"} />
              )}
            </div>

            <div className="d-flex align-items-center justify-content-center justify-content-md-end  gap-3 pt-3">
              <div className="d-flex align-items-center gap-3">
                {isUploading ? (
                  <button
                    type="button"
                    className="submit-btn d-flex gap-2 align-items-center"
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      disabled
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Creating . . .
                  </button>
                ) : (
                  <button type="submit" className="submit-btn">
                    Submit
                  </button>
                )}
                <button
                  type="button"
                  className="close-btn"
                  onClick={() => navigate("/")}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Form>
        <div className="row w-100 justify-content-center d-none">
          <table id="my-table2" className="row w-100 justify-content-center">
            <tbody>
              {excelData?.map((tableData, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="card-body text-left">
                        <h4>Dear Parents,</h4>
                        <br />
                        <br />
                        <p className="card-text">
                          Please scan the QR Code and take 3 pictures of your
                          childs teeth as shown in the video and submit.
                        </p>
                        <br />
                        <br />
                        <p className="card-text">
                          <a
                            href="#"
                            className="stretched-link text-danger"
                            style={{ position: "relative" }}
                          >
                            https://www.youtube.com/watch?v=y6l3FSt0DFs
                          </a>
                        </p>
                      </div>
                    </td>
                    <td>
                      <QRCode
                        id={`${tableData.screeningId}`}
                        value={`${tableData.school};${tableData.grade};${tableData.classteacher};${tableData.screeningId}`}
                        size={200}
                        includeMargin={true}
                        className="card-img-top"
                      />
                    </td>
                    <td>
                      <div className="card-body text-left">
                        <h6 className="card-subtitle mb-2 text-muted">
                          School : {tableData.school} <br />
                        </h6>
                        <h6 className="card-subtitle mb-2 text-muted">
                          Grade : {tableData.grade}
                          {tableData.grade == 1 ? (
                            <sup>st</sup>
                          ) : tableData.grade == 2 ? (
                            <sup>nd</sup>
                          ) : tableData.grade == 3 ? (
                            <sup>rd</sup>
                          ) : (
                            <sup>th</sup>
                          )}
                          <br />
                        </h6>
                        <h6 className="card-subtitle mb-2 text-muted">
                          Class Teacher : {tableData.classteacher} <br />
                        </h6>
                        <h6 className="card-subtitle mb-2 text-muted">
                          Student Id : {tableData.screeningId} <br />
                        </h6>
                        <h6 className="card-subtitle mb-2 text-muted">
                          First Name : {tableData.firstname} <br />
                        </h6>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default CreateStudentForm