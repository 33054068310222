import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import CreateBranch from "../pages/CustomerAdmin/CreateBranch";
import Navbar from "../Components/Navbar/Navbar";
import { Profile } from "iconsax-react";
import EmailVerification from "../pages/Verification/EmailVerification";
import ScrollToTop from "../Helper/ScrollToTop";
import ImportDistrict from "../pages/CommunityScreening/ImportDistrict";
import Dashboard from "../pages/CommunityScreening/Dashboard";
import ImportEvaluator from "../pages/CommunityScreening/ImportEvaluator";
import Reports from "../pages/CommunityScreening/Reports";
import CreateScreener from "../pages/BranchAdmin/CreateScreener";
import CreateDistrictAndNurse from "../pages/CommunityScreening/CreateDistrictAndNurse";
import { AppointmentTableBranch } from "../Components/CalendarDashboard/AppointmentTableBranch";

const CommunityScreeningRoutes = () => {

  const routes = [
    {
      route: [
        {
          url: "/",
          name: "Dashboard",
        },
        {
          url: "/import",
          name: "Import Districts & Nurses",
          subRoutes: [
            {
              url: "/import-district",
              name: "Import Districts",
            },
            {
              url: "/create-district-nurse",
              name: "Create District And Nurse",
            },
          ],
        },
        {
          url: "/import-evaluator",
          name: "Create Evaluator",
        },
        {
          url: "/AppointmentsList",
          name: "Appointments List"
        },
      ],
    },
    {
      button: [
        {
          url: "/",
          name: "logout",
          icon: <Profile size="20" />,
        },
      ],
    },
  ];
  



  return (
    <>
      <Navbar routes={routes} />
      <ScrollToTop>
        <div
          className="position-relative  w-100 vh-100 container-xxl"
          style={{ top: "92px" }}
        >
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/import-district" element={<ImportDistrict />} />
            <Route path="/import-evaluator" element={<CreateScreener />} />
            <Route path="/create-district-nurse" element={<CreateDistrictAndNurse />} />
            <Route path="/reports" element={<Reports />} />
            <Route
              path="/verify-email"
              element={<EmailVerification redirectURL={"/"} />}
            />
            <Route path="/AppointmentsList" element={<AppointmentTableBranch/>} />
          </Routes>
        </div>
      </ScrollToTop>
    </>
  );
};

export default CommunityScreeningRoutes;
