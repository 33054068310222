import React, { useState } from "react";
import { FormGroup, Input } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { CognitoUser } from "amazon-cognito-identity-js";
import UserPool from "../../constants/UserPool";
import { NavLink, useNavigate } from "react-router-dom";
import { SmsNotification } from "iconsax-react";
import ErrorComponents from "../../Components/ErrorComponents/ErrorComponents";
import { errorAlert, successAlert } from "../../Components/Alerts/Alerts";
import Navbar from "../../Components/Navbar/Navbar";
import { Eye, EyeSlash, Lock } from "iconsax-react";
import { toast } from "react-hot-toast";

const ForgetPassword = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [resetStep, setResetStep] = useState(1);
  const [username1, setUsername1] = useState("");
  const [PasswordToggle, setPasswordToggle] = useState(false);

  const routes = [
    {
      route: [
        {
          url: "/help",
          name: "Help",
        },
      ],
    },
  ];

  const onSubmit = async (data) => {

    setUsername1(data.username);

    const userData = {
      Username: data.username,
      Pool: UserPool,
    };

    const cognitoUser = new CognitoUser(userData);

    try {
      cognitoUser.forgotPassword({
        onSuccess: function (result) {
          successAlert("Code Sended To Your Email Please Check ");
          setResetStep(2);
          reset({
            username: "",
            verificationCode: "",
          });
        },
        onFailure: function (error) {
          errorAlert(error.message);
        },
      });
    } catch (error) {
      toast.error("An error occurred. Please try again.")
    }
  };

  const onSubmitCode = async (data) => {
    const userData = {
      Username: username1,
      Pool: UserPool,
    };

    const cognitoUser = new CognitoUser(userData);

    try {
      cognitoUser.confirmPassword(data.verificationCode, data.newPassword, {
        onSuccess: function (result) {
          successAlert("Password Reset Successfully");
          navigate("/");
        },
        onFailure: function (error) {
          errorAlert(error.message);
        },
      });
    } catch (err) {
      toast.error("An error occurred. Please try again.")
    }
  };
  return (
    <>
      <Navbar routes={routes} />
      <section className="d-flex justify-content-center align-items-center py-6 ">
        <div className="container py-5 h-100 ">
          <div className="row d-flex justify-content-center align-items-center h-100 ">
            <div className="col col-xl-10 ">
              <div className="card border-0 rounded-4 bg-white shadow-lg p-2 bg-white">
                <div className="d-md-flex g-0 p-md-2  justify-content-md-center align-items-md-center">
                  <div className="col-md-6 col-lg-8 d-flex align-items-center">
                    <div className="card-body p-2 p-lg-4 text-black">
                      {resetStep === 1 ? (
                        <form
                          onSubmit={handleSubmit(onSubmit)}
                          autoComplete="off"
                        >
                          <div className="d-flex align-items-center">
                            <span className="fs-2 title-font-800  mb-0">
                              Reset{" "}
                              <span className="text-base-color2">Password</span>
                            </span>
                          </div>
                          <FormGroup>
                            <label
                              htmlFor="email"
                              className="m-0 fs-6  title-font-600"
                            >
                              Username
                            </label>
                            <Controller
                              control={control}
                              name="username"
                              rules={{
                                required: true,
                              }}
                              render={({ field: { onChange, value } }) => (
                                <div className="d-flex align-items-center rounded-3 base-white-light p-1 px-2">
                                  <div className="bg-white p-1 rounded-3">
                                    <SmsNotification
                                      size="25"
                                      className="text-base-color"
                                    />
                                  </div>
                                  <Input
                                    type="text"
                                    invalid={errors.username}
                                    valid={errors.username}
                                    className="border-0 shadow-none bg-transparent title-font-500"
                                    id="email"
                                    placeholder="Please enter user name."
                                    autoComplete="nope"
                                    onChange={onChange}
                                    value={value}
                                  />
                                </div>
                              )}
                            />
                            {errors.username?.type === "required" && (
                              <ErrorComponents
                                error={"User name is required *"}
                              />
                            )}
                          </FormGroup>
                          <FormGroup>
                            <div className="row">
                              <div className="col-sm-6 pb-3">
                                <NavLink
                                  to="/login"
                                  className="fs-6  text-base-gray-light base-font-500 text-decoration-underline"
                                >
                                  Back to Sign In
                                </NavLink>
                              </div>
                              <div className="col-sm-6">
                                {load ? (
                                  <button
                                    className="fw-bold btn fs-5 bg-base-color border-0 w-100 rounded-3 d-flex gap-2 align-items-center justify-content-center text-white title-font-bold bg-base-color text-uppercase"
                                    type="button"
                                    disabled
                                  >
                                    <span
                                      className="spinner-border spinner-border-sm" disabled
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    Sending . . .
                                  </button>
                                ) : (
                                  <button className=" fw-bold btn fs-5 bg-base-color border-0 w-100 rounded-3 text-white title-font-bold text-uppercase ">
                                    Send Code
                                  </button>
                                )}
                              </div>
                            </div>
                          </FormGroup>
                        </form>
                      ) : (
                        <form
                          onSubmit={handleSubmit(onSubmitCode)}
                          autoComplete="off"
                        >
                          <div className="d-flex align-items-center">
                            <span className="fs-2 title-font-800  mb-0">
                              Reset{" "}
                              <span className="text-base-color2">Password</span>
                            </span>
                          </div>

                          <FormGroup>
                            <label
                              htmlFor="verificationCode"
                              className="m-0 fs-6  title-font-600"
                            >
                              Verification code
                            </label>

                            <Controller
                              control={control}
                              name="verificationCode"
                              rules={{
                                required: true,
                              }}
                              render={({ field: { onChange, value } }) => (
                                <div className="d-flex align-items-center rounded-3 base-white-light p-1 px-2">
                                  <Input
                                    type="text"
                                    invalid={errors.verificationCode}
                                    valid={errors.verificationCode}
                                    className="border-0 shadow-none bg-transparent title-font-500"
                                    id="verificationCode"
                                    placeholder="Please enter verification code."
                                    autoComplete="off"
                                    onChange={onChange}
                                    value={value}
                                  />
                                </div>
                              )}
                            />
                            {errors.verificationCode?.type === "required" && (
                              <ErrorComponents
                                error={"Verification Code is required *"}
                              />
                            )}
                          </FormGroup>

                          <FormGroup>
                            <label
                              htmlFor="email"
                              className="m-0 fs-6  title-font-600"
                            >
                              New Password
                            </label>

                            <Controller
                              control={control}
                              name="newPassword"
                              rules={{
                                required: true,
                              }}
                              render={({ field: { onChange, value } }) => (
                                <div className="d-flex align-items-center rounded-3 base-white-light p-1 px-2">
                                  <Input
                                    type={PasswordToggle ? "text" : "password"}
                                    invalid={errors.newPassword}
                                    valid={errors.newPassword}
                                    className="border-0 shadow-none bg-transparent title-font-500"
                                    id="email"
                                    placeholder="Please enter new password."
                                    autoComplete="nope"
                                    onChange={onChange}
                                    value={value}
                                  />

                                  <button
                                    type="button"
                                    className="bg-white border-0 shadow-none p-1 rounded-3"
                                    onClick={() =>
                                      setPasswordToggle(!PasswordToggle)
                                    }
                                  >
                                    {PasswordToggle ? (
                                      <Eye
                                        size="25"
                                        className="text-base-color"
                                      />
                                    ) : (
                                      <EyeSlash
                                        size="25"
                                        className="text-base-color"
                                      />
                                    )}
                                  </button>
                                </div>
                              )}
                            />
                            {errors.newPassword?.type === "required" && (
                              <ErrorComponents
                                error={"Password is required *"}
                              />
                            )}
                          </FormGroup>
                          <FormGroup>
                            <div className="row">
                              <div className="col-sm-6 pb-3">
                                <NavLink
                                  to="/login"
                                  className="fs-6  text-base-gray-light base-font-500 text-decoration-underline"
                                >
                                  Back to Sign In
                                </NavLink>
                              </div>
                              <div className="col-sm-6">
                                <button className=" fw-bold btn fs-5 bg-base-color border-0 w-100 rounded-3 text-white title-font-bold text-uppercase ">
                                  Submit
                                </button>
                              </div>
                            </div>
                          </FormGroup>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgetPassword;
