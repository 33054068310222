import React, { useState, useEffect } from 'react'
import Table from '../../Components/Table/Table/Table';
import ProfileHeader from '../../Components/ProfileHeader/ProfileHeader';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { loggedUserDetails } from '../../redux/Slice/LoginSlice';
import { ApiEndPoint } from '../../constants/apiEndPoints/endPoints';
import BranchAdminEdit from '../../Components/Modal/BranchAdminModals/BranchAdminEdit';
import CustomerProfileEdit from '../../Components/Modal/CustomerAdminModals/CustomerProfileEdit';
import ProfileLoader from '../../Components/ProfileHeader/ProfileLoader';
import { customerProfile, setCustomerBranch } from '../../redux/Slice/CustomerSlice';
import EmptyTable from '../../Components/Table/EmptyTable';
import { toast } from 'react-hot-toast';

const Dashboard = () => {
    const dispatch = useDispatch();
    const username = useSelector((state) => state.login.username);
    const CustomerAdminDetails = useSelector((state) => state.login.loggedUserDetails);
    const customerProfileDetails = useSelector((state) => state.customer.customerProfileDetails);
    const customerBranchLists = useSelector((state) => state.customer.customerBranchList);

    console.log('customerBranchLists==>',customerBranchLists)


    const [Loader, setLoader] = useState(false);

    const GetCustomerBranch = () => {
        const CustomerBranchListURL = `${ApiEndPoint.customerBranchList}${CustomerAdminDetails?.id}/`;
        axios.get(CustomerBranchListURL).then((response) => {
            dispatch(setCustomerBranch(response?.data?.branchList));
        }).catch((e) => {
            if (e) {
                toast.error("An error occurred. Please try again.")
            }
        });
    };

    const GetUserDetails = async () => {
        // const url = `https://utrer78zf2.execute-api.us-east-1.amazonaws.com/devapi/customer-detail/${username}/`;
        const url = `${ApiEndPoint.customerDetails}${username}/`;
        await axios.get(url).then((response) => {
            dispatch(loggedUserDetails(response.data.customerdetails));
            dispatch(customerProfile(response.data.customerdetails));
            setLoader(false)
        }).catch((e) => {
            if (e) {
                toast.error("An error occurred. Please try again.")
            }
        });
    };

    useEffect(() => {
        setLoader(true)
        if (username) {
            GetUserDetails();
        };
    }, [username]);

    useEffect(() => {
        if (CustomerAdminDetails?.id) {
            GetCustomerBranch();
        };
    }, [CustomerAdminDetails]);

    const columns = [
        {
            name: '#',
            cell: (row, index) => index + 1
        },
        {
            name: 'Branch Name',
            selector: row => row.name,
            sortable: true,
            wrap: true,
        },
        {
            name: 'User Name',
            selector: row => row.branch_admin_user_id,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Address',
            selector: row => row.street_address,
            sortable: true,
            wrap: true,
        },
        {
            name: 'City',
            selector: row => row.city,
            sortable: true,
            wrap: true,
        },
        {
            name: 'State',
            selector: row => row.state,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Zip',
            selector: row => row.zip,
            sortable: true,
            wrap: true,
        },
        {
            name: 'Action',
            selector: (row) => <div className="d-flex justify-content-between align-items-center gap-2 ">
                <BranchAdminEdit info={row} />
            </div>,
        },
    ];


    return (
        <>
            <section className='m-2 m-md-3 d-flex gap-4 justify-content-between flex-column'>
                {Loader ? <ProfileLoader /> : <ProfileHeader
                    profilePic={customerProfileDetails?.logo ? customerProfileDetails?.logo : "https://i.pinimg.com/564x/1f/a9/fc/1fa9fc02ab8e2acab10bc6ca19016039.jpg"}
                    name={`${customerProfileDetails?.name}`}
                    email={customerProfileDetails?.email}
                    contact={customerProfileDetails?.phone_number}
                    address={customerProfileDetails?.street_address ? customerProfileDetails?.street_address : ''}
                    city={customerProfileDetails?.city ? customerProfileDetails?.city : ''}
                    state={customerProfileDetails?.state ? customerProfileDetails?.state : ''}
                    zip={customerProfileDetails?.zip ? customerProfileDetails?.zip : ''}
                    editModal={<CustomerProfileEdit data={customerProfileDetails} />}
                    userName={customerProfileDetails?.customer_admin_user_id}
                />}
                <div className="bg-white p-3 p-md-4  mb-4 shadow-lg border-radius-3">
                    <div className="">
                        <h4 className='title-font-bold fs-4 text-base-color text-center text-md-start pb-2'>List of Branches</h4>
                        {customerBranchLists && customerBranchLists.length > 0 ? <Table columns={columns} data={customerBranchLists} /> : <EmptyTable />}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Dashboard