import React, { useState, useEffect } from "react";
import Table from "../../Components/Table/Table/Table";
import ProfileHeader from "../../Components/ProfileHeader/ProfileHeader";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { loggedUserDetails } from "../../redux/Slice/LoginSlice";
import { ApiEndPoint } from "../../constants/apiEndPoints/endPoints";
import BranchAdminEdit from "../../Components/Modal/BranchAdminModals/BranchAdminEdit";
import CustomerProfileEdit from "../../Components/Modal/CustomerAdminModals/CustomerProfileEdit";
import ProfileLoader from "../../Components/ProfileHeader/ProfileLoader";
import {
  customerProfile,
  setCustomerBranch,
} from "../../redux/Slice/CustomerSlice";
import { setNurseList, setScreenerList } from "../../redux/Slice/BranchSlice";
import EvaluatorEditModal from "../../Components/Modal/BranchAdminModals/EvaluatorEditModal";
import NurseEdit from "../../Components/Modal/NurseModals/NurseEdit";
import EmptyTable from "../../Components/Table/EmptyTable";
import { toast } from "react-hot-toast";
import { async } from "q";
import "../../css/Table.css";
import NurseEditModal from "../../Components/Modal/BranchAdminModals/NurseEditModal";

const Dashboard = () => {
  const dispatch = useDispatch();
  const username = useSelector((state) => state.login.username);
  const CustomerAdminDetails = useSelector(
    (state) => state.login.loggedUserDetails
  );
  const customerProfileDetails = useSelector(
    (state) => state.customer.customerProfileDetails
  );

  const customerBranchLists = useSelector(
    (state) => state.customer.customerBranchList
  );
  const screenerList = useSelector((state) => state.branch.branchScreenerList);
  const nurseList = useSelector((state) => state.branch.branchNurseList);
  const [toggle, setToggle] = useState(false);
  const [districtCount, setDistrictCount] = useState([]);
  const [schoolCount, setSchoolCount] = useState([]);

  const [Loader, setLoader] = useState(false);

  const CreateDefaultBranch = () => {
    const payload = {
      customer_id: CustomerAdminDetails.id,
      branch_name: `Default District`,
      district: `Default${CustomerAdminDetails.id}`,
    };
    axios
      .post(ApiEndPoint.createCustomerDefaultBranch, payload)
      .then((response) => {
        if (response.data.status === "success") {
          toast.success("Create Default Branch.");
        } else {
          toast.error("Something went wrong.");
        }
      });
  };
  const getColumnTotal = (column) => {
    return districtCount.reduce((acc, item) => acc + item[column], 0);
  };
  const getColumnTotal1 = (column) => {
    return schoolCount.reduce((acc, item) => acc + item[column], 0);
  };

  const CheckDefaultBranch = async () => {
    try {
      const result = await axios
        .get(
          `${ApiEndPoint.checkCustomerDefaultBranch}${CustomerAdminDetails.id}/`
        )
        .catch((err) => toast.error(err.message));
      if (result.data.status === "success") {
        if (result.data.default_branch_count <= 0) {
          CreateDefaultBranch();
        }
      } else {
        toast.error("An error occurred. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again11111.");
    }
  };

  useEffect(() => {
    if (CustomerAdminDetails) {
      CheckDefaultBranch();
    }
  }, [CustomerAdminDetails]);

  const GetCustomerBranch = () => {
    if (CustomerAdminDetails) {
      const CustomerBranchListURL = `${ApiEndPoint.customerBranchList}${CustomerAdminDetails?.id}/`;
      axios
        .get(CustomerBranchListURL)
        .then((response) => {
          dispatch(setCustomerBranch(response?.data?.branchList));
        })
        .catch((e) => {
          if (e) {
            toast.error("An error occurred. Please try again.");
          }
        });
    }
  };

  const GetScreenerList = () => {
    if (CustomerAdminDetails) {
      const branchScreenerListURL = `${ApiEndPoint.communityEvaluatorList}${CustomerAdminDetails?.id}/`;
      axios
        .get(branchScreenerListURL)
        .then((response) => {
          dispatch(setScreenerList(response?.data?.screenerList));
        })
        .catch((e) => {
          if (e) {
            toast.error("An error occurred. Please try again.");
          }
        });
    }
  };

  const GetNurseList = () => {
    if (CustomerAdminDetails) {
      const branchNurseListURL = `${ApiEndPoint.communityNurseList}${CustomerAdminDetails?.id}/`;
      axios
        .get(branchNurseListURL)
        .then((response) => {
          dispatch(setNurseList(response?.data?.nurseList));
        })
        .catch((e) => {
          if (e) {
            toast.error("An error occurred. Please try again.");
          }
        });
    }
  };

  const GetUserDetails = async () => {
    const url = `${ApiEndPoint.customerDetails}${username}/`;
    await axios
      .get(url)
      .then((response) => {
        dispatch(loggedUserDetails(response.data.customerdetails));
        dispatch(customerProfile(response.data.customerdetails));
        setLoader(false);
      })
      .catch((e) => {
        if (e) {
          toast.error("An error occurred. Please try again.");
        }
      });
  };

  const GetDistrictCountList = async () => {
    const url = `${ApiEndPoint.communityStudentCountDistrict}${CustomerAdminDetails?.id}`;
    await axios.get(url).then((res) => {
      setDistrictCount(res.data.countData);
    });
  };

  const GetSchoolCountList = async () => {
    const url = `${ApiEndPoint.communityStudentCountSchool}${CustomerAdminDetails?.id}`;
    await axios.get(url).then((res) => {
      setSchoolCount(res.data.countData);
    });
  };

  useEffect(() => {
    setLoader(true);
    if (username) {
      GetUserDetails();
    }
  }, [username]);

  useEffect(() => {
    if (CustomerAdminDetails) {
      GetCustomerBranch();
      GetScreenerList();
      GetNurseList();
      GetDistrictCountList();
      GetSchoolCountList();
    }
  }, [CustomerAdminDetails]);

  const columns = [
    {
      name: "#",
      wrap: true,
      cell: (row, index) => index + 1,
    },
    {
      name: "District Name",
      wrap: true,
      selector: (row) => row.branch?.name,
      sortable: true,
    },
    {
      name: "First Name",
      wrap: true,
      selector: (row) => row.fname,
      sortable: true,
    },
    {
      name: "Last Name",
      wrap: true,
      selector: (row) => row.lname,
      sortable: true,
    },
    {
      name: "User Name",
      wrap: true,
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "Email",
      wrap: true,
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex justify-content-between align-items-center gap-2 ">
          <NurseEditModal
            data={row}
            button="row"
            setToggle={setToggle}
            login="community"
          />
        </div>
      ),
    },
  ];

  const columnss = [
    {
      name: "Sr No",
      wrap: true,
      cell: (_, index) => index + 1,
    },
    {
      name: "First Name",
      selector: (row) => row.fname,
      sortable: true,
      wrap: true,
    },
    {
      name: "Last Name",
      selector: (row) => row.lname,
      sortable: true,
      wrap: true,
    },
    // {
    //     name: 'Address',
    //     selector: row => row.address,
    //     sortable: true,
    //  wrap: true,
    // },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      wrap: true,
    },
    {
      name: "User Name",
      selector: (row) => row.username,
      sortable: true,
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex justify-content-between align-items-center gap-2 ">
          <EvaluatorEditModal
            data={row}
            button="row"
            setToggle={setToggle}
            login="community"
          />
        </div>
      ),
    },
  ];


  return (
    <>
      <section className="m-2 m-md-3 d-flex gap-4 justify-content-between flex-column">
        {Loader ? (
          <ProfileLoader />
        ) : (
          <ProfileHeader
            profilePic={
              customerProfileDetails?.logo
                ? customerProfileDetails?.logo
                : "https://i.pinimg.com/564x/1f/a9/fc/1fa9fc02ab8e2acab10bc6ca19016039.jpg"
            }
            name={`${customerProfileDetails?.name}`}
            email={customerProfileDetails?.email}
            contact={customerProfileDetails?.phone_number}
            address={
              customerProfileDetails?.street_address
                ? customerProfileDetails?.street_address
                : ""
            }
            city={
              customerProfileDetails?.city ? customerProfileDetails?.city : ""
            }
            state={
              customerProfileDetails?.state ? customerProfileDetails?.state : ""
            }
            zip={customerProfileDetails?.zip ? customerProfileDetails?.zip : ""}
            editModal={<CustomerProfileEdit data={customerProfileDetails} />}
            userName={customerProfileDetails?.customer_admin_user_id}
          />
        )}
        <div className="bg-white p-3 p-md-4  mb-4 shadow-lg border-radius-3">
          <div className="">
            <h4 className="title-font-bold fs-4 text-base-color text-center text-md-start pb-2">
              List of Districts & Nurses
            </h4>
            {nurseList && nurseList.length > 0 ? (
              <Table columns={columns} data={nurseList} />
            ) : (
              <EmptyTable />
            )}
          </div>
        </div>
        <div className="bg-white p-3 p-md-4  mb-4 shadow-lg border-radius-3">
          <div className="">
            <h4 className="title-font-bold fs-4 text-base-color text-center text-md-start pb-2">
              List of Evaluators
            </h4>
            {screenerList && screenerList.length > 0 ? (
              <Table columns={columnss} data={screenerList} />
            ) : (
              <EmptyTable />
            )}
          </div>
        </div>


        <div className="bg-white p-3 p-md-4  mb-4 shadow-lg border-radius-3">
          <div className="">
            <h4 className="title-font-bold fs-4 text-base-color text-center text-md-start pb-2">
              Screening Count by District
            </h4>
            <div className="table-responsive">
              <table className="table table-bordered table-light text-center">
                <thead>
                  <tr className="border-0">
                    <th className=" text-white border-end-2 custom_table_color rounded-top-4 rounded-end-0 "></th>
                    <th
                      className=" text-white border-end-2 custom_table_color"
                      colSpan={5}
                    >
                      Student Counts by Screening Status
                    </th>
                    <th
                      className=" text-white custom_table_color rounded-top-4 rounded-start-0"
                      colSpan={4}
                    >
                      Student Counts by Screening Results
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th className="bg-white">District</th>
                    <th className="bg-white">Total</th>
                    <th className="bg-white">New</th>
                    <th className="bg-white">Screened</th>
                    <th className="bg-white">Completed</th>
                    <th className="bg-white">Reported</th>
                    <th className="bg-white">Code 1</th>
                    <th className="bg-white">Code 2</th>
                    <th className="bg-white">Code 3</th>
                    <th className="bg-white">Code 4</th>
                  </tr>
                  {districtCount.map((data, i) => (
                    <tr key={i}>
                      <td className="bg-white">{data.branch_name}</td>
                      <td className="bg-white">{data.total}</td>
                      <td className="bg-white">{data.new}</td>
                      <td className="bg-white">{data.screened}</td>
                      <td className="bg-white">{data.completed}</td>
                      <td className="bg-white">{data.reported}</td>
                      <td className="bg-white">{data.code_1}</td>
                      <td className="bg-white">{data.code_2}</td>
                      <td className="bg-white">{data.code_3}</td>
                      <td className="bg-white">{data.code_4}</td>
                    </tr>
                  ))}
                </tbody>
                  <tfoot className="text-center">
                    <tr>
                      <td
                        className="base-font-700 fs-6 text-black table-light"
                      >
                        Total
                      </td>
                      <td className="base-font-700 fs-6 text-black table-light">
                        {getColumnTotal("total")}
                      </td>
                      <td className="base-font-700 fs-6 text-black table-light">
                        {getColumnTotal("new")}
                      </td>
                      <td className="base-font-700 fs-6 text-black table-light">
                        {getColumnTotal("screened")}
                      </td>
                      <td className="base-font-700 fs-6 text-black table-light">
                        {getColumnTotal("completed")}
                      </td>
                      <td className="base-font-700 fs-6 text-black table-light">
                        {getColumnTotal("reported")}
                      </td>
                      <td className="base-font-700 fs-6 text-black table-light">
                        {getColumnTotal("code_1")}
                      </td>
                      <td className="base-font-700 fs-6 text-black table-light">
                        {getColumnTotal("code_2")}
                      </td>
                      <td className="base-font-700 fs-6 text-black table-light">
                        {getColumnTotal("code_3")}
                      </td>
                      <td className="base-font-700 fs-6 text-black table-light">
                        {getColumnTotal("code_4")}
                      </td>
                    </tr>
                  </tfoot>
              </table>
            </div>
          </div>
        </div>

        <div className="bg-white p-3 p-md-4  mb-4 shadow-lg border-radius-3">
          <div className="">
            <h4 className="title-font-bold fs-4 text-base-color text-center text-md-start pb-2">
              Screening Count by School
            </h4>
            <div className="table-responsive">
              <table className="table table-bordered table-light text-center">
                <thead>
                  <tr className="border-0">
                    <th className=" text-white border-end-2 custom_table_color rounded-top-4 rounded-end-0" colSpan={2}></th>
                    <th
                      className=" text-white border-end-2 custom_table_color"
                      colSpan={5}
                    >
                      Student Counts by Screening Status
                    </th>
                    <th
                      className=" text-white custom_table_color rounded-top-4 rounded-start-0"
                      colSpan={4}
                    >
                      Student Counts by Screening Results
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th className="bg-white">District</th>
                    <th className="bg-white">School</th>
                    <th className="bg-white">Total</th>
                    <th className="bg-white">New</th>
                    <th className="bg-white">Screened</th>
                    <th className="bg-white">Completed</th>
                    <th className="bg-white">Reported</th>
                    <th className="bg-white">Code 1</th>
                    <th className="bg-white">Code 2</th>
                    <th className="bg-white">Code 3</th>
                    <th className="bg-white">Code 4</th>
                  </tr>
                  {schoolCount.map((data, i) => (
                    <tr key={i}>
                      <td className="bg-white">{data.branch_name}</td>
                      <td className="bg-white">{data.school}</td>
                      <td className="bg-white">{data.total}</td>
                      <td className="bg-white">{data.new}</td>
                      <td className="bg-white">{data.screened}</td>
                      <td className="bg-white">{data.completed}</td>
                      <td className="bg-white">{data.reported}</td>
                      <td className="bg-white">{data.code_1}</td>
                      <td className="bg-white">{data.code_2}</td>
                      <td className="bg-white">{data.code_3}</td>
                      <td className="bg-white">{data.code_4}</td>
                    </tr>
                  ))}
                </tbody>
                  <tfoot className="text-center">
                    <tr>
                      <td
                        className="base-font-700 fs-6 text-black table-light"
                        colSpan={2}
                      >
                        Total
                      </td>

                      <td className="base-font-700 fs-6 text-black table-light">
                        {getColumnTotal1("total")}
                      </td>
                      <td className="base-font-700 fs-6 text-black table-light">
                        {getColumnTotal1("new")}
                      </td>
                      <td className="base-font-700 fs-6 text-black table-light">
                        {getColumnTotal1("screened")}
                      </td>
                      <td className="base-font-700 fs-6 text-black table-light">
                        {getColumnTotal1("completed")}
                      </td>
                      <td className="base-font-700 fs-6 text-black table-light">
                        {getColumnTotal1("reported")}
                      </td>
                      <td className="base-font-700 fs-6 text-black table-light">
                        {getColumnTotal1("code_1")}
                      </td>
                      <td className="base-font-700 fs-6 text-black table-light">
                        {getColumnTotal1("code_2")}
                      </td>
                      <td className="base-font-700 fs-6 text-black table-light">
                        {getColumnTotal1("code_3")}
                      </td>
                      <td className="base-font-700 fs-6 text-black table-light">
                        {getColumnTotal1("code_4")}
                      </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
