import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { ApiEndPoint } from "../../constants/apiEndPoints/endPoints";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Select from "react-select";
import { Form, Modal, ModalBody } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { errorAlert, successAlert } from "../Alerts/Alerts";
import { reportCodeStudent } from "../../constants/apiEndPoints/ReportCode";
import {
  notificationShow,
  setPatientLists,
  setUnFilteredPatientLists,
} from "../../redux/Slice/EvaluatorSlice";
import {
  ArrowDown2,
  ArrowUp2,
  ArrowLeft,
  ArrowRight,
  ArrowSquareLeft,
  ArrowSquareRight,
  ArrowLeft2,
  ArrowRight2,
} from "iconsax-react";
import moment from "moment";
import { toast } from "react-hot-toast";
import { PaginationControl } from "react-bootstrap-pagination-control";
import "bootstrap/dist/css/bootstrap.min.css";

const StudentTable = ({ evalstatus }) => {
  var continueScreening = false;
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [loaders, setLoaders] = useState(false);
  const scrollToBottom = useRef(null);
  const [modal, setModal] = useState(false);
  const [patientsList, setPatientsList] = useState([]);
  const [unFilteredPatientList, setUnFilteredPatientList] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [open, setOpen] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [selectedReason, setSelectedReason] = useState(null);
  const [dropToggle, setdropToggle] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [size, setSize] = useState(20);

  const [selectedRowIndex, setSelectedRowIndex] = useState(0);
  const scrollToTop = useRef(null);
  const tableRef = useRef(null);

  const toggle = () => setModal(!modal);
  const dropDownToggle = () => setDropdownOpen((prevState) => !prevState);

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const CustomerAdminDetails = useSelector(
    (state) => state.login.loggedUserDetails
  );
  const ScreeenerDetails = useSelector(
    (state) => state.login.loggedUserDetails
  );
  const selectedPatientFromNotification = useSelector(
    (state) => state.evaluator.selectedPatientIdFormNotification
  );
  const notificationsList = useSelector(
    (state) => state.evaluator.notificationsList
  );
  const evaluationStatus = useSelector(
    (state) => state?.evaluator?.selectedEvalStatus
  );
  const patientListData = useSelector((state) => state?.evaluator?.patientList);
  const unFilteredPatientListData = useSelector(
    (state) => state?.evaluator?.unFilteredPatientList
  );
  const selectedPatientDistrict = useSelector(
    (state) => state?.evaluator?.selectedPatientDistrict
  );

  var t_codes = watch("treatment_codes");

  const evalStatus = [
    { value: "new", label: "New" },
    { value: "screened", label: "Screened" },
    { value: "optout", label: "Optout" },
    { value: "completed", label: "Completed" },
    { value: "reported", label: "Reported" },
    { value: "incomplete", label: "Incomplete" },
  ];

  const DentalPain = [
    { value: "yes", label: "Yes", dbvalue: "true" },
    { value: "no", label: "No", dbvalue: "false" },
  ];

  const EvaluateImages = [
    {
      value: "N/A",
      label: "N/A",
      eval_status: "complete",
      isevaluate: true,
    },
    {
      value: "One Or More Images Not Clear",
      label: "One Or More Images Not Clear",
      eval_status: "incomplete",
      isevaluate: false,
    },
    {
      value: "Student Opted Out of Screening",
      label: "Student Opted Out of Screening",
      eval_status: "incomplete",
      isevaluate: false,
    },
  ];

  const TreatmentCode = [
    { value: "CODE 1", label: "CODE 1" },
    { value: "CODE 2", label: "CODE 2" },
    { value: "CODE 3", label: "CODE 3" },
    { value: "CODE 4", label: "CODE 4" },
  ];

  const patientHeader = [
    { header: "#", input: "dropdown", dbkey: "index", searchtype: "id" },
    {
      header: "Nurse Name",
      input: "dropdown",
      dbkey: "nurse_name",
      searchtype: "input",
    },
    {
      header: "District",
      input: "dropdown",
      dbkey: "hierarchy_level1",
      searchtype: "input",
    },
    {
      header: "School",
      input: "dropdown",
      dbkey: "hierarchy_level2",
      searchtype: "input",
    },
    {
      header: "Class Teacher",
      input: "dropdown",
      dbkey: "hierarchy_level3",
      searchtype: "input",
    },
    {
      header: "Grade",
      input: "dropdown",
      dbkey: "hierarchy_level4",
      searchtype: "input",
    },
    {
      header: "Screening Id",
      input: "dropdown",
      dbkey: "number",
      searchtype: "input",
    },
    {
      header: "First Name",
      input: "dropdown",
      dbkey: "first_name",
      searchtype: "input",
    },
    {
      header: "Status",
      input: "dropdown",
      dbkey: "eval_status",
      searchtype: "input",
    },
    {
      header: "Screened Date",
      input: "dropdown",
      dbkey: "screening_date",
      searchtype: "hidden",
    },
    // {
    //   header: "Gender",
    //   input: "dropdown",
    //   dbkey: "gender",
    //   searchtype: "input",
    // },
    // { header: "Screening Date", input: "dropdown", dbkey: "eval_status", searchtype: 'input' },
    // { header: "Status", input: "dropdown", dbkey: "eval_status", searchtype: 'select' },
  ];

  const handleOnSelect = (select, value) => {
    upsert({ name: select, value: value });
  };

  const handleSearch = (select, value) => {
    upsert({ name: select, value: value });
  };

  const handleSelectStatus = (selectedStatus) => {
    if (selectedStatus) {
      upsert({ name: "eval_status", value: selectedStatus.value });
    } else {
      handleOnClear("eval_status");
    }
  };

  const handleOnClear = (select) => {
    const filteredArray = filters.filter((obj) => obj.name !== select);
    setFilters(filteredArray);
  };

  const upsert = (item) => {
    const previousfilters = filters.find((_item) => _item.name === item.name);
    if (previousfilters) {
      const updatedArray = filters.map((obj) => {
        if (obj.name === item.name) {
          return { ...obj, value: item.value };
        }
        return obj;
      });
      setFilters(updatedArray);
    } else {
      setFilters([...filters, item]);
    }
  };
  const handleRowClick = (row) => {
    if (evalstatus != "new") {
      if (selectedRow && selectedRow?.id === row?.id) {
        setSelectedRow(null);
      } else {
        setValue("treatment_codes", "");
        setSelectedRow(row);
        setSelectedRowIndex(row.pid);
        setTimeout(() => {
          scrollToTop.current?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 500);
      }
    }
  };

  const handleNextClick = () => {
    if (selectedRowIndex < patientsList.length) {
      const nextRowIndex = selectedRowIndex + 1;
      if (nextRowIndex <= patientsList.length) {
        const nextRow = patientsList[nextRowIndex - 1];
        handleRowClick(nextRow);
      }
    }
  };

  const handlePreviousClick = () => {
    if (selectedRowIndex > 1) {
      const previousRowIndex = selectedRowIndex - 1;
      const previousRow = patientsList[previousRowIndex - 1];
      handleRowClick(previousRow);
    }
  };

  const GetPatientList = async (currPage, currSize) => {
    setLoaders(true);
    const payload = {
      clinic: CustomerAdminDetails?.branch?.customer?.id,
      branch:
        CustomerAdminDetails?.branch?.customer?.service_type ===
        "community_screening"
          ? Number(selectedPatientDistrict?.value)
          : CustomerAdminDetails?.branch?.id,
      evalstatus: evaluationStatus?.value,
      patient_type: "student",
    };

    await axios
      .post(
        `${ApiEndPoint.patientEvaluation}?page=${currPage}&size=${currSize}`,
        payload
      )
      .then(async (response) => {
        setTotalPage(response?.data?.count);
        const nurseWithName = await response?.data?.patientList?.map(
          (item, index) => ({
            ...item,
            pid: index + 1,
            indexNo: (currPage - 1) * currSize + index + 1,
            nurse_name: `${item?.nurse?.fname} ${item?.nurse?.lname}`,
            modified_date: item?.last_modified_date
              ? formatDate(item?.last_modified_date)
              : null,
          })
        );

        dispatch(setPatientLists(nurseWithName));
        dispatch(setUnFilteredPatientLists(nurseWithName));
        setLoaders(false);
        setPatientsList(nurseWithName);
        setUnFilteredPatientList(nurseWithName);
        if (continueScreening) {
          if (nurseWithName[0]) {
            setSelectedRow(nurseWithName[0]);
          } else {
            continueScreening = false;
          }
        } else {
          setSelectedRow(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const GetAllNotificationShowList = () => {
    const payload = {
      branch: ScreeenerDetails?.branch?.id,
      evaluator: ScreeenerDetails?.id,
    };

    axios
      .post(ApiEndPoint.screenerNotificationList, payload)
      .then((response) => {
        dispatch(notificationShow(response.data.notificationList));
      })
      .catch((err) => {
        toast.error("An error occurred. Please try again.");
      });
  };

  const ShowPatientFromNotification = async () => {
    await GetPatientList(page, size);
  };

  const handleImageZoom = (imageURL) => {
    toggle();
    setSelectedImage(imageURL);
    setOpen(!open);
  };

  const onSubmit = async (data) => {
    data.id = selectedRow.id;
    // setLoader(true);
    data.id = selectedRow.id;
    data.eval_comments = data.comment;
    data.cannot_evaluate = data?.evaluate_images?.isevaluate
      ? data?.evaluate_images?.isevaluate
      : true;
    data.eval_status = data?.evaluate_images?.eval_status
      ? data?.evaluate_images?.eval_status
      : "completed";
    data.evaluate_images = data?.evaluate_images?.value
      ? data?.evaluate_images?.value
      : "completed";
    data.sealants_present = data.sealants_present.dbvalue;
    data.treated_decay = data.treated_decay.dbvalue;
    data.untreated_decay = data.untreated_decay.dbvalue;
    data.treatment_recommendation_code = data.treatment_codes.value;
    data.treatment_codes = data.treatment_codes.value;
    data.patient_number = selectedRow.patient_number;
    data.nurse_id = selectedRow.nurse.id;
    await axios
      .post(ApiEndPoint.patientEvaluationSubmit, data)
      .then((submitResponse) => {
        if (submitResponse.data.status === "success") {
          continueScreening = true;
          successAlert(submitResponse?.data?.message);
          setLoader(false);
          setSelectedReason(null);
          GetAllNotificationShowList();
          const datafff = patientsList.map((patientData) => {
            if (patientData.id == selectedRow.id) {
              return {
                ...patientData,
                eval_status: data.eval_status,
                eval_comments: data.eval_comments,
                cannot_evaluate: data.cannot_evaluate,
                evaluate_images: data.evaluate_images,
                sealants_present: data.sealants_present,
                treated_decay: data.treated_decay,
                untreated_decay: data.untreated_decay,
                treatment_recommendation_code:
                  data.treatment_recommendation_code,
                treatment_codes: data.treatment_codes,
                patient_number: selectedRow.patient_number,
                nurse_id: selectedRow.nurse.id,
              };
            }
            return patientData;
          });
          reset();
          setPatientsList(datafff);
          setSelectedRow(datafff[selectedRow.pid]);
          dispatch(setUnFilteredPatientLists(datafff));
        } else {
          errorAlert(submitResponse?.data?.message);
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const handlePageChange = async (page) => {
    setPage(page);
    // tableRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    const navbarHeight = 100; // Replace with your actual navbar height
    const offset = tableRef.current
      ? tableRef.current.getBoundingClientRect().top -
        navbarHeight +
        window.scrollY
      : 0;
    if (tableRef.current) {
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
    await GetPatientList(page, size);
  };

  const handleSelectPageSize = (e) => {
    const selectedSize = e.target.value;
    if (selectedSize === "All") {
      // setSize(patientsList.length);
      setSize(10000);
      setPage(1);
      // GetPatientList(1, patientsList.length);
      GetPatientList(1, 10000);
    } else {
      setSize(selectedSize);
      setPage(1);
      GetPatientList(1, selectedSize);
    }
  };

  useEffect(() => {
    var filterPatient = unFilteredPatientListData?.filter((patient) => {
      return filters.every((filter) => {
        return (
          patient[filter.name] &&
          patient[filter.name]
            .toString()
            .toUpperCase()
            .startsWith(filter.value.toString().toUpperCase())
        );
      });
    });
    setPatientsList(filterPatient);
    // setSelectedRow(filterPatient[0])
  }, [filters]);

  useEffect(() => {
    if (selectedRow) {
      if (selectedRow.eval_status !== "new") {
        reset({
          untreated_decay: DentalPain.filter(
            (untreatedDecay) =>
              untreatedDecay.dbvalue ==
              selectedRow.untreated_decay?.toLowerCase()
          )[0],
          treated_decay: DentalPain.filter(
            (treated_decay) =>
              treated_decay.dbvalue == selectedRow.treated_decay?.toLowerCase()
          )[0],
          sealants_present: DentalPain.filter(
            (DentalPain) =>
              DentalPain.dbvalue == selectedRow.sealants_present?.toLowerCase()
          )[0],
          treatment_codes: TreatmentCode.filter(
            (untreatedDecay) =>
              untreatedDecay.value == selectedRow.treatment_recommendation_code
          )[0],
          // treatment_codes: '',
          evaluate_images: EvaluateImages.filter(
            (untreatedDecay) =>
              untreatedDecay.isevaluate == selectedRow.evaluate_images
          )[0],
          comment: selectedRow.eval_comments,
        });
      }
      if (selectedRow?.eval_status == "reported") {
        setDisableFields(true);
      } else {
        setDisableFields(false);
      }
    }
  }, [selectedRow]);

  useEffect(() => {
    if (t_codes?.value && selectedRow?.eval_status !== "reported") {
      if (t_codes.value === "CODE 1") {
        setValue("comment", reportCodeStudent.code1);
      } else if (t_codes.value === "CODE 2") {
        setValue("comment", reportCodeStudent.code2);
      } else if (t_codes.value === "CODE 3") {
        setValue("comment", reportCodeStudent.code3);
      } else if (t_codes.value === "CODE 4") {
        setValue("comment", reportCodeStudent.code4);
      } else {
        setValue("comment", "");
      }
    }
  }, [t_codes]);

  useEffect(() => {
    if (evaluationStatus && CustomerAdminDetails && selectedPatientDistrict) {
      setPage(1);
      GetPatientList(1, size);
    }
  }, [evaluationStatus, CustomerAdminDetails, selectedPatientDistrict]);

  useEffect(() => {
    if (
      notificationsList?.length > 0 &&
      !selectedRow &&
      CustomerAdminDetails &&
      selectedPatientDistrict
    ) {
      GetPatientList(page, size);
    }
  }, [notificationsList]);

  useEffect(() => {
    if (selectedPatientFromNotification && CustomerAdminDetails) {
      ShowPatientFromNotification();
    }
  }, [selectedPatientFromNotification, CustomerAdminDetails]);

  useEffect(() => {
    if (selectedPatientFromNotification && patientListData?.length > 0) {
      const selectedPatientIs = patientListData?.filter(
        (patient) => patient.patient_number == selectedPatientFromNotification
      );
      setSelectedRow(selectedPatientIs[0]);
      setTimeout(() => {
        scrollToBottom?.current?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }, [selectedPatientFromNotification, patientListData]);

  const handleReasonChange = (newValue) => {
    setSelectedReason(newValue);
    setValue("treatment_codes", "");
    setValue("comment", "");
  };

  const formatDate = (dateString) => {
    const date = moment(dateString);
    return date.format("D MMM YYYY HH:MM A");
  };

  const rowHeight = 40.8;
  const threshold = 25;
  const tableHeight =
    patientsList?.length > threshold ? `${rowHeight * threshold}px` : "auto";

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalBody>
          <img
            className="cursor"
            src={selectedImage}
            style={{ width: "100%" }}
          />
        </ModalBody>
      </Modal>
      <div 
        className="shadow border rounded-3 p-3 px-md-3 py-md-1 mb-md-4"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div className="table-responsive-xxl pt-3  mb-0 ">
          <div
            className="table-container"
            style={{ maxHeight: tableHeight, overflowY: "auto" }}
          >
            <table className="table  table-responsive border rounded-3 table-hover">
              <thead className="position-sticky top-0 bg-white" ref={tableRef}>
                <tr className="">
                  {patientHeader?.map((headers, index) => (
                    <th
                      className="bg-base-color2 border-0 text-white fs-8"
                      key={index}
                    >
                      <span className="title-font-700 fs-14 text-uppercase text-center">
                        {headers?.header}
                      </span>
                      {headers?.dbkey !== "index" && (
                        <span>
                          {headers?.searchtype === "input" ? (
                            <ReactSearchAutocomplete
                              items={patientsList}
                              fuseOptions={{ keys: [headers?.dbkey] }}
                              resultStringKeyName={headers?.dbkey}
                              onSearch={(event) =>
                                handleSearch(headers?.dbkey, event)
                              }
                              onSelect={(event) =>
                                handleOnSelect(
                                  headers?.dbkey,
                                  event[headers?.dbkey]
                                )
                              }
                              onClear={(event) => handleOnClear(headers?.dbkey)}
                              showIcon={false}
                              placeholder={headers?.header + "..."}
                              className="shadow-none w-100 fs-14 title-font-500 rounded-1 py-0"
                              styling={{
                                borderRadius: "8px",
                                zIndex: 2,
                                fontSize:'0.8rem'
                              }}
                            />
                          ) : headers?.searchtype === "select" ? (
                            <Select
                              options={evalStatus}
                              onChange={handleSelectStatus}
                              isClearable={true}
                              className="mb-2 border-radius-10 shadow-none w-100 fs-12 title-font-500 modal-input text-black"
                            />
                          ) : (
                            <>
                              <Select
                                placeholder=""
                                className="mb-1 border-radius-10 shadow-none w-100 fs-8 title-font-500 modal-input text-black"
                                isDisabled={true}
                              />
                            </>
                          )}
                        </span>
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              {loaders ? (
                <tbody>
                  <tr>
                    <td
                      colSpan="10"
                      className="base-font-600 fs-8 text-black text-center"
                    >
                      Loading...
                    </td>
                  </tr>
                </tbody>
              ) : patientsList?.length > 0 ? (
                <tbody className="">
                  {patientsList?.map((item, index) => (
                    <tr
                      key={item.id}
                      style={{ cursor: "pointer" }}
                      className={`${
                        selectedRow && selectedRow.id === item.id
                          ? selectedRow.dental_pain === "True"
                            ? "selected-row bg-danger"
                            : "selected-row"
                          : "border"
                      }`}
                      onClick={() => handleRowClick(item)}
                    >
                      {patientHeader?.map((columnName, phindex) => (
                        <td
                          key={phindex}
                          className={`base-font-500 fs-14 text-black ${
                            item.dental_pain === "True" ? "bg-danger" : ""
                          }`}
                        >
                          {columnName?.dbkey === "index"
                            ? item.indexNo
                            : columnName?.dbkey === "screening_date"
                            ? formatDate(item.screening_date)
                            : item[columnName.dbkey] == undefined
                            ? "-----------"
                            : item[columnName.dbkey] == null
                            ? "-----------"
                            : item[columnName.dbkey]
                            }
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td
                      colSpan="10"
                      className="base-font-600 fs-8 text-black text-center"
                    >
                      Data not found!
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>

          {patientsList?.length > 0 && (
          <>
            <div className="d-flex justify-content-center m-2">
              <PaginationControl
                page={page}
                between={3}
                total={totalPage}
                limit={size}
                changePage={(page) => {
                  handlePageChange(page);
                }}
                next={true}
                last={true}
                ellipsis={3}
                />
              <select
                className="px-3 py-1 rounded-3 bg-size ms-2 border-light-subtle"
                // value={size}
                value={size === 10000 ? "All" : size}
                onChange={(e) => handleSelectPageSize(e)}
              >
                <option>5</option>
                <option>10</option>
                <option>20</option>
                <option>30</option>
                <option>40</option>
                <option>50</option>
                <option>All</option>
              </select>
              <span ref={scrollToTop}></span>
            </div>
          </>
          )}
        </div>
      </div>
      {selectedRow && selectedRow.eval_status != "new" && (
        <div className="mt-5">
          <div className="p-3 my-3 bg-base-color rounded-1">
            <div className="d-flex justify-content-center align-items-center gap-3 flex-wrap">
              <button
                type="button"
                onClick={handlePreviousClick}
                disabled={selectedRowIndex === 1}
                className="border-0 rounded-1 p-1 me-1 bg-base-color2 text-white title-font-500"
              >
                <ArrowLeft2 size="28" color="#FFFFFF" />
              </button>
              <h6 className="title-font-700 fs-6 mb-0 text-white">
                District:{" "}
                <span className="title-font-500">
                  {selectedRow?.hierarchy_level1}
                </span>
              </h6>
              <h6 className="title-font-700 fs-6 mb-0 text-white">
                School Name:{" "}
                <span className="title-font-500">
                  {selectedRow?.hierarchy_level2}
                </span>
              </h6>

              <h6 className="title-font-700 fs-6 mb-0 text-white">
                Grade:{" "}
                <span className="title-font-500">
                  {selectedRow?.hierarchy_level4}
                </span>
              </h6>

              <h6 className="title-font-700 fs-6 mb-0 text-white">
                Name:{" "}
                <span className="title-font-500">
                  {selectedRow?.first_name} {selectedRow?.last_name}
                </span>
              </h6>

              <h6 className="title-font-700 fs-6 mb-0 text-white">
                Screening Id:{" "}
                <span className="base-font-500">{selectedRow?.number}</span>
              </h6>
              <h6 className="title-font-700 fs-6 mb-0 text-white">
                Dental Pain:{" "}
                <span className="base-font-500">
                  {selectedRow?.dental_pain == "True" ? "Yes" : "No"}
                </span>
              </h6>
              <button
                type="button"
                onClick={handleNextClick}
                disabled={selectedRowIndex === patientsList?.length}
                className="border-0 rounded-1  p-1 ms-1 bg-base-color2 text-white title-font-500"
              >
                <ArrowRight2 size="28" color="#FFFFFF" />
              </button>
            </div>
          </div>
          {!selectedRow?.optout ? (
            <div className="row row-gap-3 justify-content-evenly align-items-center mx-0">
              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-3  text-center border p-3 rounded-3 shadow images_card">
                <h6 className="title-font-600 text-black fs-6  text-black py-b">
                  Front Teeth Image
                </h6>
                <label className="image_overlay">
                  <div className="image_box custom_img_height">
                    <img
                      className="cursor"
                      src={selectedRow?.front_image}
                      onClick={() => handleImageZoom(selectedRow?.front_image)}
                    />
                  </div>
                </label>
              </div>

              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-3  text-center border p-3 rounded-3 shadow images_card">
                <h6 className="title-font-600 text-black fs-6  text-black py-b">
                  Top Teeth Image
                </h6>
                <label className="image_overlay">
                  <div className="image_box custom_img_height">
                    <img
                      className="cursor"
                      src={selectedRow?.top_image}
                      onClick={() => handleImageZoom(selectedRow?.top_image)}
                    />
                  </div>
                </label>
              </div>
              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-3  text-center border p-3 rounded-3 shadow images_card">
                <h6 className="title-font-600 text-black fs-6  text-black py-b">
                  Bottom Teeth Image
                </h6>
                <label className="image_overlay">
                  <div className="image_box custom_img_height">
                    <img
                      className="cursor"
                      src={selectedRow?.bottom_image}
                      onClick={() => handleImageZoom(selectedRow?.bottom_image)}
                    />
                  </div>
                </label>
              </div>
            </div>
          ) : (
            <div className="row row-gap-3 justify-content-evenly align-items-center mx-0">
              <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-3  text-center border p-3 rounded-3 shadow images_card">
                <h6 className="title-font-600 text-black fs-6  text-black py-b">
                  Opt Out Letter
                </h6>
                <label className="image_overlay">
                  <div className="image_box ">
                    <img
                      className="cursor"
                      src={selectedRow?.optout}
                      onClick={() => handleImageZoom(selectedRow?.optout)}
                    />
                  </div>
                </label>
              </div>
            </div>
          )}
          <div className="px-3 py-2 my-4 bg-base-color rounded-1">
            <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="row align-items-center px-0 px-md-2">
                <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-3 pb-3 pt-1">
                  <label
                    htmlFor="type"
                    className="fs-6 title-font-500 mb-0 text-white"
                  >
                    Untreated Decay:
                  </label>
                  <Controller
                    control={control}
                    name="untreated_decay"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={DentalPain}
                        value={value}
                        onChange={onChange}
                        isDisabled={disableFields}
                        className="border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input "
                      />
                    )}
                  />
                </div>
                <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-3 pb-3 pt-1">
                  <label
                    htmlFor="type"
                    className="fs-6 title-font-500 mb-0 text-white"
                  >
                    Treated Decay:
                  </label>
                  <Controller
                    control={control}
                    name="treated_decay"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={DentalPain}
                        value={value}
                        onChange={onChange}
                        isDisabled={disableFields}
                        className="border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input "
                      />
                    )}
                  />
                </div>
                <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-3 pb-3 pt-1">
                  <label
                    htmlFor="type"
                    className="fs-6 title-font-500 mb-0 text-white"
                  >
                    Sealants Present:
                  </label>
                  <Controller
                    control={control}
                    name="sealants_present"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={DentalPain}
                        value={value}
                        onChange={onChange}
                        isDisabled={disableFields}
                        className="border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input "
                      />
                    )}
                  />
                </div>
                <div className="col-11 mx-auto mx-md-0 col-md-6 col-lg-3 pb-3 pt-1">
                  <label
                    htmlFor="type"
                    className="fs-6 title-font-500 mb-0 text-white"
                  >
                    Treatment Recomendation codes:
                  </label>
                  <Controller
                    control={control}
                    name="treatment_codes"
                    rules={{
                      required: selectedReason === null,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={TreatmentCode}
                        value={value}
                        onChange={onChange}
                        isDisabled={selectedReason !== null || disableFields}
                        className="border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input "
                      />
                    )}
                  />
                </div>
                <div className="col-11 mx-auto col-md-12 pb-3 pt-1">
                  <label className="fs-6 title-font-500 mb-0 text-white">
                    Evaluation Comment:
                  </label>
                  <div className="mt-1 d-flex gap-3">
                    <textarea
                      {...register("comment")}
                      placeholder="Enter Comment..."
                      disabled={disableFields}
                      className="p-2 border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input"
                    />
                  </div>
                </div>
                <div className="col-12 mx-auto border-top"></div>
                <div className="col-12 mx-auto mx-md-0 pb-3 pt-2 d-md-flex justify-content-between flex-md-row flex-column">
                  <div>
                    <label
                      htmlFor="type"
                      className="fs-6 title-font-500 mb-2 text-white"
                      style={{ opacity: 0.7 }}
                      onClick={() => setdropToggle(!dropToggle)}
                    >
                      Cannot evaluate the images?{" "}
                      {dropToggle ? (
                        <ArrowUp2 size={20} className="text-white" />
                      ) : (
                        <ArrowDown2 size={20} className="text-white " />
                      )}
                    </label>
                    {dropToggle && (
                      <Controller
                        control={control}
                        name="evaluate_images"
                        render={({ field: { onChange, value } }) => (
                          <Select
                            options={EvaluateImages}
                            value={value}
                            onChange={(newValue) => {
                              handleReasonChange(newValue);
                              onChange(newValue);
                            }}
                            isDisabled={disableFields}
                            className="border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input mb-3 "
                          />
                        )}
                      />
                    )}
                  </div>
                  <div>
                    {loader ? (
                      <button
                        type="button"
                        className="border-0 rounded-1 py-2  px-3 bg-base-color2 text-white title-font-500 d-flex gap-2 align-items-center"
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          disabled
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Submiting . . .
                      </button>
                    ) : (
                      <button
                        className=" border-0 rounded-1 py-2 mb-2 px-3 bg-base-color2 text-white title-font-500"
                        disabled={disableFields}
                      >
                        Submit Screening Results
                      </button>
                    )}
                  </div>
                </div>
                {/* <div className="d-flex align-items-center justify-content-center justify-content-lg-end">
                  
                </div> */}
              </div>
            </Form>
          </div>
        </div>
      )}
    </>
  );
};

export default StudentTable;
