import React, { useEffect, useState } from 'react';
import { Collapse } from 'react-collapse';
import { useForm, Controller } from "react-hook-form";
import { ApiEndPoint } from '../../../constants/apiEndPoints/endPoints';
import axios from 'axios';
import {
    TongueImage,
    SideBiteImage,
    TopImage,
} from "../../../constants/apiEndPoints/ReportCode";
import { Add } from 'iconsax-react';
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import EvaluationResultSleepApnea from '../EvaluationResultSleepApnea';
import ErrorComponents from '../../ErrorComponents/ErrorComponents';
import { ScreeningImageLink, UploadScreeningImages } from '../../../constants/AWS/AWSConfiguration';
import { errorAlert, successAlert, warningAlert } from '../../Alerts/Alerts';
import { selectPatient, sleepApneaSelectPatient } from '../../../redux/Slice/CollectionApp';
import toast from 'react-hot-toast';
export const SleepApneaScreeningContent = ({handleClearSelect}) => {
    const dispatch = useDispatch();
    const {
        register: register1,
        formState: { errors: errors1 },
        handleSubmit: handleSubmit1,
        control,
        setValue,
        reset: reset1,
    } = useForm();

    const [questionsData, setQuestionsData] = useState([]);
    const [sleepApneaTongueImageURL, setSleepApneaTongueImageURL] = useState("");
    const [sleepApneaTopImageURL, setSleepApneaTopImageURL] = useState("");
    const [sleepApneaSideBiteImageURL, setSleepApneaSideBiteImageURL] = useState("");
    const [sleepApneaImages, setSleepApneaImages] = useState();
    const [answerData, setAnswerData] = useState();
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [Loader, setLoader] = useState(false);
    const [combinedDataSleepApnea, setCombinedDataSleepApnea] = useState();

    const sleepApneaSelectedPatient = useSelector(
        (state) => state.collection.sleepApneaSelectedPatient
    );

    const selectedPatient = useSelector(
        (state) => state.collection.selectedPatient
    );

    const CustomerAdminDetails = useSelector(
        (state) => state.login.loggedUserDetails
    );


    const GetQuestions = async () => {
        await axios.get(ApiEndPoint.sleepApneaScreeningQuestions).then((res) => {
            setQuestionsData(res.data);
        });
    };

    const diableForCompletedOrReported = (evalStatus) => {
        if (evalStatus == "completed") {
            return true;
        } else if (evalStatus == "reported") {
            return true;
        }
        return false;
    };

    const disabledTillSubmit = (submitedValue) => {
        if (submitedValue) {
            return true;
        }
        return false;
    };

    const DentalPain = [
        { value: "yes", label: "Yes", dbvalue: "true" },
        { value: "no", label: "No", dbvalue: "false" },
    ];

    const onSubmitSleepApnea = async (sleepScreeningSubmissionData) => {
        setIsFormSubmitted(true);

        if (sleepScreeningSubmissionData?.sleep_apnea_side_bite_image?.length > 0) {
            const sleep_apnea_side_bite_image_url_extension =
                sleepScreeningSubmissionData?.sleep_apnea_side_bite_image[0]?.name.split(
                    "."
                )[1];
            await UploadScreeningImages(
                sleepScreeningSubmissionData?.sleep_apnea_side_bite_image[0],
                `sleep_apnea_side_bite_image_${selectedPatient?.patient_number}.${sleep_apnea_side_bite_image_url_extension}`
            );
            var sleep_apnea_side_bite_image_url = `${ScreeningImageLink}sleep_apnea_side_bite_image_${selectedPatient?.patient_number}.${sleep_apnea_side_bite_image_url_extension}`;
        }

        if (sleepScreeningSubmissionData?.sleep_apnea_tongue_image?.length > 0) {
            const sleep_apnea_tongue_image_url_extension =
                sleepScreeningSubmissionData?.sleep_apnea_tongue_image[0]?.name.split(
                    "."
                )[1];
            await UploadScreeningImages(
                sleepScreeningSubmissionData?.sleep_apnea_tongue_image[0],
                `sleep_apnea_tongue_image_${selectedPatient?.patient_number}.${sleep_apnea_tongue_image_url_extension}`
            );
            var sleep_apnea_tongue_image_url = `${ScreeningImageLink}sleep_apnea_tongue_image_${selectedPatient?.patient_number}.${sleep_apnea_tongue_image_url_extension}`;
        }

        if (sleepScreeningSubmissionData?.sleep_apnea_top_image?.length > 0) {
            const sleep_apnea_top_image_url_extension =
                sleepScreeningSubmissionData?.sleep_apnea_top_image[0]?.name.split(
                    "."
                )[1];
            await UploadScreeningImages(
                sleepScreeningSubmissionData?.sleep_apnea_top_image[0],
                `sleep_apnea_top_image_${selectedPatient?.patient_number}.${sleep_apnea_top_image_url_extension}`
            );
            var sleep_apnea_top_image_url = `${ScreeningImageLink}sleep_apnea_top_image_${selectedPatient?.patient_number}.${sleep_apnea_top_image_url_extension}`;
        }

        sleepScreeningSubmissionData.sleep_apnea_side_bite_image =
            sleep_apnea_side_bite_image_url
                ? sleep_apnea_side_bite_image_url
                : sleepApneaSelectedPatient?.patient_images?.image3;

        sleepScreeningSubmissionData.sleep_apnea_tongue_image =
            sleep_apnea_tongue_image_url
                ? sleep_apnea_tongue_image_url
                : sleepApneaSelectedPatient?.patient_images?.image1;

        sleepScreeningSubmissionData.sleep_apnea_top_image =
            sleep_apnea_top_image_url
                ? sleep_apnea_top_image_url
                : sleepApneaSelectedPatient?.patient_images?.image2;

        sleepScreeningSubmissionData.eval_status = "screened";
        sleepScreeningSubmissionData.patient_number =
            selectedPatient?.patient_number;

        const formattedData = {};
        Object.keys(sleepScreeningSubmissionData).forEach((key) => {
            const match = key.match(/answer-(\d+)/);
            if (match) {
                const questionNumber = match[1];
                const questionKey = `question${questionNumber}`;
                const answerKey = `answer${questionNumber}`;
                formattedData[questionKey] = `${questionNumber}`;
                formattedData[answerKey] = sleepScreeningSubmissionData[key]?.value;
            }
        });
        const payload = {
            ...formattedData,
            eval_status: sleepScreeningSubmissionData.eval_status,
            patient_id: selectedPatient.id,
            sleep_apnea_tongue_image:
                sleepScreeningSubmissionData.sleep_apnea_tongue_image,
            sleep_apnea_top_image: sleepScreeningSubmissionData.sleep_apnea_top_image,
            sleep_apnea_side_bite_image:
                sleepScreeningSubmissionData.sleep_apnea_side_bite_image,
        };

        axios
            .post(ApiEndPoint.sleepApneaScreeningDocCollection, payload)
            .then((response) => {
                if (response.data.status === "success") {
                    dispatch(sleepApneaSelectPatient(response.data.doc_data));
                    successAlert("Data collection is completed.");
                    reset1();
                    clear();
                    setIsFormSubmitted(false);
                } else if (response.data.message === "doc is already collected") {
                    warningAlert(response.data.message);
                    reset1();
                    clear();
                    setIsFormSubmitted(false);
                } else {
                    warningAlert("Data collection is not completed.");
                    setIsFormSubmitted(false);
                }
            })
            .catch((err) => {
                errorAlert(err.message);
                setIsFormSubmitted(false);
            });
    };

    const handleSleepApneaTongueImage = (sleepApneaTongueImage) => {
        setSleepApneaTongueImageURL();
        if (sleepApneaTongueImage.length > 0) {
            const sleepApneaTongueImageCreatedURL = URL.createObjectURL(
                sleepApneaTongueImage[0]
            );
            setSleepApneaTongueImageURL(sleepApneaTongueImageCreatedURL);
        }
    };

    const handleSleepApneaTopImage = (sleepApneaTopImage) => {
        setSleepApneaTopImageURL();
        if (sleepApneaTopImage.length > 0) {
            const sleepApneaTopImageCreatedURL = URL.createObjectURL(
                sleepApneaTopImage[0]
            );
            setSleepApneaTopImageURL(sleepApneaTopImageCreatedURL);
        }
    };

    const handleSleepApneaSideBiteImage = (sleepApneaSideBiteImage) => {
        setSleepApneaSideBiteImageURL();
        if (sleepApneaSideBiteImage.length > 0) {
            const sleepApneaSideBiteImageCreatedURL = URL.createObjectURL(
                sleepApneaSideBiteImage[0]
            );
            setSleepApneaSideBiteImageURL(sleepApneaSideBiteImageCreatedURL);
        }
    };

    const screening = (evalStatus) => {
        if (evalStatus == "completed") {
            return false;
        } else if (evalStatus == "reported") {
            return false;
        }
        return true;
    };

    const clear = () => {
        reset1({
            name: "",
            schoolname: "",
            grade: "",
            school_teacher: "",
            studentlistc: "",
            top_teeth: "",
            gmail1: "",
            front_teeth: "",
            email2: "",
            dentalpain: "",
            bottom_teeth: "",
            Opt_Out_Letter: "",
            gender: "",
            fname: "",
            lname: "",
            contact: "",
            email: "",
            address: "",
            emr: "",
            sleep_apnea_side_bite_image: "",
            sleep_apnea_tongue_image: "",
            sleep_apnea_top_image: "",
        });
        toast.success("Form Clear successfully!");
        setSleepApneaTongueImageURL("");
        setSleepApneaTopImageURL("");
        setSleepApneaSideBiteImageURL("");
        dispatch(sleepApneaSelectPatient());
        dispatch(selectPatient())
        handleClearSelect()
    };

    const GetSleepApneaImages = async () => {
        axios
            .get(
                `${ApiEndPoint.sleepApneaScreeningPatientImages}${selectedPatient?.id}`
            )
            .then((res) => {
                setSleepApneaImages(res.data.data);
                setSleepApneaTongueImageURL(res.data.data.image1);
                setSleepApneaTopImageURL(res.data.data.image2);
                setSleepApneaSideBiteImageURL(res.data.data.image3);
            })
            .catch((err) => {
                setSleepApneaTongueImageURL("");
                setSleepApneaTopImageURL("");
                setSleepApneaSideBiteImageURL("");
            });
    };

    const GetSleepApneaCombinedData = async () => {
        await axios
            .get(
                `${ApiEndPoint.sleepApneaCombinedQNAImagesResult}${selectedPatient?.id}`
            )
            .then((res) => {
                setCombinedDataSleepApnea(res.data);
                dispatch(sleepApneaSelectPatient(res.data));
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const GetSleepApneaData = async () => {
        axios
            .get(`${ApiEndPoint.sleepApneaScreeningPatientQNA}${selectedPatient?.id}`)
            .then((res) => {
                if (res.data.data) {
                    setAnswerData(res.data.data);

                    // const resetData = res.data.data.reduce((acc, obj) => {
                    //     const key = `answer-${obj.question}`;
                    //     acc[key] = obj.response ? obj.response : "";
                    //     return acc;
                    // }, {});
                    const resetData = res.data.data.reduce((acc, obj) => {
                        const key = `answer-${obj.question}`;
                        acc[key] = obj.response ? { label: obj.response, value: obj.response } : ""
                        return acc;
                    }, {});


                    setTimeout(()=>{
                        reset1(resetData);
                    },2000)

                } else {
                    setAnswerData([]);
                }
            })
            .catch((err) => {
                setAnswerData([]);
            });
    };

    const handleSendReportSleep = async (patient) => {

        setLoader(true);
        const sendMailPayloadSleepApnea = {
            to: patient.email,
            branch_id: CustomerAdminDetails?.branch?.id,
            code: sleepApneaSelectedPatient?.patient_images
                ?.treatment_recommendation_code,
            comments: sleepApneaSelectedPatient?.patient_image_result?.comments,
            patient_id: patient.id,
        };

        axios
            .post(ApiEndPoint.sleepApneaSendReportMail, sendMailPayloadSleepApnea)
            .then((sendMailResponse) => {
                if (sendMailResponse.data.status === "success") {
                    toast.success("Report sent");
                    setLoader(false);
                    GetSleepApneaCombinedData();
                } else {
                    toast.error("Report not sent");
                    setLoader(false);
                }
            })
            .catch((error) => {
                setLoader(false);
            });
    };

    const answerValue = (question) => {
        if (selectedPatient && answerData) {
            const result = answerData.find((answer) => answer.question === question);
            if (result) {
                if (result.response == 'yes') {
                    return { label: 'Yes', value: 'yes' }
                } else if (result.response == 'no') {
                    return { label: 'No', value: 'no' }
                } else {
                    return { label: 'Please Select', value: '' }
                }
            } else {
                return { label: 'Please Select', value: '' }
            }

        } else {
            return { label: 'Please Select', value: '' }
        }
    };

    useEffect(() => {
        GetQuestions();
    }, []);

    useEffect(() => {
        if (selectedPatient) {
            if (selectedPatient?.id) {
                GetSleepApneaImages();
                GetSleepApneaData();
                GetSleepApneaCombinedData();
            }
        }
    }, [selectedPatient]);

    return (
        <div className="row px-0 px-md-2 p-lg-3 pb-lg-4 shadow mt-4 rounded-2 border mx-0">
            <form onSubmit={handleSubmit1(onSubmitSleepApnea)}>
                <div
                    className="d-flex justify-content-between align-items-center px-2 px-lg-2 mt-4 mb-4"
                    style={{ cursor: "pointer" }}
                >
                    <h5 className="title-font-bold text-base-color fs-5 pb-1 mb-0 border-bottom-color-2">
                        SLEEP APNEA SCREENING
                    </h5>

                </div>
               
                <Collapse isOpened={true}>
                    <div className="px-2 px-lg-2 mt-4">
                        <p className="base-font-600 fs-5 text-base-gray pt-1">
                            Sleep Apnea Screening Questions
                        </p>
                    </div>
                    <div className="container">
                        <div className="row flex-wrap g-3">
                            {questionsData.map((question) => (
                                <div
                                    key={question.id}
                                    className="col-11 mx-auto mx-md-0 col-md-6 col-lg-4 d-flex flex-column justify-content-between pb-2"
                                >
                                    <label
                                        htmlFor={`question-${question.id}`}
                                        className="fs-14 title-font-600 mb-0 text-black"
                                    >
                                        {question.question_list}
                                    </label>
                                    <div>
                                        <Controller
                                            control={control}
                                            name={`answer-${question.id}`}
                                            rules={{
                                                required: true,
                                                message: "This is a required field *",
                                            }}
                                            render={({ field: { onChange, value } }) => {
                                                return (
                                                    <>
                                                        <Select
                                                            options={DentalPain}
                                                            value={value}
                                                            onChange={onChange}
                                                            className="border-radius-10 shadow-none w-100 fs-6 title-font-500 modal-input"
                                                        />
                                                    </>
                                                );
                                            }}
                                        />
                                        {/* <select {...register1(`answer-${question.id}`, { required: true })} className='w-100 px-2 py-2 rounded modal-input'>
                                            <option value={""}>Please Select</option>
                                            {
                                                DentalPain.map((data, index) => {
                                                    return (
                                                        <option key={index} value={data.value}>{data.label}</option>
                                                    )
                                                })
                                            }
                                        </select> */}
                                        {errors1[`answer-${question.id}`] && (
                                            <ErrorComponents
                                                error={"This is a required field *"}
                                            />
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="col-12 mx-auto border-top my-3 border-2"></div>

                    <div className="px-2 px-lg-2 mt-2">
                        <p className="base-font-700 fs-16 text-base-gray pt-1">
                            Please have your patient in good lighting and take the pictures
                            as shown . You can refer to this{" "}
                            <a
                                className="text-base-color title-font-600 "
                                href="https://www.youtube.com/watch?v=ZRb-4HpAE9Y"
                                target="_blank"
                            >
                                Video
                            </a>{" "}
                            on how to take the best photos for screening.
                        </p>
                    </div>

                    <div className="row row-gap-3 mt-5 mx-auto">
                        <div className="col mx-auto mx-md-0 col-md-6 col-lg-4 col-xl-4 text-center">
                            <div className="col mx-auto mb-4 text-center">
                                <h6 className="title-font-600 text-black fs-6">
                                    Throat Demo Image*
                                </h6>
                                <label className="image_overlay">
                                    <div className="image_box ">
                                        <img
                                            src={TongueImage}
                                            alt="home_wrapper"
                                            className="img-fluid rounded-3 "
                                        />
                                    </div>
                                </label>
                            </div>
                            {sleepApneaTongueImageURL ? (
                                <label
                                    htmlFor={
                                        screening(sleepApneaImages?.eval_status)
                                            ? "Sleep_Apnea_Tongue_Image"
                                            : ""
                                    }
                                    className={
                                        screening(sleepApneaImages?.eval_status)
                                            ? "image_overlay cursor"
                                            : "image_overlay"
                                    }
                                    title="Sleep Apnea Tongue Image"
                                >
                                    <figure className=" position-relative">
                                        <img
                                            src={sleepApneaTongueImageURL}
                                            alt="Sleep Apnea Tongue Image"
                                            className="img-fluid rounded-3 "
                                        />
                                    </figure>
                                </label>
                            ) : (
                                <label
                                    htmlFor="Sleep_Apnea_Tongue_Image"
                                    className="images_card_box"
                                    title="Sleep Apnea Tongue Image"
                                >
                                    <div className="figcaptions">
                                        <h6 className="base-font-600 text-white  ">
                                            <span>
                                                <Add size={24} className="text-white" />
                                            </span>{" "}
                                            Click Here to Add Sleep Apnea Throat Photo.
                                        </h6>
                                    </div>
                                </label>
                            )}
                            <div className="">
                                <input
                                    id="Sleep_Apnea_Tongue_Image"
                                    className="d-none"
                                    type="file"
                                    title="Sleep Apnea Tongue Image"
                                    placeholder="Upload Images..."
                                    accept="image/*"
                                    {...register1("sleep_apnea_tongue_image", {
                                        required: sleepApneaSelectedPatient?.patient_images?.image1
                                            ? false
                                            : "Sleep Apnea Throat Image*",
                                        onChange: (e) => {
                                            handleSleepApneaTongueImage(e.target.files);
                                        },
                                    })}
                                />
                                {errors1.sleep_apnea_tongue_image && (
                                    <ErrorComponents
                                        error={errors1.sleep_apnea_tongue_image?.message}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="col mx-auto mx-md-0 col-md-6 col-lg-4 col-xl-4 text-center">
                            <div className="col mx-auto mb-4 text-center">
                                <h6 className="title-font-600 text-black fs-6">
                                    Top Palete Demo Image*
                                </h6>
                                <label className="image_overlay">
                                    <div className="image_box ">
                                        <img
                                            src={TopImage}
                                            alt="home_wrapper"
                                            className="img-fluid rounded-3 "
                                        />
                                    </div>
                                </label>
                            </div>
                            {sleepApneaTopImageURL ? (
                                <label
                                    htmlFor={
                                        screening(sleepApneaImages?.eval_status)
                                            ? "Sleep_Apnea_Top_Image"
                                            : ""
                                    }
                                    className={
                                        screening(sleepApneaImages?.eval_status)
                                            ? "image_overlay cursor"
                                            : "image_overlay"
                                    }
                                    title="Sleep Apnea Top Image"
                                >
                                    <figure className=" position-relative">
                                        <img
                                            src={sleepApneaTopImageURL}
                                            alt="Sleep Apnea Top Image"
                                            className="img-fluid rounded-3 "
                                        />
                                    </figure>
                                </label>
                            ) : (
                                <label
                                    htmlFor="Sleep_Apnea_Top_Image"
                                    className="images_card_box"
                                    title="Sleep Apnea Top Image"
                                >
                                    <div className="figcaptions">
                                        <h6 className="base-font-600 text-white  ">
                                            <span>
                                                <Add size={24} className="text-white" />
                                            </span>{" "}
                                            Click Here to Add Sleep Apnea Top Palate Photo.
                                        </h6>
                                    </div>
                                </label>
                            )}
                            <div className="">
                                <input
                                    id="Sleep_Apnea_Top_Image"
                                    className="d-none"
                                    type="file"
                                    title="Sleep Apnea Top Image"
                                    placeholder="Upload Images..."
                                    accept="image/*"
                                    {...register1("sleep_apnea_top_image", {
                                        required: sleepApneaSelectedPatient?.patient_images?.image2
                                            ? false
                                            : "Sleep Apnea Top Palete Image*",
                                        onChange: (e) => {
                                            handleSleepApneaTopImage(e.target.files);
                                        },
                                    })}
                                />
                                {errors1.sleep_apnea_top_image && (
                                    <ErrorComponents
                                        error={errors1.sleep_apnea_top_image?.message}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="col mx-auto mx-md-0 col-md-6 col-lg-4 col-xl-4 text-center">
                            <div className="col mx-auto mb-4 text-center">
                                <h6 className="title-font-600 text-black fs-6">
                                    Side Bite Demo Image*
                                </h6>
                                <label className="image_overlay">
                                    <div className="image_box ">
                                        <img
                                            src={SideBiteImage}
                                            alt="home_wrapper"
                                            className="img-fluid rounded-3 "
                                        />
                                    </div>
                                </label>
                            </div>
                            {sleepApneaSideBiteImageURL ? (
                                <label
                                    htmlFor={
                                        screening(sleepApneaImages?.eval_status)
                                            ? "Sleep_Apnea_Side_Bite_Image"
                                            : ""
                                    }
                                    className={
                                        screening(sleepApneaImages?.eval_status)
                                            ? "image_overlay cursor"
                                            : "image_overlay"
                                    }
                                    title="Sleep Apnea Side Bite Image"
                                >
                                    <figure className=" position-relative ">
                                        <img
                                            src={sleepApneaSideBiteImageURL}
                                            alt="Sleep Apnea Side Bite Image"
                                            className="img-fluid rounded-3 "
                                        />
                                    </figure>
                                </label>
                            ) : (
                                <label
                                    htmlFor="Sleep_Apnea_Side_Bite_Image"
                                    className="images_card_box"
                                >
                                    <div
                                        className="figcaptions"
                                        title="Sleep Apnea Side Bite Image"
                                    >
                                        <h6 className="base-font-600 text-white  ">
                                            <span>
                                                <Add size={24} className="text-white" />
                                            </span>{" "}
                                            Click Here to Add Sleep Apnea Side Bite Photo
                                        </h6>
                                    </div>
                                </label>
                            )}
                            <div className="">
                                <input
                                    id="Sleep_Apnea_Side_Bite_Image"
                                    className="d-none"
                                    type="file"
                                    title="Sleep Apnea Side Bite Image"
                                    placeholder="Upload Images..."
                                    accept="image/*"
                                    {...register1("sleep_apnea_side_bite_image", {
                                        required: sleepApneaSelectedPatient?.patient_images?.image3
                                            ? false
                                            : "Sleep Apnea Side Bite Image*",
                                        onChange: (e) => {
                                            handleSleepApneaSideBiteImage(e.target.files);
                                        },
                                    })}
                                />
                                {errors1.sleep_apnea_side_bite_image && (
                                    <ErrorComponents
                                        error={errors1.sleep_apnea_side_bite_image?.message}
                                    />
                                )}
                            </div>
                        </div>
                        <EvaluationResultSleepApnea />
                    </div>
                    <div className="d-flex align-items-center justify-content-center justify-content-md-end gap-3 pt-3 mb-4 mt-2 flex-wrap flex-md-nowrap">
                        {(sleepApneaSelectedPatient?.patient_images?.eval_status ==
                            "completed" || sleepApneaSelectedPatient?.patient_images?.eval_status ==
                            "reported") && (
                                <>
                                    {Loader ? (
                                        <button
                                            type="button"
                                            className="submit-btn d-flex gap-2 align-items-center"
                                        >
                                            <span
                                                className="spinner-border spinner-border-sm"
                                                disabled
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                            Sending . . .
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="submit-btn"
                                            onClick={() => handleSendReportSleep(selectedPatient)}
                                        >
                                            Send Report
                                        </button>
                                    )}
                                </>
                            )}
                        {isFormSubmitted ? (
                            <button
                                type="button"
                                className="submit-btn d-flex gap-2 align-items-center"
                            >
                                <span
                                    className="spinner-border spinner-border-sm"
                                    disabled
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                Submitting . . .
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className="submit-btn"
                                disabled={diableForCompletedOrReported(
                                    sleepApneaSelectedPatient?.patient_images?.eval_status
                                )}
                            >
                                Submit
                            </button>
                        )}
                        <button
                            type="button"
                            className="close-btn"
                            onClick={() => clear()}
                            disabled={disabledTillSubmit(isFormSubmitted)}
                        >
                            Clear
                        </button>
                    </div>
                </Collapse>
            </form>
        </div>
    )
}
