import DataTable, { TableColumn } from "react-data-table-component";
import React, { useState, useEffect } from "react";
import "../../../css/Table.css";
import { useDispatch } from "react-redux";
import { setSelectedImportStudent } from "../../../redux/Slice/CollectionApp";

const customStyles = {
    rows: {
        style: {
            minHeight: "68px",
        },
    },
    headCells: {
        style: {
            fontSize: "5rem",
        },
    },
    cells: {
        style: {
            paddingLeft: "20px",
            paddingRight: "20px",
            color: "gray"
        },
    },
    contextMenu: {
        style: {
            fontSize: "18px",
            fontWeight: 400,
            paddingLeft: "16px",
            paddingRight: "8px",
            transform: "translate3d(0, -100%, 0)",
            transitionDuration: "125ms",
            transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
            willChange: "transform",
            backgroundColor: "red",
        },
        activeStyle: {
            transform: "translate3d(0, 0, 0)",
        },
    },
};


const Table2 = ({ columns, data }) => {
    const dispatch = useDispatch();
    const [pending, setPending] = useState(true);
    const handleChange = ({ selectedRows }) => {
        dispatch(setSelectedImportStudent(selectedRows));
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setPending(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, []);
    const id = "my-table2";
    return (
        <DataTable
            columns={columns}
            data={data}
            customStyles={customStyles}
            highlightOnHover
            selectableRows
            onSelectedRowsChange={handleChange}
            progressPending={pending}
            id={id}
        />
    );
};

export default Table2;
